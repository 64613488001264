<template>
  <li :id="`cart-item-${cartItem.sku}`">
    <div class="flex-start mb">
      <div class="no-shrink mr-quarter">
        <svg-icon-component :icon="getIcon(cartItem.sku)" class="icon-native-fill" />
      </div>
      <div class="grow mr-half truncate">
        <h3 :id="createId('name')" class="mb-half">
          {{ t(`Brand.${isStandAloneWebrootSku ? "WSAV" : cartItem?.sku}`) }}
        </h3>
        <NotableFeature :cart-item="props.cartItem" />
        <p v-if="buyFlowStore.isFlatRateUpgrade" class="text-grey">
          {{ tn("appliesToRemainder") }}
        </p>
        <div v-else>
          <select
            v-if="!isPageStateReviewOrder && showSelect()"
            id="selectPlanLength"
            v-model="selectedRatePlanId"
            autocomplete="off"
            class="select-css constrain-40 mb-half"
          >
            <option v-for="r in orderedRatePlans" :key="r.ratePlanId" :value="r.ratePlanId">
              {{ getTermDescriptionForRatePlan(r) }}
            </option>
          </select>
          <p v-else-if="isPageStateReviewOrder || !isStandAloneWebrootSku" id="ratePlanDescription" class="text-grey">
            {{ getTermDescriptionForRatePlan(selectedRatePlan) }}
          </p>
        </div>
        <div v-if="showStepper(cartItem)" class="flex-start align-items-center">
          <div class="mr-half">{{ tn("quantity") }}</div>
          <QuantityStepper
            v-if="!buyFlowStore.isRenewOrUpgrade"
            :initial-number="quantity"
            :sku="cartItem.sku"
            @increase="quantity += 1"
            @decrease="quantity += -1"
          />
        </div>
        <div v-else-if="buyFlowStore.getMaxQuantityBySku(cartItem.sku) === 1">
          <p id="quantityMax1" class="mr-half text-grey">{{ tn("quantity") }} {{ quantity }}</p>
        </div>
        <div v-else-if="fromCarbonite || buyFlowStore.isRenewOrUpgrade">
          <p id="quantityFromCarb" class="mr-half text-grey">{{ tn("quantity") }} {{ quantity }}</p>
        </div>
        <div v-if="cartItem.expirationDate && isPageStateReviewOrder">
          <p class="text-grey">
            {{ t("BuyReceiptComponent.subscriptionExpiresOn", { date: formatDateString(cartItem.expirationDate) }) }}
          </p>
        </div>
        <div
          v-if="
            isWebrootSku &&
            FLAGS.ENABLE_BUYFLOW_WEBROOT &&
            !isStandAloneWebrootSku &&
            buyFlowStore.pageState === pageStates.email
          "
        >
          <button
            :id="`btnRemove${cartItem.sku}`"
            class="btn-link left-link"
            type="button"
            @click="buyFlowStore.removeFromCart(props.cartItem, props.index)"
          >
            {{ t("Common.remove") }}
          </button>
        </div>
      </div>
      <div class="amount">
        <strong :id="createId('itemSubtotal')">{{ formatMoney(cartItem?.total || 0) }}</strong>
        <span
          v-if="!buyFlowStore.isRenewOrUpgrade && !buyFlowStore.isFlatRateUpgrade && showStrikeThroughPrice"
          :id="createId('strikeThroughPrice')"
          class="strike text-sm text-grey block text-right"
        >
          {{ formatMoney(strikeThroughPrice) }}
        </span>
      </div>
    </div>
    <div class="indent mb-double">
      <BundleItems
        v-if="!buyFlowStore.planetOpenTextDiscountApplied"
        :bundle-items="bundledItems"
        :cart-item="props.cartItem"
      />
      <Adjustments :adjustments="cartItem.adjustments || []" />
    </div>
  </li>
</template>

<script setup lang="ts">
import { ICartItem } from "@/common/api/unifiedPortal/interfaces";
import { ProductCatalogPriceType, terms } from "@/components/Buy/BuyEnums";
import { computed, PropType, ref, watch, onMounted } from "vue";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { t } from "@/i18n";
import { formatMoney } from "@/components/Buy/commonFn";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import Adjustments from "@/components/Buy/Adjustments.vue";
import QuantityStepper from "@/components/Buy/QuantityStepper.vue";
import { DEFAULT_TERM_LENGTH_MONTHS, MONTHS_IN_YEAR, NUMERIC_YEAR } from "@/define";
import { FLAGS } from "@/define";
import BundleItems from "@/components/Buy/Cart/BundleItems.vue";
import { isMobile } from "@/common/browserDetect";
import { getComputerName } from "@/components/Buy/BuyHelper";
import NotableFeature from "@/components/Buy/Cart/NotableFeature.vue";
import { debounce } from "@/common/helper";
import { pageStates } from "@/components/Buy/BuyEnums";
import { useRoute } from "vue-router";
import { IProductIcons } from "../Interfaces";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";
import { useUserStore } from "@/stores/user";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useUnauthenticatedBuyFlowStore } from "@/stores/unauthorizedBuyflow";
import { formatDateString } from "@/common/dateFormat";
import { googleTagCartEvents } from "@/common/googleTagEvents";

//had to change from Enum since typescript no longer allows enums to have the same value for multiple items
const productIcons = ref<IProductIcons>({
  WebrootSoftwareInstallationCD: "webroot-icon",
  WebrootTotalProtection: "webroot-icon",
  WebrootTotalProtectionFamily: "webroot-icon",
  WebrootGamer: "webroot-icon",
  WebrootSecureVPN: "webroot-wifi-security",
  WebrootChromebook: "webroot-icon",
  WebrootAntiVirus: "webroot-icon",
  WebrootPlus: "webroot-icon",
  WebrootComplete: "webroot-icon",
  WebrootCompleteFamily: "webroot-icon",
  PersonalBasic: "carb-icon",
  PersonalPlus: "carb-icon",
  PersonalPrime: "carb-icon",
  WebrootPremium: "webroot-icon",
  WebrootPremiumFamily: "webroot-icon",
  AllstateIdentityProtectionBasic: "icon-allstate",
  AllstateIdentityProtectionPremium: "icon-allstate",
  AllstateIdentityProtectionPremiumFamily: "icon-allstate",
  AndroidMobile: "webroot-security-antivirus-premier",
  WindowWasher: "window-washer",
  WebrootSmallBusinessAntiVirus: "webroot-small-business-antivirus",
  WebrootPcOptimizer: "webroot-pc-optimizer",
  Webroot: "webroot-icon",
  WebrootSecureVPNP: "webroot-secure-vpn",
  WebrootEssentials: "webroot-icon",
});

const buyFlowStore = useBuyFlowStore();
const unauthenticatedBuyflowStore = useUnauthenticatedBuyFlowStore();
const userStore = useUserStore();
const subscriptionStore = useSubscriptionsStore();
const computerName = ref<string>("");
const componentName = "CartItem";
const route = useRoute();
const showStrikeThroughPrice = ref<boolean>(false);
const fromCarbonite = computed(() => route.query.referralUrl?.toString().includes("carbonite"));

const props = defineProps({
  cartItem: {
    type: Object as PropType<ICartItem>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

const quantity = ref(props.cartItem.quantity);
const bundledItems = ref<ICartItem[]>(props.cartItem?.bundledItems || []);
onMounted(() => {
  if (cartItem.value.computerId > 0) {
    computerName.value = getComputerName(cartItem.value.computerId);
  }
  selectedRatePlanId.value = cartItem.value.ratePlanId;
  //Make sure to call this function to show the "Add CD" link in the cart for renews and upgrades
  updateBundleItems();
});

const cartItem = computed(() => {
  return props.cartItem;
});

//This needs to be set to empty string so the computed variables using it will trigger on load
const selectedRatePlanId = ref<string>("");

const product = computed(() => {
  return buyFlowStore.getCatalogItemBySku(cartItem.value.sku);
});

const hasSingleRatePlan = computed(() => {
  return product.value?.ratePlans.length === 1;
});

// Checks if the SKU is the Webroot Standalone SKU sold by Carbonite
// TODO: We should remove this when starting to sell Webroot via Zuora
const isStandAloneWebrootSku = computed(() => {
  return cartItem.value.sku === "Webroot";
});

const isMobileClient = computed(() => {
  return isMobile();
});

const selectedRatePlan = computed(() => {
  return buyFlowStore.getRatePlanInfo(props.cartItem.ratePlanId);
});

const userIsLoggedIn = computed(() => {
  return userStore.currentUser;
});

const orderedRatePlans = computed(() => {
  const currentRatePlan = buyFlowStore.getRatePlanInfo(cartItem.value.ratePlanId);
  let ratePlans = buyFlowStore.getRatePlansForSku(product.value?.sku ?? "");

  if (currentRatePlan?.includedUnits) {
    ratePlans = ratePlans?.filter(l => l.includedUnits === currentRatePlan.includedUnits);
  }

  if (buyFlowStore.isRenewOrUpgrade) {
    let distributionCode = "";
    if (userIsLoggedIn.value) {
      //Find the sub to get the retail code
      const sub = subscriptionStore.subscriptions.find(l => l.computer?.id === cartItem.value.computerId);
      distributionCode = sub?.carbLicenseDistributionMethodCode || "";
    } else {
      if (!unauthenticatedBuyflowStore.subscription && cartItem.value.computerId > 0) {
        unauthenticatedBuyflowStore.populateSubscription(cartItem.value.computerId);
      }
      const sub = unauthenticatedBuyflowStore.subscription;
      if (sub) {
        distributionCode = sub.carbLicenseDistributionMethodCode;
      }
    }
    //Filter to 1 year if setting is turned on for Distribution type
    if (
      distributionCode &&
      canDisplayPerSubscriptionFeature(perSubscriptionFeatures.restrictRenewalToOneYear, distributionCode)
    ) {
      ratePlans = ratePlans.filter(l => l.months === MONTHS_IN_YEAR);
    }
  }

  return ratePlans.sort((a, b) => a.months - b.months);
});

const ratePlansYearlyPriceAreAllEqual = computed(() => {
  const priceToUse = buyFlowStore.isRenewOrUpgrade
    ? ProductCatalogPriceType.CatalogPrice
    : ProductCatalogPriceType.NewSubscriptionPrice;
  //If there is a rate plan months that is not divisible by 12 skip this.
  if (orderedRatePlans.value.some(l => (l.months / MONTHS_IN_YEAR) % 1 !== 0)) {
    return false;
  }

  const yearlyPlan = orderedRatePlans.value.find(l => l.months === MONTHS_IN_YEAR);
  const yearlyPlanPrice = Math.round(yearlyPlan ? yearlyPlan[priceToUse] : 0);
  const multiYearPlans = orderedRatePlans.value.filter(l => l.ratePlanId !== yearlyPlan?.ratePlanId);

  return multiYearPlans.every(l => {
    const numberOfYears = l.months / MONTHS_IN_YEAR;
    return Math.round(l[priceToUse] / numberOfYears) === yearlyPlanPrice;
  });
});

const strikeThroughPrice = computed(() => {
  var catalogPrice = buyFlowStore.getRatePlanInfo(selectedRatePlanId.value)?.catalogPrice || 0;
  return catalogPrice * cartItem.value.quantity;
});

const isWebrootSku = computed(() => {
  return buyFlowStore.isWebrootSku(cartItem.value.sku);
});

const isPageStateReviewOrder = computed(() => {
  return buyFlowStore.pageState === pageStates.reviewOrder;
});

function createId(prefix: string) {
  return `${prefix}_${props.index}`;
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function getTermDescriptionForRatePlan(ratePlan) {
  const priceToUse = buyFlowStore.isRenewOrUpgrade
    ? ProductCatalogPriceType.CatalogPrice
    : ProductCatalogPriceType.NewSubscriptionPrice;
  const termValue =
    ratePlan.months > DEFAULT_TERM_LENGTH_MONTHS
      ? ratePlan.months / DEFAULT_TERM_LENGTH_MONTHS
      : ratePlan.months === DEFAULT_TERM_LENGTH_MONTHS
        ? NUMERIC_YEAR
        : 0;

  // if termValue is zero, do not show anything.
  const termValueString = termValue === 0 ? "" : termValue.toString();

  const RatePlanTermType =
    ratePlan.months > DEFAULT_TERM_LENGTH_MONTHS
      ? terms.year
      : ratePlan.months === DEFAULT_TERM_LENGTH_MONTHS
        ? terms.year
        : terms.monthToMonth;

  if (isMobileClient.value) {
    return `${termValueString} ${t(`Common.${RatePlanTermType?.toString()}`)} ${t("CartComponent.subscription")}`;
  } else {
    if (!ratePlansYearlyPriceAreAllEqual.value) {
      return `${termValueString} ${t(`Common.${RatePlanTermType?.toString()}`)} ${t(
        "CartComponent.subscription"
      )} (${formatMoney(ratePlan[priceToUse] / termValue)} ${tn("perYear")})`;
    } else {
      return `${termValueString} ${t(`Common.${RatePlanTermType?.toString()}`)} ${t("CartComponent.subscription")} ${formatMoney(ratePlan[priceToUse])}`;
    }
  }
}

watch(quantity, debounce(updateQuantityInCart, 500));

function updateQuantityInCart() {
  const cartItem = buyFlowStore.cart?.items.find(i => i.ratePlanId === selectedRatePlanId.value);

  if (cartItem) {
    cartItem.quantity = quantity.value;
    buyFlowStore.updateShoppingCartTotal(false, false, googleTagCartEvents.change);
  }
}

function getIcon(sku: string): string {
  return productIcons.value[sku];
}

const showQuantity = computed(() => {
  return buyFlowStore.getMaxQuantityBySku(cartItem.value.sku) > 1 ? true : false;
});

function showStepper(cartItem) {
  // we want to shop the quantity stepper if the maximum quantity allowed is more than one and this is not a renewal
  // we also want to show the quantity stepper if the webroot product we're selling is the webroot standalone SKU sold on carbonite (that we're not selling any more) and we're on the email page
  //however, if we came from carbonite.com, then don't show the quantity selector regardless of the other conditions because the user has already picked their quantity at carbonite.com
  const cartItemPlan = buyFlowStore.getMaxQuantityBySku(cartItem.sku);
  return (
    cartItemPlan > 1 &&
    ((!cartItem.computerId && showQuantity && !fromCarbonite.value) ||
      (isStandAloneWebrootSku.value && buyFlowStore.pageState === pageStates.email && !fromCarbonite.value))
  );
}

function showSelect() {
  if (buyFlowStore.planetOpenTextDiscountApplied) {
    return false;
  }
  // we want to shop the duration select if the duration allowed is more than one and this is not the the webroot standalone SKU old on carbonite
  // we also want to show the duration select if we're on the email page
  //however, if we came from carbonite.com, then don't show the duration select regardless of the other conditions because the user has already picked the duration at carbonite.com
  return (
    ((!hasSingleRatePlan.value && !isStandAloneWebrootSku.value) || buyFlowStore.pageState === pageStates.email) &&
    !fromCarbonite.value &&
    !buyFlowStore.hasWebrootRenewOnly
  );
}

watch(selectedRatePlanId, () => {
  // selectedRatePlanId is set to undefined during google auto fill CC
  // happening in chrome update(v119), setting the rate plan from cart item
  if (!selectedRatePlanId.value) {
    selectedRatePlanId.value = cartItem.value.ratePlanId;
    return;
  }
  const item = buyFlowStore.cart?.items.find(i => i.ratePlanId === props.cartItem.ratePlanId);
  // showStrikeThroughPrice is getting calculated only cartItem update, now it will be calculated on onMounted event
  showStrikeThroughPrice.value = formatMoney(cartItem.value.total) != formatMoney(strikeThroughPrice.value);
  if (item && item.ratePlanId != selectedRatePlanId.value) {
    item.ratePlanId = selectedRatePlanId.value;
    buyFlowStore.updateShoppingCartTotal();
  }
});

//Need this watch to set the selected term after a remove happens
watch(cartItem, () => {
  selectedRatePlanId.value = cartItem.value.ratePlanId;
  //Javascript multiplication of 3 * 95.99, 6 * 95.99 gives 10 decimal values & cartItem.total(backend value) gives number rounding to two.
  showStrikeThroughPrice.value = formatMoney(cartItem.value.total) != formatMoney(strikeThroughPrice.value);
  updateBundleItems();
});

// As bundle item maybe deleted we should repopulate to show in bundle item component
function updateBundleItems() {
  bundledItems.value = [];
  if (product.value?.candyRackDetailItems && product.value?.candyRackDetailItems.length > 0) {
    for (const candyRackDetailItem of product.value.candyRackDetailItems) {
      const candyRackItem = buyFlowStore.plans.find(x => x.sku === candyRackDetailItem.sku);
      if (candyRackItem && candyRackItem.candyRackSettings && candyRackItem.candyRackSettings.bundleWithCart) {
        const bundleItem = {
          sku: candyRackItem.sku,
          quantity: 1,
          total: candyRackItem.ratePlans[0].catalogPrice,
          ratePlanId: candyRackItem.ratePlans[0].ratePlanId,
        } as ICartItem;
        bundledItems.value.push(bundleItem);
      }
    }
  }
}
</script>
