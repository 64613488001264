import { URLS } from "@/define";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { clearSession as pendoClearSession } from "./pendo";

export const logoutURL = `${URLS.UP_ENDPOINT.protocol}://${URLS.UP_ENDPOINT.hostname}/api/cp/auth/logout`;

export async function clearSession() {
  await pendoClearSession(); // Clear session from Pendo
  const siteSettingsStore = useSiteSettingsStore();
  siteSettingsStore.setIsCreateAccountFlow(false);
  siteSettingsStore.setShowedVerifyEmailDialogAfterCreateAccountFlow(false);
  siteSettingsStore.setSubscriberRefreshedAfterLogin(false);
  siteSettingsStore.setSkipAccountSetup(false);
  siteSettingsStore.reset();

  // Delete all the keys starts with "DownloadRestrictedFilesToken" as user can have multiple computers
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key && key.startsWith("DownloadRestrictedFilesToken")) {
      sessionStorage.removeItem(key);
    }
  }

  // We used to reset the UserStore here,
  // but that causes API calls to happen after the logout,
  // which refreshes the token which logs the user back in.
}

export async function redirectToLogoutURL() {
  window.location.replace(logoutURL);
}

export async function logout() {
  await clearSession();
  redirectToLogoutURL();
}
