export default {
  AccountCreditEarned: {
    text: "A credit of {amount} will be applied to your account, for future @:Brand.carbonite{''} or @:Brand.webroot{''} purchases.",
  },
  AccountLockedOutNotification: {
    AccountLockedOutLink: "Forgot password",
  },
  AccountSecurityComponent: {
    accountSecurity: "Account security",
    descriptionHeading: "Recommend and Optional:",
    description:
      "Adding 2-Factor Authentication to your account adds another layer of protection while accessing your account, by requiring you to enter a code sent to your phone whenever you log in.",
    btnSetup2fa: "Set up 2-Factor Authentication",
    questionsHeading: "Security Questions.",
    questions: "To finish setting up your account, please answer the security questions below.",
    labelQ1select: "Question 1",
    labelQ2select: "Question 2",
    labelQ3select: "Question 3",
    answer: "Answer",
    btnSave: "Save and Continue",
    btnSkip: "Skip for Now",
    heading2fa: "2-Factor Authentication (Recommended)",
    description2fa1:
      "Adding 2-Factor Authentication to your account adds another layer of protection while accessing your account, by requiring you to enter a code sent to your phone whenever you log in.",
    description2fa2:
      "You can change or update these settings any time in the Personal Information tab. Learn more about 2-factor authentication {here}.",
  },
  AccountSetup: {
    headerStep0: "Security Questions",
    headerStep1: "2-Factor Authentication (Recommended)",
    headerStep2: "Two Factor Authentication",
    headerStep3: "Verify Security Code",
    headerLanguagePreference: "Preferred Language",
    footerButtonLanguagePreference: "Next",
    stepLanguagePreferenceText:
      "What language would you prefer for Carbonite and Webroot communications (Account status, news and promotional emails)?",
    step0Text: "To finish setting up your account, please answer the security questions below.",
    step1TextA:
      "Adding 2-factor authentication gives you enhanced security when accessing your account. It works by sending a unique code to your phone, which you then enter when you log in.",
    step1TextB:
      "You can change or update these settings any time in the Personal Information tab. Learn more about 2-factor authentication {here}.",
    footerButtonStep0: "Save and Continue",
    footerButtonStep1: "Set up 2-Factor Authentication",
    footerButtonStep1Skip: "Skip for now",
  },
  AccountSummaryComponent: {
    accountSummary: "Account Summary",
    apply: "Apply",
    trialExpired: "Your trial has expired.",
    buyNow: "Buy now",
    activationCodeP: "If you have an activation code, enter it below to activate your subscription.",
    activationCode: "Activation code",
  },
  AddCreditCardComponent: {
    cancel: "Cancel",
    next: "Next",
  },
  AddEncryptionKeyComponent: {
    headerH1: "Enter your Encryption Key",
    p1: "When you installed @:Brand.carboniteSafe{''} on your computer, you elected to manage your own encryption key. In order to install @:Brand.carboniteSafe{''} on this computer, please upload your key now.",
    selectEncryptionFile: "Select Encryption Key File",
    selectEncryptionFileHint:
      'Click the "{browse}" button to locate and select your encryption key file (typically named Carbonite-Encryption-Key.pem)',
    encryptionPassword: "Encryption Key Password (only if you added it during setup)",
    passwordHint: "Password hint: <strong>{hint}</strong>",
    note: "Note: If you upload your encryption key, then @:Brand.carbonite{''} will manage it for you from now on.",
    browse: "Browse...",
    footerButtonCancel: "Cancel",
    footerButtonSave: "Save",
    HintNotSet: "Hint not set.",
  },
  AddKeycodeComponent: {
    headerH1: "Add a Keycode",
    keycodeLabel: "Enter your keycode to get started",
    lineItem1: "How to find your keycode:",
    lineItem2Heading: "Online purchase: ",
    lineItem2: "check your email for a receipt with instructions from the website/vendor you purchased.",
    lineItem3Heading: "In-store purchase: ",
    lineItem3: "Check the box or card that you purchased at the store for instructions.",
    footerButtonAdd: "Add",
    footerButtonLogout: "Log Out",
    footerButtonCancel: "Cancel",
    linkFooterNeedHelp: "Need more help?",
    nextSteps: "Next Steps:",
    stepOne: "Log out of this account and {createNewAccount} with a different email address.",
    stepTwo: "Enter your new @:Brand.WSAP{''} or @:Brand.allstate{''} keycode to assign it to the new account.",
    createNewAccount: "create a new account",
  },
  AddPaymentMethodComponent: {
    secureCheckout: "Secure Checkout",
    next: "Next",
    allStateAvailabilityMessage: "Note: Allstate Identity Protection is only available in the U.S.",
  },
  AddVatNumberComponent: {
    headerH1: "Add your Vat ID Number",
    headerBuyflow: "Add a VAT ID",
    vatId: "VAT ID",
    countryPlaceholder: "--Select country--",
    country: "Country",
    ok: "OK",
    add: "Add",
    cancel: "Cancel",
    invalidVatIdFormat: "Invalid VAT ID format",
  },
  Adjustments: {
    BonusMonthsPromo: "Bonus {replacementText} months free",
    TrialDaysRemaining: "{replacementText} Trial days included",
    PromoCodeAmt: "Promo Code discount",
    PromoCodePct: "Promo Code discount",
    PriorSubscriptionCredit: "Prior subscription credit",
    UsedCreditBalance: "Account credit",
    OneTimeAmt: "Initial Purchase discount", // TODO: Define the real description for this
    Tax: "Sales tax",
    Discounts: "Discounts",
    LicenseDaysRemaining: "Includes {replacementText} days from current subscription",
  },
  AllstateAlreadySignedUpComponent: {
    h1Heading: "@:Brand.allstate{''} @:Brand.identityProtection{''}",
    summaryText:
      "Visit the @:Brand.allstate{''} @:Brand.identityProtection{''} dashboard to activate features, add and monitor your personal information, and manage account alerts.",
    identityProtection: "@:Brand.identityProtection{''}",
    goToIDP: "Go to @:Brand.idProtection{''} account",
    subIncludesIdentityProtection:
      "Your subscription includes @:Brand.identityProtection{''}. Our Premium plans now include up to $1M in stolen funds and fraud expense reimbursement for up to 10 family members.",
    subtitle: "Next Steps: Activate @:Brand.identityProtection{''} features",
    upgradeText: "Want to extend your @:Brand.idProtection{''} with $500k expense and credit monitoring?",
    viewUpgradeOptions: "View Upgrade Options",
  },
  AllstateCanSignUpComponent: {
    h1Heading: "@:Brand.allstate{''} @:Brand.identityProtection{''}",
    summaryText: "Your {subscription} account includes @:Brand.identityProtection{''} managed by @:Brand.allstate{'.'}",
    identityProtection: "@:Brand.identityProtection{''}",
    managedId: "Set up @:Brand.idProtection{''}",
    inactiveDisclaimer:
      "@:Brand.idProtection{''} setup will be ready within 24 hours after you install @:Brand.webrootProtection{'.'}",
    pleaseChoose: "Please choose the subscription to setup with @:Brand.identityProtection{':'}",
    expires: "Expires ",
    keycode: "Keycode: ",
  },
  AllStateDownloadsTab: {
    header: "@:Brand.allStateFullName{''}",
    btnManage: "Manage @:Brand.idProtection{''}",
    btnSetUp: "Create @:Brand.idProtection{''}",
    p1: "Identity theft protection with dark web, credit and financial monitoring",
    infoText: "Ready within 24 hours after Webroot activation.",
  },
  AllstateFormErrors: {
    firstNameInvalidCharacter: "The {0} character cannot be used.",
    lastNameInvalidCharacter: "The {0} character cannot be used.",
    addressInvalidCharacter: "The {0} character cannot be used.",
    cityInvalidCharacter: "The {0} character cannot be used.",
    nameLengthMin: "First name Required",
    invalidPhoneFormat: "This is not a valid U.S. phone number.",
    phoneLength: "This is not a valid U.S. phone number.",
    addressLength: "Address must be between 3 and 60 characters",
    cityLength: "City must be between 2 and 50 characters",
    stateLength: "U.S. state is required.",
    zipLength: "Zip must be 5 digits.",
    zipNumbersOnly: "Zip must be 5 numeric digits.",
    firstNameLengthMax: "First name must be under 50 characters.",
    lastNameLengthMax: "Last name must be under 50 characters.",
    firstNameLengthMin: "First name is required.",
    lastNameLengthMin: "Last name is required.",
  },
  AllstateNotPurchasedComponent: {
    h1Heading: "@:Brand.webroot{''} Security and @:Brand.identityProtection{''}",
    notification: "There is no active @:Brand.identityProtection{''} subscription to display.",
    action: "Add @:Brand.webroot{''} security with @:Brand.identityProtection{''} today.",
    summaryText:
      "Award-winning @:Brand.webroot{''} Anti-virus now includes @:Brand.allstate{''} @:Brand.identityProtection{'.'} Click the button below to learn how you can start protecting your devices, identity, and privacy today.",
    summaryTextFamily:
      "@:Brand.webroot{''}'s award-winning virus protection now includes @:Brand.allstate{''} @:Brand.identityProtection{'.'} Learn how you can start protecting your devices, identity, and privacy.",
    learnMore: "Learn More",
    notes:
      "If you have a keycode from a retail or similar purchase, you can add the subscription by entering the keycode on the {downloads} page.",
    downloads: "Downloads",
  },
  AllStateOverviewComponent: {
    allStateOverview: "@:Brand.identityProtection{''}",
    zeroStateP1:
      "@:Brand.identityProtection{''} helps you safeguard your personal information by providing proactive alerts to threats to your identity and 24/7 US based full service remediation support.",
    zeroStateBestBuyTTSB: "Choose a plan for 1 individual or your whole family (10 individuals).",
    zeroStateP2: "U.S. mailing address is required for this product.",
    compareOptionsBtn: "View Details and Pricing",
    premiumCreateAccountP1:
      "Your @:Brand.WSAP{''} subscription includes @:Brand.identityProtection{''} managed by @:Brand.allstate{'.'}",
    createAccountBtn: "Create @:Brand.idProtection{''} Account",
    manageAccountP1:
      "Visit the @:Brand.allstate{''} @:Brand.identityProtection{''} dashboard to setup and manage account features and alerts.",
    premiumManageAccountP1:
      "Your @:Brand.WSAP{''} subscription includes @:Brand.identityProtection{''} managed by @:Brand.allstate{'.'}",
    basicManageAccountP1:
      "Your @:Brand.AllstateIdentityProtectionBasic{''} subscription includes @:Brand.identityProtection{''} managed by @:Brand.allstate{'.'}",
    manageAccountBtn: "Manage @:Brand.idProtection{''}",
    basicCreateAccountP1:
      "Your @:Brand.AllstateIdentityProtectionBasic{''} subscription includes @:Brand.identityProtection{''} managed by @:Brand.allstate{'.'}",
    premiumExpired:
      "Your @:Brand.allstate{''} @:Brand.identityProtection{''} is part of your expired @:Brand.WebrootPremium plan.",
    addOnExpired: "Your @:Brand.allstate{''} @:Brand.identityProtection{''} plan has expired.",
    inactiveDisclaimer:
      "@:Brand.idProtection{''} setup will be ready within 24 hours after you install @:Brand.webrootProtection{'.'}",
    attentionOnCreate:
      "Create your account to access and activate your @:Brand.allstate{''} @:Brand.identityProtection{''} services.",
    goToIDPBtn: "Go to @:Brand.idProtection{''} account",
  },
  AllstateSignUpFormComponent: {
    h1Heading: "Step 1: Create @:Brand.allstate{''} @:Brand.identityProtection{''} account",
    summaryText:
      "Create an account to access and manage @:Brand.allstate{'’s'} @:Brand.identityProtection{''} site and services. The email associated with @:Brand.myAccount{''} will be used to create the account to manage @:Brand.identityProtection{''}.",
    email: "Email",
    firstName: "First name",
    lastName: "Last name",
    phone: "U.S. phone",
    address1: "U.S. street address",
    address2: "Apartment, suite, building number (optional)",
    city: "City",
    state: "State",
    zip: "Zip",
    country: "Country",
    privacyAgreement:
      "I agree to send this data to @:Brand.allstate{','} which will be used to create my @:Brand.identityProtection{''} account on the @:Brand.allstate{''} dashboard. All personal information will be subject to @:Brand.allstate{''} privacy policy.",
    privacyPolicyLink: "@:Brand.allstate{''} privacy policy",
    createAllstate: "Create @:Brand.idProtection{''} account",
    dateExample: "Example: MM / DD / YYYY",
    us: "United States",
    selectOption: "Select an Option",
  },
  AllstateSupportSectionComponent: {
    allstateIdentityProtection: "@:Brand.allstate{''} @:Brand.identityProtection{''}",
    findHelpText: "Find help for @:Brand.allstate{''} @:Brand.identityProtection{''}",
    goAllstateFAQText: "@:Brand.allstate{''} FAQs",
    contactAllState: "Contact @:Brand.allstate{''}",
  },
  AllstateUpgradeComponent: {
    /* existing pre-family plan comparison table */
    notes: "Some features require a U.S. mailing address.",
    buy: "Buy @:Brand.identityProtection{''}",
    featureComparison: "Feature Comparison",
    premiumOption: "Includes @:Brand.allstate{''} @:Brand.idProtection{''}",
    addOnOption: "Add to an existing @:Brand.webroot{''} account",
    financialMonitoring: "Financial Monitoring",
    advancedDarkWebMonitoring: "Advanced Dark Web Monitoring",
    identityMonitoring: "Identity Monitoring",
    lostWalletProtection: "Lost Wallet Protection",
    reimbursement: "Reimbursement",
    expenseReimbursementWebrootPremium: "$500K",
    expenseReimbursementWebrootPremiumFullFeature:
      "@:AllstateUpgradeComponent.expenseReimbursementWebrootPremium expense reimbursement",
    expenseReimbursementAddOn: "$25K",
    expenseReimbursementAddOnFullFeature: "@:AllstateUpgradeComponent.expenseReimbursementAddOn expense reimbursement",
    stolenFundsReimbursement: "$50K Stolen Funds Reimbursement",
    oneBureauCreditMonitoring: "One Bureau Credit Monitoring",
    allstateIdProtectionAddOn: "@:Brand.AllstateIdentityProtectionBasic{''}",
    /* new family plan included comparison table */
    expires: "Expires ",
    expired: "Expired ",
    chooseKeycodeLine1: "@:Brand.identityProtection{''} can be applied to {onlyOne} on an account.",
    onlyOne: "only one subscription",
    chooseKeycodeLine2: "Please choose a subscription to view your options for @:Brand.identityProtection{''}",
    summaryText:
      "@:Brand.identityProtection{''} helps you safeguard your personal information by providing proactive alerts to threats to your identity and 24/7 U.S.-based full-service remediation support.",
    summaryTextFamily:
      "@:Brand.identityProtection{''} monitors your identity and accounts, with access to 24/7 US-based remediation experts in case fraud is detected.",
    summaryTextFamilyP2: "Plans are available to protect one identity or your whole family (up to 10 individuals).",
    h1Heading: "@:Brand.identityProtection{''} Upgrade",
    btnDetailsAndPricing: "View Details and Pricing",
    featuresComparison: "Features Comparison",
    devicesProtected: "# of Devices Protected",
    devicesProtectedVary: "Varies based on your @:Brand.webroot{''} plan",
    identitiesProtected: "# of Identities Protected",
    option1description: "Add @:Brand.idProtection{''} to your current @:Brand.webroot{''} plan",
    option2description: "@:Brand.webroot{''} device security + @:Brand.allstate{''} @:Brand.idProtection{''}",
    allstateAddOn: "@:Brand.AllstateIdentityProtectionBasic{''}",
    financialMonitor: "Financial Monitoring",
    creditAndDebitCards: "Credit and debit cards",
    bankAccountTransactions: "Bank account transactions",
    investmentAccounts: "401(k) investment accounts",
    accountTakeoverMonitoring: "Account takeover monitoring",
    darkWebMonitor: "Advanced Dark Web Monitoring",
    humanSourcedIntelligence: "Human-sourced intelligence",
    ssnMonitoring: "Social Security Number monitoring",
    idMonitoring: "ID monitoring",
    creditDebitScan: "Credit/debit card scan",
    breachNotifications: "Breach notifications",
    identityMonitor: "Identity Monitoring",
    healthStatus: "Identity Health Status",
    emergingThreatAndScamAlerts: "Emerging threat and scam alerts",
    restoration: "Restoration",
    customerCare: "24/7 customer care",
    remediationSupport: "Full-service remediation support",
    lostWallet: "Lost Wallet Protection",
    expenseReimbursement: "Expense Reimbursement",
    stolenFunds: "$50K Stolen Funds Reimbursement",
    creditMonitor: "One Bureau Credit Monitoring",
    rapidAlertDetection: "Rapid alert detection",
    autoOnCapability: "Auto-on capability",
    transUnion: "TransUnion",
  },
  ApplyActivationCodeComponent: {
    headerH1: "Apply @:Brand.carbonite{''} Activation Code",
    footerButtonCancel: "Cancel",
    footerButtonNext: "Next",
    footerButtonApply: "Apply",
    label1: "Enter your @:Brand.carbonite{''} activation code",
    invalidAC: "Invalid AC - Temp Text",
    paragraph1: "How do you want to use this activation code?",
    label2: "Apply it to an existing subscription",
    label3: "Apply it to this computer and install",
    footerNeedHelp: "Need help?",
  },
  AutoLogoutComponent: {
    headerH1: "Session Timeout",
    sessionExpiry: "Your session is about to expire. You will be logged out in {timer} seconds.",
    ctnSession: "Do you want to continue your session?",
    yes: "Yes",
    no: "No",
  },
  AutoRenewOffSurveyComponent: {
    autoRenewOffSurveyH2: "Let us know why you are turning off auto-renewal.",
    reasonTurnOff: "Please select the response that best describes your reason.",
    r101: "I plan to renew, but I want to do it manually.",
    r102: "I plan to renew, but I want to change my credit card.",
    r103: "I don't plan to renew because I'm switching to another vendor.",
    r104: "I don't plan to renew because @:Brand.carbonite{''} is too expensive.",
    r105: "I don't plan to renew because I'm getting backup for free or doing it myself.",
    r106: "Other (please specify):",
  },
  AutoRenewOptionComponent: {
    AutoRenewTurnOnHeader: "Turn ON Auto-Renewal",
    AutoRenewTurnOffHeader: "Turn OFF Auto-Renewal",
    AutoRenewOffSurveyComponentHeader: "Survey",
    WebrootARTurnOnHeader: "Turn ON Auto-Renewal",
    WebrootARTurnOffHeader: "Manage Subscription Renewals",
    footerButtonCancel: "No Thanks",
    footerButtonClose: "Close",
    footerButtonTurnOnAutoRenewal: "Turn ON Auto-Renewal",
    footerButtonTurnOffAutoRenewal: "Turn OFF Auto-Renewal",
    footerKeepAutoRenewalOn: "Keep Auto-Renewal ON",
    skipSurvey: "Skip Survey",
    submit: "Submit",
  },
  AutoRenewTurnOffComponent: {
    autoRenewOffLine1: "Are you sure you want to turn off auto-renewal for this subscription?",
    subscription: "Subscription:",
    computer: "Computer Nickname:",
    expires: "Expires:",
    notifyProtectionIolo:
      "For your protection, we will still notify you when your subscription is about to expire. However, if you forget to manually renew your account, your PC performance might be impacted. Are you sure you want to turn off auto-renewal?",
    notifyProtection:
      "For your protection, we will still notify you when your subscription is about to expire. However, if you forget to manually renew your account, your device will be vulnerable to ransomware, spyware, and more. Are you sure you want to turn off auto-renewal?",
    oneTimeDiscount:
      "Special offer: Save {renewalDiscount}% on your next automatic subscription renewal, if you keep auto-renewal ON for this subscription.",
  },
  AutoRenewTurnOnComponent: {
    autoRenewForLine1: "Would you like to turn on auto-renewal for:",
    subscription: "Subscription:",
    computer: "Computer Nickname:",
    expires: "Expires:",
    willRenewOn: "Will renew on",
    paymentChoice: "You will choose a payment method on the next screen.",
    creditCardInfo: "This subscription will automatically renew using the default credit card on file:",
    changePaymentLink: "Change payment method",
  },
  BackupComponent: {
    addComputer: "Add a new computer",
    backupH1: "Backup",
    readyToInstall: "Ready to install @:Brand.carboniteSafe{''} and start backing up your new computer.",
    linkFooterNeedHelp: "View detailed instructions",
    downloadsH1: "Download",
    noneComputer: "None of your computers are set up with @:Brand.carboniteSafe{''} cloud backup yet.",
    noneCdlp: "None of your computers are set up with @:Brand.WebrootTotalProtection{''} yet.",
    getStarted: "To get started:",
    stepOne: "Visit this page on the {computer}.",
    stepTwo: "Click {button} to download and run the installation file.",
    stepThree: "Follow the on-screen prompts to back-up your files.",
    stepOneCdlp: "Visit this page on the computer you want to back up.",
    stepTwoCdlp: "Click Go to Downloads to download and run the installation file.",
    stepThreeCdlp: "Follow the on-screen prompts to back-up your files.",
    computerBackup: "computer you want to back up",
    downloadNow: "Download Now",
    installNow: "Install Now",
    note: "Once installed, @:Brand.carboniteSafe{''} software identifies the files and folders you most likely want to protect, or you can select them yourself. Once the software is running, it automatically and securely copies your files to the cloud.",
    upgradeOrExtend: "Upgrade or Extend Your @:Brand.carbonite{''} Backup",
    BackupAdditional: "Back Up Additional Devices",
    BackupYourDevices: "Backup Your Devices",
    youHaveUninstalledSubs:
      "You have one or more @:Brand.carboniteSafe{''} subscriptions available. To back up your computer:",
    youHaveUninstalledCdlpSubs:
      "You have a @:Brand.WebrootBackupAndRestore{''} subscription available. To back up an additional computer:",
    upgradeIntro: "Upgrade your current @:Brand.carbonite{''} backup subscriptions.",
    upgradeIntro2: "Add an activation code",
    upgradeIntro3: "Add another subscription to backup additional devices",
    upgradeAdd: "Upgrade / Add",
    noteOnlyAvailableFor: "Note: @:Brand.carboniteSafe{''} is only available for Windows and macOS.",
    startATrial: "Start a Trial",
    bestBuyOnly: "Your @:Brand.bestBuy{''} subscription doesn't include backup.",
  },
  BestBuySupportSectionComponent: {
    line1: "Get help with @:Brand.webrootProtection{'.'}",
    line2a: "Chat with an Agent",
    line2b: "or call <strong>1-888-BEST-BUY</strong>",
  },
  BillingInformationComponent: {
    billingInformation: "Billing Information",
    billingOwner: "Billing owner",
    accountCredit: "Account credit",
    accountCreditDetails:
      "You have a credit of {amount} on your account. This credit can be applied to any future purchase.",
    billingHistory: "Billing history",
    billingHistoryDetails: "There is no billing history for this account.",
  },
  Brand: {
    /* In the Japanese language, there are no spaces between words.  If you use @:Brand, you need to add {''} at the end of every usage
    to allow it to render without a space "@:Brand.carboniteSafe{''}のナレッジベースの検索"
    If you need a character directly at the end of your @:Brand, edit the {''} at the end with the character you want to render
    To add a possessive, {'’s'}
    - NOTE the apostrophe for the possessive is not the same as the standard keyboard apostrophe - you need to copy the apostrophe ’ from here
    To add a period, use {'.'}
    To add a comma, use {','}
     To add a colon, use {':'}
     To add a registered trademark, use {'®'} */
    // partial names used in other strings
    allstate: "Allstate",
    idProtection: "ID Protection",
    identityProtection: "Identity Protection",
    amazon: "Amazon",
    prime: "Prime",
    plus: "Plus",
    bestBuy: "Best Buy",
    lastPass: "LastPass",
    lastPassFullName: "LastPass® Password Manager",
    allStateFullName: "Allstate® Identity Protection",
    opentext: "Open Text",
    carbonite: "Carbonite",
    carboniteCustomerCare: "Carbonite Customer Care",
    courierRecovery: "Courier Recovery",
    courierRecoveryService: "Courier Recovery Service",
    myAccount: "My Account",
    remoteFileAccess: "Remote File Access",
    anytimeAnywhereAccess: "Anytime Anywhere Access",
    ServiceFee: "Service Fee",
    webroot: "Webroot",
    webrootSecureAnywhere: "Webroot SecureAnywhere",
    wsaAntivirus: "AntiVirus",
    wsaISP: "Internet Security Plus",
    webrootMobileLogin: "Webroot Mobile Login",
    webrootCloudBackup: "@:Brand.webroot{''} Cloud Backup",
    WebrootEssentials: "@:Brand.webroot{''} Essentials",
    // Carbonite products not in our productList - products that are not sold on VD but may be mentioned on VD
    carboniteSafe: "Carbonite Safe",
    carboniteSafePro: "Carbonite Safe Pro",
    carboniteOffice: "Carbonite Office",
    carboniteSafeUltimate: "Carbonite Safe Ultimate",
    carboniteSafeLite: "Carbonite Safe Lite",
    carboniteSafePlus: "Carbonite Safe Plus",
    CarboniteHome: "Carbonite Home",
    CarboniteHomePlus: "Carbonite Home Plus",
    CarboniteHomePremier: "Carbonite Home Premier",
    carboniteMobile: "Carbonite Mobile",
    carboniteSafeCloudBackup: "Carbonite Safe Cloud Backup",
    PersonalLite: "Carbonite Safe Lite",
    // Webroot products - These need to keep the casing
    webrootProtection: "Webroot Protection", // this is not a product, it is a catch-all name that covers the family of webroot products
    WSAV: "@:Brand.WebrootAntiVirus{''}",
    WSAI: "@:Brand.WebrootPlus{''}",
    WSAC: "@:Brand.WebrootComplete{''}",
    WSAFMPK: "@:Brand.WebrootCompleteFamily{''}",
    WSCB: "@:Brand.WebrootChromebook{''}",
    WSAG: "@:Brand.WebrootGamer{''}",
    WIFI: "@:Brand.WebrootSecureVPN{''}",
    WSAP: "@:Brand.WebrootPremium{''}",
    WSAPF: "@:Brand.WebrootPremiumFamily{''}",
    WSID: "@:Brand.AllstateIdentityProtectionBasic{''}",
    WSIDP: "@:Brand.AllstateIdentityProtectionPremium{''}",
    WSIDPF: "@:Brand.AllstateIdentityProtectionPremiumFamily{''}",
    WTP: "@:Brand.WebrootTotalProtection{''}",
    WTPF: "@:Brand.WebrootTotalProtectionFamily{''}",
    WE: "@:Brand.WebrootEssentials{''}",
    WPCO: "@:Brand.WebrootPcOptimizer{''}",
    WSASB: "@:Brand.WebrootSmallBusinessAntiVirus{''}",
    WSAIA: "Webroot Internet Security with Antivirus & Allstate Identity Protection",
    WSVPN: "@:Brand.WebrootSecureVPNP{''}",
    WW: "@:Brand.WindowWasher{''}",
    ADP: "@:Brand.AndroidMobile{''}",
    webrootSecureAnywhereAntivirus: "@:Brand.WebrootAntiVirus{''}",
    webrootSecureAnywhereComplete: "@:Brand.WebrootComplete{''}",
    // Browser extensions for the Downloads page
    WebrootWTSBE: "Webroot Web Threat Shield Browser Extension",
    WebrootWTS: "Webroot Web Threat Shield",
    WebrootMS: "Webroot Mobile Security",
    // these keys are skus and the product names they are mapped to
    AllstateIdentityProtectionBasic: "Allstate Identity Protection Basic",
    AllstateIdentityProtectionPremium: "Allstate Identity Protection Premium",
    AllstateIdentityProtectionPremiumFamily: "Allstate Identity Protection Premium Family",
    WebrootAntiVirus: "Webroot AntiVirus",
    webrootAntivirus: "@:Brand.WebrootAntiVirus",
    WebrootPremium: "Webroot Premium",
    WebrootPremiumFamily: "Webroot Premium Family",
    WebrootComplete: "Webroot Internet Security Complete",
    WebrootCompleteFamily: "Webroot Internet Security Complete Family",
    WebrootChromebook: "Webroot Chromebook",
    WebrootGamer: "Webroot AntiVirus for PC Gamers",
    WebrootPlus: "Webroot Internet Security Plus",
    WebrootSecureVPN: "Webroot WiFi Security VPN",
    WebrootTotalProtection: "Webroot Total Protection",
    WebrootTotalProtectionFamily: "Webroot Total Protection Family",
    WebrootPcOptimizer: "Webroot PC Optimizer",
    WebrootSoftwareInstallationCD: "Software CD",
    WebrootSmallBusinessAntiVirus: "Webroot Small Business AntiVirus",
    WindowWasher: "Window Washer",
    WebrootSecureVPNP: "Webroot Secure VPN",
    // Webroot Backup + Restore is not a sku, it is the equivalent of Carbonite Safe Cloud Backup for CDLP
    WebrootBackupAndRestore: "Webroot Backup + Restore",
    AndroidMobile: "Webroot Security & Antivirus Premier",
    PersonalBasic: "Carbonite Safe Basic",
    PersonalPlus: "Carbonite Safe Plus",
    PersonalPrime: "Carbonite Safe Prime",
    PayPal: "PayPal",
    // Old Webroot products that are fully sunset, but may show up in a users account
    SS: "Spy Sweeper",
    WAV: "Webroot AntiVirus with Spy Sweeper",
  },
  BuyAddCardComponent: {
    paymentInformation: "Payment Information",
    cancel: "Cancel",
    next: "next",
    reviewOrder: "Review Order",
    processingOrderMessage: "Please do not refresh the page or click the Back button.",
  },
  BuyAutomaticRenewDialogComponent: {
    header: "Automatic Renewal",
    p1Annual: "Would you like us to automatically renew your subscription after {expirationDate}?",
    p2Annual: "We’ll send you a reminder email 30 days before the upcoming charge.",
    btnManageAutoRenew: "Managing auto-renew",
    btnYes: "Sign up for auto-renew",
    btnNo: "No, don't auto-renew",
  },
  BuyDisclaimersComponent: {
    payment: "Payment",
    orderFor: "Order for {userName}",
    priceWarning:
      "Prices on our website do not include sales tax. If you are purchasing from an area that charges sales tax, you will be charged the applicable rate. Taxes and surcharges are applied based upon rates determined by state and local laws.",
    autoRenewDisclaimer:
      "*By clicking Complete my purchase, you acknowledge and agree that you will be charged a recurring subscription fee to keep your account active, which will continue unless you cancel your subscription or change your auto-renew preferences.",
    helpLinkDeactivatingAutoRenewal: "Deactivating Automatic Renewal for @:Brand.carboniteSafe{''}",
    helpLinkDeactivatingAutoRenewalWebroot: "Deactivating Automatic Renewal",
    byContinuing: "By continuing you are agreeing to:",
    helpLinkTermsOfService: "@:Brand.carbonite{'’s'} Terms of Service",
    helpLinkTermsOfServiceWebroot: "@:Brand.webroot{''} Terms of Service",
    helpLinkPrivacyPolicy: "Privacy Policy",
  },
  BuyEmailComponent: {
    enterYourEmail: "Enter your email address for your receipt, account registration and product activation.",
    alreadyAnAccount: "There's already an account for",
    option1Heading: "Option 1 -- This is my account",
    option1Message:
      "If this is your account, log in to use use any existing credits or saved credit cards for faster checkout, or continue with this email without logging in.",
    or: "- or -",
    option2Heading: "Option 2 -- Use a different email address:",
    btnContinueWithoutLoggingIn: "Continue without Logging in",
    differentEmail: "Please use a different email address.",
    btnContinue: "Continue to Payment",
    willSendEmails:
      "By clicking <strong>Continue to Payment</strong>, we may send you emails to assist you completing the purchase if you are not able to finalize it now.",
    allstateLimitError:
      "@:Brand.WebrootTotalProtection{''} is limited to 1 subscription per email address, and it cannot be purchased with an account that is already enrolled in @:Brand.WebrootSecureVPNP{''} or @:Brand.allstate{''} subscription. Please remove @:Brand.WebrootTotalProtection{''} from your cart or enter a different email address below.",
    allstateAlreadyIncludedError:
      "{productSku} includes @:Brand.allstate{''} @:Brand.identityProtection{''}, and only 1 @:Brand.allstate{''} subscription is allowed per email address. Please remove {productSku} from your cart. For customers who have @:Brand.allstate{''} @:Brand.identityProtection{''}, we recommend {webrootComplete}",
    alreadyOwnVPNPError:
      "Only one @:Brand.WebrootSecureVPNP{''} subscription is allowed per email address. Please remove @:Brand.WebrootSecureVPNP{''} from your cart or provide another email address below.",
    alreadyOwnWTPError:
      "@:Brand.WebrootTotalProtection{''} is limited to 1 subscription per e-mail address, and it cannot be purchased with an account that is already enrolled in @:Brand.WebrootSecureVPNP{''} or @:Brand.allstate{''} subscription. Please remove @:Brand.WebrootTotalProtection{''} from your cart or enter a different email address below.",
  },
  BuyOrTryCarboniteComponent: {
    statement1: "You do not currently have a backup subscription.",
    statement2: "Add @:Brand.carboniteSafe{''} backup today",
    statement3:
      "@:Brand.carbonite{''} provides the #1 way to securely backup your files, Need to protect your important files and data from loss, theft, hard drive failure and physical damage? Look no further.",
    startTrialCTA: "Start a Free Trial",
    buyNowCTA: "Buy Now",
  },
  BuyPaymentComponent: {
    payment: "Payment",
    orderFor: "Order for {userName}",
    priceWarning:
      "Prices on our website do not include sales tax. If you are purchasing from an area that charges sales tax, you will be charged the applicable rate. Taxes and surcharges are applied based upon rates determined by state and local laws.",
    autoRenewDisclaimer:
      "*By clicking Secure Checkout, you acknowledge and agree that you will be charged a recurring subscription fee to keep your account active, which will continue unless you cancel your subscription or change your auto-renew preferences. Instructions for changing your auto-renew preferences can be found on our Knowledge Base article below:",
    helpLinkDeactivatingAutoRenewal: "Deactivating Automatic Renewal for @:Brand.carboniteSafe{''}",
    byContinuing: "By continuing you are agreeing to:",
    helpLinkTermsOfService: "@:Brand.carbonite{'’s'} Terms of Service",
    helpLinkPrivacyPolicy: "Privacy Policy",
    enterEmail: "Please enter your email address to proceed to payment.",
    paymentTypeCC: "Credit Card",
    paymentTypePP: "PayPal",
  },
  BuyReceiptComponent: {
    h1Receipt: "Receipt",
    print: "Print Receipt",
    confirmationEmailNote: "A confirmation email with order details has also been sent to {email}.",
    confirmationEmailNoteUnauthenticated: "We have also emailed you a copy of the order details.",
    dateOfPurchase: "Date of purchase:",
    subscriptionRenews: "Subscriptions renew on expiration dates noted below",
    subscriptionEnds: "Subscriptions end on expiration dates noted below",
    endingIn: "ending in",
    colQty: "Qty",
    colSub: "Subscription",
    colAmount: "Amount",
    tax: "Tax",
    paid: "Paid",
    total: "Total",
    monthlySubscription: "monthly subscription",
    forComputer: "for computer:",
    subscriptionExpiresOn: "Subscription expires on {date}",
    paypalPayment: "@:Brand.PayPal{''} ({email})",
  },
  PayPalPaymentComponent: {
    allStateAvailabilityMessageLine1:
      "This payment method has a non-US shipping address. @:Brand.allstate{''} @:Brand.identityProtection{''} is only available in the US.",
    allStateAvailabilityMessageLine2: "Please use a payment method with a US address.",
  },
  BuySelectedCreditCardComponent: {
    endingIn: "ending in",
    exp: "Exp",
    allStateAvailabilityMessageLine1: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine1",
    allStateAvailabilityMessageLine2: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine2",
  },
  BuySelectPaymentComponent: {
    paymentInformation: "Payment Information",
    payWith: "Pay With",
    savedPaymentMethods: "Saved Payment Methods",
    endingIn: "ending in",
    exp: "Exp",
    automaticRenewSubtitle: "Automatic Renewal Keeps You Protected",
    automaticRenewP1:
      "Your files are too important to forget. Would you like us to automatically renew your subscription for you on {expirationDate}?",
    automaticRenewP2: "We'll send you a reminder email a week before the upcoming charge.",
    acceptAutomaticRenew: "Yes, automatically renew my subscription on {expirationDate}",
    secureCheckout: "Secure Checkout",
    cardName: "Cardholder Name",
    cardNumber: "Credit Card Number",
    cardExpiration: "Expiration Date",
    cardBillingAddress: "Billing Address",
    changePayment: "Change payment",
    addPaymentMethod:
      "+ Add a Payment Method" /* this string is an option in a select, that is why the + is not a svg icon */,
  },
  BundleItemsComponent: {
    addSoftwareCD: "Add software CD for",
  },
  CancelSubscriptionComponent: {
    modalTitle: "Are you sure you want to cancel?",
    note1:
      "Please note: this subscription is on a monthly plan, which we no longer offer. If you cancel, you will need to purchase a new yearly subscription to continue or resume backing up your data.",
    subscription: "Subscription:",
    computerName: "Computer:",
    footerButtonCancel: "Cancel Subscription",
    footerButtonDoNotCancel: "Don't Cancel",
  },
  CarboniteSupportSectionComponent: {
    carboniteSafeOs: "@:Brand.carboniteSafe{''} for Windows/macOS",
    carboniteMobile: "@:Brand.carboniteMobile{''}",
    findHelpCarbText: "Search @:Brand.carboniteSafe{''} Knowledge Base",
    search: "Search",
    popularSearches: "Popular searches",
    carboniteSupportText: "Go to @:Brand.carbonite{''} customer support",
    searchKnowledge: "Search knowledge base articles",
    carboniteCustomerSupport: "@:Brand.carbonite{''} Customer Support",
    reinstallCarbonite: "Reinstall @:Brand.carbonite{''}",
    renewSubscription: "Renew subscription",
    restoreFiles: "Restore files to new computer",
  },
  CardComputerComponent: {
    edit: "Edit",
    outOfDate: "Out of date",
    lastBackedUp: "Last backed up",
    files: "files",
    computerOptions: "Options",
    viewFiles: "View files",
    getFilesBack: "Get files back",
    this: "This",
    subRenewsAutoOn: "Automatically renews",
    moveMySubscription: "Move my subscription",
    moveMyBackup: "Move my backup",
    reinstallCarbonite: "Re-install @:Brand.carbonite{''}",
    reinstallCdlp: "Reinstall @:Brand.WebrootBackupAndRestore{''}",
    renewUpgrade: "Renew / Upgrade",
    changeCarbonitePlan: "Change @:Brand.carbonite{''} plan",
    expires: "Expires",
    expiresOnExpirationMessage: "This {productName} Backup {trial} subscription expires on {expiration}",
    validUntilExpirationMessage: "This {productName} Backup {trial} subscription is valid until {expiration}",
    evergreenExpirationMessage: "{productName} - Active Until Canceled",
    autoRenewExpirationMessage: "This {productName} Backup subscription renews automatically on {expiration}",
    expiredOnExpirationMessage: "This {productName} Backup {trial} subscription expired on {expiration}",
    expired: "Expired",
    backupWillBeLost: "Backed up data will be lost in {days}",
    backupDeleted: "Backup has been deleted.",
    clickBuyNow: "Click {buyNow} to resume protection for this computer.",
    days: "day(s)",
    buyNow: "Buy Now",
    renewNow: "Renew Now",
    copy: "Copy",
    keycode: "Keycode:",
    wsavIncluded: "Antivirus protection included with your {productName} subscription",
    getTheApp: "Get the App",
    trial: "Trial",
    followTheseSteps: "Follow these steps",
    refreshYourBackup: "{steps} to refresh your backup.",
    unityError:
      "There was a problem retrieving this subscription. Refresh the page, try again later, or contact support.",
    deviceStatusBackupOutOfDate: "Backup out of date",
    deviceStatusBackupOutOfDateMessage:
      "Your backup is overdue. Until your backup is refreshed, @:Brand.carbonite{''} may not have an accurate copy of all of your files.",
    deviceStatusDisabled: "Disabled",
    deviceStatusDisabledMessage:
      "Backup has been disabled on this computer.To resume backing up, launch @:Brand.carbonite{''} on your computer and enable backup.",
    deviceStatusRecoverMode: "In recover mode",
    deviceStatusRecoverModeMessage:
      "Recover Mode stops @:Brand.carbonite{''} from adding, changing or deleting any of the files in your backup while you restore your files. @:Brand.carbonite{''} will automatically enter Recover Mode if it detects a major change to your system. For example, if you are restoring files to a new computer.",
    deviceStatusStarting: "Starting backup...",
    deviceStatusStartingMessage: "",
    deviceStatusUninstalled: "Uninstalled",
    deviceStatusUninstalledMessage: "",
    getMyKeycode: " Get my keycode",
    resellerEmail: "Contact {email} to {action}",
    renew: "renew",
    buy: "buy",
    renewAutomatically: "This subscription renews automatically on",
    evergreenActiveUntilCanceled: "Active Until Canceled",
    purgedMessage:
      "We have detected that you have not @:Brand.carbonite{''} installed. To install @:Brand.carbonite{''} on the computer you are on please click {here} and follow the directions to download and install @:Brand.carbonite{''}",
    here: "here",
    changePlan: "Change Plan",
    on: " on ",
    signUpToRenewAutomatically: "Sign up to renew automatically",
    addVideo: "Turn ON Video backup",
    recoveryDisk: "Recovery disk",
    addEncryptionKey: "Upload my encryption key",
  },
  CardCreditComponent: {
    endsIn: "Ends in",
    expires: "Expires",
    defaultCreditCard: "Default credit card",
    makeDefaultLink: "Make this the default credit card",
  },
  CardUninstalledSubComponent: {
    installNow: "Install Now",
    expires: "Expires",
    activeUntilCancelled: "Active until cancelled",
  },
  CardWsacSafeLiteComponent: {
    installNow: "Install Now",
    expires: "Expires",
    installWSACDisclaimer: "Install @:Brand.WebrootComplete{''} to activate this @:Brand.carbonite{''} subscription.",
    goToDownloads: "Go to Downloads",
    notActivated: "Not activated yet",
  },
  CartComponent: {
    subscription: "subscription",
    termDropDownYear: "year subscription",
    termDropDownMonthly: "monthly subscription",
    calcSalesTax: "Calculate sales tax",
    total: "Total",
    promoCode: "Promo Code",
    apply: "Apply",
    protectionForComputer: "Protection for {quantity} computer",
    taxDisclaimer: "*Sales tax is calculated based on payment information entered.",
    updating: "Updating...",
    supportBBYTitle: "Support for @:Brand.bestBuy{''} Customers",
    itLooksLikeBBY: "It looks like you bought this subscription through @:Brand.bestBuy{''}.",
    renewYourSubscriptionBBY: "To renew your subscription,",
  },
  CartItem: {
    perYear: "Per Year",
    qty: "Qty:",
    quantity: "Quantity:",
    maxQuantityOfOne: "Quantity: 1",
    appliesToRemainder: "Applies to remainder of current subscription",
  },
  CheckHasRecentPurchase: {
    header: "Duplicate Purchase?",
    p1: "It looks like you purchased an upgrade or renewal for {computerName} in the last 5 minutes. ",
    p2: "You can check the {subscriptionsLinkText} to see the status of that purchase.",
    subscriptionsLinkText: "Subscriptions page" /* This goes into the p2 text for {subscriptionsLinkText} */,
    p3: "Duplicating this purchase will add an additional {termLength} year(s) to your subscription for {computerName}.",
    p4: "Do you want to complete this purchase?",
    yesBtn: "Yes, Complete Purchase",
    noBtn: "No, Cancel this Purchase",
  },
  ChoosePlan: {
    choosePlan: "Choose Plan",
    numberOfComputers: "Number of computers:",
    additional: "Extra",
    off: "off",
    downgradeWarningL1: "If you downgrade to {toSku}, you will lose these features:",
    backupExternalDrives: "Backup of external hard drives*",
    automaticVideoBackup: "Automatic video backup*",
    webrootAntivirus: "@:Brand.WebrootAntiVirus",
    courierRecoveryService: "@:Brand.courierRecoveryService{''}",
    courierRecoveryDisclaimer: "@:Brand.courierRecoveryService{''} is still available as a per-delivery paid service.",
    downgradeDisclaimer:
      "*Any data backed up from these features will be deleted and will be unrecoverable from our servers.",
  },
  Common: {
    // when adding new strings to Common, please add them alphabetically so, it is easy to visually scan if a string is available in this block
    address1: "Address",
    address2: "Address line 2 (optional)",
    addToCart: "Add to Cart",
    backupTrial: "Backup trial",
    basic: "Basic",
    cancel: "Cancel",
    cancelled: "Cancelled",
    cart: "Cart",
    change: "Change",
    city: "City",
    close: "Close",
    confirmEmail: "Confirm Email Address",
    contactSupport: "contact support",
    copy: "Copy",
    country: "Country",
    device: "Device",
    devices: "Devices",
    discount: "Discount",
    download: "Download",
    downloadAndInstall: "Download and install",
    edit: "Edit",
    email: "Email Address",
    expires: "Expires",
    extendYourProtection: "Extend Your Protection",
    family: "Family",
    fName: "First name",
    goToBackup: "Go to Backup",
    goToDownloads: "Go to Downloads",
    goToMyAccount: "Go To @:Brand.myAccount{''}",
    hide: "Hide",
    identity: "Identity",
    identities: "Identities",
    individual: "Individual",
    keycode: "Keycode",
    learnMore: "Learn More",
    lName: "Last name",
    loading: "Please do not refresh the page or click the Back button.",
    logIn: "Log In",
    monthToMonth: "Monthly",
    next: "Next",
    nextSteps: "Next Steps",
    of: "of",
    option1: "Option 1:",
    option2: "Option 2:",
    password: "Password",
    payment: "Payment",
    plus: "Plus",
    premium: "Premium",
    prime: "Prime",
    remove: "Remove",
    renewNow: "Renew now",
    reviewOrder: "Review Order",
    save: "Save",
    security: "Security",
    selectOne: "- Select one -",
    selectOption: "Select an Option",
    show: "Show",
    state: "State",
    submit: "Submit",
    subscription: "Subscription",
    success: "Success",
    viewDetails: "View details",
    visitMyAccount: "Visit @:Brand.myAccount{''}",
    webrootDotCom: "www.webroot.com", //Do not use as an URL. Use to display the URL.
    windows10: "Windows 10",
    windows11: "Windows 11",
    year: "year",
    yearCapital: "Year",
    years: "years",
    zip: "Zip",
  },
  CommonErrors: {
    ActivationCodeInvalid: "Activation Codes can only contain letters and numbers with hyphens.",
    keycodeInvalid:
      "Keycodes must be {length} characters long (excluding hyphens) and only contain letters and numbers.",
    emailLengthGreaterThanMin: "Email address must be at least {minLength} characters long.",
    emailLengthLessThanMax: "Email address must be under {maxLength} characters.",
    emailMoreThanOneAt: "Email address can only contain one \"{'@'}\" symbol.",
    emailFollowTLDPattern: "Email address must follow the format \"username{'@'}address.com\".",
    lengthOutOfRange: "Enter a response between {min} and {max} characters long.",
    invalidCharacters: "For security reasons, the {invalid} character(s) cannot be used",
    requiredFieldError: "Required field not completed",
  },
  ComputerNameComponent: {
    headerH1: "Rename Computer",
    computerName: "Computer Nickname",
    save: "Save",
    cancel: "Cancel",
  },
  ContactUsComponent: {
    smbSupport: "@:Brand.carbonite{''} Business Support",
    technicalSupport: "Technical Support",
    salesSupport: "Sales Support",
    chat: "Chat",
    chatNow: "Chat now" /* should not be translated */,
    phone: "Phone",
    callUs: "Call us",
    email: "Email",
    emailUs: "Email us",
    techPhoneChatHoursWeekday: "Monday - Friday, 9:00 AM - 7:30 PM ET",
    techPhoneChatHoursWeekend: "Saturday - Sunday, 9:00 AM - 5:00 PM ET",
    techEmailDays: "Monday - Sunday",
    salesPhone: "1-877-334-7621",
    salesPhoneEmailHours: "Monday - Friday, 8:30AM - 6:00PM ET",
    excludingHolidays: "(Excluding Holidays)",
  },
  CourierRecoveryComponent: {
    headerH1: "@:Brand.courierRecovery{''}",
    description:
      "@:Brand.carbonite{''} will transfer your files to an encrypted hard drive and ship it to the address you provide. To ensure security, we will email you a unique passcode to decrypt the files.",
    descriptionNote:
      "Note: @:Brand.courierRecovery{''} can only ship to addresses in the United States. We cannot ship to PO Boxes.",
    step1H1: "Step 1 - Uninstall @:Brand.carboniteSafe{''}",
    step1Description:
      "If possible, uninstall @:Brand.carboniteSafe{''} from your computer to prevent changes to your backup. Failure to do so could cause damage that would prevent full restoration of your files.",
    btnStep1UninstallMac: "Uninstalling @:Brand.carboniteSafe{''} for macOS",
    btnStep1UninstallWindows: "Uninstalling @:Brand.carboniteSafe{''} for Windows",
    step1Note:
      "Please Note: If you have suffered a ransomware infection, your backup will need to be adjusted by @:Brand.carboniteCustomerCare{''} before your @:Brand.courierRecovery{''} request can be processed.",
    btnStep1Recovering: "Recovering from Crypto Viruses and Other Ransomware",
    step2H1: "Step 2 - Select Computer for Recovery",
    step2Note:
      "If you don't see your computer in the list below, then you may have elected to manage its encryption key yourself. In order to request a @:Brand.courierRecovery{''} drive, you will need to upload the encryption key and start the request over. For more information, read the article:",
    btnStep2Note: "Managing Your Private Encryption Key for @:Brand.carboniteSafe{'.'}",
    colComputer: "Computer",
    colUsage: "Usage",
    colPlan: "Plan",
    next: "Next",
    headerH1Continued: "@:Brand.courierRecovery{''} (continued)",
    btnPreviousStep: "Previous step",
    step3Description:
      "Please enter your contact information, as well as the recipient's information and address below.",
    step3H1: "Step 3 - Contact Information",
    step3FName: "First Name",
    step3LName: "Last Name",
    step3Email: "Email Address",
    step3Phone: "Phone Number",
    step4H1: "Step 4 - Shipping Information",
    step4Note:
      "@:Brand.courierRecovery{''} can only ship to addresses in the United States. We cannot ship to PO Boxes.",
    step4FName: "Recipient First Name",
    step4LName: "Recipient Last Name",
    step4Phone: "Recipient Phone",
    step4Address: "Address Line 1",
    step4Address2: "Address Line 2 (optional)",
    step4City: "City",
    step4State: "State",
    step4Zip: "Zip Code",
    orderSummaryDescription:
      "@:Brand.carbonite{''} will transfer your files to an encrypted hard drive and ship it to the address you provide. To ensure security, we will email you a unique passcode to decrypt the files.",
    step5H1: "Order Summary",
    step5ComputerBackup: "Computer Backup:",
    step5Contact: "Contact:",
    step5ShippingInformation: "Shipping Information:",
    step5ShippingStandardA: "Standard (USPS)",
    step5ShippingStandardB: "Estimate: 1-3 business days",
    step5ShippingExpeditedA: "Expedited (FedEx)",
    step5ShippingExpeditedB: "Estimate: next business day",
    step5ShippingError: "*Please select a delivery method above.",
    CourierAddCard: "+ Add Card",
    paymentH1: "Payment",
    total: "Total",
    step6H1: "Payment",
    step6Note:
      "Any @:Brand.courierRecovery{''} drive that is not returned within 21 days of receipt will cause you to incur a charge between $130 and $300 depending on the capacity, and $10 for unreturned drive cables.",
    certifications: "Certifications",
    certificationsDescription:
      "I represent and warrant to @:Brand.carbonite{''} that I am the billing owner of the @:Brand.carbonite{''} Subscription, and that I have all of the rights necessary to permit @:Brand.carbonite{''} to access and restore my backup and perform the @:Brand.courierRecovery{''} detailed above. I acknowledge that I am bound by @:Brand.carbonite{'’s'} Terms of Service that I previously agreed to, found here:",
    tos: "@:Brand.carbonite{''} Terms of Service",
    permissionLabel:
      "I give @:Brand.carbonite{''} permission to charge my credit card for the @:Brand.courierRecovery{''} detailed above.",
    btnSecureCheckout: "Secure Checkout",
    step7H1: "@:Brand.courierRecovery{''} - Case Submitted",
    step7P1:
      "Thank you for submitting a @:Brand.courierRecovery{''} request. Our Data Recovery team will confirm your request shortly.",
    step7P2:
      "We will send you an email receipt, and an email with tracking information for your shipment once your order has been processed.",
    step7P3a: "If you need assistance, please",
    step7P3b: "visit our support page",
    step7P3c: '(search for "courier" in the search box).',
    payWith: "Pay With:",
    Step5Note:
      "Any @:Brand.courierRecovery drive that is not returned within 21 days of receipt will cause you to incur a charge between $130 and $300 depending on the capacity, and $10 for unreturned drive cables.",
    courierRecoveryFor: "@:Brand.courierRecovery for",
    courierRecoveryPrice: "$99.99",
    StandardShippingPrice: "$9.99",
    ExpeditedShippingPrice: "$29.98",
    priceZero: "$0.00",
    caseNumberIs: "Your case number is:",
    macNote: "What type of macOS computer do you plan on restoring the files to?",
    intelProcessor: "Intel processor",
    macProcessor: "Apple M1 or newer processor",
    macProcessorUrlText: "How do I determine which processor my computer has?",
    errorHeader: "Error Processing Case",
    error1Line: "There was a problem processing your request.",
    error2Line: "We recommend trying again a little later, or if you need help or continue to encounter this issue ",
    errorSupportLink: "contact customer support.",
    summaryHeader:
      "Review your order and select your payment method. After you place your order, our Data Recovery Team will confirm your order and contact you shortly with next steps.",
    courierRecoveryPriceIncluded: "$0.00",
    defaultCard: "(Default)",
    cardEndingWith: "ending with *",
    checkoutButton: "Secure Checkout",
  },
  CourierRecoveryFormErrors: {
    firstNameInvalidCharacter: "The {0} character cannot be used.",
    lastNameInvalidCharacter: "The {0} character cannot be used.",
    emailFormat: "Email address must follow the format \"username{'@'}address.com\"",
    emailLengthMin: "Email address must be at least 7 characters long.",
    emailLengthMax: "Email address must be under 128 characters.",
    emailSymbol: "Email address can only contain one \"{'@'}\" symbol.",
    addressInvalidCharacter: "The {0} character cannot be used.",
    cityInvalidCharacter: "The {0} character cannot be used.",
    nameLengthMin: "Name Required",
    invalidPhoneFormat: "This is not a valid U.S. phone number.",
    phoneLength: "This is not a valid U.S. phone number.",
    addressLength: "Address must be between 3 and 60 characters",
    cityLength: "City must be between 2 and 50 characters",
    stateSelect: "Please select a state",
    stateLength: "U.S. state is required.",
    zipLength: "Zip must be between 5 and 9 digits.",
    zipNumbersOnly: "Zip must be all numbers.",
    firstNameLengthMax: "First Name must be under 50 characters.",
    lastNameLengthMax: "Last name must be under 50 characters.",
    RecipientFirstNameLengthMax: "Recipient first name must be under 50 characters.",
    RecipientLastNameLengthMax: "Recipient last name must be under 50 characters.",
    firstNameLengthMin: "First name is required.",
    lastNameLengthMin: "Last name is required.",
  },
  CreateAccount: {
    welcomeHeading: "Welcome to @:Brand.myAccount{''}",
    welcomeMessage1:
      "Peace of mind through award-winning security and protection. Now you can access @:Brand.webroot{''} antivirus and @:Brand.carbonite{''} cloud backup in one easy spot!",
    welcomeMessage2: "Simply create an account to download, install, and manage your products.",
    createAccount: "Create Account",
    alreadyHaveAnAccountMessage: "Already have an account? ",
    redirectLogIn: "Log In",
    confirmPassword: "Confirm Password",
    createAccountError: "We could not create an account. Please check your internet connection and try again.",
    passwordRequirements: "Password requirements:",
    invalidPasswordCharacter: 'The "{char}" character cannot be used.',
    passwordNotMatch: "Passwords do not match",
  },
  CreditCardsComponent: {
    cardsOnFile: "Credit cards on file",
    addCardBtn: "Add a new credit card",
    editCardBtn: "Need to edit a credit card?",
  },
  CrossPlatformTrialMoveSubscriptionComponent: {
    headerH1: "Move my subscription",
    trialCrossPlatformP1:
      "We currently do not offer customers the ability to transfer their @:Brand.carbonite{''} trial from a {agentOs} computer to a {browserOs} computer. However, you may start another trial on your new computer by closing the window and clicking the '{upgradeAdd}' button.",
    trialCrossPlatformP2:
      "If you have any additional questions, please contact Customer Support at {carboniteContactUrl}",
    upgradeAdd: "Upgrade / Add",
    supportLink: "support.carbonite.com",
    ok: "OK",
  },
  CSSBCancelDownloadComponent: {
    headerH1: "Cancel Download",
    p1: "Download is not complete. Do you want to cancel this process?",
    continueBtn: "Continue Download",
    closeBtn: "Cancel Processing",
  },
  CssbDownloadWatcherComponent: {
    title: "Preparing download",
    subtitle: "Leave this window open to complete the download process.",
    message: "Compressing {numberOfFiles} files into a ZIP file. Download will start automatically when ready.",
  },
  DashboardTabsComponent: {
    computers: "Computers",
    servers: "Servers",
    users: "Users",
    groups: "Groups",
  },
  DeviceComponent: {
    protected: "Protected",
    shieldsDisabled: "Partially Protected",
    protectionDisabled: "Protection Disabled",
    expired: "Expired",
    howToEnableBtn: "How to Enable",
    managingShields: "Managing shields",
    identityShield: "Identity Shield:",
    infraredShield: "Infrared Shield:",
    offlineShield: "Offline Shield:",
    phishingShield: "Phishing Shield:",
    realtimeShield: "Realtime Shield:",
    rootkitShield: "Rootkit Shield:",
    scriptShield: "Script Shield",
    usbShield: "USB Shield:",
    webShield: "Web Shield:",
    hideThisDevice: "Hide this device",
    showLessLink: "Show less",
    showMoreLink: "Show more",
    version: "Version:",
    shieldOn: "On",
    shieldOff: "OFF",
    removeDevice: "Remove Device",
    removeDeviceLine1: "{nickname} will no longer be protected.",
    removeDeviceLine2: "Confirm by clicking on the <strong>Remove Device</strong> button below.",
    removeDeviceReceivedLine1: "Your request to remove {nickname} has been received.",
    removeDeviceReceivedLine2: "To re-activate protection in the future, re-install the software on this device.",
  },
  DownloadProductComponent: {
    linkFooterNeedHelp: "View detailed instructions",
    AboutSecurityMobileLogin: "About @:Brand.webrootMobileLogin{''}",
    SAFEUnsupported: "Safe Cloud Backup is only available for Windows and macOS devices.",
    SAFEStepOne: "Be sure you are on the computer you want to backup.",
    SAFEStepTwo: "Download and Run the installation file.",
    SAFEStepThree: "Follow the on-screen prompts to select the important files you want to protect.",
    SAFEStepThreeReinstall:
      "Follow the on-screen prompts to create a new backup or to restore files to your computer from an existing backup.",
    SAFEMOBILEStepOne: "Get the app from the app store.",
    SAFEMOBILEStepTwo: "Log in with your @:Brand.myAccount{''} email address and password.",
    WSAPUnsupported: "@:Brand.webrootProtection{''} is only available for Windows and macOS devices.",
    WSAPUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WSAPStepOne: "Download for:",
    WSAPStepTwo: "When prompted, enter the desired keycode (available keycodes below).",
    WWTSUnsupported: "@:Brand.WebrootWTS{''} is only available for browsers on Windows, macOS and Chrome OS devices",
    WWTSUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WWTSStepOne: "Get the extension for your browser.",
    WWTSStepTwo: "When prompted, enter the desired keycode (available keycodes below).",
    WRMSUnsupported: "This download only available for Android, iOS, Windows, macOS, Chrome OS, Linux devices",
    WRMSUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WRMSStepOne: "Visit this page on the mobile device you want to protect. {copyLink}",
    WRMSStepTwo: "Get the app from the app store.",
    WRMSStepThree:
      "The <strong>@:Brand.webrootMobileLogin{''} </strong> differs from your <strong>@:Brand.myAccount{''}</strong> portal login. Use your keycode to create a new account or if you already have a Mobile Login, use your existing credentials.",
    WSCBUnsupported: "This download only available for Android, iOS, Windows, macOS, Chrome OS, Linux devices",
    WSCBUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WSCBStepOne: "Visit this page on the mobile device you want to protect. {copyLink}",
    WSCBStepTwo: "Get the app from the app store.",
    WSCBStepThree:
      "The <strong>@:Brand.webrootMobileLogin{''} </strong> differs from your <strong>@:Brand.myAccount{''}</strong> portal login. Use your keycode to create a new account or if you already have a Mobile Login, use your existing credentials.",
    WIFIUnsupported: "This download only available for Android, iOS, Windows, macOS, Chrome OS, Linux devices",
    WIFIUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WIFIStepOne: "Download or get the app from the app store:",
    WIFIStepTwo:
      "If you have not created a @:Brand.WebrootSecureVPN{''} account yet, please open the order confirmation e-mail sent shortly after the purchase for detailed instructions to set up the account and use this app.",
    WIFIStepThree:
      "Launch the app and create a new <strong>@:Brand.webrootMobileLogin{''} </strong> using your keycode, or log in using your existing credentials.",
    LPPMUnsupported: "This download only available for Android, iOS, Windows, macOS, Chrome OS, Linux devices",
    LPPMStepOne: "Visit this page on the mobile device you want to protect. {copyLink}",
    LPPMStepTwo: "Get the mobile app or install the browser extension.",
    LPPMStepThree: "Launch the app and log in with your @:Brand.lastPass{''} account.",
    WSVPNStepOne: "Download and install the app.",
    WSVPNStepTwo: "Launch the app and log in with your <strong>@:Brand.myAccount{''}</strong> credentials.",
    WSVPNMStepOne: "Download and install the app.",
    WSVPNMStepTwo: "Launch the app and log in with your <strong>@:Brand.myAccount{''}</strong> credentials.",
    DownloadFor: "Download for",
    GetExtensionFor: "Get the Extension for",
    btnDownload: "Download",
    btnClose: "Close",
    ReinstallSafe: "Reinstall @:Brand.carboniteSafe{''}",
    timeDelayWarning: "NOTE: It might take up to 24 hrs for new installations to reflect on the portal.",
    copyLink: "Copy link",
    windowsArmInfoLink: "How do I tell which version of Windows I have?",
    WPCOMainParagraph: "@:Brand.WebrootPcOptimizer{''} is only available for Windows® computers.",
    WPCOStepOne: "Download and run the installation file.",
    WPCOStepTwo: "When prompted, enter the desired keycode (available keycodes below).",
    WPCOUnsupported: "@:Brand.WebrootPcOptimizer{''} is only available for Windows computers.",
    WPCOUnsupportedReference: "For reference: here are your keycodes, which activate the software.",
    WTPUnsupported: "@:Brand.webroot{''}  protection is only available for Windows and macOS devices.",
    WTPUnsupportedReference: "For reference, here are your keycodes, which activate the software.",
    WTPStepOne: "Download and run the installation file.",
    WTPStepTwo: "When prompted, enter the desired keycode (available keycodes below).",
    WTPNote: "Note: It may take up to 24 hours for newly installed devices to be reflected in this portal.",
  },
  DownloadSafeComponent: {
    downloadNow: "Download Now",
    downloadingOnWindows: "Downloading on Windows?",
    sha1Message:
      "{download} You may receive a security alert while running the installer. Despite this warning, it is perfectly safe to continue running the installer. {moreDetails}",
    moreDetails: "More details here",
  },
  DownloadsComponent: {
    downloadsH1: "My Available Downloads",
    GetStartedHeader: "Get started",
    SetUpKeyFeaturesHeader: "Set up key features",
    MobileAppsHeader: "Mobile apps",
    SAFEHeader: "@:Brand.carboniteSafeCloudBackup{''}",
    SAFEParagraph: "Secure cloud backup and restoration of files on your computer.",
    SAFEMOBILEHeader: "@:Brand.carboniteMobile{''}",
    SAFEMOBILEParagraph: "Access your computer backups from your mobile device.",
    WSAPHeader: "@:Brand.WebrootAntiVirus{''}",
    WSAPParagraph: "Protects your computer against viruses, ransomware, phishing attempts, and more.",
    WSCBHeader: "@:Brand.webroot{''} for Chromebook",
    WSCBParagraph: "Chromebook protection against malicious sites and apps.",
    WSCBAboutLogin: "About @:DownloadsComponent.WSCBHeader Login",
    WIFIHeader: "@:Brand.WIFI{''}",
    WIFIParagraph: "Protects your privacy on unsecured, public Wi-Fi networks.",
    WIFIAboutLogin: "About @:DownloadsComponent.WIFIHeader Login",
    WWTSHeader: "@:Brand.WebrootWTSBE{''}",
    WWTSParagraph:
      "Protects you from malicious, fraudulent and risky links and websites. Note: Windows users don’t need to download this separately.",
    WRMSHeader: "@:Brand.WebrootMS{''}",
    WRMSParagraph: "Mobile protection against malicious sites and apps.",
    WSVPNHeader: "@:Brand.WebrootSecureVPNP{''}",
    WSVPNParagraph:
      "Install @:Brand.WebrootSecureVPNP{''} to stay private on public Wi-Fi hotspots, with bank-grade encryption.",
    WSVPNMHeader: "@:Brand.WebrootSecureVPNP{''} for Mobile",
    WSVPNMParagraph:
      "Install @:Brand.WebrootSecureVPNP{''} to stay private on public Wi-Fi hotspots, with bank-grade encryption.",
    WTPHeader: "@:Brand.WebrootTotalProtection{''}",
    WTPParagraph: "Install and manage your main device protection and additional features.",
    WebrootBackupAndRestoreHeader: "@:Brand.WebrootBackupAndRestore{''}",
    WebrootBackupAndRestoreParagraph: "Secure cloud backup and restoration of files on your computer.",
    keycodesH1: "My Keycodes",
    keycodesInfo: "Keycodes are used to activate some of the @:Brand.webroot{''} apps above.",
    keycodesInfoParagraph: "Don't see one of your keycodes?",
    WPCOHeader: "@:Brand.WebrootPcOptimizer",
    WPCOParagraph: "Cleans your device and improves system performance.",
    hide: "Hide",
    show: "Show",
    expires: "expires",
    Expired: "Expired",
    btnGoToBackup: "@:Common.goToBackup",
    btnGetTheApp: "Get the App",
    downloadTheApp: "Download",
    btnGetTheExtension: "Get the Extension",
    btnActivate: "Activate",
    btnManage: "Manage",
    btnAddKeycode: "Add a Keycode",
    additionalSecurityH1: "Explore Additional Security",
    WSAVHeader: "@:Brand.WebrootAntiVirus{''}",
    validateEmailWarning: "*For your security, you will be asked to verify your email address before adding keycodes.",
    safeCloudBackupParagraph:
      "@:Brand.carbonite{''} provides the #1 way to securely back up your files.Need to protect your important files and data from loss, theft, hard drive failure and physical damage? Look no further.",
    exploreSecureAnywhereParagraph:
      "Protect your data, devices and identity from hackers and cyber-thieves with our award-winning antivirus and internet security solutions. Get the protection you need to shut the door on cybercrime.",
    learnMore: "Learn more",
    WindowsVersions: "Windows (x64/x86)",
    ArmVersions: "Windows (ARM)",
    MacVersions: "macOS",
    MacWSAPVersion: "macOS",
    ChromeVersions: "Google Chrome",
    ChromeOSVersions: "Chrome OS",
    EdgeVersions: "Microsoft Edge",
    FirefoxVersions: "Mozilla Firefox",
    AndroidVersions: "Android",
    iOSVersions: "iOS",
    keycodesInactiveHeader:
      "You don't have any active subscriptions at this time. Any previous subscriptions have expired.",
    keycodesInactiveParagraph: "Please choose one of the following options.",
    keycodesInactivePoint1:
      "If you bought a subscription from a store, add your keycode below to associate it with your account.",
    keycodesInactivePoint2: "Visit the {subscriptions} page to renew an expired subscription.",
    keycodesInactivePoint3: "Purchase a new subscription below.",
    subscriptions: "Subscriptions",
    verifyEmailMessage:
      "For security purposes, some apps or purchases can't be accessed until you verify your email address.",
    btnVerifyEmail: "Verify Email Address",
    discoverSafeSolutions: "Discover @:Brand.carboniteSafe{''} Solutions",
    discoverWebrootSolutions: "Discover @:Brand.webrootSecureAnywhere{''} Solutions",
  },
  DownloadWebrootTotalProtection: {
    p1: "Ready to install @:Brand.WebrootBackupAndRestore{''} and start backing up your new computer.",
    p2: "To get started:",
    li1: "Visit this page on the computer you want to back up.",
    li2: "Click Download Now to download and run the installation file.",
    li3: "Follow the on-screen prompts to back up your files.",
    p3: "Once installed, @:Brand.WebrootBackupAndRestore{''} software identifies the files and folders you most likely want to protect, or you can select them yourself. Once the software is running, it automatically and securely copies your files to the cloud.",
  },
  EditPayment: {
    savePayment: "Save Payment Information",
  },
  EditShipping: {
    submit: "Save Shipping Information",
    back: "Back to review order",
  },
  EmailVerification: {
    emailVerifiedH1: "Email Verified",
    successP1: "You have successfully verified your email address. Click the button below to continue to your account.",
    verificationFailedH1: "Unable to Verify Email Address",
    expiredP1:
      "The verification link may have expired. The verification link is only valid for {expirationTime} from the time it was sent.",
    expiredP2: "Click {sendNewEmail} for a new verification request to be sent.",
    sendNewEmail: "Send a New Email",
    failureP1:
      "The verification link is no longer valid. Please go back to Personal Information and submit a new request.",
    expirationTime: "6 hours",
    needHelp: "If you need help, {contactSupport}.",
    emailSent: "Email Sent",
    backToLogin: "Back to Login",
    serverErrorH1: "Something Went Wrong on Our End...",
    serverErrorP1:
      "Something happened to our website that prevented it from finding the information you were looking for. Please try again or check back later.",
  },
  EmailVerificationNotComplete: {
    header: "Unable to Verify Email Address",
    bodyP1:
      "The verification link may have expired. The verification link is only valid for 6 hours from the time it was sent.",
    bodyP2: "Click Send a New Email for a new verification request to be sent.",
    bodyP3: "If you need help, {contactSupport}.",
    btnState1: "Send a New Email",
    btnState2: "Email Sent",
  },
  ErrorComponent: {
    h1Heading: "Something Went Wrong...",
    p1Body: "Something went wrong on our end. Refresh the page, try again later, or contact Support.",
  },
  ExpiredSubSupportSectionComponent: {
    h1Heading: "Support",
    findHelpCarbText: "Find help for @:Brand.carboniteSafe{''}",
    carboniteCustomerSupport: "@:Brand.carbonite{''} Customer Support",
    carboniteSupportText: "Go to @:Brand.carbonite{''} customer support",
    webrootCustomerSupport: "@:Brand.webroot{''} Customer Support",
    findHelpWebrootText:
      "Find help for @:Brand.webroot{''} protection, the @:Brand.WebrootWTS{''} extension, @:Brand.WebrootMS{''} app and @:Brand.WIFI{''} app.",
    findHelpWebrootLine1: "Find help for:",
    findHelpWebrootLine2: "- @:Brand.webrootProtection{''}",
    findHelpWebrootLine3: "- The @:Brand.WebrootWTS{''} extension",
    findHelpWebrootLine4: "- @:Brand.WebrootMS{''} app",
    findHelpWebrootLine5: "- @:Brand.WIFI{''} app.",
    webrootSupportText: "Go to @:Brand.webroot{''} customer support",
  },
  FileAccessComponent: {
    bytes: "B",
    kilobytes: "KB",
    megabytes: "MB",
    gigabytes: "GB",
    terabytes: "TB",
    btnDownloadSelectedFiles: "Download selected files",
    selectForDownloadTitle: "Select for download",
    selectAll: "Select all on this page",
    search: "Search",
    searchResults: '{results} results for "{term}"',
    clearSearchResults: "Clear search",
    searchPlaceholder: "search...",
    info: "Maximum of 5,000 items and {maxDownloadSize}GB per download",
    selectionInfo: "{count} files ({size}) selected",
    countExceeded: "Maximum of 5,000 items per download",
    sizeExceeded: "Maximum of {maxDownloadSize}GB download",
    bothExceeded: "Maximum of 5,000 items and {maxDownloadSize}GB per download",
    fileWasDeleted: "Deleted from computer",
    fileWillBeDeleted: "- will be removed from cloud soon",
    fileAvailableSingular: "- is available to restore for the next day",
    fileAvailablePlural: "- is available to restore for the next {days} days",
    sortNameAZ: "Name (A-Z)",
    sortNameZA: "Name (Z-A)",
    sortExtensionAZ: "File type (A-Z)",
    sortExtensionZA: "File type (Z-A)",
    sortSizeSmallFirst: "Size (smallest first)",
    sortSizeLargeFirst: "Size (largest first)",
    sortDateNewFirst: "Date (newest first)",
    sortDateOldFirst: "Date (oldest first)",
    files: "({0} files)",
    downloadZipInfo: "A ZIP file is being created for download, and will automatically download when completed.",
    pagination: "{size} per page",
    nothingBackedUp: "Nothing is backed up for this device right now",
    driveEncrypted1: "This computer uses a private encryption key.",
    driveEncrypted2: "@:Brand.remoteFileAccess{''} is not available for computers that use a private encryption key.",
    driveEncrypted3a: "For more information, refer to",
    driveEncrypted3b: "Managing Your Private Encryption Key for @:Brand.carboniteSafe{'.'}",
    subscriptionExpired: "Your subscription for this computer has expired.",
    btnSubscriptionExpired: "Renew My Subscription",
    restrictedFile: "For your security, this file is restricted from downloading",
    rfaDisabledP1: "@:Brand.remoteFileAccess{''} has been disabled for this account.",
    rfaDisabledP2: "If you need to re-enable it, please {contactSupport}.",
    folderEmpty: "This folder is empty",
    searchNotAvailable: "Search is not available for servers. Please go into a backup set below to perform a search.",
    backupRun: "Backup Run",
    availableInRuns: "Available in {numberOfResults} backup runs",
    servers: "Servers",
    backupPath: "Backup Path",
    tooManyFiles: "Maximum of 5,000 files per download",
    restoreTooBig: "Maximum of 50 GB per download",
    notFound: "There was an issue, please try again",
    emptyFolder: "There are no files in this folder to download",
    downloadError: "An error occurred with compressing {numberOfFiles}. Please try again.",
    genericDownloadError: "An error occurred. Please try again.",
    requestMaxLimit: "Maximum of 5 active downloads reached. Please wait for some downloads to complete",
    multipleEncryptedRunsDownloadError1: "Downloading protected backup runs is limited to one at a time",
    multipleEncryptedRunsDownloadError2:
      "Files from other encrypted backup runs can be downloaded with a separate request",
    passPhraseError:
      "The passphrase entered was incorrect. Please retry your download and enter the correct passphrase.",
  },
  FinalizeAccount: {
    headerH1: "Finalize Your Account",
    confirmNewPassword: "Confirm Password",
    password: "Password",
    passwordRequirements: "Password requirements:",
    passwordMatch: "Passwords do not match.",
    invalidPasswordCharacter: 'The "{char}" character cannot be used.',
    finishSetup: "Finish Account Setup",
    welcome: "Welcome {email}",
    pleaseEnter: "Please enter a password to protect your account.",
    needToLogin: "You already have a password or your account was not found. Please go to ",
    passwordAdded: "Your password has been updated please go to ",
    login: "login",
  },
  FlatRateCommon: {
    btnUpgrade: "Upgrade Offers",
    upgradeOffersParagraph:
      "Click <strong>Upgrade Offers</strong> to learn more about upgrade offers available for you.",
    contactSupportParagraph: "Please {contactSupport} if you feel this was in error, or if you need help upgrading.",
  },
  FlatRateExpiredLicense: {
    header: "This offer requires an active subscription",
    p1: "This {currentSku} subscription has already expired. The upgrade offer is only valid for active subscriptions.",
    p2: "If you would like to resume your subscription and keep your devices protected, you can renew your subscription.",
    p3: "Or you can {link}.",
    exploreOffers: "explore other Webroot security offerings",
    btnRenew: "Renew Now",
  },
  FlatRateOfferMismatch: {
    header: "This offer doesn't match the selected product",
    p1: "The upgrade offer is only valid for {validSku}. Your subscription is {currentSku}.",
  },
  FlatRateRemainingDaysInvalid: {
    header: "This subscription has already been renewed",
    p1: "This {currentSku} subscription has already been renewed and the upgrade offer is no longer valid.",
  },
  FooterComponent: {
    contactUs: "Contact Us",
    agent: "Agent",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    copyright: "{currentYear} {copyrightBrand}",
    allRightsReserved: "All rights reserved",
    support: "Support",
    cookieSettings: "Cookie Preferences",
  },
  GetFilesWizardComponent: {
    headerStepStart: "Get Files Back",
    lostFiles: "Lost your files? We'll help you get them back ASAP.",
    howWould: "How would you like to restore your files?",
    stepStartOption1: "Download my files to this computer.",
    stepStartOption2: "Send my files to me on an external hard drive (additional charges will apply).",
    courierUSOnly: "@:Brand.courierRecovery{''} can only ship recovery hard drives to addresses in the United States.",
    courierContactSupportText: "If you need additional help recovering your files, {link}",
    incompatibleOSWarningMac: "This macOS backup can only be directly restored to a macOS computer.",
    incompatibleOSWarningWindows: "This Windows backup can only be directly restored to a Windows computer.",
    incompatibleOSInfoMessage: "You are using a {OS} computer.",
    next: "Next",
    headerStepDownload: "Get Files Back",
    whichFiles: "Which files would you like to restore?",
    allFiles: "All my files",
    allFilesText:
      "If you need to restore all or most of the files in your @:Brand.carbonite{''} Backup, use the Complete Restore option.",
    allFilesCdlp:
      "If you need to restore all or most of the files in your @:Brand.WebrootBackupAndRestore{''} Backup, use the Complete Restore option.",
    completeRestore: "Complete Restore",
    onlyAFew: "Only a few files",
    onlyAFewCdlp: "Retrieve specific files",
    onlyAFewText:
      "Use @:Brand.carbonite{''} @:Brand.anytimeAnywhereAccess{''} to retrieve just a few specific files from your computer.",
    onlyAFewCdlpText:
      "Use @:Brand.webroot{''} @:Brand.remoteFileAccess{''} to retrieve specific files from your computer.",
    remoteAccess: "Remote Access",
    headerStepCompleteRestore: "Get Files Back",
    note: "<strong>Note:</strong> If you'd like to restore files to a computer other than the one you are using right now, please return to our website from the computer you'd like to restore your files.",
    isCarboniteInstalled: "Is @:Brand.carbonite{''} installed and running on this computer?",
    isCdlpInstalled: "Is @:Brand.WebrootBackupAndRestore{''} installed and running on this computer?",
    howToTell: "How to Tell?",
    yes: "Yes",
    no: "No",
    noneOfComputers: "Ready to install @:Brand.carboniteSafe{''} and start backing up your new computer.",
    toGetStarted: "To get started:",
    stepDownloadInstruction1: "Visit this page on the {computer}",
    stepDownloadInstruction1sub: "computer you want to back up",
    stepDownloadInstruction2: "Click {add}, to download and run the installation file. {note} {button}",
    stepDownloadInstruction2sub: "Add this Computer",
    stepDownloadInstruction3: "Follow the on-screen prompts to back up your files",
    onceInstalled:
      "Once installed, @:Brand.carboniteSafe{''} software identifies the files and folders you most likely want to protect, or you can select them yourself. Once the software is running, it automatically and securely copies your files to the cloud.",
    viewDetailed: "View detailed instructions",
    downloadNow: "Download Now",
    onlyAvailableOnDesktop: "Note: @:Brand.carboniteSafe{''} is only available for Windows and macOS.",
    considerTheseOptions: "Please consider these options:",
    contactSupportTeam: "Contact our support team for additional assistance.",
    macWithWindowsBackup:
      "You are using a Mac computer. The Windows backup can only be directly restored to a Windows computer.",
    windowsWithNacBackup:
      "You are using a Windows computer. The Mac backup can only be directly restored to a Mac computer.",
  },
  GetHelpComponent: {
    getHelp: "Get Help",
  },
  HeaderComponent: {
    myAccount: "@:Brand.myAccount{''}",
    support: "Support",
    logOut: "Log out",
  },
  HomeComponent: {
    home: "Home",
    securityOverview: "Security Overview",
    securityZeroState: "Security for Your Devices",
    webPagesScreened: "Web Pages Screened",
    pagesBlocked: "Pages Blocked",
    filesScanned: "Files Scanned",
    devicesBackedUp: "Devices backed up",
    malwareDetections: "Malware Detections",
    numberOfScans: "Number Of Scans",
    remediations: "Remediations",
    backupOverview: "Backup Overview",
    backupZeroState: "Backup to Keep Your Data Safe",
    backupZeroStateText: "Backup section shows key data points on how we’re consistently keeping your data safe.",
    backupNotPurchasedP1:
      "Your data is not protected against accidental deletions, computer crashes and other forms of data loss.",
    backupNotPurchasedP2: "{protect}, our award-winning data backup and recovery solution.",
    protectYourSelfWithCarbonite: "Protect yourself with @:Brand.carbonite{''}",
    securityZeroStateText:
      "The Security section will show key data points on how we’re keeping you protected from threats.",
    securityNotPurchasedP1: "Your data is not secured against viruses, malware, phishing and other digital dangers.",
    securityNotPurchasedP2: "{protect}, our award-winning internet security and antivirus solution.",
    protectYourSelfWithWebroot: "Protect yourself with @:Brand.webroot{''}",
    dataBackedUp: "Data Backed Up",
    numberOfFiles: "Number of files",
    protecting: "Protecting",
    threatProtection: "Threat Protection",
    kilobytes: "Kilobytes",
    megabytes: "Megabytes",
    gigabytes: "Gigabytes",
    terabytes: "Terabytes",
    thousand: "Thousand",
    million: "Million",
    billion: "Billion",
    trillion: "Trillion",
    computers: "computer(s)",
    shields: "shields",
    subscriptions: "Subscriptions",
    dangerDetails: "Attention Needed:",
    active: "Active",
    inactive: "Inactive",
    expiring: "Expiring Soon",
    expired: "Expired",
    cancelled: "Closed",
    filesRecovered: "Files Recovered",
    install: "Install",
    installAndActivate: "Install and Activate",
    installText: "Once installed, you should expect to see data within 24 hours.",
    webrootSecureAnywhereComplete: "@:Brand.webrootComplete{''}",
    carbonitePlus: "@:Brand.carboniteSafePlus{''}",
    webrootSecureAnywhereAntivirus: "@:Brand.WebrootAntiVirus{''}",
    comparePlans: "Compare Plans",
    comparePlansBBY: "Contact @:Brand.bestBuy{''}",
    apiError:
      "There was a problem retrieving information for your devices. Refresh the page, try again later, or contact support.",
    noSubscriptions: "Your subscription has expired and your data is at risk.",
    securityEmailNotValidated:
      "For security purposes, some apps or purchases can't be accessed until you verify your email address",
    goToBackup: "@:Common.goToBackup",
    goToDownloads: "@:Common.goToDownloads",
    btnVerifyEmail: "Verify Email Address",
    activateNow: "Activate Now",
  },
  ImagePreviewComponent: {
    footerButtonDownload: "Download",
    previous: "Previous",
    name: "Name",
    size: "Size",
    modified: "Modified",
    next: "Next",
  },
  InvoiceDetailsComponent: {
    invoiceDetails: "Invoice Details",
    payWith: "Pay with:",
    editPayment: "Edit Payment Info",
    selectCol: "Select",
    invoiceIdCol: "Invoice Id",
    dateCol: "Date",
    productCol: "Product",
    subscriptionCol: "Subscription",
    outstandingBalanceCol: "Outstanding Balance",
    payThisInvoice: "Pay this invoice",
    invoiceTotal: "Selected invoice total :",
    continueStatement: "By continuing, you agree to {termsAndConditions} and {privacyPolicy}.",
    termsAndConditions: "{openTextBrand} Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    computer: "Computer:",
    keycode: "Keycode:",
    renewNow: "Renew Now",
    cancel: "Cancel",
  },
  InvoiceComponent: {
    balanceDue: "Balance due",
    billTo: "Bill To:",
    colQty: "Qty",
    colSub: "Subscription",
    colAmount: "Amount",
    date: "Date:",
    endingIn: "Paid with {type} ending in *",
    invoiceNumber: "Invoice number:",
    outstandingBalanceCol: "Outstanding Balance",
    paid: "Paid",
    PriorSubscriptionCredit: "Prior subscription credit",
    print: "Print",
    PromoCodePct: "Promo code discount",
    PromoCodeAmt: "Promo code discount",
    shipTo: "Ship to:",
    subscriptionEnds: "Subscription valid through",
    Tax: "Sales Tax",
    Total: "Total",
    UsedCreditBalance: "Account credit",
  },
  KbSearchComponent: {
    search: "Search",
    knowledgebaseArticles: "Knowledgebase articles",
    knowledgebaseArticlesWebroot: "@:Brand.webroot{''} knowledgebase articles",
    popularSearches: "Popular searches",
    reinstallCarbonite: "Reinstall @:Brand.carbonite{''}",
    reinstallCdlp: "Reinstall @:Brand.WebrootBackupAndRestore{''}",
    renewSubscription: "Renew subscription",
    restoreFiles: "Restore files",
  },
  KeycodesComponent: {
    copy: "Copy",
    expires: "expires",
    keycodes: "Keycodes",
  },
  languageDropDownComponent: {
    label: "Language",
  },
  LastPassComponent: {
    managePasswords: "Manage Passwords",
    accountSetup: "Account Setup",
    activate: "Activate",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "Accounts used: {used} of {total}",
  },
  LastPassDialogComponent: {
    unusedAccounts:
      "You have unused @:Brand.lastPass{''} accounts available with your @:Brand.webrootProtection{''} subscriptions.",
    createNew: "Create a new @:Brand.lastPass{''} account",
    finishCreate: "Finish setting up your @:Brand.lastPass{''} account",
    used: "Used {qtd} of {total}",
    notActivated: "Not activated ({qtd} available)",
    goToLastPass: "Go to @:Brand.lastPass{'®'}",
    expires: "expires",
    activate: "Activate",
    yourKeycodes: "Your @:Brand.webroot{''} Keycodes",
    accountSetup: "Account Setup",
    activateAccount: "Activate @:Brand.lastPass{''}",
    linkFooterNeedHelp: "View detailed instructions",
    footerButtonCancel: "Close",
    ok: "OK",
    pleaseWait:
      "Please wait while we activate @:Brand.lastPass{''} for your account. This may take up to a few minutes. Please do not refresh or navigate away from this page.",
    activating: "Activating... Don't refresh or navigate away from this page",
    creating: "Creating account... Don't refresh or navigate away from this page",
    clickHere: "Click here",
  },
  LastPassDownloadsTabComponent: {
    Header: "@:Brand.lastPassFullName{''}",
    downloadDescription: "Create and manage strong, secure passwords across your devices.",
    installToActivate:
      "Install @:Brand.webrootProtection{''} to activate (it may take up to 24 hours after installation to activate).",
    managePasswords: "Manage Passwords",
    accountSetup: "Account Setup",
    activate: "Activate",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "@:Brand.lastPass{''} accounts used: {used} of {total}",
    getTheApp: "Get the App",
  },
  LastPassSecurityTabComponent: {
    managePasswords: "Manage Passwords",
    accountSetup: "Account Setup",
    activate: "Activate",
    passwordManager: "@:Brand.lastPassFullName{''}",
    accountsUsed: "Accounts used: {used} of {total}",
    activateMessage: "Securely create and manage logins and passwords with the leader in secure password management.",
  },
  LoadingSpinnerComponent: {
    loading: "Loading...",
  },
  MacToMacMoveSubscriptionComponent: {
    headerH1: "Move my subscription",
    headerCdlpH1: "Move my backup",
    macToMacLine1: "Move your subscription from: ",
    macToMacLine2: "You must be on your new computer to transfer your @:Brand.carbonite{''} subscription.",
    macToMacCdlpLine2: "You must be on your new computer to transfer your @:Brand.WebrootBackupAndRestore{''}.",
    macToMacLine3:
      "When transferring your subscription, you have the option to keep all of your backed up files, When you click {Continue}, we will prompt you to restore your backup from {nickname} to your new computer.",
    macToMacCdlpLine3:
      "When transferring your backup, you have the option to keep all of your backed up files, When you click {Continue}, we will prompt you to restore your backup from {nickname} to your new computer.",
    footerButtonContinue: "Continue",
    footerButtonCancel: "Cancel",
  },
  MacToWindowsMoveSubscriptionComponent: {
    headerH1: "Move my subscription",
    headerCdlpH1: "Move my backup",
    line1: "WAIT! Have you downloaded your files to the new computer?",
    line2:
      "@:Brand.carbonite{''} needs to delete the {oldComputerOs} backup for the computer nicknamed {nickname} to transfer your subscription from your previous {oldComputerOs} to this {newComputerOs}. Please download all the files you need before transferring your subscription.",
    line2Cdlp:
      "@:Brand.webroot{''} needs to delete the {oldComputerOs} backup for the computer nicknamed {nickname} to transfer your subscription from your previous {oldComputerOs} to this {newComputerOs}. Please download all the files you need before transferring your backup.",
    downloadButton: "Download Files",
    line3: "Not on the new computer?",
    line4a: "Close this page. When you are ready, you can re-open this window on the new computer by going to",
    line4b: "account.carbonite.com",
    line4bCdlp: "myaccount.webroot.com",
    line4c: 'and selecting "Move My Subscription".',
    line4cCdlp: 'and selecting "Move My Backup".',
    footerButtonContinue: "Continue",
    footerButtonCancel: "Cancel",
    deleteLine1:
      "@:Brand.carbonite{''} needs to delete the {oldComputerOs} backup for the computer nicknamed {nickname} so that you can transfer your subscription to this {newComputerOs} computer.",
    deleteLine2: "If you have not downloaded all the files you need, restore your files now.",
    deleteLine3:
      "{iUnderstand} which prevents me from accessing my files through @:Brand.carbonite{''} until they are backed up again. ",
    deleteIUnderstandStatement: "I understand that I am deleting my backup,",
    footerButtonDelete: "Delete My Backup",
    linkToAccount: "account.carbonite.com",
  },
  ManageResellerControlRequestComponent: {
    btnYes: "Yes",
    btnNo: "No",
    header: "Request to become administrator",
    p1: "{firstName} {lastName} ({email}) has requested to manage your account. If you accept, they will be responsible for managing some aspects of your account.",
    p2: "Here's what will change:",
    li1: "All purchases will have to go through {firstName} {lastName}. You will no longer be able to purchase with this account.",
    li2: "{firstName} {lastName} will be able to see your name and email address.",
    li3: "{firstName} {lastName} will <strong>not</strong> be able to change any of your information or view your payment methods.",
    question: "Do you agree to make {firstName} {lastName} the administrator of your account?",
  },
  MonthlyToAnnualWarning: {
    header: "Confirm Upgrade",
    p1: "If you upgrade from your monthly plan to an annual plan, you will not be able to change it back to monthly.",
    p2: "You will also need to update the @:Brand.webrootProtection{''} app - if applicable - with a new keycode that we will email to you.",
    p3: "Do you want to upgrade to the selected annual plan?",
    btnActivateNewKeycode: "How to Activate a New Keycode",
    btnNo: "No Thanks",
    btnYes: "Confirm Upgrade",
  },
  MoveMySubscriptionComponent: {
    headerH1: "Move my subscription",
    footerButtonMove: "Yes, I have what I need",
    footerButtonCancel: "Cancel",
    footerButtonContinue: "Continue",
  },
  NextSteps: {
    downloadInstructionsMac1: "1. Open the File",
    downloadInstructionsMac2: "2. Launch @:Brand.carbonite{''}",
    downloadInstructionsMac3: "3. Install",
    downloadInstructionsWindows1: "1. Open the File",
    downloadInstructionsWindows2: "2. Allow Access",
    downloadInstructionsWindows3: "3. Install",
    nextStepsMac1Line1: "Your download should start automatically.",
    nextStepsMac1Line2: "Open the <strong>Carbonite-Personal-client.app.zip</strong> file.",
    nextStepsMac2Line1: "Double-click the <strong>Carbonite-Personal-client.app</strong> icon to start Carbonite.",
    nextStepsMac2Line2: "This will start the app and install it to your Applications.",
    nextStepsMac3Line1:
      "Click “Open” when macOS asks you to confirm, then just follow the instructions in the installer.",
    nextStepsMac3Line2: "Then just follow the instructions in the installer.",
    nextStepsMac3Line3: "Need more detailed instructions?",
    nextStepsWinDownloadStartAuto: "Your download should start automatically in a moment. If it doesn’t,",
    nextStepsWinDownloadStartAutoBtn: "click here to download manually",
    nextStepsWin1Line1: "Your download should start automatically.",
    nextStepsWin1Line2: "Open <strong>CarboniteSetup-personal-client.exe</strong>.",
    nextStepsWin1Line3: "Need more detailed instructions?",
    nextStepsWin2Line1: "Click <strong>Yes</strong> when Windows asks if you want to allow the app to make changes.",
    nextStepsWin3Line1: "Follow the instructions in the installer.",
    showDetails: "Show details",
    hideDetails: "Hide details",
    nextStepsRenewUpgradeP1:
      "Your @:Brand.webroot{''} protection will be extended automatically on installed computers and mobile devices, so you don’t have to download or install any updates. Please allow up to 24 hours for the app to automatically update.",
    nextStepsCDLPRenewUpgradeP1:
      "Please note that you will need to install @:Brand.WebrootTotalProtection{''} and set up your new features. For step-by-step guidance, refer to the Download Instructions below.",
    nextStepsWebrootRenewUpgradeP1:
      "to install and manage your security software, backups, identity protection, billing, renewals and more.",
    nextStepsWebrootAllStepsMyAccount:
      "<strong>@:Brand.myAccount{''}</strong> is your one-stop dashboard to install and manage your security software, backups, identity protection, billing, renewals and more.",
    nextStepsWebrootEstablishedP1:
      "is your one-stop dashboard to install and manage your security software, backups, identity protection, billing, renewals and more.",
    nextStepsWebrootEstablishedP2: "Go to the Downloads tab to install your software.",
    downloadInstructionsBelow: "Download instructions are also included below.",
    nextStepsWebrootProvisionalP2:
      "Click the button below to set a password for your account and download the installer.",
    btnSetPassword: "Set Password",
    nextStepsWebrootProvisionalStep1:
      "Click the “Finish My Account Setup” button, and select a password to protect your account.",
    nextStepsWebrootProvisionalStep2: "Go to the Downloads tab to setup your software.",
    nextStepsAllstate:
      "Once you log in the MyAccount portal, go to the <strong>@:Brand.allstate{''} @:Brand.identityProtection{''}</strong> section of the Home page, or the <strong>@:Brand.identityProtection{''}</strong> tab. From there you can go to @:Brand.allstate{'’s'} site to add or review information you'd like to protect.",
  },
  NotableFeature: {
    backupForComputer: "Backup for {quantity} computer",
    protectionForComputer_one: "Protection for {quantity} computer",
    protectionForComputer_other: "Protection for {quantity} computers",
    protectionForDevices_one: "Protection for {quantity} device",
    protectionForDevices_other: "Protection for {quantity} devices",
    protectionForDevicesWithIdentity: "Protection for {quantity} devices and {identityCount} identity",
    protectionForDevicesWithIdentities: "Protection for {quantity} devices and {identityCount} identities",
    protectionForIdentity: "Protection for {identityCount} identity",
    protectionForIdentities: "Protection for {identityCount} identities",
    protectionForPcOptimizer: "Tune-up, cleaning and repair tools for up to {quantity} PCs",
    protectionForPureVPNDevices_one: "VPN Protection for {quantity} device",
    protectionForPureVPNDevices_other: "VPN Protection for {quantity} devices",
    backupForComputerName: "Backup for Computer:",
  },
  NotificationsComponent: {
    notificationPlaceholder:
      "This is just placeholder text - actual notifications will come from the api. <strong>{text}</strong>",
    AddCreditCardFailed: "There was an error adding your credit card. Please try again later.",
    AddCreditCardSuccess: "You have successfully updated your payment method.",
    EmailVerificationIncomplete: "Email address verification incomplete",
    MovePaymentMethodMRToAR: "You have successfully updated your payment and auto-renewal details for {plan}.",
    PendingEmailVerification: "Your new email address needs to be verified. Please {link}",
    PrimaryEmailVerificationWebroot: "For security purposes, please {link} to access some features.",
    UpdateEmailLanguagePreferenceSuccess: "You have successfully updated your preferred email language",
    EmailVerificationComplete: "You have successfully updated your email address",
    RemoveCreditCardFailed: "There was an error removing your credit card. Please try again later.",
    LoginFailed: "The username or password you provided is incorrect. Please try again or use the {link} option.",
    LoginFailedLinkText: "Forgot Password",
    LoginAccountExists: "An account already exists for the email address you entered. {link}.",
    LoginAccountExistsNoLoginLink: "An account already exists for the email address you entered.",
    LoginAccountLinkText: "Log in here",
    AccountLockedOut:
      "Too many login attempts. Your account has been locked for security purposes. Please try again in 1 hour or click {link}.",
    TwoStepVerificationFailed: "Invalid security code. Please try again.",
    TwoStepVerificationEmail: "If this is a valid account email, you will receive a link to reset your password.",
    TwoStepVerificationCodeSent: "Your security code has been re-sent.",
    SetTwoStepVerificationFailure: "Invalid Phone Number. Please try again or try a different number",
    SetTwoStepVerificationSuccess: "You have successfully updated 2-factor authentication",
    SetTwoStepVerificationTooManyVerificationAttempts:
      "Too many login attempts. Your account has been locked for security purposes. Please try again in 1 hour.",
    SetTwoStepVerificationLockOut: "The account is locked out",
    SetTwoStepVerificationNoPhoneToValidate: "Invalid Phone Number. Please try again or try a different number",
    SetTwoStepVerificationPhoneAlreadyExists: "Invalid Phone Number. Please try again or try a different number",
    SetTwoStepVerificationVerificationWindowExpired: "The security code has expired. Please try again.",
    SetTwoStepVerificationInvalidPhoneType: "Invalid Phone Number. Please try again or try a different number",
    SetTwoStepVerificationHighRiskScore: "Invalid Phone Number. Please try again or try a different number",
    SetTwoStepVerificationEmptyPhone: "Please type a phone number",
    SetTwoStepVerificationInvalidCountry: "Please select a country",
    SetTwoStepVerificationDeletePhone: "You have successfully removed 2-factor authentication",
    SetTwoStepVerificationUnverifiedPhone:
      "Your 2-factor authentication setup is incomplete. Please finish setup as soon as possible.",
    SetTwoStepVerificationInvalidVerificationMethod: "Please select a verification method",
    SetSecurityQuestionsSuccess: "You have successfully changed your security questions",
    SetSecurityQuestionsFailure: "There was an error updating Security Questions. Please try again later.",
    SetPasswordSuccess: "You have successfully changed your password",
    SetPasswordFailure: "There was an error updating Password. Please try again later.",
    SetUserFieldsSuccess: "You have successfully updated your user information",
    SetUserFieldsFailure: "There was an error updating user information. Please try again later.",
    TwoStepVerificationExpired: "The security code has expired. Please try again.",
    UnderMaintenance:
      "We're updating our store website. For now you won't be able to renew, upgrade, or make new purchases. Be sure to check back soon.",
    PasswordReset: "Your new password has been set up successfully. Please log in.",
    CannotCreateAccount: "Cannot create account at this time, please try again later",
    ValidationError_FraudKeycode: "The keycode {keycode} has been flagged for possible fraud. Contact {link}.",
    ValidationError_LicenseStatusIneligible: "The keycode {keycode} has been disabled. Contact {link}.",
    ValidationError_KeycodeEmailMismatch:
      "The keycode <strong>{text}</strong> is already associated with an existing account.",
    ValidationError_KeycodeEmailMismatchDownloadsComponent:
      "The keycode <strong>{text}</strong> is already associated with an existing account.",
    support: "Support",
    ValidationError_KeycodeIneligible:
      "The keycode <strong>{text}</strong> is for a business license and cannot be added to this account.",
    ValidationError_InvalidKeycode:
      "The keycode <strong>{text}</strong> is not valid. Please check your keycode and try again.",
    ValidationError_LicenseInfoAlreadyLinked:
      "The keycode is already linked to other account. Please check your keycode and try again.",
    KeycodeAlreadyExists: "The keycode <strong>{text}</strong> has already been added.",
    KeycodeExpired: "The keycode you added has already expired. {link}.",
    RenewHere: "Renew here",
    RetrieveKeycodesFailed: "There was a problem retrieving keycode(s) for your recent purchase. {link}",
    PartialRetrieveKeycodesFailed:
      "There was a problem retrieving some of your available downloads. Refresh the page, try again later, or contact support.",
    ServerErrors: "Something went wrong on our end. Please try again later.",
    AddKeycodeSuccess: "You have successfully added your keycode <strong>{text}</strong>.",
    RecaptchaValidation_invalid: "ReCaptcha response is not valid.",
    DownloadSafeFailed: "Something went wrong. Unable to download. Please try again later.",
    UnsupportedOS: "@:Brand.carboniteSafe{''} is only available for Windows and macOS",
    CardComputerBackupOutDated:
      "One or more backup is out of date. Please check to make sure the computer is connected to the internet, and that your backup has not been disabled or paused.",
    CardComputerBackupOutDatedSevere:
      "One or more backup is out of date. Please check to make sure the computer is connected to the internet, and that your backup has not been disabled or paused.",
    MoveMySubscriptionError: "Some Error Occurred while Moving Subscription.",
    MoveMySubscriptionSuccess: "Move My Subscription is done successfully.",
    MoveMySubscriptionComputerOrUser_Invalid:
      "Invalid Computer or User does not have access to move the subscription for this device.",
    SubscriptionsAlmostExpired: "One or more subscriptions will expire soon. Renew now to ensure continued protection.",
    RenewSuccessMessage: "Thank you! We have processed your payment and renewed your subscription(s).",
    RenewFailureMessage:
      "There was an error processing your order, please check your card details and try again later.",
    ResellerSubscriptionsExpired: "One or more subscriptions has expired. Contact your provider to renew.",
    ResellerSubscriptionsAlmostExpired: "One or more subscriptions will expire soon. Contact your provider to renew.",
    ResellerSubscriptionsAlmostExpiredTrial:
      "One or more subscriptions will expire soon. Contact your provider to buy.",
    ResellerTrialSubscriptionsAlmostExpired:
      "One or more subscriptions will expire soon. Contact your provider to purchase.",
    ResellerTrialSubscriptionsExpired: "One or more subscriptions has expired. Contact your provider to purchase.",
    LastPassProvisioned: "Activation successful. {url} to finish creating your @:Brand.lastPass{''} account.",
    LastPassError: "There was a problem activating @:Brand.lastPass{'.'} Try again later.",
    SubscriptionWillExpireTrial:
      "One or more trial subscriptions will expire soon. Purchase now to ensure continued protection.",
    CardComputerSubscriptionWillExpire:
      "One or more subscriptions will expire soon. Renew now to ensure continued protection.",
    CardComputerSubscriptionExpired:
      "One or more subscriptions has expired. You may lose backed up data if you do not take action.",
    InvalidActivationCode: "The activation code is not valid. Please check your code and try again.",
    BusinessActivationCode: "The activation code is for a business license and cannot be added to this account.",
    InactiveActivationCode: "The activation code is not valid. Please check your code and try again.",
    ExpiredActivationCode: "The activation code has already expired.",
    ActivationCodeAlreadyApplied: "The activation code is already associated with an existing account.",
    ActivationCodeAppliedSuccess: "You have successfully added your @:Brand.carbonite{''} activation code.",
    ActivationCodeAppliedFailed:
      "Something went wrong - failed to apply activation code. Please try again later or contact Support.",
    AutoRenewTurnOnSuccess: "You have successfully updated your payment and auto-renewal details for {carbonitePlan}.",
    AutoRenewTurnOffSuccess: "You have successfully turned off auto-renewal for {carbonitePlan}.",
    AutoRenewTurnOnOrOffFailure:
      "Something went wrong - unable to turn {on/off} auto-renewal. Please try again or contact Support.",
    CancelSubscriptionFailure:
      "Something went wrong - unable to cancel your subscription. Please try again or contact Support.",
    CancelSubscriptionSuccess: "You have successfully cancelled your subscription.",
    DataAtRiskOutstandingInvoice:
      "DATA AT RISK: We were unable to charge your credit card on file. Update your credit card information and renew your subscription before your DATA IS PURGED.",
    SubscriptionsExpired: "One or more subscriptions has expired. Renew now to continue protection.",
    OptInToAutomaticVideoBackupSuccess:
      "{deviceName} will start automatically backing up videos. This change may take a few hours to take effect.",
    AlreadyUsedOrderHeaderToken: "Your account has already been set up. {link}.",
    showAlerts: "Show {qty} alerts",
    hideAlerts: "Hide alerts",
    VatError_Default: "There was a problem adding your VAT ID. Check the form and try again.",
    VatError_INVALID_INPUT: "Either the VAT ID number or the country is invalid. Check the form and try again.",
    VatError_FORMAT_INVALID: "Invalid VAT ID Format",
    VatError_SERVICE_UNAVAILABLE: "The service isn't available at the moment. Please try again later.",
    VatError_MS_UNAVAILABLE:
      "The Member State service is unavailable for now. Try again later or with another Member State.",
    VatError_TIMEOUT:
      "The Member State service couldn't be reached in time. Try again later or with another Member State.",
    VatError_SERVER_BUSY: "Something went wrong on our end that prevented processing. Please try again later.",
    VatError_BUY_GENERIC_ERROR: "There was a problem adding your VAT ID.",
    EncryptionKeySuccess:
      "Your encryption key has been successfully uploaded. From now on, @:Brand.carbonite{''} will manage this encryption for you, so you don't need to keep a copy of the key.",
    EncryptionKeyWrongPassword: "Password is incorrect.",
    EncryptionKeyFailure: "Your encryption key upload has failed, please try again.",
    EncryptionKeyInvalidFile: "The file uploaded is invalid.",
    SetEmailAddressFailure: "There was an error updating Email Address. Please try again later.",
    EmailAddressFormSubmissionError: "Unable to update your email address. Please check for errors below.",
    EmailAlreadyInUse: "This email address can't be used. Try a different email address or {contactSupport} for help.",
    PasswordResetNoLogin: "Your password has been successfully reset.",
    RFA_ComputerHasPrivateKey:
      "This computer uses a private encryption key to protect your data. @:Brand.remoteFileAccess{''} is unavailable for computers that use a private encryption key. For more information on @:Brand.remoteFileAccess{''} and private encryption keys, please {link}",
    ClickHere: "click here",
    RFA_ExpiredSubscription: "Your subscription for this computer has expired, please renew your subscription {link}",
    ExpiredDestination: "here",
    ValidationError_DuplicateCustomerInEcommerce:
      "There was a problem with your keycode. Please contact @:Brand.webroot{''} customer support.",
    ValidationError_AMZSLicense:
      "The keycode you entered is managed in your @:Brand.amazon{''} account. Please visit Amazon's {link} page.",
    ValidationError_AMZSLicense_link: "Your Membership & Subscriptions",
    AllstateSignUpComplete:
      "Success! Your @:Brand.allstate{''} @:Brand.identityProtection{''} account has been created.",
    BrowserNotSupportedError:
      "Some older browser versions may not support the @:Brand.myAccount{''}  portal. To access your subscription, please update your browser to the latest version.",
    RateLimitCreditCardError:
      "You have exceeded the number of attempts to add a credit card. You may try again in 24 hours.",
    GenericCreditCardError: "Error processing request.",
    AllstateExpiredError: "Your subscription has expired. Renew now to keep your identity safe from threats.",
    AllstateExpiredErrorReEnroll:
      "Your subscription has expired. Renew your subscription and re-enroll for @:Brand.identityProtection{'.'}",
    RenewNow: "Renew Now",
    AllstateExpiringWarning: "Your subscription will expire soon. Renew now to keep your identity safe from threats.",
    AllstateExpiringWarningReEnroll:
      "Your subscription will expire soon. Renew now to continue @:Brand.idProtection{''} and avoid having to re-enroll.",
    AllstateConflictError:
      "Reactivate your @:Brand.allstate{''} @:Brand.identityProtection{''} plan by calling @:Brand.allstate{'’'}s Customer Care Team at 800-789-2720.",
    unable_to_use_AllstateKeycode:
      "This account has an active @:Brand.allstate{''} @:Brand.identityProtection{''} plan and accounts are currently limited to one plan per account. Please create a new account to activate the plan associated with this keycode.",
    DoubleAllstatePremiumError:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} can be applied to only one subscription on an account. Please select the plan that you want to setup with @:Brand.identityProtection{'.'} Please {contactSupport} to learn options for your other plan.",
    AllstateSignUpError:
      "A technical issue occurred while sending your information. Please try Setup @:brand.idProtection{''} again.",
    WSIDPFUpgradeInfo:
      "You have upgraded to @:Brand.AllstateIdentityProtectionPremiumFamily{''}. Add family members to your account on @:Brand.allstate{'’s'} @:Brand.identityProtection{''} website.",
    WSIDPUpgradeInfo:
      "You have upgraded to @:Brand.AllstateIdentityProtectionPremium{''}. Add expense and credit monitoring on @:Brand.allstate{'’s'} @:Brand.identityProtection{''} website.",
    WSAPFUpgradeInfo:
      "You have upgraded to @:Brand.WebrootPremiumFamily{''}. You can add your family members to your @:Brand.allstate{'’s'} @:Brand.identityProtection{''} account.",
    AllstateUpgradeInfoBtn: "Go to ID Protection account",
    WTPFUpgradeInfo: "You have upgraded to @:Brand.WebrootTotalProtectionFamily{''}.",
    BuyflowAutoRenewMonthlyError:
      "Monthly subscriptions require automatic renewal. If you prefer to renew manually, please select an annual subscription.",
    CreditCardDeclinedError:
      "We're sorry but your bank has declined this transaction. Check your credit card details. You may need to contact your bank to remove any security check that might have been placed on your account.",
    CreditCardAddressError: "There was a problem with your billing address. Check your information and try again.",
    PayPalCreditCardAddressError:
      "The address on your PayPal account is invalid. Please provide credit card info or return to PayPal page and update this information.",
    CreditCardInsufficientFunds: "We couldn't authorize your credit card. Please try another payment method.",
    CreditCardInvalidExpirationDate: "Invalid credit card expiration date. Please check and try again.",
    PurchaseFailureError:
      "There was a problem with either your credit card or billing address. Check the information and try again.",
    BuyCreateAccountSMBUserError:
      "An account already exists for the email address you entered. Please use a different email address.",
    InactiveKeycodeAllstateError:
      "Please install @:Brand.webroot{''} before you create your @:Brand.allstate{''} account (it may take 24h to reflect this change).",
    NewAccountPasswordError: "Something went wrong on our end, Please try again later.",
    InvalidShippingAddress: "We couldn't verify your shipping address. Please double-check it and try again.",
    PurchaseDoneContinueToMyAccountPage:
      "You have successfully completed this purchase. Please click 'Continue to @:Brand.myAccount{''}' to see your subscription",
    PreliminaryUserTimeOut:
      "Your session has timed out due to inactivity. Please double-check your cart and email address, and continue to resume checkout.",
    PreliminaryUserOrderConfirmationTimeOut:
      "Your session has timed out due to inactivity. Please check your email for a link and instructions to set up your account and download your software.",
    AllstateBuyflowLimit:
      "The email address below is already associated with a @:Brand.WebrootTotalProtection{''}, @:Brand.WebrootSecureVPNP{''}, or @:Brand.allstate{''} purchase (limit 1 per email address)",
    NonMigratedLegacyWebrootError: "Please {contactSupport} to help you manage this subscription.",
    NonZuoraEcommWebrootError: "Please {contactSupport} to help you manage this subscription.",
    contactSupportTeam: "contact our Support team",
    allStateNonAvailabilityMessageLine1: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine1",
    allStateNonAvailabilityMessageLine2: "@:PayPalPaymentComponent.allStateAvailabilityMessageLine2",
    AutoRenewDiscountEnabled:
      "A savings of {renewalDiscount}% will be applied on your next renewal of the subscription noted below.",
    LicenseDaysRemaining:
      "Your subscription will be upgraded immediately, and will include {replacementText} days from your current subscription.",
    UpgradeOnRetailRenewalNotAllowed:
      "Your recent renewal must begin before you can upgrade. Please contact our support team if you need immediate assistance.",
    PlanetOpenTextInvalidCart: "We are unable to complete the purchase at this time.",
    PlanetOpenTextEnrollmentGuidError:
      "This purchase link has expired. Please generate a new one and complete your purchase within 24 hours.",
    FlatRateUpgradeWindowError:
      "We noticed you recently upgraded your subscription. You’ll be able to make another upgrade starting from your next renewal date.",
    VPNPBuyflowLimit:
      "The email address below is already associated with an @:Brand.WebrootSecureVPNP{''} purchase (limit 1 per email address).",
    WTPBuyflowLimit:
      "The email address below is already associated with a @:Brand.WebrootTotalProtection{''}, @:Brand.WebrootSecureVPNP{''}, or @:Brand.allstate{''} purchase (limit 1 per email address).",
    VPNBuyflowLimitMultipleItems:
      "This account is already associated with a @:Brand.WebrootSecureVPNP{''} subscription (limit 1 per account). Please remove Webroot Secure VPN from your cart.",
    VPNBuyflowLimitSingleItemDeadEnd:
      "This account is already associated with a @:Brand.WebrootSecureVPNP{''} subscription (limit 1 per account). Please visit {webrootDotCom} to start a new cart.",
    BadLicenseKey:
      "The subscription associated with this keycode cannot be renewed. Please contact support if you need assistance.",
    SecurityCodeSentToEmail: "Security code sent to email",
  },
  OrderConfirmation: {
    h1OrderConfirmation: "Order Confirmation",
    btnContinue: "Continue",
    orderConfirmationConclusion:
      "We’ve also sent you an email with instructions for downloading and setting up <strong>@:Brand.myAccount{''}</strong>.",
    isRenewOrUpgradeLine1:
      "Congratulations. Your subscription for the computer {computer} has been updated. The @:Brand.carboniteSafe{''} app will automatically reflect this purchase.",
    btnContinueToMyAccount: "Continue to @:Brand.myAccount{''}",
    isRenewOrUpgradeLine2:
      "at any time to check on your subscriptions, access your backed up devices, download your purchased apps or get help.",
    isEstablishedAccountLine1:
      "Congratulations. Your subscription is activated and your products are ready to download and install.",
    btnLogInToMyAccount: "Log in to @:Brand.myAccount{''}",
    isEstablishedAccountLine2:
      "at any time to check on your subscriptions, access your backed up devices, download your purchased apps or get help.",
    isEstablishedAccountNextStepsLine1:
      "If you are on the computer that you want to back up, you can download and install @:Brand.carboniteSafe{''} right away.",
    isEstablishedAccountMobile:
      "Visit {url} on the computer that you want to back up, and go to the Backup tab to download @:Brand.carboniteSafe{''}.",
    provisionalLine1:
      "If you are on the computer that you want to back up, you can download and install @:Brand.carboniteSafe{''} right away.",
    btnContinueToDownload: "Continue to Download",
    downloadInstructionsLine1: "Your download should start automatically in a moment. If it doesn’t,",
    downloadInstructionsBtnLine1: "click here to download manually",
    h1SetUpMyAccount: "Set Up @:Brand.myAccount{''}",
    setUpMyAccountLine1:
      "<strong>@:Brand.myAccount{''}</strong> is your one-stop dashboard for accessing, downloading, installing, and managing everything included in your subscription.",
    setUpMyAccountLine2: "Click the “Finish Account Setup” button, then select a password to protect your account.",
    btnFinishMyAccountSetup: "Finish @:Brand.myAccount{''} Setup",
    isFullAccountLine1:
      "If you are on the computer that you want to back up, you can download and install @:Brand.carboniteSafe{''} right away.",
    isFullAccountBtnLine2: "Log in to @:Brand.myAccount{''}",
    isFullAccountLine2:
      "at any time to check on your subscriptions, access your backed up devices, download your purchased apps or get help.",
    isMobileLine1: "Finish setting up <strong>@:Brand.myAccount{''}</strong> by choosing a password. ",
    provisionalMobileLine2:
      "Then visit {url} on the computer you want to back up, and go to the Backup tab to download and install your @:Brand.carbonite{''} software.",
    isMobileBtnLine2Url: "myaccount.carbonite.com",
    thanks: "Thanks!",
    thanksMessage:
      "Thank you for your purchase. Please follow the steps below to complete your security setup. We’ve also emailed you a copy of your receipt and the download instructions.",
  },
  OutstandingInvoiceAlertComponent: {
    h1Heading: "Action Required",
    safeLine1: "{outstandingDays} day(s) remaining until backed up data is deleted permanently.",
    webrootLine1: "{outstandingDays} day(s) remaining until your {productName} protection is suspended.",
    line2: "You have an outstanding invoice for invoice {invoiceNumber}",
    safeLine3: "{productName} on {computerName}",
    webrootLine3: "{productName} with keycode {keycode}",
    btnRenewNow: "Renew Now",
  },
  PassPhraseDialogComponent: {
    title: "Enter passphrase",
    continueBtn: "Continue Download",
    closeBtn: "Cancel",
    p1: "Your data is encrypted with the passphrase you set up in your account.",
    label1: "Enter your passphrase",
  },
  PasswordRulesComponent: {
    uppercase: "1 uppercase letter",
    lowercase: "1 lowercase letter",
    number: "1 number",
    characters: "Between {min} and {max} characters",
  },
  PaymentHistoryComponent: {
    accountCredit: "Account Credit",
    amountOfCredit: "You have a credit of {amount} on your account. This credit can be applied to any future purchase.",
    paymentHistory: "Payment History",
    dateCol: "Date",
    subscriptionNumberCol: "Subscription#",
    invoiceCol: "Invoice #",
    computerNameCol: "Computer name",
    productCol: "Product",
    creditCardCol: "Payment Method",
    amountCol: "Amount",
    taxAmountCol: "Tax amt",
    totalWithoutCredit: "Total w/o credit",
    total: "Total",
    otherTransactionNotice:
      "Payment information for some @:Brand.webroot{''} purchases may not be available. Please refer to your sales receipt.",
    keycode: "Keycode:",
    quantity: "Qty:",
    activeUntilCancelled: "active until cancelled",
    notApplicable: "n/a",
    noInvoicesToDisplay: "No payment history to display",
    requestRefund: "Request a refund",
    viewPrint: "View/Print",
  },
  PendingEmailVerificationNotification: {
    FinishEmailVerification: "finish email verification.",
  },
  PersonalInformationComponent: {
    edit: "Update",
    personalInfo: "Personal Information",
    firstName: "First Name:",
    lastName: "Last Name:",
    notVerified: "Not verified",
    Current: "(current)",
    CancelEmailChangeRequest: "Cancel email change request",
    FinishEmailVerification: "Finish email verification",
    Disclaimer: "*Until you have verified your new email address, continue to use your current one to log in.",
    email: "Email:",
    AltEmail: "Alternate Email:",
    Password: "Password",
    NewPassword: "New password:",
    TwoStep: "2-Factor Authentication",
    TwoStep2: "(Recommended)",
    TwoStepTurnOn2fa: "Turn on 2-Factor Authentication",
    TwoStepPhoneNumber: "Phone Number: ",
    TwoStepParagraph1Heading: "About 2-Factor Authentication:",
    TwoStepParagraph1:
      "Adding 2-factor authentication (2FA) will give you multiple layers of protection when accessing your account. We highly recommend enabling 2-factor authentication to help keep your account from being accessed fraudulently.",
    TwoStepParagraph2:
      "Here's how it works. After you set up 2FA, you'll receive a verification message with a unique, one-time-use code on your mobile phone or email when you log in. You'll enter this code once to verify that it's really you before any of your account details are displayed. ",
    TwoStepParagraph3:
      "This extra layer of security also makes it easier to reset your password, if needed. Instead of answering security questions, you can use the security code process instead.",
    TwoStepNotVerified: "Not verified",
    TwoStepNotSetUp: "Not currently setup",
    SecurityQuestions: "Security Questions",
    Question1Header: "Question 1",
    Question1: "In what city were you born? (Enter full name of city only)",
    Question2Header: "Question 2",
    Question2: "In what city were you married?",
    Question3Header: "Question 3",
    Question3: "In what city was your mother born? (Enter full name of city only)",
    SecurityQuestion1Header: "Security Question 1",
    SecurityQuestion2Header: "Security Question 2",
    SecurityQuestion3Header: "Security Question 3",
    noEntry: "No entry",
    update: "Update",
    FinishSetup: "Finish Setup",
    emailAddress: "Email Address",
    verifyEmailAddress: "Verify Email Address",
    verifyEmailAddressMessage: "For your security, please verify your email address.",
    verified: "Verified",
    PreferredLanguage: "Preferred Language",
    PreferredEmailLanguage: "Preferred email language:",
  },
  PhoneAssistance: {
    haveQuestions: "Have questions or prefer to place your order over the phone?",
    description1:
      "Our award-winning, US-based sales team is available Monday-Friday, 7:00 AM to 7:00 PM MST. Call us at",
    description2: "or toll free at",
    yourOrderCode: "Your order code is:",
  },
  PreferredEmailLanguageComponent: {
    headerH1: "Preferred Email Language",
    communicationEmailP1:
      "What language would you prefer for @:Brand.carbonite{''} and @:Brand.webroot{''} communications (Account status, news and promotional emails)?",
    language: "Language",
    save: "Save",
    cancel: "Cancel",
  },
  PrimaryEmailVerificationWebrootNotification: {
    VerifyEmailAddress: "verify your email address",
  },
  PromoCodeComponent: {
    promoCode: "Promo Code",
    PromoCodePct: "{amount}% savings applied",
    PromoCodeAmt: "{amount} savings applied",
    BonusMonthsPromo: "{amount} free months applied",
    invalidPromoCode: "Invalid promo code",
  },
  RefundComponent: {
    heading: "Product Refund",
    product: "Product",
    keycode: "Keycode",
    paymentMethod: "Payment Method",
    purchaseDate: "Purchase Date",
    refundBtn: "Submit for Refund",
  },
  RemoveCreditCardComponent: {
    headerH1: "Remove your credit card",
    paragraph1:
      "The credit card you requested will be removed. If you've made an error, please click the 'No, don't remove my card' link.",
    footerButtonSave: "Yes, remove my card",
    footerButtonCancel: "No, don't remove my card",
  },
  ReviewOrderComponent: {
    endingIn: "ending in",
    exp: "Exp:",
    thisSubscriptionWillAutomaticallyRenew:
      "All subscriptions will automatically renew on the dates noted above*. We will email you a reminder beforehand.",
    thisSubscriptionIsValidUntil: "This payment covers all subscriptions until the dates noted above*.",
    permissionLabel:
      "I confirm and validate that the information I have supplied is true, accurate, and complete. I also agree to {brand}{'’s'} Terms and Conditions and Privacy Policy.",
    secureCheckout: "Complete my purchase",
    GDPREmailOptIn:
      "I would like to receive email communications about additional products, services, and/or promotions from {brand}{''} LLC. I understand that I can unsubscribe at any time.",
    flatRateUpgradeRenewalText: "Your subscription will renew for another {year} year(s) on {date} for {amount}",
  },
  SafeLongExpiredComputer: {
    header: "Unable to renew this subscription",
    p1: "This subscription has been expired for more than 30 days and can no longer be renewed. Click <strong>Start Shopping</strong> to purchase a new @:Brand.carboniteSafe{''} plan.",
    startShoppingBtn: "Start Shopping",
  },
  SecurityAnswerValidation: {
    SecurityAnswer: "Security answer",
    AnswerDefaultError: "Answer cannot contain ******.",
    AnswerMatchingError: "Answers to security questions must be unique.",
    AnswerEmptyStringError: "Answer cannot contain only spaces.",
  },
  SecurityComponent: {
    headerH1: "Security Overview",
    headerH2: "My Devices",
    protecting: "Protecting",
    threatProtection: "Threat Protection",
    noLicenseHeaderH1: "Security",
    noLicenseHeaderH2: "There are no active security subscriptions to display.",
    addWebroot: "Add @:Brand.webrootProtection{''} today.",
    noLicenseP1:
      "Protect your data, devices and identity from hackers and cyber-thieves with our award-winning antivirus and internet security solutions. Get the protection you need to shut the door on cybercrime.",
    learnMoreBtn: "Learn More",
    noLicenseP2:
      "If you have a keycode from a retail or similar purchase, you can add the subscription by entering the keycode on the {downloadsLink} page.",
    downloadsLink: "Downloads",
    goToDownloads: "Go to Downloads",
    expires: "Expires:",
    expired: "Expired",
    expiresIn: "Expires in {daysToExpire} {daysNoun} ({expirationDate})",
    day: "day",
    days: "days",
    noDevicesHeader: "You haven't protected any devices with this subscription yet.",
    noDevicesParagraph:
      "To get started, visit the Downloads page to view all the apps that are available for this subscription.",
    noDevicesViewDetailsLink: "View detailed instructions",
    installBtn: "Install",
    installAndActivateBtn: "Install and Activate",
    renewBtn: "Renew Now",
    buyBtn: "Buy Now",
    bbyBtn: "Contact Best Buy",
    showHiddenDevices: "Show hidden devices",
    serverError:
      "There was a problem getting information about your devices and threat protection. Refresh the page, try again later, or {contactSupportLink}.",
    serverErrorDevices:
      "There was a problem getting information about your devices. Refresh the page, try again later, or {contactSupportLink},",
    howToEnableBtn: "How to Enable",
    isNotActiveYet: "Not Activated yet",
    activeUntilCancelled: "active until cancelled",
    verifyEmailMessage: "@:DownloadsComponent.verifyEmailMessage",
    btnVerifyEmail: "@:HomeComponent.btnVerifyEmail",
    WSCBUnavailable: "Chromebook device information is not displayed for the moment.",
    getStartedWSCB:
      "To get started, visit the Downloads page to view all the apps that are available for this subscription.",
    getTheApp: "Get the App",
    WSCBnote: "*Mobile and Chromebook devices aren't displayed for the moment.",
  },
  SelectPlan: {
    currentPlan: "Current Plan",
    bestValue: "Best Value",
    selectedPlan: "Selected Plan",
    perMonth: "/mo",
    billedAnnually: "Billed Annually",
    unlimitedBackup: "Unlimited backup",
    secureCloud: "Secure, encrypted cloud",
    photoBackup: "Automatic file and photo backup",
    allFileTypes: "Supports all file and photo types",
    easyMobile: "Easy access on mobile devices",
    hardDriveBackup: "External hard drive backup",
    videoBackup: "Automatic video backup",
    courierDelivery: "Courier delivery of recovered files",
    percentSavings: "Get {savings} off",
  },
  SelectPlanLink: {
    linkText: "Back to product selection",
  },
  SetDeviceNicknameComponent: {
    nicknameLabel: "Device Name",
    headerH1: "Rename Device",
    footerButtonCancel: "Cancel",
    footerButtonSave: "Save",
  },
  SetNewUserPassword: {
    headerH1: "Finalize Your Account",
    confirmNewPassword: "Confirm Password",
    password: "Password",
    passwordRequirements: "Password requirements:",
    passwordMatch: "Passwords do not match.",
    invalidPasswordCharacter: 'The "{char}" character cannot be used.',
    finishSetup: "Finish Account Setup",
    welcome: "Welcome {email}",
    pleaseEnter: "Please enter a password to protect your account.",
  },
  SetSecurityQuestionsComponent: {
    headerH1: "Edit Security Questions",
    answer: "Answer",
    footerButtonSave: "Save",
    footerButtonCancel: "Cancel",
    chooseOption: "- Choose a question -",
    question1: "In what city were you born? (Enter full name of city only)",
    question2: "In what city were you married?",
    question3: "In what city was your mother born? (Enter full name of city only)",
  },
  SetTwoStepVerificationComponent: {
    h1Heading: "2-Factor Authentication",
    h1Heading2: "Verify Security Code",
    informationP1: "How do you want to verify your account?",
    option1: "Text message",
    option2: "Phone call",
    option3: "Turn OFF 2-factor authentication (not recommended)",
    label1: "Add a phone number",
    heading1: "Are you sure you want to turn off 2-factor authentication?",
    informationP2:
      "2-factor authentication (2FA) gives you multiple layers of protection when accessing your account. We highly recommend enabling 2-factor authentication to help keep your account from being accessed fraudulently. This extra layer of security also makes it easier to reset your password, if needed. Instead of answering security questions, you can use the security code process instead.",
    informationP3:
      "*Standard text messaging rates apply. @:Brand.carbonite{''} and @:Brand.webroot{''} will never share your phone number with third party companies.",
    save: "Save",
    cancel: "Cancel",
    next: "Next",
    submit: "Submit Code",
    informationP1a: "If this is a valid account email, you will receive a security code by phone or text message",
    informationP1b: "Please enter the security code below.",
    labelSecurityCode: "Security Code",
    informationP3a: "Didn't receive your security code?",
    informationP3b: "Send again",
    informationP3c: "Send a new code",
    submitCode: "Submit Code",
    bodyP1: "Enter the 5-digit security code that we sent to {phone}",
    bodyP2: "This code is valid for 1 hour from the time it was sent.",
    USA: "United States ",
    AF: "Afghanistan ",
    AL: "Albania ",
    DZ: "Algeria ",
    AD: "Andorra ",
    ARG: "Argentina ",
    AW: "Aruba ",
    AUS: "Australia ",
    AUT: "Austria ",
    BHS: "Bahamas ",
    BRB: "Barbados ",
    BEL: "Belgium ",
    BMU: "Bermuda ",
    BRA: "Brazil ",
    CAN: "Canada ",
    CHL: "Chile ",
    CHN: "China ",
    COL: "Colombia ",
    CRI: "Costa Rica ",
    CU: "Cuba ",
    CZ: "Czech Republic ",
    DNK: "Denmark ",
    DM: "Dominica ",
    DO: "Dominican Republic ",
    ECU: "Ecuador ",
    FI: "Finland ",
    FRA: "France ",
    DACH: "Germany ",
    GRC: "Greece ",
    GL: "Greenland ",
    GTM: "Guatemala ",
    HKG: "Hong Kong ",
    HU: "Hungary ",
    IS: "Iceland ",
    IND: "India ",
    IRL: "Ireland ",
    ISR: "Israel ",
    ITA: "Italy ",
    JAM: "Jamaica ",
    JPN: "Japan ",
    KEN: "Kenya ",
    LU: "Luxembourg ",
    MYS: "Malaysia ",
    MEX: "Mexico ",
    NLD: "Netherlands ",
    NZL: "New Zealand ",
    NOR: "Norway ",
    PAN: "Panama ",
    PER: "Peru ",
    PHL: "Philippines ",
    PL: "Poland ",
    PT: "Portugal ",
    PRI: "Puerto Rico ",
    RUS: "Russia ",
    SGP: "Singapore ",
    ZAF: "South Africa ",
    KOR: "South Korea ",
    ESP: "Spain ",
    SWE: "Sweden ",
    CHE: "Switzerland ",
    THA: "Thailand ",
    TTO: "Trinidad & Tobago ",
    TR: "Turkey ",
    TUV: "U.S. Virgin Islands ",
    ARE: "United Arab Emirates ",
    GBR: "United Kingdom ",
    PY: "Paraguay ",
    EST: "Estonia ",
    SVN: "Slovenia ",
  },
  SetUserFieldsComponent: {
    headerH1: "Edit Personal Information",
    firstName: "First Name (Optional)",
    lastName: "Last Name (Optional)",
    email: "Email Address",
    altEmail: "Alternate Email Address (Optional)",
    footerButtonSave: "Save",
    footerButtonCancel: "Cancel",
    emailInUse: "Email address is already associated with another account.",
    alternateEmailInUse: "Email address is already associated with another account.",
    firstNameError: "First name",
    lastNameError: "Last name",
    invalidCharacters: "The following character(s) are not allowed",
  },
  SetUserPasswordComponent: {
    headerH1: "Edit password",
    confirmNewPassword: "Confirm New Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    footerButtonSave: "Save",
    footerButtonCancel: "Cancel",
    incorrectPassword: "The password is incorrect. Please try again.",
    passwordRequirements: "Password requirements:",
    passwordMatch: "Passwords do not match.",
    invalidPasswordCharacter: 'The "{char}" character cannot be used.',
  },
  ShippingAddressFormErrors: {
    firstNameInvalidCharacter: "The {0} character cannot be used.",
    lastNameInvalidCharacter: "The {0} character cannot be used.",
    addressInvalidCharacter: "The {0} character cannot be used.",
    cityInvalidCharacter: "The {0} character cannot be used.",
    nameLengthMin: "Name Required",
    addressLength: "Address must be between 3 and 60 characters",
    cityLength: "City must be between 2 and 50 characters",
    stateSelect: "Please select a state",
    stateLength: "State is required.",
    zipLength: "Zip code must be between 5 and 9 digits (don't enter a hyphen).",
    zipNumbersOnly: "Zip code must be all numbers.",
    zipRequired: "Zip code is required.",
    firstNameLengthMax: "First Name must be under 50 characters.",
    lastNameLengthMax: "Last name must be under 50 characters.",
    firstNameLengthMin: "First name is required.",
    lastNameLengthMin: "Last name is required.",
    addressRequired: "Address is required.",
    cityRequired: "City is required.",
  },
  ShippingForm: {
    shipping: "Shipping",
    sameAddressLabel: "Shipping address is the same as billing address",
    firstName: "First Name",
    lastName: "Last Name",
    country: "Country",
    state: "State",
    address: "Address",
    address2: "Address Line 2 (Optional)",
    city: "City",
    zip: "Zip/Postal Code",
    selectOne: "- Select one -",
  },
  SidebarComponent: {
    downloads: "Downloads and Features",
    upgradeBoxHeader: "Upgrade to Pro",
    upgradeBoxHeaderSmb: "Plan Upgrade",
    upgradeBoxBody:
      "@:Brand.carboniteSafePro{''} includes unlimited computers, centralized computer management, and HIPAA compliance.",
    upgradeBoxBodySmb: "Upgrade your @:Brand.carboniteOffice{''} plan",
    upgradeBoxLink: "See plans now",
    upgradeBoxLinkSmb: "Upgrade your plan",
    creditCards: "Credit cards",
    paymentHistory: "Payment History",
    personalInformation: "Personal Information",
    subscriptions: "Subscriptions",
    support: "Support",
    backup: "Backup",
    smbBackupDashboard: "Backup Dashboard",
    smbNotifications: "Notifications",
    smbManageBackupPolicies: "Manage Backup Policies",
    smbAccountSummary: "Account Summary",
    smbBillingInformation: "Billing Information",
    smbPersonalInformation: "Personal Information",
    smbGetHelp: "Get Help",
    smbContactUs: "Contact Us",
    home: "Home",
    security: "Security",
    allstate: "@:Brand.identityProtection{''}",
  },
  SpecialOffers: {
    specialOffersH1: "Special Offers",
    AllstateIdentityProtectionBasicDescription1: "Protect yourself from identity theft with @:Brand.allstate{''}.",
    AllstateIdentityProtectionBasicDescription2: "This product is only available in the U.S.",
    AllstateIdentityProtectionBasicDescription3: "Protection for 1 identity, $25K coverage limit.",
    AllstateIdentityProtectionPremiumDescription1: "Protect yourself from identity theft with @:Brand.allstate{''}.",
    AllstateIdentityProtectionPremiumDescription2: "This product is only available in the U.S.",
    AllstateIdentityProtectionPremiumDescription3: "Protection for 1 identity, $1M coverage limit.",
    AllstateIdentityProtectionPremiumFamilyDescription1:
      "Protect your whole family against identity theft and frauds to your accounts with @:Brand.allstate{''} @:Brand.identityProtection{''}.",
    AllstateIdentityProtectionPremiumFamilyDescription2: "This product is only available in the U.S.",
    AllstateIdentityProtectionPremiumFamilyDescription3: "Protection for 10 identities, $1M coverage limit.",
    WebrootWiFiSecurityDescription1: "Keep your WiFi connections private and secure with @:Brand.webroot{''} VPN.",
    WebrootWiFiSecurityDescription2: "Protection for 3 devices",
    WebrootVPNDescription1: "Keep your WiFi connections private and secure with @:Brand.WebrootSecureVPNP{''}.",
    WebrootVPNDescription2: "VPN Protection for 3 devices",
    WebrootPcOptimizerDescription1:
      "Speed up, optimize, clean, and repair errors to make your Windows PC run like new.",
    WebrootPcOptimizerDescription2: "Optimization for 10 devices",
    WebrootPlusDescription1: "Safeguards your devices against viruses, phishing attempts and much more.",
    WebrootPlusDescription2: "Protection for 3 devices.",
    startingAt: "Starting at",
  },
  SpecialOffersInfo: {
    StartingAt: "Starting at {price}",
    SoftwareCDP1:
      "The software CD is an optional method for reinstalling your @:Brand.webrootProtection{''}. Some customers prefer this method for reinstalling. Please note that a software download is included with your purchase.",
    AllstateIdentityProtectionBasicLI1: "@:Brand.allstate{''} @:Brand.identityProtection{''} Basic for 1 individual",
    AllstateIdentityProtectionBasicLI2: "Identity, financial and dark web monitoring",
    AllstateIdentityProtectionBasicLI3: "24/7 US-based remediation experts",
    AllstateIdentityProtectionPremiumL1: "@:Brand.allstate{''} @:Brand.identityProtection{''} Premium for 1 individual",
    AllstateIdentityProtectionPremiumL2: "Identity, financial and dark web monitoring",
    AllstateIdentityProtectionPremiumL3: "Credit monitoring (1 bureau)",
    AllstateIdentityProtectionPremiumL4: "24/7 US-based remediation experts",
    AllstateIdentityProtectionPremiumFamilyL1:
      "@:Brand.allstate{''} @:Brand.identityProtection{''} Premium for 10 family members",
    AllstateIdentityProtectionPremiumFamilyL2: "Identity, financial and dark web monitoring",
    AllstateIdentityProtectionPremiumFamilyL3: "Credit monitoring (1 bureau)",
    AllstateIdentityProtectionPremiumFamilyL4: "24/7 US-based remediation experts",
    WebrootWiFiSecurityP1:
      "@:Brand.webroot{''} WiFi Privacy VPN encrypts your connections to WiFi hotspots, at home or in public places, so you can protect your privacy and data by staying anonymous online.",
    WebrootWiFiSecurityP2: "Secure your connections now with @:Brand.webroot{''} Privacy VPN.",
    WebrootVPNP1:
      "@:Brand.WebrootSecureVPNP{''} encrypts your connections to WiFi hotspots, at home or in public places, so you can protect your privacy and data by staying anonymous online.",
    WebrootVPNP2: "Secure your connections now with @:Brand.WebrootSecureVPNP{''}.",
    WebrootPcOptimizerDescriptionP1: "The ultimate PC tune-up tool to boost speed, make space and repair errors.",
    WebrootPcOptimizerDescriptionP2: "Restore your Windows PC to peak performance in seconds.",
    WebrootPlusL1: "Protects up to 3 PCs, Macs, Chromebooks, smartphones or tablets",
    WebrootPlusL2: "Award-winning, lightning-fast, uninterrupted protection",
    WebrootPlusL3: "Includes Password Manager to secure your passwords",
  },
  SplitButtonComponent: {
    AlsoAvailableFor: "Also available for:",
  },
  StrongPasswordMessages: {
    useUpperCase: "Password must have at least 1 UPPERCASE letters (e.g. ABCD)",
    useLowerCase: "Password must have at least 1 lowercase letter (e.g. abcd)",
    oneNumber: "Password must have at least 1 number (e.g. 1234)",
    characters: "Password must be between {min} and {max} characters",
    cannotUseBraces: "Password can not use {'<'} and {'>'}",
  },
  SubscriptionInformationComponent: {
    subscriptionsHeading: "Subscriptions",
    productCol: "Product",
    paymentMethodCol: "Payment Method",
    nextPaymentDateCol: "Next Payment",
    manageCol: "Manage",
    accountServicesHeading: "Account Services",
    applyACLink: "Apply @:Brand.carbonite{''} Activation Code",
    optionsLink: "Options",
    installLink: "Install",
    computer: "Computer Nickname: ",
    upgradeLink: "Upgrade",
    renewUpgradeLink: "Renew/Upgrade",
    notApplicable: "n/a",
    buyNowLink: "Buy Now",
    renewNowLink: "Renew Now",
    trial: "Trial",
    automatic: "Automatic",
    manual: "Manual payment",
    keycode: "Keycode:",
    expired: "Expired",
    closed: "Closed",
    notActivatedYet: "Not Activated yet",
    contactBestBuyLink: "Contact @:Brand.bestBuy{''}",
    contactAmazonLink: "Contact @:Brand.amazon{''}",
    processingRenewal: "Processing renewal",
    paymentDetails: "Payment Method Details",
    note1:
      "Note: For security reasons, you cannot edit details of an existing payment method. Click {change} and add these details as a new card instead.",
    changePaymentMethod: "Change Payment Method",
    selectPaymentMethod: "Select Payment Method",
    addPaymentMethod: "Add Payment Method",
    paymentDetailsHeaderCarb: "This payment method will be applied to all of your @:Brand.carbonite{''} subscriptions.",
    paymentDetailsHeaderWebroot: "This payment method will be applied to your @:Brand.webroot{''} subscription.",
    on: "on",
    addPaymentHeader: "This payment method will be applied to all of your @:Brand.carbonite{''} subscriptions.",
    all: "all",
    unassigned: "unassigned",
    addNewPaymentMethod: "Add a payment method",
    vatIdNumber: "VAT ID Number",
    edit: "Edit",
    noDataToDisplay: "No subscriptions to display",
    installToActive: "Install to activate",
    changeSubscription: "Change @:Brand.carbonite{''} plan",
    changePaymentProcessingMessage:
      "We are updating your payment information. Please do not refresh the page or click the Back button.",
    paymentTypeCC: "@:BuyPaymentComponent.paymentTypeCC",
    paymentTypePP: "@:BuyPaymentComponent.paymentTypePP",
  },
  SupportComponent: {
    h1Heading: "Support",
    additionalProducts: "Additional Products",
  },
  TwoStepVerificationComponent: {
    h1Heading: "Verify Security Code",
    cancel: "Cancel",
    next: "Submit Code",
  },
  UnderMaintenanceComponent: {
    pardonTheInconvenience: "Pardon the inconvenience",
    UnderMaintenanceP1: "We're updating the store right now and hope to be back quickly. Be sure to check back soon.",
  },
  UndoRemove: {
    text: "{product} removed",
    undoBtn: "Undo",
    cartEmpty: "Your cart is empty",
    startShopping: "Start Shopping",
  },
  UpdateEmailAddressComponent: {
    headerH1: "Edit Email Address",
    email: "Email Address",
    password: "Password",
    emailInUse: "Cannot use this email address.",
    save: "Save",
    cancel: "Cancel",
    passwordStatement: "Password required to prevent unauthorized changes.",
    incorrectPassword: "Incorrect password.",
    newEmailSameAsOld: "This is your current email address. Please enter a new one.",
  },
  UpgradeSubscriptionComponent: {
    header: "Upgrade / Add",
    upgrade: "Upgrade",
    yourSubscriptions: "Your Subscriptions",
    choose: "Choose the computer for which you would like to purchase a subscription.",
    computerCol: "Computer",
    planCol: "Plan",
    expirationCol: "Expires On",
    statusCol: "Status",
    next: "Next",
    cancel: "Cancel",
    continue: "Continue",
    addNewSub: "Add new subscription",
    youHaveUnusedH1: "You have {qty} Unused Subscription Available",
    youHaveUnusedP:
      "You have {qty} unused @:Brand.carbonite{''} subscription available on your @:Brand.carbonite{''} account. You can apply this subscription or purchase a new one.",
    wouldYouLikeToApplyNow: "Would you like to apply this subscription now?",
    yesApply: "Yes, help me apply my unused subscription",
    noPurchase: "No, I'd like to purchase additional subscription(s)",
    selectAC: "1. Select the activation code to apply",
    chooseUse: "2. How do you want to use this activation code?",
    applyToExistingComputer: "Apply it to an existing computer with the same @:Brand.carbonite{''} plan:",
    applyToThisComputerAndInstall: "Apply it to this computer and install",
    addAnotherCode: "Add another code",
    active: "Active",
    activeAutoRenewal: "Active - Auto Renewal",
    activeUntilCancelled: "Active - Active Until Cancelled",
    expiresIn1day: "Expires in 1 day",
    expiresIn: "Expires in ",
    days: " days",
    trial: "Trial",
    expired: "Expired",
    trialExpired: "- Expired",
  },
  UpgradeToProComponent: {
    header: "Upgrade to a @:Brand.carboniteOffice{''} Plan",
    paragraph1:
      "If you’re backing up multiple computers, Windows File servers, External Hard Drives, or you have sensitive health, financial or education files, you need a @:Brand.carboniteOffice{''} plan.",
    paragraph2:
      "ONLY @:Brand.carboniteOffice{''} plans give you the level of robust protection you need to keep your business operating continuously. Features include:",
    bulletPoint1: "Backup an unlimited number of computers, external drives and NAS devices",
    bulletPoint2: "Support Windows file servers",
    bulletPoint3: "Support HIPAA, FINRA and FERPA compliance",
    bulletPoint4: "Easy setup and Valet install for additional computers",
    questions: "Questions",
    smallBusinessBackupEmail: "SmallBusinessBackup{'@'}carbonite.com",
    clickHere: "Click here to view plans",
    noThanks: "No, thanks",
    phoneNumber: "1-855-227-2249",
  },
  UpgradeNotAllowed: {
    header: "This Subscription Can't Be Upgraded Yet",
    p1: "Thank you for your interest in upgrading your @:Brand.webrootProtection{''}",
    p2: "For retail subscriptions we can only offer an upgrade when there are 30 days or fewer remaining, in order to be sure you get full credit for the time remaining on your current subscription.",
    p3: "This subscription has more than 30 days before renewal or expiration.",
  },
  VatInputComponent: {
    label: "EU VAT identification number",
    edit: "Edit",
    vatIdVerified: "VAT ID verified",
    addVat: "Add VAT ID",
    businessCustomers:
      "Business customers: Add your VAT ID number if this purchase qualifies for a tax privilege/exemption.",
  },
  VerifyExistingEmailAddressComponent: {
    close: "Close",
    Heading: "Verify Email Address",
    VerifyEmail: "We need to verify your email address.",
    ClickWord: "Click",
    ButtonLabel: "Send Verification Email",
    AfterButton: "below to send a verification email to",
    From: "from",
    CarbWebroot: "@:Brand.carbonite{''} + @:Brand.webroot{''}",
    YouHave: "You will have",
    TimeFrame: "6 hours",
    AfterTimeFrame: "from the time the email is sent to verify your email address.",
    EmailSent: "Email Sent!",
  },
  VerifyPendingEmailAddressComponent: {
    headerH1: "Verify Email Address",
    line1: "We need to verify your new email address. Check your email {email}.",
    line2:
      'We\'ve sent an email from {carbonitePlusWebroot} with the subject "{alert}" with a link to verify your email address. Be sure to check the junk folder, just in case.',
    line3: "You will have {time} from the time the verification email is sent to verify your email address.",
    time: "6 hours",
    close: "Close",
    sendAnotherEmail: "Send Another Email",
    emailSent: "Email Sent",
    carbonitePlusWebroot: "@:Brand.carbonite{''} + @:Brand.webroot{''}", // used in line 2
    alert: "Alert: Verify your email", // alert from line 2
  },
  VerifyPrimaryEmailAddressComponent: {
    headerH1: "Verify Email Address",
    line1: "We need to verify your email address. Check your email {email}.",
    line2:
      'We’ve sent an email from {carbonitePlusWebroot} with the subject "{alert}" with a link to verify your email address. Be sure to check the junk folder, just in case.',
    line3: "You will have {time} from the time the verification email is sent to verify your email address.",
    time: "6 hours",
    close: "Close",
    sendAnotherEmail: "Send Another Email",
    emailSent: "Email Sent",
    carbonitePlusWebroot: "@:Brand.carbonite{''} + @:Brand.webroot{''}", // used in line 2
    alert: "Alert: Verify your email", // alert from line 2
  },
  VerifyPrimaryEmailAddressCtnComponent: {
    headerH1: "Verify Email Address",
    line1: "Before you add a keycode, please verify your email address.",
    line2:
      "We've sent an email to {email} with a link to verify your email address. Be sure to check the junk folder, just in case.",
    line3: "You will have {time} from the time the verification email is sent to verify your email address.",
    time: "6 hours",
    cancel: "Cancel",
    continue: "Continue",
    sendAnotherEmail: "Send Another Email",
    emailSent: "Email Sent",
  },
  Verify2FA: {
    h1Heading: "Verify Security Code",
    informationP1a: "If this is a valid account email, you will receive a security code by phone or text message",
    informationP1b: "Please enter the security code below.",
    informationP2: "This code will expire in ",
    labelSecurityCode: "Security Code",
    informationP3a: "Didn't receive your security code?",
    informationP3b: "Send again",
    informationP3c: "Send a new code",
    cancel: "Cancel",
    next: "Submit Code",
    bodyP1: "Enter the 5-digit security code that we sent to {phone}",
    bodyP2: "This code is valid for 1 hour from the time it was sent.",
  },
  WebrootARTurnOffComponent: {
    WRAutoRenewOffLine1: "Opt Out of Auto-Renewal",
    WRAutoRenewOffLine2: "1) To opt out of auto-renewal, visit {page}",
    WRAutoRenewOffLine3: "2) You will need to enter your keycode on that page (included here for your convenience).",
    WRAutoRenewOffLine4: "copy to clipboard",
    WRAutoRenewOffLine5: "To learn more about managing your @:Brand.webroot{''} subscription, search our {link}",
    Link1: "knowledge base.",
    Link2: "this page.",
    Keycode: "Keycode: ",
  },
  WebrootARTurnOnComponent: {
    WRAutoRenewOnLine1: "If you would like to turn on automatic renewal for this subscription, please contact {link}",
    supportLink: "@:Brand.webroot{''} Support.",
  },
  WebrootAutoRenewOffSurveyComponent: {
    autoRenewOffSurveyH2: "Let us know why you are turning off auto-renewal.",
    reasonTurnOff: "Please select the response that best describes your reason.",
    r301: "I plan to renew, but I want to do it manually.",
    r302: "I plan to renew, but I want to change my payment method.",
    r304: "I don't plan to renew because this service is too expensive.",
    r312: "I don't plan to renew because I'm switching to a paid subscription from a different vendor or brand.",
    r313: "I don't plan to renew because I'm switching to a free solution.",
    r306: "Other (please specify):",
  },
  WebrootDownloadInstructions: {
    downloadInstructions: "Download Instructions",
    toInstall: "To install:",
    important: "Important:",
    WTPImportantMessage:
      "Customers upgrading from @:Brand.WebrootAntiVirus{''}, @:Brand.wsaISP{''}, Complete or Premium - be sure to download and install @:Brand.WebrootTotalProtection{''} on each of your computers to replace the older app.",
    WTPol3: "@:Common.downloadAndInstall{''} {productName}.",
    WTPol4: "During installation, you will be prompted to enter a keycode, shown below.",
    WIFIp1:
      "This product requires its own account and password (<strong>@:Brand.WebrootSecureVPN{''}</strong> account), different from <strong>@:Brand.myAccount{''}</strong>.",
    WIFIol1btn: "Create your @:Brand.WebrootSecureVPN{''} account.",
    WIFIol1: "If you already have an account, skip to step 3.",
    WIFIol2:
      "You will receive an email with a verification link. Click on the link in that email to activate your @:Brand.WebrootSecureVPN{''} account.",
    WIFIol3btn: "Downloads page",
    WIFIol3: "Visit the {downloadsBtn} in @:Brand.myAccount{''} to download the app.",
    forRenewals: "For renewals:",
    WIFIp3:
      "Remember, use your <strong>@:Brand.WebrootSecureVPN{''}</strong> account credentials to log in to the app, and use your <strong>@:Brand.myAccount{''}</strong> credentials to log in to the account management website.",
    webrootForChromebook: "@:Brand.webroot{''} for Chromebook",
    WSCBol1: "Visit {myAccount} on the Chromebook you want to protect.",
    WSCBol2: "Install the @:Brand.webroot{''} for Chromebook app.",
    WSCBol3: "Create your Mobile Security account* in the app using the keycode below:",
    keycodeRetrieving: "Retrieving keycode...",
    keycodeSuccess: "Your keycode is:",
    keycodeFailed:
      "Your keycode will be emailed to you, and is also available in @:Brand.myAccount{''} on the Downloads tab.",
    WSCBp1:
      "This product requires its own account and password (Webroot Mobile Security account), different from @:Brand.myAccount{''}. ",
    WSCBp2a:
      "Use your <strong>@:Brand.WebrootMS{''}</strong> account credentials to log in to the @:Brand.webroot{''} for Chromebook app.",
    WSCBp2b: "Use your <strong>@:Brand.myAccount{''}</strong> credentials to log in to the account management website.",
    WSCBp4:
      "Also, be sure to download the {downloadApp} for Google Chrome™. Get warnings about high-risk websites before you click, and automatically block bad ones.",
    WPCOol1:
      "Visit {myAccount} with the Windows PC where you want to download and install @:Brand.WebrootPcOptimizer{''}.",
    WPCOol2:
      "After you have set up <strong>@:Brand.myAccount{''}</strong>, log in and go to the <strong>Downloads</strong> tab.",
    WPCOol3: "During installation, you will be prompted to enter a keycode. ",
    allOthersOl1: "Visit {myAccount} on the computer or mobile device you want to protect.",
    allOthersOl2:
      "After you have set up <strong> @:Brand.myAccount{''}</strong>, log in and go to the <strong>Downloads</strong> tab.",
    allOthersOl3: "During installation, you will be prompted to enter a keycode.  ",
    allOthersP1a: "If you already have Webroot protection installed and want to apply this keycode to it,",
    allOthersP1b: "click here for detailed instructions",
    pvpnl3: "@:Common.downloadAndInstall{''} <strong>@:Brand.WebrootSecureVPNP{''}</strong>.",
    pvpnl4: "When prompted, enter your <strong>@:Brand.myAccount{''}</strong> email address and password.",
  },
  WebrootKeyCodes: {
    keyCodesOnlyLetterAndNumbers: "Keycodes can contain only letters and numbers (excluding hyphens)",
  },
  WebrootProductsInfo: {
    li: "This payment method will be applied to your {sku} (keycode {keycode}).",
  },
  WebrootSupportSectionComponent: {
    webrootSecureAnyWhere: "@:Brand.webrootProtection{''}",
    webThreatShield: "@:Brand.WebrootWTS{''} browser extension",
    mobileSecurity: "@:Brand.WebrootMS{''} app",
    searchKnowledge: "Search knowledge base articles",
    popularSearches: "Popular searches",
    chromebookSecurity: "Chromebook Security app",
    wifiSecurity: "@:Brand.WebrootSecureVPNP{''}",
    webrootSecureVPN: "@:Brand.WebrootSecureVPNP{''}",
    search: "Search",
    findHelpWebrootText: "Search @:Brand.webroot{''} Knowledge Base",
    webrootCustomerSupport: "@:Brand.webroot{''} Customer Support",
    webrootSupportText: "Go to @:Brand.webroot{''} customer support",
    installPC: "Install (PC)",
    locateYourKeycode: "Locate Your Keycode",
    uninstallPC: "Uninstall (PC)",
    WebrootPcOptimizer: "@:Brand.WebrootPcOptimizer{''}",
  },
  WindowsArmInfo: {
    headerH1: "How to tell if your computer has an x64/x86 processor or an ARM processor",
    howToTellWhich: "Here's how to tell if your computer has an x64/x86 processor or an ARM processor on",
    win11olLi1: "Click the <strong>Start</strong> button and click <strong>Settings</strong>.",
    win11olLi2: "In the left panel, click <strong>System</strong>.",
    win11olLi3: "In the right panel, scroll down if necessary and click <strong>About</strong>.",
    win10olLi1: "Click the <strong>Start</strong> button and click <strong>Settings</strong>.",
    win10olLi2: "Click <strong>System</strong>.",
    win10olLi3: "In the left panel, scroll down if necessary and click <strong>About</strong>.",
  },
  WindowsMoveSubscriptionComponent: {
    headerH1: "Move my subscription",
    headerCdlpH1: "Move my backup",
    moveNicknameLabel: "Move your computer from: ",
    moveNicknameInstruction:
      "You must be on your new computer to move your @:Brand.carbonite{''} subscription. @:Brand.carbonite{''} subscriptions are computer-specific; only one subscription can be used for a single computer at a time.",
    moveNicknameCdlpInstruction:
      "You must be on your new computer to move your @:Brand.WebrootBackupAndRestore{''} backup. @:Brand.WebrootBackupAndRestore{''} backups are computer-specific; only one computer can be backed up at a time.",
    moveNicknameOptions: "Choose an option:",
    moveAndBackup: "Move my subscription and {backup}",
    moveAndBackupCdlp: "Move to a new computer and {backup}",
    startNewBackup: "start a new backup",
    moveAndNote:
      "Note: If you move your computer and start a new backup, your previous backup will remain accessible for 30 days.",
    moveAndTransfer: "Move my subscription and {transfer}",
    moveAndTransferCdlp: "Move to a new computer and {transfer}",
    transferFiles: "transfer my files",
    next: "Next",
    footerButtonCancel: "Cancel",
  },
  DownloadRestrictedFilesComponent: {
    header1: "CONFIRM YOUR IDENTITY TO DOWNLOAD FILES",
    header2: "UNABLE TO DOWNLOAD FILES",
    restrictedFilesLineOne: "The files selected may contain sensitive data.",
    restrictedFilesLineTwo: "For security reasons, we require additional information to verify your account.",
    restrictedFilesLineThree:
      "Please enter the 5-digit security code that we sent to {device} to help confirm your identity.",
    restrictedFilesLineFour: "This code is valid for 1 hour from the time it was sent.",
    securityCode: "Security Code",
    trustDevice: "Trust this device.",
    restrictedFilesLineFive: "Didn't receive your security code?",
    restrictedFilesLineSix: "Don't have access to your phone?",
    sendAgain: "Send again",
    callMe: "Call me",
    emailCode: "Email your code to {email}",
    submitCode: "Submit Code",
    downloadFromClientOrCourier: "Please download the files from Carbonite client or order a courier recovery.",
    infoTooltip: "You will not be requested a code for next 30 days.",
    resendTimerText: "Send again in {seconds} seconds",
    downloadWithoutRestrictedFiles: "Download files without sensitive data",
    proceed: "Proceed",
  },
};
