<template>
  <div class="side-by-side">
    <select
      id="selectDownload"
      :key="selectedValue"
      v-model="selectedValue"
      class="select-css auto mr-half mb-half-mobile"
    >
      <option v-for="l in listOptions" :key="l.value" :value="l.value">{{ l.description }}</option>
    </select>
    <button id="btnDownload" class="btn-primary" type="button" @click="download()">
      {{ t(`${parentComponentName}.btnDownload`) }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IListOptions } from "@/common/api/unifiedPortal/interfaces";
import { downloadClient } from "@/common/safeClient";
import { IProduct, productList, windows, windowsArm } from "@/common/productList";
import { productType } from "@/globalEnums";
import { getOsFamily, getBrowserFamily } from "@/common/browserDetect";
import { useSiteSettingsStore } from "@/stores/siteSettings";
import { cpuArchitecture } from "@/common/cpuDetect";
import { hasAnyBestBuySubscriptions } from "@/common/bestBuy";

const componentName = "SelectorComponent";
const parentComponentName = "DownloadProductComponent";

const props = defineProps({
  productCode: {
    type: String,
    default: null,
  },
  pureVpnWindowsInstallerUrl: {
    type: String,
    default: null,
  },
  pureVpnMacInstallerUrl: {
    type: String,
    default: null,
  },
  hideWindowsOption: {
    type: Boolean,
  },
});

logEvent("created", componentName, props);
const listOptions = ref<IListOptions[]>([]);
const selectedValue = ref<string>("");

const siteSettingsStore = useSiteSettingsStore();

const hasAnyBestBuySubs = ref(false);

onMounted(async () => {
  selectedValue.value = "";
  hasAnyBestBuySubs.value = await hasAnyBestBuySubscriptions();

  // Populate the list
  const currentProduct = productList().find(p => p.code == props.productCode);
  updateDownloadUrlForVPNP(currentProduct);
  if (currentProduct) {
    currentProduct.targetSystem.forEach(item => {
      listOptions.value.push({
        value: hasAnyBestBuySubs.value && item.bbyLink.length > 0 ? item.bbyLink : item.link,
        description: item.fullName,
      });
    });

    // Get current OS name
    let currentSystemFullName = currentProduct.targetSystem.find(
      c => c.familyName.toLowerCase() === getBrowserFamily().toLowerCase()
    )?.fullName;
    if (currentProduct.productType !== productType.Browser) {
      const userCpuArchitecture = await siteSettingsStore.getUserCpuArchitecture();
      //Try to figure out if the user's CPU architecture if the flag is turned on.
      //Falls back on checking the OS from the browser.
      if (userCpuArchitecture !== cpuArchitecture.unknown) {
        switch (userCpuArchitecture) {
          case cpuArchitecture.window32:
          case cpuArchitecture.windows64:
            currentSystemFullName = currentProduct.targetSystem.find(l => l.familyName === windows)?.fullName;
            break;
          case cpuArchitecture.arm:
          case cpuArchitecture.arm64:
            currentSystemFullName = currentProduct.targetSystem.find(l => l.familyName === windowsArm)?.fullName;
            break;
          default:
            currentSystemFullName = "";
        }
      } else {
        currentSystemFullName = currentProduct.targetSystem.find(
          c => c.familyName.toLowerCase() === getOsFamily().toLowerCase()
        )?.fullName;
      }
    }

    // Hide Windows options for CDLP
    if (props.hideWindowsOption) {
      listOptions.value = listOptions.value.filter(l => !l.description.includes(windows));
    }

    // Make the current OS appear first
    if (currentSystemFullName) {
      listOptions.value.sort(function (a, b) {
        return a.description == currentSystemFullName ? -1 : b.description == currentSystemFullName ? 1 : 0;
      });
    }
    selectedValue.value = listOptions.value[0].value;
  }
});

function updateDownloadUrlForVPNP(currentProduct: IProduct | undefined) {
  if (currentProduct && props.pureVpnMacInstallerUrl && props.pureVpnWindowsInstallerUrl) {
    const windowsSystem = currentProduct.targetSystem.find(x => x.familyName == "Windows");
    if (windowsSystem) {
      windowsSystem.link = props.pureVpnWindowsInstallerUrl;
    }
    const macSystem = currentProduct.targetSystem.find(x => x.familyName == "Mac");
    if (macSystem) {
      macSystem.link = props.pureVpnMacInstallerUrl;
    }
  }
}

function download() {
  logEvent("download", componentName, selectedValue.value);
  if (isFile(selectedValue.value)) {
    downloadClient(selectedValue.value);
  } else {
    window.open(selectedValue.value, "_blank");
  }
}

function isFile(location: string) {
  location = location.toLowerCase();
  return (
    location.includes(".exe") || location.includes(".dmg") || location.includes(".zip") || location.includes(".pkg")
  );
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
