import { IEndpointConfig } from "@/common/api/interfaces";
import { webrootLicenseCategories } from "@/common/webrootProductName";

declare let DEFINE: {
  ENV: string;
  BUILD_DATE: string;
  BUILD_HASH: string;
  PACKAGE: unknown;
};

export const FLAGS = {
  IS_DEBUG: DEFINE.ENV === "development" || DEFINE.ENV === "local",
  SHOW_RFA_COMPONENT: import.meta.env.VITE_APP_SHOW_RFA_COMPONENT || "false",
  SHOW_CSSB_RFA_COMPONENT: import.meta.env.VITE_APP_SHOW_CSSB_RFA_COMPONENT || "false",
  SHOW_SECURITY_TAB: import.meta.env.VITE_APP_SHOW_SECURITY_TAB || "false",
  SHOW_LANGUAGE_PICKER: import.meta.env.VITE_APP_SHOW_LANGUAGE_PICKER || "false",
  ENABLE_EMAIL_VALIDATION_KEYCODE: import.meta.env.VITE_APP_ENABLE_EMAIL_VALIDATION_KEYCODE || "false",
  ENABLE_ALLSTATE_FAMILY: import.meta.env.VITE_APP_ENABLE_ALLSTATE_FAMILY || "false",
  ENABLE_CHROMEBOOK: import.meta.env.VITE_APP_ENABLE_CHROMEBOOK || "false",
  ENABLE_VPN: import.meta.env.VITE_APP_ENABLE_VPN || "false",
  AUTO_CLOSE_PENDO_TOOLTIP: import.meta.env.VITE_APP_AUTO_CLOSE_PENDO_TOOLTIP || "false",
  ENABLE_VD_COURIER_RECOVERY: import.meta.env.VITE_APP_ENABLE_VD_COURIER_RECOVERY || "false",
  ENABLE_BUYFLOW: import.meta.env.VITE_APP_ENABLE_BUYFLOW || "false",
  DISPLAY_ALLSTATE_UPGRADE: import.meta.env.VITE_APP_DISPLAY_ALLSTATE_UPGRADE || "false",
  ENABLE_BUYFLOW_WEBROOT: import.meta.env.VITE_APP_ENABLE_BUYFLOW_WEBROOT || "false",
  ENABLE_BUYFLOW_PAYPAL: import.meta.env.VITE_APP_ENABLE_BUYFLOW_PAYPAL || "false",
  ENABLE_BUYFLOW_PAYPAL_FOR_CARBONITE: import.meta.env.VITE_APP_ENABLE_BUYFLOW_PAYPAL_FOR_CARBONITE || "false",
  ENABLE_SAFE_WEBROOT_MIGRATION: import.meta.env.VITE_APP_ENABLE_SAFE_WEBROOT_MIGRATION || "false",
  HIDE_H2B_LEGACY_WEBROOT: import.meta.env.VITE_APP_HIDE_H2B_LEGACY_WEBROOT || "false",
  ENABLE_ALLSTATE_BBY: import.meta.env.VITE_APP_ENABLE_ALLSTATE_BBY || "false",
  ENABLE_ERSO: import.meta.env.VITE_APP_ENABLE_ERSO || "false",
  ENABLE_CDLP: import.meta.env.VITE_APP_ENABLE_CDLP || "false",
  ENABLE_WPCO: import.meta.env.VITE_APP_ENABLE_WPCO || "false",
  ENABLE_WSVPN: import.meta.env.VITE_APP_ENABLE_WSVPN || "false",
  ENABLE_RESTRICTED_FILES_DOWNLOAD: import.meta.env.VITE_APP_ENABLE_RESTRICTED_FILES_DOWNLOAD || "false",
};

export const BUILD_DATE = DEFINE.BUILD_DATE;

export const BUILD_HASH = DEFINE.BUILD_HASH;

export const PACKAGE = DEFINE.PACKAGE;

const origin = `${window.location.origin.toLowerCase()}/`;
const webrootUrl = import.meta.env.VITE_APP_WEBROOT_URL;
const upEndpoint = origin.startsWith(webrootUrl)
  ? import.meta.env.VITE_APP_UP_HOSTNAME_WEBROOT
  : import.meta.env.VITE_APP_UP_HOSTNAME_CARBONITE;
const cpEndpoint = origin.startsWith(webrootUrl)
  ? import.meta.env.VITE_APP_CP_HOSTNAME_WEBROOT
  : import.meta.env.VITE_APP_CP_HOSTNAME_CARBONITE;
const loginUrl = `https://${upEndpoint}/api/cp/auth/cplogin`;

export const URLS = {
  UP_ENDPOINT: {
    protocol: import.meta.env.VITE_APP_UP_PROTOCOL ?? "https",
    hostname: upEndpoint ?? "localhost",
  } as IEndpointConfig,
  CP_ENDPOINT: {
    protocol: import.meta.env.VITE_APP_UP_PROTOCOL ?? "https",
    hostname: cpEndpoint ?? "localhost",
  } as IEndpointConfig,
  CP_LOGIN: loginUrl as string,
  VITE_APP_URL: (import.meta.env.VITE_APP_URL ?? "") as string,
  VITE_APP_WEBROOT_URL: (import.meta.env.VITE_APP_WEBROOT_URL ?? "") as string,
  CARBONITE_BUY_URL: (import.meta.env.VITE_APP_CARBONITE_BUY_URL ?? "") as string,
  WEBROOT_BUY_URL: (import.meta.env.VITE_APP_WEBROOT_BUY_URL ?? "") as string,
  REMOTE_FILE_ACCESS_URL: (import.meta.env.VITE_APP_REMOTE_FILE_ACCESS_URL ?? "") as string,
  VITE_APP_CARBONITE_2FA_INFO_URL: (import.meta.env.VITE_APP_CARBONITE_2FA_INFO_URL ?? "") as string,
  VITE_APP_CARBONITE_INSTALLED_HOW_TO_TELL_URL: (import.meta.env.VITE_APP_CARBONITE_INSTALLED_HOW_TO_TELL_URL ??
    "") as string,
  VITE_APP_CARBONITE_PERSONAL_WINDOWS_RESTORE_ALL_FILES_URL: (import.meta.env
    .VITE_APP_CARBONITE_PERSONAL_WINDOWS_RESTORE_ALL_FILES_URL ?? "") as string,
  VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL: (import.meta.env
    .VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL ?? "") as string,
  COURIER_DELIVERY: (import.meta.env.VITE_APP_COURIER_DELIVERY ?? "") as string,
  LASTPASS_PORTAL_URL: (import.meta.env.VITE_APP_LASTPASS_PORTAL_URL ?? "") as string,
  LASTPASS_DETAILED_INSTRUCTIONS_URL: (import.meta.env.VITE_APP_LASTPASS_DETAILED_INSTRUCTIONS_URL ?? "") as string,
  WEBROOT_LEARN_MORE: (import.meta.env.VITE_APP_WEBROOT_LEARN_MORE_URL ?? "") as string,
  SAFE_LEARN_MORE: (import.meta.env.VITE_APP_SAFE_LEARN_MORE_URL ?? "") as string,
  MIRROR_ISO: (import.meta.env.VITE_APP_MIRROR_ISO ?? "") as string,
  VITE_APP_SAFE_SHA1_MORE_DETAILS_URL: (import.meta.env.VITE_APP_SAFE_SHA1_MORE_DETAILS_URL ?? "") as string,
  VITE_APP_WEBROOT_INSTALL_SUPPORT: (import.meta.env.VITE_APP_WEBROOT_INSTALL_SUPPORT ?? "") as string,
  VITE_APP_WPCO_INSTALL_SUPPORT: (import.meta.env.VITE_APP_WPCO_INSTALL_SUPPORT ?? "") as string,
  WEBROOT_COMPLETE_URL: (import.meta.env.VITE_APP_WEBROOT_COMPLETE_URL ?? "") as string,
  VITE_APP_WEBROOT_HOW_TO_INSTALL: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_INSTALL ?? "") as string,
  VITE_APP_WEBROOT_HOW_TO_UNINSTALL: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_UNINSTALL ?? "") as string,
  VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT: (import.meta.env.VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT ?? "") as string,
  VITE_APP_SAFE_REINSTALL_SUPPORT: (import.meta.env.VITE_APP_SAFE_REINSTALL_SUPPORT ?? "") as string,
  VITE_APP_CARBONITE_SUPPORT_SITE: (import.meta.env.VITE_APP_CARBONITE_SUPPORT_SITE ?? "") as string,
  VITE_APP_CARBONITE_COMPARE_PLANS: (import.meta.env.VITE_APP_CARBONITE_COMPARE_PLANS ?? "") as string,
  CARBONITE_UP_URL: (import.meta.env.VITE_APP_CARBONITE_UP_URL ?? "") as string,
  AMAZON_MEMBERSHIP_URL: (import.meta.env.VITE_APP_AMAZON_MEMBERSHIP_URL ?? "") as string,
  ALLSTATE_PRIVACY_POLICY_URL: (import.meta.env.VITE_APP_ALLSTATE_PRIVACY_POLICY_URL ?? "") as string,
  ALLSTATE_MANAGE_URL: (import.meta.env.VITE_APP_ALLSTATE_MANAGE_URL ?? "") as string,
  BESTBUY_URL: (import.meta.env.VITE_APP_BESTBUY_URL ?? "") as string,
  WINDOWS_UNINSTALL_URL: import.meta.env.VITE_APP_WINDOWS_UNINSTALL_URL as string,
  MAC_UNINSTALL_URL: (import.meta.env.VITE_APP_MAC_UNINSTALL_URL ?? "") as string,
  RANSOMEWARE_INFECTION_URL: import.meta.env.VITE_APP_RANSOMEWARE_INFECTION_URL as string,
  ENCRYPTION_KEY_URL: (import.meta.env.VITE_APP_ENCRYPTION_KEY_URL ?? "") as string,
  MAC_PROCESSOR_URL: (import.meta.env.VITE_APP_MAC_PROCESSOR_URL ?? "") as string,
  CONTACT_SUPPORT_URL: (import.meta.env.VITE_APP_CONTACT_SUPPORT ?? "") as string,
  SUPPORT_URL: (import.meta.env.VITE_APP_SUPPORT ?? "") as string,
  TERMS_URL: (import.meta.env.VITE_TERMS_URL ?? "") as string,
  CARBONITE_COM_SITE: (import.meta.env.VITE_APP_CARBONITE_COM_SITE ?? "") as string,
  WEBROOT_COM_SITE: (import.meta.env.VITE_APP_WEBROOT_COM_SITE ?? "") as string,
  WEBROOT_VIEW_INSTRUCTIONS: (import.meta.env.VITE_APP_WEBROOT_VIEW_INSTRUCTIONS ?? "") as string,
  WEBROOT_RENEWAL_OPT_OUT: (import.meta.env.VITE_APP_WEBROOT_RENEWAL_OPT_OUT ?? "") as string,
  VITE_APP_CONTACT_US: (import.meta.env.VITE_APP_CONTACT_US ?? "") as string,
  AUTO_RENEWAL_OPT_OUT: (import.meta.env.VITE_APP_AUTO_RENEWAL_OPT_OUT ?? "") as string,
  WEBROOT_HOME: (import.meta.env.VITE_APP_WEBROOT_HOME ?? "") as string,
  WEBROOT_MAIN_PAGE: (import.meta.env.VITE_APP_WEBROOT_MAIN_PAGE ?? "") as string,
  WEBROOT_SUPPORT_HOME: (import.meta.env.VITE_APP_WEBROOT_SUPPORT_HOME ?? "") as string,
  WEBROOT_HOW_TO_ENABLE: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_ENABLE ?? "") as string,
  WEBROOT_THREAT_SHIELD_EXTENSION: (import.meta.env.VITE_APP_WEBROOT_THREAT_SHIELD_EXTENSION ?? "") as string,
  RFA_EXPIRED_SUPPORT: (import.meta.env.VITE_APP_RFA_EXPIRED_SUPPORT ?? "") as string,
  RFA_ENCRYPTED_SUPPORT: (import.meta.env.VITE_APP_RFA_ENCRYPTED_SUPPORT ?? "") as string,
  BUY_DEACTIVATION_URL: (import.meta.env.VITE_APP_BUY_DEACTIVATION_URL ?? "") as string,
  BUY_DEACTIVATION_WEBROOT_URL: (import.meta.env.VITE_APP_BUY_DEACTIVATION_WEBROOT_URL ?? "") as string,
  BUY_PRIVACY_URL: (import.meta.env.VITE_APP_BUY_PRIVACY_POLICY_URL ?? "") as string,
  BUY_TERMS_URL: (import.meta.env.VITE_APP_BUY_TERMS_URL ?? "") as string,
  BUY_TERMS_WEBROOT_URL: (import.meta.env.VITE_APP_BUY_TERMS_WEBROOT_URL ?? "") as string,
  WEBROOT_SUPPORT_SEARCH: (import.meta.env.VITE_APP_WEBROOT_SEARCH_SUPPORT ?? "") as string,
  ALLSTATE_UPGRADE_CART: (import.meta.env.VITE_APP_ALLSTATE_UPGRADE_CART ?? "") as string,
  ALLSTATE_BUY: (import.meta.env.VITE_APP_ALLSTATE_BUY ?? "") as string,
  WEBROOT_INTERSTITIAL: (import.meta.env.VITE_APP_WEBROOT_INTERSTITIAL ?? "") as string,
  WEBROOT_REQUEST_A_REFUND: (import.meta.env.VITE_APP_WEBROOT_REQUEST_A_REFUND ?? "") as string,
};

export const URLS_JA = {
  CP_LOGIN: loginUrl as string,
  VITE_APP_URL: (import.meta.env.VITE_APP_URL_JA ?? "") as string,
  VITE_APP_WEBROOT_URL: (import.meta.env.VITE_APP_WEBROOT_URL ?? "") as string,
  CARBONITE_BUY_URL: (import.meta.env.VITE_APP_CARBONITE_BUY_URL_JA ?? "") as string,
  WEBROOT_BUY_URL: (import.meta.env.VITE_APP_WEBROOT_BUY_URL_JA ?? "") as string,
  REMOTE_FILE_ACCESS_URL: (import.meta.env.VITE_APP_REMOTE_FILE_ACCESS_URL_JA ?? "") as string,
  VITE_APP_CARBONITE_2FA_INFO_URL: (import.meta.env.VITE_APP_CARBONITE_2FA_INFO_URL_JA ?? "") as string,
  VITE_APP_CARBONITE_INSTALLED_HOW_TO_TELL_URL: (import.meta.env.VITE_APP_CARBONITE_INSTALLED_HOW_TO_TELL_URL_JA ??
    "") as string,
  VITE_APP_CARBONITE_PERSONAL_WINDOWS_RESTORE_ALL_FILES_URL: (import.meta.env
    .VITE_APP_CARBONITE_PERSONAL_WINDOWS_RESTORE_ALL_FILES_URL_JA ?? "") as string,
  VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL: (import.meta.env
    .VITE_APP_CARBONITE_PRO_WINDOWS_RESTORE_ALL_FILES_URL_JA ?? "") as string,
  COURIER_DELIVERY: (import.meta.env.VITE_APP_COURIER_DELIVERY_JA ?? "") as string,
  LASTPASS_PORTAL_URL: (import.meta.env.VITE_APP_LASTPASS_PORTAL_URL_JA ?? "") as string,
  LASTPASS_DETAILED_INSTRUCTIONS_URL: (import.meta.env.VITE_APP_LASTPASS_DETAILED_INSTRUCTIONS_URL_JA ?? "") as string,
  WEBROOT_LEARN_MORE: (import.meta.env.VITE_APP_WEBROOT_LEARN_MORE_URL_JA ?? "") as string,
  SAFE_LEARN_MORE: (import.meta.env.VITE_APP_SAFE_LEARN_MORE_URL_JA ?? "") as string,
  MIRROR_ISO: (import.meta.env.VITE_APP_MIRROR_ISO_JA ?? "") as string,
  VITE_APP_SAFE_SHA1_MORE_DETAILS_URL: (import.meta.env.VITE_APP_SAFE_SHA1_MORE_DETAILS_URL_JA ?? "") as string,
  VITE_APP_WEBROOT_INSTALL_SUPPORT: (import.meta.env.VITE_APP_WEBROOT_INSTALL_SUPPORT_JA ?? "") as string,
  VITE_APP_WPCO_INSTALL_SUPPORT: (import.meta.env.VITE_APP_WPCO_INSTALL_SUPPORT_JA ?? "") as string,
  WEBROOT_COMPLETE_URL_JA: (import.meta.env.VITE_APP_WEBROOT_COMPLETE_URL_JA ?? "") as string,
  VITE_APP_WEBROOT_HOW_TO_INSTALL: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_INSTALL_JA ?? "") as string,
  VITE_APP_WEBROOT_HOW_TO_UNINSTALL: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_UNINSTALL_JA ?? "") as string,
  VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT: (import.meta.env.VITE_APP_SAFE_RESTORE_ALL_FILES_SUPPORT_JA ?? "") as string,
  VITE_APP_SAFE_REINSTALL_SUPPORT: (import.meta.env.VITE_APP_SAFE_REINSTALL_SUPPORT_JA ?? "") as string,
  VITE_APP_CARBONITE_SUPPORT_SITE: (import.meta.env.VITE_APP_CARBONITE_SUPPORT_SITE_JA ?? "") as string,
  VITE_APP_CARBONITE_COMPARE_PLANS: (import.meta.env.VITE_APP_CARBONITE_COMPARE_PLANS_JA ?? "") as string,
  CARBONITE_UP_URL: (import.meta.env.VITE_APP_CARBONITE_UP_URL_JA ?? "") as string,
  AMAZON_MEMBERSHIP_URL: (import.meta.env.VITE_APP_AMAZON_MEMBERSHIP_URL_JA ?? "") as string,
  ALLSTATE_PRIVACY_POLICY_URL: (import.meta.env.VITE_APP_ALLSTATE_PRIVACY_POLICY_URL_JA ?? "") as string,
  ALLSTATE_MANAGE_URL: (import.meta.env.VITE_APP_ALLSTATE_MANAGE_URL_JA ?? "") as string,
  BESTBUY_URL: (import.meta.env.VITE_APP_BESTBUY_URL_JA ?? "") as string,
  WINDOWS_UNINSTALL_URL: import.meta.env.VITE_APP_WINDOWS_UNINSTALL_URL_JA as string,
  MAC_UNINSTALL_URL: (import.meta.env.VITE_APP_MAC_UNINSTALL_URL_JA ?? "") as string,
  RANSOMEWARE_INFECTION_URL: import.meta.env.VITE_APP_RANSOMEWARE_INFECTION_URL_JA as string,
  ENCRYPTION_KEY_URL: (import.meta.env.VITE_APP_ENCRYPTION_KEY_URL_JA ?? "") as string,
  MAC_PROCESSOR_URL: (import.meta.env.VITE_APP_MAC_PROCESSOR_URL_JA ?? "") as string,
  CONTACT_SUPPORT_URL: (import.meta.env.VITE_APP_CONTACT_SUPPORT_JA ?? "") as string,
  SUPPORT_URL: (import.meta.env.VITE_APP_SUPPORT_JA ?? "") as string,
  TERMS_URL: (import.meta.env.VITE_TERMS_URL_JA ?? "") as string,
  CARBONITE_COM_SITE: (import.meta.env.VITE_APP_CARBONITE_COM_SITE_JA ?? "") as string,
  WEBROOT_COM_SITE: (import.meta.env.VITE_APP_WEBROOT_COM_SITE_JA ?? "") as string,
  WEBROOT_VIEW_INSTRUCTIONS: (import.meta.env.VITE_APP_WEBROOT_VIEW_INSTRUCTIONS_JA ?? "") as string,
  WEBROOT_RENEWAL_OPT_OUT: (import.meta.env.VITE_APP_WEBROOT_RENEWAL_OPT_OUT_JA ?? "") as string,
  VITE_APP_CONTACT_US: (import.meta.env.VITE_APP_CONTACT_US_JA ?? "") as string,
  AUTO_RENEWAL_OPT_OUT: (import.meta.env.VITE_APP_AUTO_RENEWAL_OPT_OUT_JA ?? "") as string,
  WEBROOT_HOME: (import.meta.env.VITE_APP_WEBROOT_HOME_JA ?? "") as string,
  WEBROOT_SUPPORT_HOME: (import.meta.env.VITE_APP_WEBROOT_SUPPORT_HOME_JA ?? "") as string,
  WEBROOT_HOW_TO_ENABLE: (import.meta.env.VITE_APP_WEBROOT_HOW_TO_ENABLE_JA ?? "") as string,
  RFA_EXPIRED_SUPPORT: (import.meta.env.VITE_APP_RFA_EXPIRED_SUPPORT_JA ?? "") as string,
  RFA_ENCRYPTED_SUPPORT: (import.meta.env.VITE_APP_RFA_ENCRYPTED_SUPPORT_JA ?? "") as string,
  WEBROOT_HOME_RENEWAL: (import.meta.env.VITE_APP_WEBROOT_HOME_RENEWAL_JA ?? "") as string,
  BUY_DEACTIVATION_URL: (import.meta.env.VITE_APP_BUY_DEACTIVATION_URL_JA ?? "") as string,
  BUY_DEACTIVATION_WEBROOT_URL: (import.meta.env.VITE_APP_BUY_DEACTIVATION_WEBROOT_URL_JA ?? "") as string,
  BUY_PRIVACY_URL: (import.meta.env.VITE_APP_BUY_PRIVACY_POLICY_URL_JA ?? "") as string,
  BUY_TERMS_URL: (import.meta.env.VITE_APP_BUY_TERMS_URL_JA ?? "") as string,
  BUY_TERMS_WEBROOT_URL: (import.meta.env.VITE_APP_BUY_TERMS_WEBROOT_URL_JA ?? "") as string,
  WEBROOT_SUPPORT_SEARCH: (import.meta.env.VITE_APP_WEBROOT_SEARCH_SUPPORT_JA ?? "") as string,
  ALLSTATE_UPGRADE_CART: (import.meta.env.VITE_APP_ALLSTATE_UPGRADE_CART_JA ?? "") as string,
  ALLSTATE_BUY: (import.meta.env.VITE_APP_ALLSTATE_BUY_JA ?? "") as string,
  WEBROOT_INTERSTITIAL: (import.meta.env.VITE_APP_WEBROOT_INTERSTITIAL ?? "") as string,
  WEBROOT_REQUEST_A_REFUND: (import.meta.env.VITE_APP_WEBROOT_REQUEST_A_REFUND ?? "") as string,
};

export const DOWNLOAD_URLS = {
  WTP: {
    windows: (import.meta.env.VITE_APP_WTP_WINDOWS_DOWNLOAD_URL ?? "") as string,
    mac: (import.meta.env.VITE_APP_WTP_MAC_DOWNLOAD_URL ?? "") as string,
  },
};

export const googleTagConfigSettings = {
  GOOGLE_TAG_KEY_CARB: import.meta.env.VITE_APP_GOOGLE_TAG_KEY_CARB || "",
  GOOGLE_TAG_KEY_WR: import.meta.env.VITE_APP_GOOGLE_TAG_KEY_WR || "",
};

export const bazaarVoiceScriptWebrootUrl = import.meta.env.VITE_APP_BAZAAR_VOICE_WEBROOT_URL;

export const recaptchaSiteKey = import.meta.env.VITE_APP_RECAPTCHA_V3_SITEKEY;

//***********
//time constants
//***********
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MSEC_IN_SECOND = 1000;
export const MSEC_IN_MINUTE = SECONDS_IN_MINUTE * MSEC_IN_SECOND;
export const MSEC_IN_HOUR = MINUTES_IN_HOUR * MSEC_IN_MINUTE;
export const MSEC_IN_DAY = HOURS_IN_DAY * MSEC_IN_HOUR;
export const MONTHS_IN_YEAR = 12;
export const PURCHASE_KEYCODE_DELAY_IN_SECOND = 5000;

// cache expiry time for security info
export const LAST_API_FETCH_INTERVAL = MSEC_IN_MINUTE;
// time for session to keep alive if it is idle
// For QE Convenience added a flag
export const MAX_SESSION_IDLE_DURATION = sessionStorage.getItem("IDLE_TIME")
  ? parseInt(sessionStorage.getItem("IDLE_TIME") || "") * MSEC_IN_MINUTE
  : 30 * MSEC_IN_MINUTE;
// time interval for auto logout if no actions is performed by the user after AutoLogout Dialog display
export const AUTO_LOGOUT_KEEP_ALIVE_IN_MIN = 25;
export const AUTO_LOGOUT_INTERVAL_SEC = 60;
export const AUTO_LOGOUT_INTERVAL_MSEC = AUTO_LOGOUT_INTERVAL_SEC * MSEC_IN_SECOND;
export const SESSION_CHECK_INTERVAL = MSEC_IN_SECOND;
// default time to wait for api response before aborting
export const MAX_API_WAIT_DURATION = 2 * SECONDS_IN_MINUTE * MSEC_IN_SECOND;
// time to wait before reloading the Backup page after successfully renewing
export const INVOICE_PAID_REFRESH_DELAY = 3 * MSEC_IN_SECOND;
export const PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MIN = 5;
export const PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MSEC = PRELIMINARY_USER_KEEP_ALIVE_TIME_IN_MIN * MSEC_IN_MINUTE;
//Preliminary users session should be kept alive for 2 hours or so.
//Because of how the interval works with the auto log out we have to calculate the 2 hours off of
//29 minutes instead of 30 minutes so at 87 minutes we will be in the final cycle that will equal 116 minutes
export const PRELIMINARY_USER_TOTAL_KEEP_ALIVE_TIME_IN_MINUTES = sessionStorage.getItem("PRELIMINARY_KEEP_ALIVE")
  ? parseInt(sessionStorage.getItem("PRELIMINARY_KEEP_ALIVE") || "")
  : 120;

//***********
//length constants
//***********
export const MAX_USER_NAME_LENGTH = 50;
export const MIN_USER_NAME_LENGTH = 2;
export const MAX_PHONE_LENGTH = 20;
export const MIN_EMAIL_LENGTH = 7;
export const MAX_EMAIL_LENGTH = 128;
export const MAX_NICKNAME_LENGTH = 256;
export const WEBROOT_KEYCODE_LENGTH = 20;
export const WEBROOT_KEYCODE_PHRASE_LENGTH = 4;
export const MIN_SECURITY_QUESTION_LENGTH = 2;
export const MAX_SECURITY_QUESTION_LENGTH = 128;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 128;
export const TWO_FACTOR_CODE_LENGTH = 5;
export const BUILD_HASH_DISPLAY_LENGTH = 7;
export const MAX_COMPUTER_NAME_LENGTH = 128;
export const MAX_DISPLAY_COMPUTER_NAME_LENGTH = 30;

export const SUBSCRIPTION_BASE_YEAR = 1960;
export const SUBSCRIPTION_MIN_DAYS_TO_EXPIRATION_FOR_INACTIVE = 15000;
export const SUBSCRIPTION_MIN_ACTIVE_DAY = 90;
export const SUBSCRIPTION_MIN_WARNING_DAY = 30;
export const SUBSCRIPTION_MIN_RENEWAL_DAY = 5;
export const SUBSCRIPTION_MIN_EXPIRED_DAY = 0;
export const SUBSCRIPTION_MIN_GRACE_PERIOD_DAY = -30;
export const SUBSCRIPTION_BESTBUY_RENEWAL_CONVERSION_DAY = -32;
export const SUBSCRIPTION_MIN_DATA_LOSS_DAY = -60;
export const INACTIVE_EXPIRATION_DAYS = -738399;
export const TRIAL_HIDE_EXPIRATION_DAYS = -30;

export const INVOICE_FIRST_OUTSTANDING_DAY = 0;
export const INVOICE_LAST_OUTSTANDING_DAY = -30;

export const SUBSCRIPTION_LAST_AMAZON_EXPIRED_DAY = -60;
export const SUBSCRIPTION_LAST_ALLSTATE_DAY = -60;

export const PENDO_LISTENER_INTERVAL_IN_MS = 100;

export const PENDO_LISTENER_TIMEOUT_IN_MS = 20000;

export const SafeDataPurgeDays = {
  Trials: -15,
  Direct: -30,
  Reseller: -30,
};
export const SafeResellerConvertToDirectDay = -29;

export const AutoRenewOneTimeDiscount = 25;

export const RateLimitCreditCardError = "addRequestDenied";
export const InvalidOrderCode = "InvalidOrderCode";

export const GenericCreditCardError = "GenericCreditCardError";

export const SAFE_RENEWAL_WINDOW_DAYS = 30;
export const MONTHLY_PLAN_MONTHS = 1;
export const DEFAULT_TERM_LENGTH_MONTHS = 12;
export const DEFAULT_SKU = "PersonalPlus";
export const DEFAULT_QUANTITY = 1;
export const DEFAULT_MAX_QUANTITY = 9;
export const NUMERIC_YEAR = 1;
export const DEFAULT_UPGRADE_GROUP = "ConsumerCarbonite";
export const UNITED_STATES = "United States";
export const UNITED_STATES_COUNTRY_CODE = "US";
export const WEBROOT_ANTI_VIRUS_SKU = "WebrootAntiVirus";
// PayPal const was added to protect from errors due to calling it Paypal (not PayPal)
export const PAYPAL_CARD_TYPE = "PayPal";

//***********
//size constants
//***********
export const BYTE_SCALE = 1024;
export const NOMINAL_BYTE_SCALE = 1000;
export const COUNT_SCALE = 1000;
export const BYTES_IN_MEGABYTE = BYTE_SCALE * BYTE_SCALE;
export const BYTES_IN_GIGABYTE = BYTE_SCALE * BYTES_IN_MEGABYTE;

//***********
//http status constants
//***********
export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_ACCEPTED = 202;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_UNAUTHORIZED = 401;
export const HTTP_STATUS_FORBIDDEN = 403;
export const HTTP_STATUS_NOT_FOUND = 404;
export const HTTP_STATUS_NOT_ACCEPTABLE = 406;
export const HTTP_STATUS_SERVER_ERROR = 500;

export const COURIER_PRICES = {
  CourierRecoveryIncluded: "$0.00",
  CourierPrice: "$99.99",
  CourierExpeditedShipping: "$29.98",
  CourierStandardShipping: "$9.99",
};

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const REFERRAL_URL_TRUSTED_DOMAINS = [
  "www.carbonite.com",
  "new.carbonite.com",
  "test.carbonite.com",
  "prep.carbonite.com",
  "account-generator.carboniteinc.com",
  "www.webroot.com",
];

export const CASTLE_PK = import.meta.env.VITE_APP_CASTLE_PK;
export const CASTLE_API = import.meta.env.VITE_APP_CASTLE_API;

export const PREMIGRATION_WEBROOT_SKU = "WSAV";
export const WEBROOT_SECURE_VPN = "WebrootSecureVPN";
export const WEBROOT_SECURE_VPNP = "WebrootSecureVPNP";
export const WEBROOT_CHROMEBOOK = "WebrootChromebook";
export const WEBROOT_PC_OPTIMIZER = "WebrootPcOptimizer";
export const WEBROOT_TOTAL_PROTECTION = "WebrootTotalProtection";
export const WEBROOT_TOTAL_PROTECTION_FAMILY = "WebrootTotalProtectionFamily";
export const WINDOW_WASHER = "WindowWasher";
export const ANDROID_MOBILE = "AndroidMobile";
export const WEBROOT_SMALL_BUSINESS_ANTIVIRUS = "WebrootSmallBusinessAntiVirus";
export const EXCLUDED_FROM_SECURITY_AND_HOME_TAB_SECURITY = [
  webrootLicenseCategories.WW,
  webrootLicenseCategories.ADP,
  webrootLicenseCategories.WSASB,
];

export const selectPlanRouteName = "selectPlan";
export const API_MAX_POLLING_INTERVAL_MSEC = 800;
export const API_INITIAL_POLLING_INTERVAL_MSEC = 50;
export const API_MAX_POLLING_INTERVAL_FOR_DOWNLOAD_MSEC = 3000;
export const RETRY_INTERVAL_FOR_ERR_ON_DOWNLOAD = 5000;
export const NUMBER_OF_RETRIES_FOR_ERR_ON_DOWNLOAD = 2;

export const dotComPricingPath = "personal/backup/#priceplans";
export const buyFlowRouteName = "buy";
export const SubscriptionInformationRouteName = "subscription_information";

export const WEBROOT_PREMIUM = "WebrootPremium";
export const WEBROOT_PREMIUM_FAMILY = "WebrootPremiumFamily";
export const migratedCDInvoicePrefix = "WINM";
export const cancelled = "cancelled";
export const WINDOWS = "Windows";
export const MAC = "Mac";
