<template>
  <home-component />
</template>

<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import HomeComponent from "@/components/Home/Home.vue";
import { getLandingPage } from "@/common/landingPage";
import { refreshSubscriber } from "@/common/refreshSubscriber";
import { useSiteSettingsStore } from "@/stores/siteSettings";

const router = useRouter();
const route = useRoute();

onBeforeMount(async () => {
  //Subscriber Refresh if Webroot API was down when email was validated
  //Not using await since the code should not be blocked
  if (!useSiteSettingsStore().getSubscriberRefreshedAfterLogin()) {
    refreshSubscriber();
  }
  const nextUrl = await getLandingPage(route.query);
  router.push(nextUrl);
});
</script>
