<template>
  <!--content-->
  <section v-if="buyFlowStore.pageState === pageStates.reviewOrder">
    <DisplayShippingInfo
      v-if="FLAGS.ENABLE_BUYFLOW_WEBROOT && buyFlowStore.hasShippableItem && !buyFlowStore.isPayPalPayment"
      class="mb"
    />
    <DisplayPayPalShippingInfo
      v-if="
        FLAGS.ENABLE_BUYFLOW_WEBROOT &&
        FLAGS.ENABLE_BUYFLOW_PAYPAL &&
        buyFlowStore.hasShippableItem &&
        buyFlowStore.isPayPalPayment
      "
      class="mb"
    />
    <!--Permission label and expiration-->
    <ul class="form">
      <li v-if="showPromotionCheckBox" class="checkbox">
        <input id="OptInGDPR" v-model="emailOptIn" class="no-shrink" type="checkbox" />
        <label for="OptInGDPR">{{ tn("GDPREmailOptIn", { brand: cartBrand }) }}</label>
      </li>
      <li class="checkbox">
        <input id="acceptTos" v-model="permissionChecked" class="no-shrink" type="checkbox" />
        <label for="acceptTos">{{ tn("permissionLabel", { brand: cartBrand }) }}</label>
      </li>
    </ul>
    <p v-if="buyFlowStore.isFlatRateUpgrade">
      {{ getFlatRateUpgradeRenewalText() }}
    </p>
    <p v-else-if="!isManualRenewEnabledByDefault" id="renew-date-text" class="mb-double">
      {{ tn("thisSubscriptionWillAutomaticallyRenew") }}
    </p>
    <p v-else id="renew-date-text" class="mb-double">
      {{ tn("thisSubscriptionIsValidUntil") }}
    </p>
    <!--Checkout button-->
    <div class="mb">
      <button
        id="btnCheckOut"
        class="btn-primary mb"
        :disabled="!validSecureCheckout || !permissionChecked"
        @click="goToNextAction"
      >
        {{ tn("secureCheckout") }}
      </button>
    </div>
    <div id="trustBadges" class="mb">
      <svg-icon-component
        v-if="!buyFlowStore.hasWebrootCartItems()"
        id="guaranteeCarbonite"
        icon="trust-badge-30"
        class="no-shrink mr"
      />
      <svg-icon-component
        v-if="buyFlowStore.hasWebrootCartItems()"
        id="guaranteeWebroot"
        icon="trust-badge-70"
        class="no-shrink mr"
      />
      <svg-icon-component icon="trust-badge-support" class="no-shrink mr" />
    </div>
    <BuyDisclaimers :show-auto-renew-links="!isManualRenewEnabledByDefault" />
    <AutomaticRenew
      :show-dialog="displayAutoRenewalDialog"
      @continue-purchase="closeAutoRenewAndContinue"
      @close-modal="toggleAutoRenewDialog"
    />
    <CheckHasRecentPurchase
      :show-dialog="displayCheckHasRecentPurchaseDialog"
      :term-length="termLength"
      :computer-name="computerName"
      @close-modal="continueWithPurchase"
    />
  </section>
</template>

<script setup lang="ts">
//Components
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import BuyDisclaimers from "@/components/Buy/BuyDisclaimers.vue";
import AutomaticRenew from "../Dialogs/AutomaticRenew.vue";
import CheckHasRecentPurchase from "@/components/Buy/Dialogs/CheckHasRecentPurchase.vue";
import DisplayShippingInfo from "@/components/Buy/ReviewOrder/DisplayShippingInfo.vue";
import DisplayPayPalShippingInfo from "@/components/Buy/ReviewOrder/DisplayPayPalShippingInfo.vue";
//Other imports
import {
  formatMoney,
  getRenewDate,
  handlePurchaseError,
  redirectToOutstandingInvoice,
  setShippingAddressFromCC,
} from "@/components/Buy/BuyHelper";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { computed, ref, onMounted } from "vue";
import { useNotificationsStore } from "@/stores/notifications";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { unifiedApi } from "@/common";
import { openTextBrands, pageStates, purchaseErrors } from "@/components/Buy/BuyEnums";
import { useRouter } from "vue-router";
import { AxiosError } from "axios";
import { IPurchaseError } from "../Interfaces";
import { autoRenewOptInCountries, autoRenewOptInUSStates } from "../Payment/PaymentConfigs";
import {
  DEFAULT_TERM_LENGTH_MONTHS,
  UNITED_STATES,
  FLAGS,
  PURCHASE_KEYCODE_DELAY_IN_SECOND,
  UNITED_STATES_COUNTRY_CODE,
} from "@/define";
import { useUserStore } from "@/stores/user";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { cartUpdate, googleTagCartEvents } from "@/common/googleTagEvents";
import { IPurchaseKeycode } from "@/common/api/unifiedPortal/interfaces";
import { bvWebrootEventHandler } from "@/common/bazaarVoiceEvents";

const permissionChecked = ref<boolean>(false);
const emailOptIn = ref<boolean>(false);
const displayAutoRenewalDialog = ref<boolean>(false);
const displayCheckHasRecentPurchaseDialog = ref<boolean>(false);
const notificationsStore = useNotificationsStore();
const buyFlowStore = useBuyFlowStore();
const router = useRouter();
const userStore = useUserStore();
const subscriptionStore = useSubscriptionsStore();
const fiveMinutesInMilliseconds = 300000;

const creditCard = computed(() => {
  return buyFlowStore.creditCards.find(c => c.id === buyFlowStore.cart?.paymentMethodId);
});

//PayPal returns the country as "US" not "United States" like our CCs do
const isUSCreditCard = computed(
  () =>
    creditCard.value?.address?.country === UNITED_STATES ||
    creditCard.value?.address?.country === UNITED_STATES_COUNTRY_CODE
);

const validSecureCheckout = computed(() => {
  //if cart has AllState with Non US Paypal Shipping Address checkout should be disabled
  if (buyFlowStore.hasAllStateNonUSPaypalShippingAddress) {
    return false;
  }
  if (creditCard.value || buyFlowStore.isPayPalPayment) {
    return true;
  }
  return false;
});

const showPromotionCheckBox = computed(() => {
  //The Email Comms checkbox will NOT be shown when:
  //Shipping/billing address within US

  if (buyFlowStore.shippingMatchesBilling) {
    return !isUSCreditCard.value;
  } else {
    const isUSAddress =
      buyFlowStore.cart?.shippingAddress?.country === UNITED_STATES_COUNTRY_CODE ||
      buyFlowStore.cart?.shippingAddress?.country === UNITED_STATES;
    return !isUSAddress || !isUSCreditCard.value;
  }
});

const componentName = "ReviewOrderComponent";

logEvent("created", componentName);

onMounted(async () => {
  logEvent("mounting", componentName, buyFlowStore.cart);
});

const isManualRenewEnabledByDefault = computed(() => {
  const cc = buyFlowStore.creditCards.find(l => l.id === buyFlowStore.cart?.paymentMethodId);

  return autoRenewOptInUSStates.includes(cc?.address?.stateOrProvince || "");
});

const cartBrand = computed(() => {
  /*Identifies whether the cart contains Carbonite or Webroot products to fill in the {brand} 
  placeholder in ReviewOrderComponent.permissionLabel*/
  const brand = buyFlowStore.cart?.brand || buyFlowStore.openTextBrand || openTextBrands.Carbonite;
  return t(`Brand.${brand?.toLowerCase()}`);
});

const computerName = computed(() => {
  if (subscriptionStore.subscriptions.length > 0) {
    const itemInCart = buyFlowStore.cart?.items.find(l => l.computerId > 0);
    if (itemInCart) {
      const sub = subscriptionStore.subscriptions.find(l => l.computer?.id === itemInCart.computerId);

      if (sub) {
        return sub.computer?.description || sub.computer?.name || "";
      }
    }
  }

  return buyFlowStore.computerName || "";
});

const termLength = computed(() => {
  const itemInCart = buyFlowStore.cart?.items.find(l => l.computerId > 0);

  if (itemInCart) {
    const plan = buyFlowStore.plans.find(l => l.sku === itemInCart.sku);
    if (plan) {
      const ratePlan = plan.ratePlans.find(l => l.ratePlanId === itemInCart.ratePlanId);

      if (ratePlan?.months) {
        return ratePlan.months / 12;
      }
    }
  }

  return DEFAULT_TERM_LENGTH_MONTHS / 12;
});

function toggleAutoRenewDialog() {
  displayAutoRenewalDialog.value = !displayAutoRenewalDialog.value;
}

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

async function goToNextAction() {
  //Decide if auto-renewal dialog should be displayed
  //auto-renewal dialog handles checkout on its own
  if (
    creditCard.value &&
    creditCard.value.address.country &&
    autoRenewOptInCountries.includes(creditCard.value.address.country) &&
    creditCard.value.address.stateOrProvince &&
    autoRenewOptInUSStates.includes(creditCard.value.address.stateOrProvince) &&
    !buyFlowStore.checkSalesTax
  ) {
    displayAutoRenewalDialog.value = true;
    return;
  }

  //default path
  await checkHasRecentPurchaseForComputer();
}

async function closeAutoRenewAndContinue() {
  toggleAutoRenewDialog();
  await checkHasRecentPurchaseForComputer();
}

async function checkHasRecentPurchaseForComputer() {
  const itemWithId = buyFlowStore.cart?.items.find(l => l.computerId > 0);
  if (itemWithId && userStore.currentUser) {
    try {
      if (buyFlowStore.recentPurchase && buyFlowStore.recentPurchase.computerId === itemWithId.computerId) {
        const now = Date.now();
        const difference = now - buyFlowStore.recentPurchase.purchasedOnMilliseconds;
        if (difference < fiveMinutesInMilliseconds) {
          //Show dialog
          displayCheckHasRecentPurchaseDialog.value = true;
        } else {
          checkOut();
        }
      } else {
        const response = (await unifiedApi.checkHasRecentPurchase(itemWithId.computerId)).data;

        if (response && response.success) {
          if (response.hasRecentPurchase) {
            //Show dialog
            displayCheckHasRecentPurchaseDialog.value = true;
          } else {
            checkOut();
          }
        } else {
          notificationsStore.addNotification({ type: "ServerErrors", dismissible: false });
          logEvent(`CheckHasRecentPurchase API call was not successful, ${response.errorMessage}`, componentName);
        }
      }
    } catch (err) {
      logException(err as AxiosError, false);
      notificationsStore.addNotification({ type: "ServerErrors", dismissible: false });
    }
  } else {
    await checkOut();
  }
}

function continueWithPurchase(continuePurchase: boolean) {
  if (continuePurchase) {
    checkOut();
  }

  displayCheckHasRecentPurchaseDialog.value = false;
}

async function checkOut() {
  try {
    notificationsStore.clearNotifications();
    if (buyFlowStore.cart) {
      buyFlowStore.showProcessingOrderMessage = true;
      buyFlowStore.cart.userGuid = buyFlowStore.userGuid;
      buyFlowStore.cart.emailOptIn = showPromotionCheckBox.value ? emailOptIn.value : undefined;
      logEvent("checkout-buyFlowStore.cart", componentName, buyFlowStore.cart);
      if (buyFlowStore.hasShippableItem && buyFlowStore.shippingMatchesBilling === true && creditCard.value?.address) {
        setShippingAddressFromCC(creditCard.value);
      }
      buyFlowStore.cart = (await unifiedApi.purchaseShoppingCart(buyFlowStore.cart)).data;

      if (
        buyFlowStore.cart.brand === openTextBrands.Webroot &&
        buyFlowStore.cart.encryptedEmail &&
        buyFlowStore.cart.encryptionKeyId
      ) {
        bvWebrootEventHandler(buyFlowStore.cart);
      }

      //Retrieve keycode after a fixed number of seconds
      await new Promise(resolve => setTimeout(resolve, PURCHASE_KEYCODE_DELAY_IN_SECOND));
      //function to retrieve.once received the keycodes are stored in buyFlowStore.PurchaseKeycodes
      await fetchPurchasedKeycodes();
      cartUpdate(buyFlowStore.cart, googleTagCartEvents.purchase);
      //await awaitAsyncPurchaseProcess();
      buyFlowStore.showProcessingOrderMessage = false;
      displayAutoRenewalDialog.value = false;
      const itemWithId = buyFlowStore.cart?.items.find(l => l.computerId > 0);
      if (itemWithId) {
        buyFlowStore.recentPurchase = { computerId: itemWithId.computerId, purchasedOnMilliseconds: Date.now() };
      }
      setTimeout(() => router.push({ name: "orderConfirmation" }), 500);
    } else {
      //Throw an error. This should never happen
      //buyFlowStore.showProcessingOrderMessage = false;
      throw new Error("cart is undefined");
    }
  } catch (error) {
    buyFlowStore.showProcessingOrderMessage = false;
    const err = error as AxiosError;
    const response = err?.response?.data as IPurchaseError;

    //Redirect if user is under maintenance
    if (response && response?.message === purchaseErrors.UnderMaintenance) {
      router.push("/underMaintenance");
      return;
    }

    if (response.message && response.message === purchaseErrors.HasOutstandingInvoices) {
      redirectToOutstandingInvoice();
      return;
    }

    if (response) {
      handlePurchaseError(response.message, err?.response?.status);
    } else {
      notificationsStore.addNotification({ type: "PurchaseFailureError", dismissible: false });
    }

    displayAutoRenewalDialog.value = false;
    logException(error as Error, `${componentName}: processPurchase`);
  }
}
async function fetchPurchasedKeycodes() {
  try {
    if (buyFlowStore.cart != null && buyFlowStore.cart?.invoiceNumbers != null) {
      var invoiceNumber = buyFlowStore.cart.invoiceNumbers[0];
      var OrderCode = buyFlowStore.cart?.orderCode;
      if (OrderCode != null) {
        var purchaseKeycodes: IPurchaseKeycode[] = (await unifiedApi.getPurchaseKeycodes(invoiceNumber, OrderCode))
          .data;
        purchaseKeycodes.forEach(p => {
          var itemInCart = buyFlowStore.cart?.items.filter(n =>
            n.subscriptionNumbers.some(s => s === p.subscriptionNumber)
          );
          if (itemInCart != null) {
            p.sku = itemInCart[0].sku;
          }
        });
        buyFlowStore.PurchaseKeycodes = purchaseKeycodes;
      }
    }
  } catch (error) {
    logException(error as Error, `${componentName}: fetchPurchasedKeycodes`);
  }
}

function getFlatRateUpgradeRenewalText() {
  if (!buyFlowStore.isFlatRateUpgrade) {
    return "";
  }

  //Look up the current term of the license key
  const months =
    buyFlowStore.getRatePlanInfo(buyFlowStore.licenseKeyInfo?.ratePlanId || "")?.months || DEFAULT_TERM_LENGTH_MONTHS;
  const currentTerm = months / DEFAULT_TERM_LENGTH_MONTHS;

  //Renewal Date
  const renewDate = getRenewDate();

  //Get price for sub when it renews into the upgraded plan
  const flatRatePlan = buyFlowStore.getRatePlanInfo(buyFlowStore.selectedPlan?.ratePlanId || "");
  const upgradeRatePlanId = buyFlowStore.getRatePlanIdBySku(
    buyFlowStore.selectedPlan?.sku || "",
    months,
    flatRatePlan?.includedUnits
  );

  const upgradeRatePlan = buyFlowStore.getRatePlanInfo(upgradeRatePlanId);

  return tn("flatRateUpgradeRenewalText", {
    year: currentTerm,
    date: renewDate,
    amount: formatMoney(upgradeRatePlan?.catalogPrice),
  });
}
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
