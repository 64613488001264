import { IValidationResult, IValidationRule, validate } from "./validator";
import { t } from "@/i18n";
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "@/define";
import { RuleNames } from "@/globalEnums";

const re = new RegExp(`^.{${MIN_PASSWORD_LENGTH},${MAX_PASSWORD_LENGTH}}$`);

function getRules() {
  return [
    {
      name: RuleNames.characters,
      description: t("StrongPasswordMessages.characters", {
        min: MIN_PASSWORD_LENGTH,
        max: MAX_PASSWORD_LENGTH,
      }),
      test: password => (password ? re.test(password) : false),
      displayOrder: 3,
    },
    {
      name: RuleNames.uppercase,
      description: t("StrongPasswordMessages.useUpperCase"),
      test: password => (password ? /[A-Z]/.test(password) : false),
      displayOrder: 0,
    },
    {
      name: RuleNames.lowercase,
      description: t("StrongPasswordMessages.useLowerCase"),
      test: password => (password ? /[a-z]/.test(password) : false),
      displayOrder: 1,
    },
    {
      name: RuleNames.number,
      description: t("StrongPasswordMessages.oneNumber"),
      test: password => (password ? /[\d]/.test(password) : false),
      displayOrder: 2,
    },
    {
      name: RuleNames.invalidCharacterBrace,
      description: t("StrongPasswordMessages.cannotUseBraces"),
      test: password => (password ? password.indexOf("<") === -1 && password.indexOf(">") === -1 : false),
      displayOrder: 4,
    },
  ] as IValidationRule<string>[];
}

export function validatePassword(password: string): IValidationResult[] {
  const rules = getRules();
  return validate(password, rules);
}
