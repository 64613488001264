<template>
  <div v-if="showSpinner" class="content invoice-details">
    <LoadingSpinner />
  </div>
  <div v-else class="content max-900 m-auto invoice-details">
    <!--header-->
    <h1>
      <div class="opposite-ends align-items-center">
        <div>
          <svg-icon-component icon="logo" class="" />
        </div>
        <button id="print" type="button" class="text-sm inline-btn-link no-print" @click="print">
          <svg-icon-component icon="print" class="icon-sm" />
          {{ tn("print") }}
        </button>
      </div>
    </h1>

    <!--invoice information-->
    <ul class="mb border-around border-radius pa-half">
      <li>
        <span id="invoiceNumber" class="text-grey pr-half">{{ tn("invoiceNumber") }}</span>
        <strong>{{ invoiceNumber }}</strong>
      </li>
      <li v-if="invoiceDate">
        <span id="invoiceNumber" class="text-grey pr-half">{{ tn("date") }}</span>
        <strong>{{ formatDateString(invoiceDate) }}</strong>
      </li>
    </ul>
    <!--billing information-->
    <div class="flex-start-md">
      <div class="border-around border-radius pa-half mr-md match-height grow">
        <ul>
          <li class="mb-half">
            <p id="" class="text-grey">{{ tn("billTo") }}</p>
          </li>
          <li v-if="billingAddress" class="mb-half">
            <span id="userName" class="truncate">{{ billingAddress?.firstName }} {{ billingAddress?.lastName }}</span>
          </li>
          <li v-if="billingAddress">
            <span id="userAddress" class="truncate">{{ billingAddress?.address1 }} {{ billingAddress?.address2 }}</span>
          </li>
          <li v-if="billingAddress" class="mb-half">
            <span id="userAddress" class="truncate">
              {{ billingAddress?.city }} {{ billingAddress?.state }} {{ billingAddress?.zipCode }}
            </span>
          </li>
          <li v-if="billingAddress">
            <p id="userEmail" class="truncate">{{ billingAddress?.email }}</p>
          </li>
        </ul>
      </div>
      <!--shipping information-->
      <div v-if="shippingAddress" class="border-around border-radius pa-half match-height grow">
        <ul>
          <li class="mb-half">
            <span class="text-grey">{{ tn("shipTo") }}</span>
          </li>
          <li v-if="shippingAddress">
            <span id="userName" class="truncate mb-half">
              {{ shippingAddress?.firstName }} {{ shippingAddress?.lastName }}
            </span>
          </li>
          <li v-if="shippingAddress">
            <span id="userAddress" class="truncate">
              {{ shippingAddress?.address1 }} {{ shippingAddress?.address2 }}
            </span>
          </li>
          <li v-if="shippingAddress" class="mb-half">
            <span id="userAddress" class="truncate">
              {{ shippingAddress?.city }} {{ shippingAddress?.state }} {{ shippingAddress?.zipCode }}
            </span>
          </li>
          <li v-if="shippingAddress">
            <span id="userEmail" class="truncate">{{ shippingAddress?.email }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="table-to-list-wrapper border-around border-radius pa-0">
      <!--purchases table-->
      <table class="full-width only-bottom-border">
        <thead>
          <tr>
            <th class="qty text-grey">{{ tn("colQty") }}</th>
            <th class="py-half text-grey">{{ tn("colSub") }}</th>
            <th class="py-half amount text-grey">{{ tn("colAmount") }}</th>
          </tr>
        </thead>
        <!--purchased item - purchases table-->
        <tbody>
          <!--purchased items-->
          <tr
            v-for="(item, index) in getInvoiceItems()"
            :id="`invoice-item-${index}`"
            :key="index"
            class="only-bottom-border"
          >
            <td :data-th="tn('colQty')" class="inline-marker no-border-bottom">{{ item?.quantity }}</td>
            <td :data-th="tn('colSub')">
              <p>{{ item.description }}</p>
              <div v-if="item.expirationDate" class="text-grey">
                {{ tn("subscriptionEnds") }} {{ formatDateString(item.expirationDate) }}
              </div>
            </td>
            <td :data-th="tn('colAmount')" class="text-right subscription-item">
              {{ formatMoney(item?.amount || 0) }}
            </td>
          </tr>
          <tr v-for="item in adjustments" :key="item.description" class="no-border-bottom">
            <td class="no-marker no-border-bottom">&nbsp;</td>
            <td :class="item.descriptionCssClass" class="no-marker">
              {{ item.description }}
            </td>
            <td :class="item.amountCssClass" class="text-right">{{ formatMoney(item.amount || 0) }}</td>
          </tr>
          <tr v-if="invoiceTotal !== null && invoicePaid !== null">
            <td class="no-marker no-border-bottom">&nbsp;</td>
            <td class="no-marker text-right bold-text">
              <strong>{{ tn("balanceDue") }}</strong>
            </td>
            <td class="no-marker text-right">
              {{ formatMoney(invoiceTotal - invoicePaid || 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { useRoute } from "vue-router";
import { formatDateString } from "@/common/dateFormat";
import { IBillingAddress, IInvoice, IInvoiceItemDetail } from "@/common/api/unifiedPortal/interfaces";
import { removeStars, formatMoney } from "@/components/Buy/BuyHelper";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";

const invoiceNumber = useRoute().params.invoiceNumber;
const componentName = "InvoiceComponent";
const billingAddress = ref<IBillingAddress | null>(null);
const shippingAddress = ref<IBillingAddress | null>(null);
const invoiceDate = ref<string>("");
const invoiceDetails = ref<IInvoice | null>(null);
const invoiceItemDetails = ref<IInvoiceItemDetail[] | null>(null);
const invoiceAdjustments = ref<IInvoiceItemDetail[] | null>(null);
const invoiceTotal = ref<number | null>(0);
const invoicePaid = ref<number | null>(0);
const adjustments = ref<IInvoiceItemDetail[] | null>(null);

logEvent("created", componentName);

const showSpinner = ref(true);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`InvoiceComponent.${v}`, params);
}

onMounted(async () => {
  var invoiceId = String(invoiceNumber);
  invoiceDetails.value = (await unifiedApi.getInvoiceDetails(invoiceId))?.data;
  if (invoiceDetails.value) {
    billingAddress.value = invoiceDetails.value.billingAddressResponse;
    shippingAddress.value = invoiceDetails.value.shippingAddressResponse;
    invoiceDate.value = invoiceDetails.value.invoiceDate;
    invoiceItemDetails.value = invoiceDetails.value.invoiceItemDetails;
    invoiceAdjustments.value = invoiceDetails.value.invoiceAdjustments;
    getInvoiceAdjustments();
  }
  showSpinner.value = false;
});

function print() {
  window.print();
}

interface ISortItem {
  description: string;
  order: number;
  descriptionCssClass: string;
  amountCssClass: string;
}

//Create a config object with the sort order for adjustment items
const adjustmentsSortOrder = [
  {
    description: "outstandingBalanceCol",
    order: 1,
    descriptionCssClass: "",
    amountCssClass: "",
  },
  {
    description: "PromoCodePct",
    order: 2,
    descriptionCssClass: "success-text",
    amountCssClass: "",
  },
  {
    description: "PromoCodeAmt",
    order: 2,
    descriptionCssClass: "success-text",
    amountCssClass: "",
  },
  {
    description: "PriorSubscriptionCredit",
    order: 3,
    descriptionCssClass: "success-text",
    amountCssClass: "",
  },
  {
    description: "UsedCreditBalance",
    order: 4,
    descriptionCssClass: "success-text",
    amountCssClass: "",
  },
  {
    description: "Tax",
    order: 5,
    descriptionCssClass: "td-border-bottom",
    amountCssClass: "td-border-bottom",
  },
  {
    description: "Total",
    order: 6,
    descriptionCssClass: "text-right bold-text",
    amountCssClass: "",
  },
] as ISortItem[];

function getInvoiceItems() {
  var items: IInvoiceItemDetail[] = [];

  if (invoiceItemDetails.value) {
    for (const item of invoiceItemDetails.value) {
      items.push(item);
    }
  }
  return items;
}

function getInvoiceAdjustments() {
  if (invoiceAdjustments.value) {
    adjustments.value = invoiceAdjustments.value
      .filter(l => l.amount != 0 || l.description === "Tax" || l.description === "Total")
      .sort((a: IInvoiceItemDetail, b: IInvoiceItemDetail) => {
        const aSort = adjustmentsSortOrder.find(l => l.description.includes(a.description))?.order || 99;
        const bSort = adjustmentsSortOrder.find(l => l.description.includes(b.description))?.order || 99;
        if (aSort > bSort) return 1;
        if (aSort < bSort) return -1;
        return 0;
      });
    for (const a of adjustments.value) {
      a.descriptionCssClass =
        adjustmentsSortOrder.find(l => l.description.includes(a.description))?.descriptionCssClass || "";
      a.amountCssClass = adjustmentsSortOrder.find(l => l.description.includes(a.description))?.amountCssClass || "";

      if (a.description.includes("********")) {
        var cardDetails = a.description.split(",");
        a.description = tn("endingIn", { type: cardDetails[0] }) + removeStars(cardDetails[1]);
        a.descriptionCssClass = "text-right bold-text";
        invoicePaid.value = a.amount;
      } else {
        a.description = tn(a.description);
        if (a.description === "Total") {
          invoiceTotal.value = a.amount;
        }
      }
    }
  }
}
</script>
