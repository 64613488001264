<template>
  <article class="constrain-90 m-auto mt-double mb-200">
    <h1 class="mb">{{ tn("header") }}</h1>

    <p class="mb">
      {{ tn("p1") }}
    </p>

    <p class="mb">
      {{ tn("p2") }}
    </p>

    <p class="mb">
      {{ tn("p3") }}
    </p>
  </article>
</template>

<script setup lang="ts">
import { t } from "@/i18n";

const componentName = "UpgradeNotAllowed";

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}
</script>
