// Good ActivationCodes
// CX-01001-26790-5253, CX-01001-26800-9651
import { IValidationResult, IValidationRule, validate } from "./validator";
import { t } from "@/i18n";
import { alphaNumericWithHyphenRegex } from "@/globalRegex";

function getRules() {
  return [
    {
      name: "CommonErrors.ActivationCodeInvalid",
      test: k => alphaNumericWithHyphenRegex.test(k),
      description: t(`CommonErrors.ActivationCodeInvalid`),
    },
  ] as IValidationRule<string>[];
}

export function validateActivationCode(activationCode: string): IValidationResult[] {
  const rules = getRules();
  return validate<string>(activationCode, rules);
}
