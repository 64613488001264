<template>
  <div class="login">
    <div class="content pt pb">
      <header>
        <h1 v-if="step === pageStates.securityQuestions">{{ tn("headerStep0") }}</h1>
        <h1 v-else-if="step === pageStates.twoFactorAuthPart1">{{ tn("headerStep1") }}</h1>
        <h1 v-else-if="step === pageStates.twoFactorAuthPart2">{{ tn("headerStep2") }}</h1>
        <h1 v-else-if="step === pageStates.twoFactorAuthPart3">{{ tn("headerStep3") }}</h1>
        <h1 v-else-if="step === pageStates.languagePreference">{{ tn("headerLanguagePreference") }}</h1>
      </header>
      <NotificationsComponent context="SetTwoStepVerificationComponent" />
      <section v-if="step === pageStates.securityQuestions">
        <p>{{ tn("step0Text") }}</p>
        <ul class="form">
          <!--Question 1-->
          <li>
            <label for="setSecurityQuestion1">{{ t("PersonalInformationComponent.Question1Header") }}</label>
            <div>
              <select id="setSecurityQuestion1" v-model="question1" class="select-css">
                <option value="" selected disabled>
                  {{ t("SetSecurityQuestionsComponent.chooseOption") }}
                </option>
                <option v-for="option in questionsList.questionGroup1" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </li>
          <!--Question 1 Answer-->
          <li>
            <label for="setSecurityAnswer1">
              {{ !!question1 ? question1 : t("SetSecurityQuestionsComponent.answer") }}
            </label>
            <div>
              <InputErrorComponent :show="hasSecurityAnswer1Error">
                <input
                  id="setSecurityAnswer1"
                  v-model="answer1"
                  type="text"
                  autocomplete="off"
                  @keyup="dirtyFlagAnswer1 = true"
                />
                <template #error>
                  <span>{{ securityAnswer1ErrorText }}</span>
                </template>
              </InputErrorComponent>
            </div>
          </li>
          <!--Question 2-->
          <li>
            <label for="setSecurityQuestion2">{{ t("PersonalInformationComponent.Question2Header") }}</label>
            <div>
              <select id="setSecurityQuestion2" v-model="question2" class="select-css">
                <option value="" selected disabled>
                  {{ t("SetSecurityQuestionsComponent.chooseOption") }}
                </option>
                <option v-for="option in questionsList.questionGroup2" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </li>
          <!--Question 2 Answer-->
          <li>
            <label for="setSecurityAnswer2">
              {{ !!question2 ? question2 : t("SetSecurityQuestionsComponent.answer") }}
            </label>
            <div>
              <InputErrorComponent :show="hasSecurityAnswer2Error">
                <input
                  id="setSecurityAnswer2"
                  v-model="answer2"
                  type="text"
                  autocomplete="off"
                  @keyup="dirtyFlagAnswer2 = true"
                />
                <template #error>
                  <span>{{ securityAnswer2ErrorText }}</span>
                </template>
              </InputErrorComponent>
            </div>
          </li>
          <!--Question 3-->
          <li>
            <label for="setSecurityQuestion3">{{ t("PersonalInformationComponent.Question3Header") }}</label>
            <div>
              <select id="setSecurityQuestion3" v-model="question3" class="select-css">
                <option value="" selected disabled>
                  {{ t("SetSecurityQuestionsComponent.chooseOption") }}
                </option>
                <option v-for="option in questionsList.questionGroup3" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </li>
          <!--Question 3 Answer-->
          <li>
            <label for="setSecurityAnswer3">
              {{ !!question3 ? question3 : t("SetSecurityQuestionsComponent.answer") }}
            </label>
            <div>
              <InputErrorComponent :show="hasSecurityAnswer3Error">
                <input
                  id="setSecurityAnswer3"
                  v-model="answer3"
                  type="text"
                  autocomplete="off"
                  @keyup="dirtyFlagAnswer3 = true"
                />
                <template #error>
                  <span>{{ securityAnswer3ErrorText }}</span>
                </template>
              </InputErrorComponent>
            </div>
          </li>
        </ul>
      </section>

      <section v-else-if="step === pageStates.twoFactorAuthPart1">
        <p>{{ tn("step1TextA") }}</p>

        <i18n-t keypath="AccountSetup.step1TextB" tag="p" class="mb-double" scope="global">
          <template #here><a :href="getUrl('VITE_APP_CARBONITE_2FA_INFO_URL')" target="_blank">here</a></template>
        </i18n-t>
      </section>

      <section v-else-if="step === pageStates.twoFactorAuthPart2">
        <p>{{ t("SetTwoStepVerificationComponent.informationP1") }}</p>
        <ul class="form">
          <li class="radio">
            <input
              id="sms"
              v-model="verificationMethod"
              type="radio"
              name="method"
              value="Sms"
              @click="changePrimaryButtonAction(false)"
            />
            <label for="sms">{{ t("SetTwoStepVerificationComponent.option1") }}</label>
          </li>
          <li class="radio">
            <input
              id="call"
              v-model="verificationMethod"
              type="radio"
              name="method"
              value="Call"
              @click="changePrimaryButtonAction(false)"
            />
            <label for="call">{{ t("SetTwoStepVerificationComponent.option2") }}</label>
          </li>
          <li v-if="hasSecurityPhone" class="radio">
            <input
              id="turnOff"
              v-model="verificationMethod"
              type="radio"
              name="method"
              value="turnOff"
              @click="changePrimaryButtonAction(true)"
            />
            <label for="turnOff">{{ t("SetTwoStepVerificationComponent.option3") }}</label>
          </li>
          <li v-if="showPhoneNumber">
            <label for="newPhoneNumber">{{ t("SetTwoStepVerificationComponent.label1") }}:</label>
            <div class="side-by-side">
              <div class="mr-quarter">
                <select id="countryCode" v-model="selectedCountry" class="select-css">
                  <option v-for="country in countryCodes" :key="country.name" :value="country">
                    {{ country.displayName }}
                  </option>
                </select>
              </div>
              <div>
                <input id="newPhoneNumber" v-model="newPhoneNumber" type="text" :maxlength="MAX_PHONE_LENGTH" />
              </div>
            </div>
          </li>
        </ul>
        <h3 v-if="showDelete2FAWarning">{{ t("SetTwoStepVerificationComponent.heading1") }}</h3>
        <p v-if="showDelete2FAWarning">{{ t("SetTwoStepVerificationComponent.informationP2") }}</p>
        <div v-if="showPhoneNumber">
          <p class="text-sm">{{ t("SetTwoStepVerificationComponent.informationP3") }}</p>
        </div>
      </section>

      <section v-else-if="step === pageStates.twoFactorAuthPart3">
        <Verify2FA
          :phone-number="securityPhoneNumber"
          :current-user="user"
          :verification-method="verificationMethod"
          @finish="finish"
          @submit-code="confirmCode"
          @notification="displayResultMessage"
        />
      </section>

      <section v-else-if="step === pageStates.languagePreference" class="form">
        <p>{{ tn("stepLanguagePreferenceText") }}</p>
        <languageDropDown :selected-language="selectedLanguage" @set-selected-language="setPreferredLanguage" />
      </section>

      <footer>
        <button
          v-if="step === pageStates.securityQuestions"
          id="setSecurityQuestions"
          type="button"
          class="btn-primary"
          :disabled="!saveButtonEnabled || isFinishing || hasErrors()"
          @click="save()"
        >
          <spinner :is-spinning="isFinishing" />
          {{ tn("footerButtonStep0") }}
        </button>

        <button
          v-if="step === pageStates.twoFactorAuthPart1"
          id="start2FA"
          type="button"
          class="btn-primary mr-quarter"
          @click="goToStep(pageStates.twoFactorAuthPart2)"
        >
          <svg-icon-component icon="plus" />
          {{ tn("footerButtonStep1") }}
        </button>

        <button
          v-if="step === pageStates.twoFactorAuthPart1"
          id="btnSkip2FA"
          type="button"
          class="btn-outline-primary"
          :disabled="isFinishing"
          @click="goToStep(pageStates.languagePreference)"
        >
          <spinner :is-spinning="isFinishing" />
          {{ tn("footerButtonStep1Skip") }}
        </button>

        <button
          v-if="step === pageStates.twoFactorAuthPart2"
          id="btnStep2Skip2FA"
          type="button"
          class="btn-outline-primary mr-quarter"
          :disabled="isFinishing"
          @click="goToStep(pageStates.languagePreference)"
        >
          <spinner :is-spinning="isFinishing" />
          {{ t("SetTwoStepVerificationComponent.cancel") }}
        </button>

        <button
          v-if="step === pageStates.twoFactorAuthPart2 && !showDelete2FAWarning"
          id="btnUpdatePhone"
          type="submit"
          class="btn-primary"
          :disabled="!newPhoneNumber || !selectedCountry"
          @click="updatePhone()"
        >
          {{ t("SetTwoStepVerificationComponent.next") }}
        </button>

        <button
          v-if="step === pageStates.twoFactorAuthPart2 && showDelete2FAWarning"
          id="btnDeletePhone"
          type="submit"
          class="btn-primary"
          @click="deletePhone()"
        >
          {{ t("SetTwoStepVerificationComponent.save") }}
        </button>

        <button
          v-else-if="step === pageStates.languagePreference"
          id="btnPreferredLanguage"
          type="button"
          class="btn-primary"
          @click="savePreferredLanguage()"
        >
          {{ tn("footerButtonLanguagePreference") }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, watch } from "vue";
import { logEvent, logException } from "@/common/logger";
import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import {
  ISecurityQuestionsList,
  IPhoneCountryCodes,
  IUserSecurityPhone,
  IUserSecurityPhoneRequest,
  ISetLanguagePreferenceRequest,
} from "@/common/api/unifiedPortal/interfaces";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useNotificationsStore } from "@/stores/notifications";
import { getLandingPage } from "@/common/landingPage";
import { MAX_PHONE_LENGTH } from "@/define";
import { NotificationType } from "@/stores/notifications";
import { reloadSubscriberInfo } from "@/common/reloadSubscriberInfo";
import Spinner from "@/components/shared/Spinner.vue";
import InputErrorComponent from "@/components/shared/InputError.vue";
import Verify2FA from "@/components/Account/setup/Verify2FA.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { AxiosError } from "axios";
import { handleApiError } from "@/common/handleApiError";
import { securityAnswerValidator } from "@/common/validateSecurityAnswer";
import { transformPhoneCodeDisplayNames } from "@/common/phoneCodeHelper";
import { getUrl } from "@/common/getUrl";
import languageDropDown from "@/components/shared/LanguageDropDown/LanguageDropDown.vue";
import { pageStates } from "@/components/Account/setup/AccountSetupEnums";

const componentName = "AccountSetup";

logEvent("created", componentName);
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();
const securityPhone = ref<IUserSecurityPhone>();
const isFinishing = ref<boolean>(false);
const router = useRouter();
const route = useRoute();

// provided by showSetSecurityAndTwoFA in index.ts
const questionsList = ref<ISecurityQuestionsList>({
  questionGroup1: [],
  questionGroup2: [],
  questionGroup3: [],
});

const selectedLanguage = computed(() => {
  //this page may load before main.ts enters the default selectedLanguage value
  //TODO: check if this is still needed (main.ts updated default parameter setup conditions)
  if (userStore.selectedLanguage == "") {
    return "en";
  }
  return userStore.selectedLanguage;
});

const preferredLanguage = ref<string>(selectedLanguage.value);
function setPreferredLanguage(lang: string) {
  preferredLanguage.value = lang;
}

async function savePreferredLanguage() {
  const request: ISetLanguagePreferenceRequest = {
    languageCode: preferredLanguage.value,
  };
  try {
    //Save the language
    await unifiedApi.setLanguagePreference(request);
    //Finish the setup
    finish();
  } catch (error) {
    handleApiError(error as AxiosError, true);
  }
}

const countryCodes = ref<IPhoneCountryCodes[]>([]);

const step = ref<pageStates>(pageStates.securityQuestions);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onBeforeMount(async () => {
  try {
    if (!securityPhone.value) {
      securityPhone.value = (await unifiedApi.getUserSecurityPhone()).data;
    }

    // load data
    const [questionsRes, countryCodesRes] = await Promise.all([
      unifiedApi.getSecurityQuestionsList(selectedLanguage.value),
      unifiedApi.getPhoneCountryCodes(),
    ]);

    questionsList.value = questionsRes.data;
    countryCodes.value = transformPhoneCodeDisplayNames(selectedLanguage.value, countryCodesRes.data);
  } catch (err) {
    logException(err as Error);
    notificationsStore.addNotification({ type: "ServerErrors" });
  }
});

const user = computed(() => {
  return userStore.currentUser ?? undefined;
});

const question1 = ref<string>("");
const question2 = ref<string>("");
const question3 = ref<string>("");
const answer1 = ref<string>("");
const answer2 = ref<string>("");
const answer3 = ref<string>("");
const dirtyFlagAnswer1 = ref<boolean>(false);
const dirtyFlagAnswer2 = ref<boolean>(false);
const dirtyFlagAnswer3 = ref<boolean>(false);

const saveButtonEnabled = computed(() => {
  return question1.value && question2.value && question3.value && answer1.value && answer2.value && answer3.value;
});

const hasSecurityAnswer1Error = computed(() => {
  return securityAnswer1ErrorText.value.length > 0;
});

const hasSecurityAnswer2Error = computed(() => {
  return securityAnswer2ErrorText.value.length > 0;
});

const hasSecurityAnswer3Error = computed(() => {
  return securityAnswer3ErrorText.value.length > 0;
});

const securityAnswer1ErrorText = computed(() => {
  return dirtyFlagAnswer1.value === false ? [] : securityAnswerValidator(answer1, question1);
});

const securityAnswer2ErrorText = computed(() => {
  if (answer1.value && answer2.value && answer1.value.toLowerCase() === answer2.value.toLowerCase()) {
    return t("SecurityAnswerValidation.AnswerMatchingError");
  }
  return dirtyFlagAnswer2.value === false ? [] : securityAnswerValidator(answer2, question2);
});

const securityAnswer3ErrorText = computed(() => {
  if (
    (answer1.value && answer3.value && answer1.value.toLowerCase() === answer3.value.toLowerCase()) ||
    (answer2.value && answer3.value && answer2.value.toLowerCase() === answer3.value.toLowerCase())
  ) {
    return t("SecurityAnswerValidation.AnswerMatchingError");
  }
  return dirtyFlagAnswer3.value === false ? [] : securityAnswerValidator(answer3, question3);
});

function hasErrors() {
  if (hasSecurityAnswer1Error.value || hasSecurityAnswer2Error.value || hasSecurityAnswer3Error.value) {
    return true;
  }
  return false;
}

watch(selectedLanguage, async () => {
  const [questionsRes] = await Promise.all([unifiedApi.getSecurityQuestionsList(selectedLanguage.value)]);
  questionsList.value = questionsRes.data;
});

async function save() {
  if (!hasErrors()) {
    try {
      await unifiedApi.setSecurityQuestions(
        {
          securityQuestionOne: question1.value,
          securityAnswerOne: answer1.value,
          securityQuestionTwo: question2.value,
          securityAnswerTwo: answer2.value,
          securityQuestionThree: question3.value,
          securityAnswerThree: answer3.value,
        },
        selectedLanguage.value
      );

      // if 2fa is already set up, we will skip asking
      if (securityPhone.value?.phoneNumber && securityPhone.value?.phoneNumberValidatedDate) {
        goToStep(pageStates.languagePreference);
      } else {
        goToStep(pageStates.twoFactorAuthPart1);
      }
    } catch (error) {
      handleApiError(error as AxiosError, false);
    }
  }
}

async function finish() {
  isFinishing.value = true;
  await reloadSubscriberInfo();
  const nextUrl = await getLandingPage(route.query);
  router.push(nextUrl);
  isFinishing.value = false;
}

// 2fa
//const securityPhoneValidation = ref<IUserSecurityPhoneValidation | null>(null);
const verificationMethod = ref<string>("");
const selectedCountry = ref<IPhoneCountryCodes | null>(null);
const newPhoneNumber = ref<string | null>(null);
//const showEmail = ref<boolean>(true);

const showPhoneNumber = ref<boolean>(false);
const showDelete2FAWarning = ref<boolean>(false);

function goToStep(state: pageStates) {
  step.value = state;
}

async function updatePhone() {
  notificationsStore.clearNotifications();
  const regex = new RegExp("^[0-9-]*$");
  newPhoneNumber.value = newPhoneNumber.value?.replace(/[\s.]/g, "") ?? null;

  try {
    if (!newPhoneNumber.value) throw "EmptyPhone";
    if (!regex.test(newPhoneNumber.value)) throw "InvalidPhoneType";
    if (!selectedCountry.value) throw "InvalidCountry";
    if (!verificationMethod.value) throw "InvalidVerificationMethod";

    const request: IUserSecurityPhoneRequest = {
      phoneNumber: newPhoneNumber.value,
      countryCode: selectedCountry.value.primaryCode,
      country: selectedCountry.value.name,
      validationMethod: verificationMethod.value,
    };
    const response = (await unifiedApi.setSecurityPhone(request)).data;
    if (!response.isValid) throw response.securityPhoneOperationCode;

    goToStep(pageStates.twoFactorAuthPart3);
  } catch (error) {
    displayResultMessage(error as string);
    handleApiError(error as AxiosError, false);
  }
}

async function changePrimaryButtonAction(isTurnOff: boolean) {
  showDelete2FAWarning.value = isTurnOff;
  showPhoneNumber.value = !isTurnOff;
}

async function confirmCode(result: string) {
  notificationsStore.clearNotifications();
  if (result === "OK") {
    userStore.twoFactorAuthAddedFromFirstLogin = true;
    goToStep(pageStates.languagePreference);
  } else {
    try {
      await unifiedApi.deleteSecurityPhone();
    } catch (error) {
      handleApiError(error as AxiosError, false);
    }
  }
}

async function deletePhone() {
  notificationsStore.clearNotifications();
  try {
    await unifiedApi.deleteSecurityPhone();
  } catch (error) {
    handleApiError(error as AxiosError, false);
  }
}

function displayResultMessage(message: string) {
  const errType = `SetTwoStepVerification${message}` as NotificationType;
  notificationsStore.clearNotifications();
  notificationsStore.addNotification({ type: errType });
}

const hasSecurityPhone = computed(() => {
  return !!securityPhone.value?.phoneNumber;
});

const securityPhoneNumber = computed(() => {
  return newPhoneNumber.value && selectedCountry.value
    ? selectedCountry.value.primaryCode + newPhoneNumber.value
    : securityPhone.value?.phoneNumber;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";

dd input[type="text"] {
  width: 100%;
}

h3 {
  margin-bottom: var(--space-third);
}
</style>
