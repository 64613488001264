import { t } from "@/i18n";
import { unifiedApi } from "@/common";
import { IPayPalAgreementTokenResponse, IPayPalAgreementTokenRequest } from "@/common/api/unifiedPortal/interfaces";
import { handleApiError } from "@/common/handleApiError";
import { AxiosError } from "axios";
import { logEvent } from "@/common/logger";
import { useBuyFlowStore } from "@/stores/buyFlow";

export async function createPayPalAgreementToken(): Promise<IPayPalAgreementTokenResponse | undefined> {
  try {
    const buyFlowStore = useBuyFlowStore();
    const hostName = window.location.hostname;
    let domain = t("Brand.carbonite").toLowerCase();
    if (hostName.toLowerCase().indexOf(t("Brand.webroot").toLowerCase()) > -1) {
      domain = t("Brand.webroot").toLowerCase();
    }
    const request: IPayPalAgreementTokenRequest = {
      domain: domain,
      brand: buyFlowStore.cart?.brand ?? "UNKNOWN",
    };
    logEvent("PayPalAgreementToken:Request", JSON.stringify(request));
    return (await unifiedApi.createPayPalAgreementToken(request)).data;
  } catch (err) {
    handleApiError(err as AxiosError, true);
  }
}
