<template>
  <div v-if="!hideSidebar && user" class="sidebar">
    <div class="mobile">
      <nav>
        <div id="mobileUser" class="user" @click="toggleUserNav()">
          <svg-icon-component icon="user" />
        </div>
        <div id="mobileDownloads" class="home">
          <a href="/home">
            <svg-icon-component icon="home" />
          </a>
        </div>
        <div id="mobileCollapsedNav" class="menu" @click="toggleHamburgerNav()">
          <svg-icon-component icon="hamburger" />
        </div>
      </nav>
      <ul v-if="showHamburgerNav" id="ulMobileExpandedNav" class="mobile-expanded-nav">
        <li v-for="r in routes" :key="r.name" :class="isCurrentRoute(r) ? 'current' : ''">
          <router-link :to="{ name: r.name }">
            <i18n-t v-if="r.i18n" tag="span" :keypath="r.i18n" scope="global"></i18n-t>
            <span v-else>{{ r.display }}</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="showUserNav" id="ulMobileExpandedUserNav" class="mobile-expanded-nav">
        <li>
          <span v-if="userFullName.length < 24" id="sidenavUser" class="user-email">{{ userFullName }}</span>
          <span v-else id="sidenavUserTruncated" :title="userFullName" class="user-email">
            {{ userFullName.substring(0, 24) + ".." }}
          </span>
        </li>
        <li>
          <button class="btn-link" @click="logout">
            {{ t("HeaderComponent.logOut") }}
          </button>
        </li>
        <li>
          <LanguageSelectorComponent v-if="showLanguagePicker" />
        </li>
      </ul>
    </div>
    <div v-if="!isLoading" class="desktop">
      <!-- <p v-if="type === 'smb'" class="plan-name"><strong>{{ t("Brand.carboniteSafeUltimate") }}</strong></p> -->
      <ul v-if="!isCssbUser" id="ulDesktopSideNav">
        <li
          v-for="r in routes"
          :key="r.name"
          :class="isCurrentRoute(r) ? 'current' : ''"
          class="opposite-ends align-items-center"
        >
          <router-link :to="{ name: r.name }" class="grow">
            <i18n-t v-if="r.i18n" tag="span" :keypath="r.i18n" scope="global"></i18n-t>
            <span v-else>{{ r.display }}</span>
          </router-link>
          <svg-icon-component icon="caret-right" class="current-icon" />
        </li>
      </ul>
      <ul v-else>
        <li class="current">
          <a :href="getCssbRoute('smb/dashboard')">{{ t("SidebarComponent.smbBackupDashboard") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/notifications')">{{ t("SidebarComponent.smbNotifications") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/policy/manage')">{{ t("SidebarComponent.smbManageBackupPolicies") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/Account/accountSummary')">{{ t("SidebarComponent.smbAccountSummary") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/billing/info')">{{ t("SidebarComponent.smbBillingInformation") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/Account/personalInfo')">{{ t("SidebarComponent.smbPersonalInformation") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/help')">{{ t("SidebarComponent.smbGetHelp") }}</a>
        </li>
        <li>
          <a :href="getCssbRoute('smb/support/contactUs')">{{ t("SidebarComponent.smbContactUs") }}</a>
        </li>
      </ul>
      <aside v-if="isCssbUser">
        <h6>{{ tn("upgradeBoxHeaderSmb") }}</h6>
        <p>{{ tn("upgradeBoxBodySmb") }}</p>
        <p>
          <a :href="getCssbRoute('smb/buy/upgrade')">{{ tn("upgradeBoxLinkSmb") }}</a>
        </p>
      </aside>
      <aside v-if="showUpgradeToPro" id="upgradeToPro">
        <h6>{{ tn("upgradeBoxHeader") }}</h6>
        <i18n-t keypath="SidebarComponent.upgradeBoxBody" tag="p" scope="global">
          <template #carboniteSafePro>{{ t("Brand.carboniteSafePro") }}</template>
        </i18n-t>
        <p>
          <a id="linkUpgradeToPro" href="#" @click="toggleUpgradeToProModal('click')">{{ tn("upgradeBoxLink") }}</a>
        </p>
      </aside>
    </div>
    <UpgradeToProComponent :show-dialog="showUpgradeToProModal" @close-modal="toggleUpgradeToProModal" />
  </div>
  <div v-else class="hide-sidebar-bottom mobile">
    <nav>
      <div v-if="user">
        <button v-if="!buyFlowStore.isNewUserBuyFlow" class="btn-link" @click="logout()">
          {{ t("HeaderComponent.logOut") }}
        </button>
      </div>
      <div v-else>
        <button class="btn-link log-in" @click="logIn()">{{ t("Common.logIn") }}</button>
      </div>
      <LanguageSelectorComponent v-if="showLanguagePicker" />
    </nav>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, onBeforeMount } from "vue";
import { logEvent } from "@/common/logger";
import { useRoute } from "vue-router";
import { t } from "@/i18n";
import { useUserStore } from "@/stores/user";
import { UserType } from "@/globalEnums";
import { FLAGS, URLS } from "@/define";
import { logout } from "@/common/logout";
import { showPaymentHistory } from "@/common/showPaymentHistory";
import UpgradeToProComponent from "@/components/shared/dialogs/UpgradeToPro/UpgradeToPro.vue";
import SvgIconComponent from "@/components/shared/SvgIcon/SvgIcon.vue";
import { hasOnlyBestBuyS2 } from "@/common/bestBuy";
import { storeToRefs } from "pinia";
import LanguageSelectorComponent from "@/components/shared/LanguageSelector/LanguageSelector.vue";
import { IRouteMetadata } from "@/routes";
import {
  getCustomerPortalRedirectURL,
  getRedirectToLoginURLWithEmail,
  getRedirectToLoginURL,
} from "@/common/loginRedirect";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { canDisplayGlobalFeature, globalFeatures } from "@/common/featureDisplayAccess";
import { hasLegacyWebroot } from "@/common/legacyWebroot";
import { hasAllstateSubscriptions } from "../Allstate/AllstateHelper";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { carboniteSkus } from "@/common/productsBySku";

const componentName = "SidebarComponent";

logEvent("created", componentName);

const userStore = useUserStore();
const subscriptionsStore = useSubscriptionsStore();
const showHamburgerNav = ref<boolean>(false);
const showUserNav = ref<boolean>(false);
const showUpgradeToProModal = ref<boolean>(false);
const { userType } = storeToRefs(userStore);
const route = useRoute();
const showAllstate = ref<boolean>(true);
const isBestBuyS2 = ref<boolean>(false);
const buyFlowStore = useBuyFlowStore();
const isLegacyWebroot = ref<boolean>(false);
const isLoading = ref<boolean>(false);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

onBeforeMount(async () => {
  //PLEASE DO NOT ADD API CALLS HERE THAT AN SMB USER CAN NOT ACCESS!! This is breaking CSSB RFA
  if (!hideSidebar.value) {
    isLoading.value = true;
    if (user.value) {
      await displayAllstate();
      isBestBuyS2.value = await hasOnlyBestBuyS2(); // Disable H2B if user has a legacy Webroot
      isLegacyWebroot.value = FLAGS.HIDE_H2B_LEGACY_WEBROOT === "true" && (await hasLegacyWebroot());
      if (!userStore.isSmbAccount) {
        subscriptionsStore.populateSubscriptions();
      }
    }
    isLoading.value = false;
  }
});

async function displayAllstate() {
  if (!isCssbUser.value) {
    const bbyS2Only = await hasOnlyBestBuyS2();

    if (FLAGS.ENABLE_ALLSTATE_BBY === "true" && hasBestBuyS2.value) {
      showAllstate.value = await hasAllstateSubscriptions();
    } else {
      showAllstate.value = !bbyS2Only;
    }
  }
}

function getCssbRoute(relativeRoute: string): string {
  return `${URLS.CP_ENDPOINT.protocol}://${URLS.CP_ENDPOINT.hostname}/${relativeRoute}`;
}

function toggleUpgradeToProModal(type: string | null) {
  type = type || "modal";
  logEvent(
    "toggleUpgradeToProModal",
    componentName,
    `${showUpgradeToProModal.value ? "Hiding" : "Showing"} via ${type}`
  );
  return (showUpgradeToProModal.value = !showUpgradeToProModal.value);
}

const hideSidebar = computed(() => {
  const metadata: IRouteMetadata | undefined = route.meta;
  return metadata?.hideSidebar ?? false;
});

const isCssbUser = computed(() => {
  return userType.value === UserType.smb;
});

const currentRouteName = computed(() => {
  return route.name;
});

const user = computed(() => {
  return userStore.currentUser;
});

const showLanguagePicker = computed(() => {
  return FLAGS.SHOW_LANGUAGE_PICKER === "true";
});

const userFullName = computed(() => {
  return (
    (user.value?.firstName || user.value?.lastName
      ? `${user.value?.firstName ? user.value?.firstName : ""} ${user.value?.lastName ? user.value?.lastName : ""}`
      : user.value?.email) ?? ""
  );
});
function logIn() {
  if (route.name === "buy" || route.name === "selectPlan") {
    window.location.href = userStore.email
      ? getRedirectToLoginURLWithEmail(buyFlowStore.prepareReturnUrl(route.name), userStore.email)
      : getRedirectToLoginURL(buyFlowStore.prepareReturnUrl(route.name));
  } else {
    window.location.href = getCustomerPortalRedirectURL(route.name?.toString());
  }
}
function isCurrentRoute(r) {
  // Added special condition for treating root route as home
  return (route.name === "root" && r.name === "home") || route.name === r.name || route.meta?.parent === r.name;
}

function toggleHamburgerNav() {
  showHamburgerNav.value = !showHamburgerNav.value;
  showUserNav.value = false;
}

function toggleUserNav() {
  showUserNav.value = !showUserNav.value;
  showHamburgerNav.value = false;
}

// returns true if this user is managed by a reseller
const isResellerManaged = computed(() => {
  const user = userStore.currentUser;
  return user?.subscriberInfo?.hasResellerComputer && !user?.subscriberInfo?.isPartnerReferral;
});

const isCarboniteOnlyCustomer = computed(() => {
  const subs = subscriptionsStore.subscriptions;
  return subs.every(l => carboniteSkus.some(k => k === l.sku));
});

const showUpgradeToPro = computed(() => {
  if (!canDisplayGlobalFeature(globalFeatures.convertToBusiness)) {
    return false;
  }

  if (!isCarboniteOnlyCustomer.value) {
    return false;
  }

  return !isResellerManaged.value && !isCssbUser.value && !isBestBuyS2.value && !isLegacyWebroot.value;
});

const showSecurityPage = computed(() => {
  return FLAGS.SHOW_SECURITY_TAB === "true";
});

// Last Update to the navigation displayOrder: 2022-10-27 by DLima for ZUOR-40851
// Please keep this comment updated
const routes = computed(() => {
  const routes = [
    {
      name: "downloads",
      display: "Downloads",
      i18n: "SidebarComponent.downloads",
      displayOrder: 4,
      featureName: globalFeatures.downloadsTab,
    },
    {
      name: "backup",
      display: "Backup",
      i18n: "SidebarComponent.backup",
      displayOrder: 1,
      featureName: globalFeatures.backupTab,
    },
    {
      name: "subscription_information",
      display: "Subscriptions",
      i18n: "SidebarComponent.subscriptions",
      displayOrder: 5,
      featureName: globalFeatures.subscriptionInformationTab,
    },
    {
      name: "personal_information",
      display: "Personal Information",
      i18n: "SidebarComponent.personalInformation",
      displayOrder: 7,
      featureName: globalFeatures.personalInformationTab,
    },
    {
      name: "support",
      display: "Support",
      i18n: "SidebarComponent.support",
      displayOrder: 8,
      featureName: globalFeatures.supportTab,
    },
  ];

  if (showPaymentHistory()) {
    routes.push({
      name: "payment_history",
      display: "Payment History",
      i18n: "SidebarComponent.paymentHistory",
      displayOrder: 6,
      featureName: globalFeatures.paymentHistoryTab,
    });
    // routes = routes.filter(r => r.name !== "payment_history");
  }

  routes.push({
    name: "home",
    display: "Home",
    i18n: "SidebarComponent.home",
    displayOrder: 0,
    featureName: globalFeatures.homeTab,
  });

  if (showSecurityPage.value) {
    routes.push({
      name: "security",
      display: "security",
      i18n: "SidebarComponent.security",
      displayOrder: 2,
      featureName: globalFeatures.securityTab,
    });
  }

  if (showAllstate.value && userStore.selectedLanguage === "en") {
    routes.push({
      name: "allstateSignUp",
      display: "Identity Protection",
      i18n: "SidebarComponent.allstate",
      displayOrder: 3,
      featureName: globalFeatures.allstateSignUpTab,
    });
  }

  routes.sort((a, b) => a.displayOrder - b.displayOrder);

  //Remove routes based on display access
  return routes.filter(l => canDisplayGlobalFeature(l.featureName));
});

watch(currentRouteName, () => {
  showHamburgerNav.value = false;
  showUserNav.value = false;
});

// Trigger display allstate if HasBestBuyS2 conditions changed.
const hasBestBuyS2 = computed(() => user.value?.subscriberInfo?.hasBestBuyS2);
watch(hasBestBuyS2, async () => {
  await displayAllstate();
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
@import "@/styles/variables.css";

.sidebar {
  background-color: white;
}
.plan-name {
  margin: 0 var(--space) var(--space);
  padding-bottom: var(--space-half);
}
ul {
  margin: 0 var(--space-half) 30px;
}
.sidebar a {
  color: white;
  display: block;
  padding: var(--space-half) var(--space-quarter);
}
li {
  border-bottom: 1px solid var(--grey);
  position: relative;
}
.desktop li:hover {
  background-color: var(--light-grey);
}
.desktop li:hover a {
  text-decoration: none;
}
li.current {
  border-bottom: 2px solid var(--secondary);
}
.current-icon {
  display: none;
}
.current .current-icon {
  display: inline-block;
}
li.current:before,
li.current:after {
  left: 95%;
  top: var(--space);
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* mobile sidebar is physically different, it is in a nav block */
nav,
.hide-sidebar-bottom nav {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--text-color);
  height: var(--space-double);
}

/* mobile bottom nave for when hideSideBar is active */
.hide-sidebar-bottom .btn-link {
  color: white;
}
.user,
.home,
.menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: var(--space-quarter);
}
.user:hover,
.home:hover,
.menu:hover {
  background-color: var(--grey);
  border-radius: 5px;
}
.mobile .home a {
  padding: 0;
  position: relative;
  top: -2px;
}
.mobile .home a:hover {
  background-color: transparent;
}
.user,
.home,
.menu {
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: var(--space-quarter);
}
.user:hover,
.home:hover,
.menu:hover {
  background-color: var(--grey);
  border-radius: var(--space-quarter);
}
.mobile-expanded-nav {
  margin: 0;
  position: fixed;
  z-index: 2;
  bottom: var(--space-double);
  width: 100%;
}
.mobile-expanded-nav li {
  background-color: var(--darkest-grey);
  border-left: 8px solid transparent;
}
.mobile-expanded-nav li:hover {
  border-left-color: var(--secondary);
  background-color: var(--text-color); /* needed here for the expanded user menu */
}
.mobile-expanded-nav li.current {
  border-left-color: var(--secondary-active);
  border-bottom: 1px solid var(--grey);
  background-color: transparent;
}
.mobile-expanded-nav a {
  padding-left: var(--space);
  background-color: var(--darkest-grey);
}
.mobile-expanded-nav a:hover {
  color: white;
  text-decoration: none;
  background-color: var(--text-color); /* needed here for the expanded hamburger menu */
}
.mobile-expanded-nav li > span {
  /* used for the users name, displayed in the
  expanded user section of mobile footer nav */
  display: block;
  padding: var(--space-half) 0 var(--space-half) var(--space);
  color: white;
}
.mobile-expanded-nav button {
  /* used for log out and language, displayed in the
  expanded user section of mobile footer nav */
  padding-left: var(--space);
  margin-bottom: 0;
  color: white;
}

.hide-sidebar-bottom .language-selector,
.no-grid .language-selector {
  display: block;
}
.desktop {
  display: none;
}
@media (min-width: 600px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  a {
    color: var(--text-color);
  }
  a:hover {
    color: var(--text-color) !important;
  }
  .sidebar a {
    color: var(--text-color);
  }
  aside {
    background-color: var(--light-grey);
    border: 1px solid var(--grey);
    padding: var(--space-quarter) var(--space-quarter) var(--space-quarter) var(--space-half);
    font-size: var(--small-text);
    margin: 0 var(--space-half);
  }
  aside h6 {
    font-size: var(--regular-text);
    margin-bottom: var(--space-quarter);
  }
  aside a {
    text-decoration: underline;
    padding: 0 !important;
    color: var(--link-color);
  }
}
</style>
