<template>
  <div v-if="isLoading">
    <LoadingSpinner />
  </div>
  <div v-else>
    <NotificationsComponent context="BuyflowComponent" />
    <article
      v-if="buyFlowStore.layoutState === layoutStates.purchase"
      class="main pa-desktop constrain-90 m-auto px-half"
    >
      <BuyProgressbar />
      <Cart />
      <AccountCreditEarned :adjustments="buyFlowStore?.cart?.adjustments || []" />
      <VatInputComponent class="mb" />
      <PromoCode
        v-if="!buyFlowStore.isFlatRateUpgrade && !buyFlowStore.planetOpenTextDiscountApplied"
        class="mb"
        @remove-promo-code="removePromoCode"
      />
      <SelectPlanLink v-if="showSelectPlanLink" class="mb" />
      <SpecialOffers v-if="buyFlowStore.hasCartItems && !buyFlowStore.planetOpenTextDiscountApplied" class="mb" />
      <BuyEmail v-if="showBuyEmailComponent" class="mb" />
      <ChangeStateBtn
        v-if="buyFlowStore.pageState == pageStates.email && !showBuyEmailComponent && !isCartEmpty"
        btn-id="btnGoToPayment"
        btn-class="btn-primary"
        btn-text="BuyEmailComponent.btnContinue"
        :btn-destination="pageStates.payment"
        :disabled="
          buyFlowStore.alreadyOwnsVPNPError ||
          buyFlowStore.hasAlreadyHaveAllstateError ||
          buyFlowStore.planetOpenTextDiscountMismatch
        "
      />
      <BuyPayment v-if="buyFlowStore.hasCartItems" class="mb" />
      <ReviewOrder class="mb" />
      <PhoneAssistance
        v-if="
          buyFlowStore.currentBrand === openTextBrands.Webroot ||
          buyFlowStore.cart?.brand === openTextBrands.Webroot ||
          webrootProductWasInCart()
        "
        class="mb"
      />
    </article>
    <article
      v-else-if="
        buyFlowStore.layoutState === layoutStates.editShipping ||
        buyFlowStore.layoutState === layoutStates.editPayPalShippingAddress
      "
      class="main pa-desktop constrain-90 m-auto px-half"
    >
      <EditShipping />
    </article>
    <article
      v-else-if="buyFlowStore.layoutState === layoutStates.editPayment"
      class="main pa-desktop constrain-90 m-auto px-half"
    >
      <EditPayment />
    </article>
    <LoadingBoxComponent
      :show-dialog="buyFlowStore.showProcessingOrderMessage || false"
      :message="t('Common.loading')"
    />
  </div>
</template>

<script setup lang="ts">
//Components
import SelectPlanLink from "@/components/Buy/SelectPlanLink.vue";
import BuyProgressbar from "./BuyProgressbar.vue";
import VatInputComponent from "./ReviewOrder/VatInput.vue";
import Cart from "@/components/Buy/Cart/Cart.vue";
import BuyEmail from "./Email/Email.vue";
import BuyPayment from "./Payment/Payment.vue";
import PromoCode from "./ReviewOrder/PromoCode.vue";
import ReviewOrder from "./ReviewOrder/ReviewOrder.vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import NotificationsComponent from "@/components/shared/Notifications.vue";
import LoadingBoxComponent from "@/components/shared/LoadingBox.vue";
import EditShipping from "@/components/Buy/ReviewOrder/EditShipping.vue";
//Other
import { logEvent } from "@/common/logger";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { computed, onBeforeMount, ref, watch, onBeforeUnmount } from "vue";
import { useUnauthenticatedBuyFlowStore } from "@/stores/unauthorizedBuyflow";
import { useSubscriptionsStore } from "@/stores/subscriptions";
import { useUserStore } from "@/stores/user";
import { DEFAULT_SKU, DEFAULT_TERM_LENGTH_MONTHS, DEFAULT_QUANTITY, InvalidOrderCode, EMPTY_GUID } from "@/define";
import {
  pageStates,
  layoutStates,
  openTextBrands,
  paymentTypes,
  purchaseErrors,
  purchaseErrorsNotifications,
  PlanetOTErrors,
} from "@/components/Buy/BuyEnums";
import { ISetupCartRequest, IPurchaseError } from "@/components/Buy/Interfaces";
import { getRedirectToLoginURL } from "@/common/loginRedirect";
import {
  buildQueryString,
  setupCartForUser,
  setupCartNewUser,
  checkLoggedStatus,
  getProspectId,
  redirectToOutstandingInvoice,
  getLicenseKeyFromCartItems,
  getCartItemWithLicenseKey,
  getSpecialOffersToDisplay,
} from "@/components/Buy/BuyHelper";
import { useRoute, useRouter } from "vue-router";
import { changePageState, getQueryParam } from "@/components/Buy/BuyHelper";
import AccountCreditEarned from "@/components/Buy/AccountCreditEarned.vue";
import { AxiosError } from "axios";
import { HTTP_STATUS_NOT_FOUND } from "@/define";
import { handleApiError } from "@/common/handleApiError";
import SpecialOffers from "@/components/Buy/SpecialOffers.vue";
import { sortArrayOfObjects } from "./commonFn";
import PhoneAssistance from "@/components/Buy/PhoneAssistance.vue";
import { validGuidFormat } from "@/globalRegex";
import { t } from "@/i18n";
import EditPayment from "@/components/Buy/Payment/EditPayment.vue";
import { canDisplayPerSubscriptionFeature, perSubscriptionFeatures } from "@/common/featureDisplayAccess";
import ChangeStateBtn from "@/components/Buy/ChangeStateBtn.vue";
import { unifiedApi } from "@/common";
import { cartUpdate, googleTagCartEvents } from "@/common/googleTagEvents";
import { isSafeSubscriptionClosed } from "@/common/subscriptionHelper";
import { usePayPalStore } from "@/stores/payPal";
import { useNotificationsStore } from "@/stores/notifications";
import { CASTLE_PK, CASTLE_API } from "@/define";
import * as Castle from "@castleio/castle-js";
import { clearSession } from "@/common/logout";
import { groupedProducts } from "@/common/GroupedProducts";

const componentName = "BuyComponent";
logEvent("created", componentName);

const route = useRoute();
const router = useRouter();
const subscriptionStore = useSubscriptionsStore();
const unauthenticatedBuyflowStore = useUnauthenticatedBuyFlowStore();
const userStore = useUserStore();
const isLoading = ref<boolean>(true);
const buyFlowStore = useBuyFlowStore();
const notificationsStore = useNotificationsStore();

const castleRequestToken = ref<string>("");
const castleOptions = {
  pk: CASTLE_PK,
  apiUrl: CASTLE_API,
} as Castle.ConfigureOptions;

Castle.configure(castleOptions);

const showSelectPlanLink = computed(() => {
  if (buyFlowStore.isRenewOrUpgrade) {
    let distributionCode = "";
    //Find the sub to get the retail code
    if (userIsLoggedIn.value) {
      const sub = subscriptionStore.subscriptions.find(l => l.computer?.id === deviceId);
      if (sub) {
        distributionCode = sub.carbLicenseDistributionMethodCode;
      }
    } else {
      try {
        if (!unauthenticatedBuyflowStore.subscription && deviceId > 0) {
          unauthenticatedBuyflowStore.populateSubscription(deviceId);
        }
        const sub = unauthenticatedBuyflowStore.subscription;
        if (sub) {
          distributionCode = sub.carbLicenseDistributionMethodCode;
        }
      } catch {
        distributionCode = "";
      }
    }

    if (distributionCode) {
      return canDisplayPerSubscriptionFeature(perSubscriptionFeatures.buyFlowSelectPlanLink, distributionCode);
    }
  }

  return true;
});

const showBuyEmailComponent = computed(() => {
  if (buyFlowStore.currentBrand === openTextBrands.Webroot) {
    return (
      !buyFlowStore.userGuid &&
      !userStore.isEstablishedAccount &&
      !buyFlowStore.licenseKeyInfo?.hasAccount &&
      buyFlowStore.hasCartItems
    );
  }
  return buyFlowStore.hasCartItems;
});

const userIsLoggedIn = computed(() => {
  return userStore.currentUser;
});

const isCartEmpty = computed(() => {
  return buyFlowStore.cart?.items.length === 0 || !buyFlowStore.cart;
});

// commented while not in use
// function tn(v: string, params?: Record<string, unknown>): string {
//   return t(`${componentName}.${v}`, params);
// }

//Check query parameters
let sku = getQueryParam(route.query, "sku") || "";
let ratePlanId = getQueryParam(route.query, "ratePlanId") || getQueryParam(route.query, "rpi") || "";
const prospectGuid = getQueryParam(route.query, "guid") || getQueryParam(route.query, "pguid") || "";
const orderCode = getQueryParam(route.query, "orderCode") || getQueryParam(route.query, "oc") || "";
let step = getQueryParam(route.query, "step") || pageStates.email.toString(); //Can't short hand do to navigation
let deviceId = parseInt(getQueryParam(route.query, "deviceId") || getQueryParam(route.query, "did") || "0");
let userGuid = getQueryParam(route.query, "userGuid") || getQueryParam(route.query, "ug") || "";
let subscriptionMonths =
  parseInt(getQueryParam(route.query, "subscriptionMonths") || getQueryParam(route.query, "sm") || "") ||
  DEFAULT_TERM_LENGTH_MONTHS;
let quantity = parseInt(getQueryParam(route.query, "quantity") || getQueryParam(route.query, "qty") || "1");
let userId = parseInt(getQueryParam(route.query, "userId") || getQueryParam(route.query, "uid") || "") || 0;
let promoCode = getQueryParam(route.query, "couponcode") || getQueryParam(route.query, "cc") || "";
let catId = parseInt(getQueryParam(route.query, "catid") || getQueryParam(route.query, "cid") || "0");
let subscriptionNumber = getQueryParam(route.query, "subscriptionnumber") || getQueryParam(route.query, "sn") || "";
let includedUnits = parseInt(getQueryParam(route.query, "iu") || "1");
const webrootProductWasInCart = function () {
  if (buyFlowStore.isWebrootSku(buyFlowStore.lastCartItemRemoved?.sku || "")) {
    return true;
  }
};
const enrollmentGuid = getQueryParam(route.query, "eg");
let licenseKey = getQueryParam(route.query, "lk");
const vendorKey = getQueryParam(route.query, "sn");
const loadCartFromDB = getQueryParam(route.query, "lcd") || 0;
buyFlowStore.referralUrl =
  getQueryParam(route.query, "referralurl") || getQueryParam(route.query, "rurl") || buyFlowStore.referralUrl || "";

const callerURL = window.location.href;
let isWebrootPurchase = callerURL.indexOf("webroot") !== -1;
const fromPayPal = buyFlowStore.isPayPalPayment; //route.query.from?.toString() =="Paypal"||
const payPalStore = usePayPalStore();
if (
  payPalStore.purchaseError != null &&
  (payPalStore.purchaseError === purchaseErrorsNotifications.CCAddress ||
    payPalStore.purchaseError === purchaseErrorsNotifications.CCTransaction ||
    payPalStore.purchaseError === purchaseErrorsNotifications.PurchaseFailureError)
) {
  changePageState(pageStates.payment, false);
}

buyFlowStore.openTextBrand = isWebrootPurchase ? openTextBrands.Webroot : openTextBrands.Carbonite;
const loadSessionStorageCart = getQueryParam(route.query, "lsc") === "1";

onBeforeMount(async () => {
  //Clear persisted Buy flow notifications
  notificationsStore.deletePersistedNotification("FlatRateUpgradeWindowError");
  castleRequestToken.value = await Castle.createRequestToken();
  //Make sure the user guid is a valid format for a guid
  if (!validGuidFormat.test(userGuid)) {
    userGuid = "";
  }

  //Make sure the step on the URL is valid
  if (!Object.values(pageStates).includes(step as pageStates)) {
    step = pageStates.email;
  }

  //Set DeviceId to 0 if we get passed a value that can not parse to an int
  if (isNaN(deviceId)) {
    deviceId = 0;
  }

  //Popstate is triggered every time the back OR forward browser button is used.
  window.onpopstate = function (event) {
    const params = event.state.current?.split("?")[1]?.split("&");
    const currentStep = params?.find(p => p.includes("step="))?.split("=")[1];
    let step = pageStates.email;
    if (currentStep) {
      step = currentStep;
    }

    if (step !== buyFlowStore.pageState) {
      changePageState(step);
    }
  };

  if (vendorKey) {
    try {
      const vendorKeyInfo = (await unifiedApi.getLicenseKeyFromVendorKey(vendorKey)).data;
      if (vendorKeyInfo.success) {
        licenseKey = vendorKeyInfo.licenseKey;
      }
    } catch {
      //continue on
      logEvent("GET vendorKey call failed");
    }
  }

  await checkLoggedStatus();
  //If we have a userId we want to redirect them to log in

  // If userGuid and userId passed, userId is ignored
  if (userGuid && userId) {
    userId = 0;
  }

  if (userId > 0) {
    //create return URL without the userId
    let url = window.location.origin;
    const queryParams = buildQueryString(route.query, ["userId"]);

    if (queryParams) {
      url = `${url}/buy?${queryParams}`;
    }

    window.location.href = getRedirectToLoginURL(url);
  }

  let isRenewOrUpgrade = false;
  if (
    deviceId > 0 ||
    licenseKey ||
    (buyFlowStore.cart && buyFlowStore.cart.items.some(l => l.computerId > 0)) ||
    buyFlowStore.isRenewOrUpgrade
  ) {
    isRenewOrUpgrade = true;
  }

  //Make sure the plans are populated
  await buyFlowStore.populatePlans(catId, isRenewOrUpgrade);
  isWebrootPurchase = isWebrootPurchase || buyFlowStore.isWebrootSku(sku);

  if (enrollmentGuid) {
    try {
      buyFlowStore.openTextBrand = openTextBrands.Webroot;
      //Check if user is logged in and log them out if they are
      if (userStore.currentUser) {
        await clearSession();
        await unifiedApi.logout();
        userStore.resetState();
      }

      buyFlowStore.pageState = pageStates.email;
      const cart = (await unifiedApi.getShoppingCartByEnrollmentGuid(enrollmentGuid, castleRequestToken.value)).data;
      cart.items.forEach(l => {
        l.autoRenew = true;
      });
      buyFlowStore.cart = cart;
      buyFlowStore.planetOpenTextDiscountApplied = true;
      buyFlowStore.currentEmail = cart.userEmail;
      catId = cart.campaignId;
      await buyFlowStore.populatePlans(catId, false);
      buyFlowStore.planetOpenTextDiscountApplied = true;
    } catch (err) {
      const error = err as AxiosError;
      const response = error.response?.data as IPurchaseError;
      const errorMessage = response.message;

      if (
        errorMessage === PlanetOTErrors.GuidAlreadyUsed ||
        errorMessage === PlanetOTErrors.GuidExpired ||
        errorMessage === PlanetOTErrors.InvalidGuid
      ) {
        notificationsStore.addNotification({ type: "PlanetOpenTextEnrollmentGuidError" });
      } else {
        notificationsStore.addNotification({ type: "PlanetOpenTextInvalidCart" });
      }
    }
    isLoading.value = false;
    return;
  }

  if (orderCode) {
    try {
      const cart = (await unifiedApi.getShoppingCartByOrderCode(orderCode)).data;
      buyFlowStore.cart = cart;

      if (cart && cart.campaignId > 0 && !buyFlowStore.isRenewOrUpgrade) {
        catId = cart.campaignId;
        await buyFlowStore.populatePlans(catId, isRenewOrUpgrade, false, true);
      }

      if (!buyFlowStore.userGuid) {
        buyFlowStore.userGuid = userGuid;
      }

      //Check cart of license key or use query parameter
      buyFlowStore.licenseKey = getLicenseKeyFromCartItems(cart?.items || []) || licenseKey;
      if (buyFlowStore.licenseKey) {
        const cartItemWithLicenseKey = getCartItemWithLicenseKey(cart?.items);
        //Get license key info for renewal, need to figure out how to do that for an upgrade
        buyFlowStore.setLicenseKeyInfo(
          (await unifiedApi.getLicenseKeyInfo(buyFlowStore.licenseKey, cartItemWithLicenseKey?.sku || "")).data
        );

        if (buyFlowStore.licenseKeyInfo?.hasOutstandingInvoice) {
          redirectToOutstandingInvoice(true);
          return;
        }
      }

      //if there is a promo code add it to the cart
      if (promoCode && buyFlowStore.cart) {
        buyFlowStore.cart.promotionCode = promoCode;
        await buyFlowStore.updateShoppingCartTotal();
      }

      if (
        getSpecialOffersToDisplay(buyFlowStore.cart.items[0]).length === 0 &&
        (buyFlowStore.licenseKeyInfo?.hasAccount || userIsLoggedIn.value)
      ) {
        //If there are no candy rack items, move to payment
        changePageState(pageStates.payment);
      } else {
        //Should only be done here because we skip the rest of the flow
        changePageState(pageStates[step]);
      }
      cartUpdate(cart, buyFlowStore.pageState);
      if (userIsLoggedIn.value) {
        await subscriptionStore.populateSubscriptions();
      }
      buyFlowStore.planetOpenTextDiscountApplied = getPlanetOpenTextDiscountStatus();
      isLoading.value = false;
      return;
    } catch (err) {
      logEvent("Error on getting cart by orderCode", JSON.stringify(err as AxiosError));
      const axiosError = err as AxiosError;
      const response = axiosError.response?.data as IPurchaseError;
      // if Webroot, clear the cart and show Start Shopping button
      if (isWebrootPurchase && response.message === InvalidOrderCode) {
        await buyFlowStore.clearCart(openTextBrands.Webroot, true);
        await buyFlowStore.updateShoppingCartTotal();
        isLoading.value = false;
        return;
      }
    }
  }

  if (loadCartFromDB === "1") {
    try {
      await buyFlowStore.getShoppingCart(userGuid);

      buyFlowStore.userGuid = userGuid;
      buyFlowStore.pageState = pageStates.payment;
      //if there is a promo code add it to the cart
      if (promoCode && buyFlowStore.cart) {
        buyFlowStore.cart.promotionCode = promoCode;
        await buyFlowStore.updateShoppingCartTotal();
      }

      //Should only be done here because we skip the rest of the flow
      changePageState(pageStates[step]);
      isLoading.value = false;
      return;
    } catch (err) {
      logEvent("Error on getting cart", JSON.stringify(err as AxiosError));
      if (isWebrootPurchase) {
        await buyFlowStore.clearCart(openTextBrands.Webroot, true);
        buyFlowStore.pageState = pageStates.email;
        isLoading.value = false;
        return;
      }
    }
  }

  if (loadSessionStorageCart) {
    buyFlowStore.cart = JSON.parse(sessionStorage["BuyFlow_Session_Cart"]);

    //Should only be done here because we skip the rest of the flow
    changePageState(pageStates[step]);

    // Should update cart if it has an Allstate item
    if (buyFlowStore.hasAllStateInCart) {
      await buyFlowStore.updateShoppingCartTotal();
    }
    if (userIsLoggedIn.value) {
      await subscriptionStore.populateSubscriptions();
    }
    buyFlowStore.planetOpenTextDiscountApplied = getPlanetOpenTextDiscountStatus();

    isLoading.value = false;
    return;
  }

  //Skip verification checks for grouped products
  if (sku && !groupedProducts[sku]) {
    // Check if sku and months on queryString are valid
    //This check is only for Carbonite products
    if (sku && subscriptionMonths && !isWebrootPurchase) {
      const ratePlan = buyFlowStore.getRatePlanIdBySku(sku, subscriptionMonths);
      if (!ratePlan) {
        sku = buyFlowStore.isSkuValid(sku) ? sku : "PersonalPlus";
        subscriptionMonths = 12;
      } else if (ratePlanId && ratePlanId != ratePlan) {
        // if ratePlanId passed in URL is different (invalid) then productCatalog, override.
        ratePlanId = ratePlan;
      }
    } else if (sku && subscriptionMonths && isWebrootPurchase) {
      //Set defaults for Webroot here
      //Default for WR sku will be an error message
      const skuRatePlans = buyFlowStore.getRatePlansForSku(sku);
      let seats = skuRatePlans.find(l => l.includedUnits === includedUnits)?.includedUnits;

      if (!seats && skuRatePlans.length > 0) {
        const sortedRatePlans = sortArrayOfObjects(skuRatePlans, "months", "ascending");
        seats = sortedRatePlans[0].includedUnits;

        if (seats) {
          includedUnits = seats;
        }
      }
    }
  }

  // Check if quantity is greater or less than allowed
  // There are also some items that cannot be purchased more than 1 unit per customer
  quantity =
    quantity > buyFlowStore.getMaxQuantityBySku(sku)
      ? DEFAULT_QUANTITY
      : quantity < DEFAULT_QUANTITY || !quantity
        ? DEFAULT_QUANTITY
        : quantity;

  // if deviceId is passed, force quantity equals 1
  if (deviceId > 0) {
    quantity = 1;
  }

  try {
    //Get info on license key.
    if (licenseKey) {
      buyFlowStore.licenseKey = licenseKey;
      let hasOutstandingInvoice = false;
      // for upgrade using rateplanId
      if (!sku && ratePlanId) {
        sku = buyFlowStore.plans.find(p => p.ratePlans.some(r => r.ratePlanId == ratePlanId))?.sku ?? "";
      }
      const sub = subscriptionStore.subscriptions.find(l => l.licenseKey === licenseKey);
      if (sub && sub.hasOutstandingInvoices) {
        hasOutstandingInvoice = true;
      } else {
        buyFlowStore.setLicenseKeyInfo((await unifiedApi.getLicenseKeyInfo(licenseKey, sku)).data);
      }

      if (hasOutstandingInvoice || buyFlowStore.licenseKeyInfo?.hasOutstandingInvoice) {
        redirectToOutstandingInvoice(!userIsLoggedIn.value);
        return;
      }

      //If this is an S2 recapture we need to get the product catalog again with the catId to get correct prices
      if (buyFlowStore.licenseKeyInfo?.isBestBuyS2Recapture) {
        await buyFlowStore.populatePlans(catId, false, false, true);
      }
    }
  } catch (err) {
    logEvent("Error on license key info", JSON.stringify(err as AxiosError));
    // clear the cart and show Start Shopping button
    await buyFlowStore.clearCart(openTextBrands.Webroot, true);
    isLoading.value = false;
    return;
  }

  if (!buyFlowStore.cart || buyFlowStore.cart?.items.length <= 0) {
    await setupCart();
  }

  // If there is nothing in the cart we should set it according to the params, or set to the default values
  // (This fixes page reloads to only get the default sku)
  if (!buyFlowStore.cart || buyFlowStore.cart?.items.length === 0) {
    if (licenseKey || isWebrootPurchase) {
      await buyFlowStore.clearCart(openTextBrands.Webroot, true);
      changePageState(pageStates.email);
    } else {
      const ratePlanId = buyFlowStore.getRatePlanIdBySku(
        sku || DEFAULT_SKU,
        subscriptionMonths || DEFAULT_TERM_LENGTH_MONTHS,
        includedUnits || 1
      );
      const beginShoppingCartRequest = {
        sku: sku || DEFAULT_SKU,
        ratePlanId: ratePlanId,
        quantity: quantity,
        deviceId: deviceId,
        subscriptionNumber: subscriptionNumber,
        step: step,
      };
      buyFlowStore.beginShoppingCart(beginShoppingCartRequest);
      try {
        await buyFlowStore.updateShoppingCartTotal(false, true);
      } catch (error) {
        await handleErrorOnUpdate(error);
      }
    }
  }

  isLoading.value = false;
});

//This will look at all the data and setup the cart
async function setupCart() {
  let newUser = true;
  buyFlowStore.userGuid = userGuid;
  // We may use that in future
  //const referralUrl = route.query?.referralUrl?.toString() || "";

  const request = {
    ratePlanId: ratePlanId,
    sku: sku,
    step: step,
    deviceId: deviceId,
    userGuid: userGuid,
    subscriptionMonths: subscriptionMonths,
    quantity: quantity,
    subscriptionNumber: subscriptionNumber,
    includedUnits: includedUnits,
    licenseKey: licenseKey,
    isWebroot: isWebrootPurchase,
  } as ISetupCartRequest;

  //TODO: Figure out which state each flow should land on
  //We already have a logged in user or userGuid
  if (userIsLoggedIn.value || licenseKey || userGuid || deviceId > 0) {
    newUser = false;
    if (userIsLoggedIn.value) {
      //Populate the subscriptions store is we have a User
      await subscriptionStore.populateSubscriptions();
      if (deviceId > 0) {
        const sub = subscriptionStore.subscriptions.find(l => l.computer?.id === deviceId);
        if (sub && isSafeSubscriptionClosed(sub)) {
          router.push({ path: "/buy/error/unrecoverable" });
          return;
        }

        if (subscriptionStore.subscriptions.find(l => l.computer?.id === deviceId)?.hasOutstandingInvoices) {
          redirectToOutstandingInvoice();
          return;
        }
      } else if (licenseKey) {
        const sub = subscriptionStore.subscriptions.find(l => l.licenseKey === licenseKey);

        if (sub && sub.hasOutstandingInvoices) {
          redirectToOutstandingInvoice();
          return;
        }
      }
    } else if (deviceId > 0) {
      //Get the subscription for the given device / userGuid
      try {
        await unauthenticatedBuyflowStore.populateSubscription(deviceId, true);

        if (unauthenticatedBuyflowStore?.subscription?.hasOutstandingInvoices) {
          redirectToOutstandingInvoice(true);
          return;
        }
      } catch (error) {
        const err = error as AxiosError;
        const response = err?.response?.data as IPurchaseError;
        //Device has been purged error
        if (response && response?.message === purchaseErrors.NotEligibleForRenewal) {
          goToPurgedDeviceErrorPage();
          return;
        }
      }
    }

    if (!newUser) {
      await setupCartForUser(request);
      if (userGuid) {
        if (buyFlowStore.cart) {
          buyFlowStore.cart.userGuid = userGuid;
        }
      }
    }
  }

  if (newUser) {
    setupCartNewUser(request);
  }

  if (promoCode && buyFlowStore.cart) {
    buyFlowStore.cart.promotionCode = promoCode;
  }

  if (catId && buyFlowStore.cart) {
    buyFlowStore.cart.campaignId = catId;
  }

  //If prospectGuid exist in query params then fetch the prospectId from API and save to Buyflow store
  if (prospectGuid != "" && buyFlowStore.cart) {
    const prospectId = (await getProspectId(prospectGuid)) ?? 0;
    buyFlowStore.cart.prospectId = prospectId;
  }

  try {
    await buyFlowStore.updateShoppingCartTotal(false, true);
  } catch (e) {
    const err = e as AxiosError;
    if (err.response?.status === HTTP_STATUS_NOT_FOUND) {
      userGuid = "";
      buyFlowStore.userGuid = "";
      buyFlowStore.pageState = pageStates.email;
    } else {
      handleErrorOnUpdate(err);
    }
  }
}

function goToPurgedDeviceErrorPage() {
  router.push({ path: "/buy/error/unrecoverable" });
}

function getPlanetOpenTextDiscountStatus() {
  if (buyFlowStore.cart?.enrollmentGuid && buyFlowStore.cart?.enrollmentGuid != EMPTY_GUID) {
    return true;
  } else if (buyFlowStore.cart && subscriptionStore.subscriptions) {
    return buyFlowStore.cart.items.some(item => {
      const hasPlanetOtSub = subscriptionStore.subscriptions.some(
        sub => sub.licenseKey === item.licenseKey && sub.isPlanetOt && sub.ratePlanId === item.ratePlanId
      );
      return hasPlanetOtSub;
    });
  } else {
    return false;
  }
}

//Watch the buyflow store pageState for changes
watch(
  () => buyFlowStore.$state.pageState,
  (stateChanged, previousState) => {
    //Prevent router push adding in extra web history by pushing the same state
    if (previousState && stateChanged === previousState) return;
    logEvent(`Buyflow state change to ${stateChanged}`, componentName);
    buyFlowStore.clearUndo();
    //If there is a logged in established SAFE user, skip the email step and do not put it on the history
    //If Webroot products are in the cart we need to stay on the email step to see the candy rack
    if (
      stateChanged === pageStates.email &&
      (buyFlowStore.openTextBrand === openTextBrands.Carbonite ||
        buyFlowStore.currentBrand === openTextBrands.Carbonite) &&
      ((userStore.currentUser && userStore.currentUser?.subscriberInfo?.hasPasswordSet) || deviceId)
    ) {
      changePageState(pageStates.payment);
    } else if (
      stateChanged !== pageStates.email &&
      !userStore.currentUser &&
      !userGuid &&
      deviceId === 0 &&
      !userStore.email &&
      !fromPayPal &&
      (!buyFlowStore.licenseKeyInfo || !buyFlowStore.licenseKeyInfo.hasAccount)
    ) {
      changePageState(pageStates.email);
    } else {
      //Every time the page state changes we need to push a new state to the route history
      //Make sure all query params are copied with the new state
      const queryParams = Object.assign({}, route.query, { step: stateChanged });
      setTimeout(() => router.push({ name: "buy", query: queryParams }));
    }
  }
);

// For some preview errors we want to retry the preview call with a modified cart
// This method modifies the cart depending on the error
async function handleErrorOnUpdate(error) {
  let retryPreview = false;
  const axiosError = error as AxiosError;
  if (buyFlowStore.cart) {
    const response = axiosError.response?.data as IPurchaseError;

    if (
      (response.message === purchaseErrors.InvalidComputerId ||
        response.message === purchaseErrors.SmbComputerIneligible) &&
      deviceId > 0
    ) {
      // If deviceId query string is greater then 0 and is invalid
      // Override all computerIds in the items = 0
      deviceId = 0;
      for (const item of buyFlowStore.cart.items) {
        item.computerId = 0;
      }

      //If there isn't a current user or user GUID for this safe purchase
      //We have nothing to Identify the user, so send them to the email step
      if (!userStore.currentUser && !userGuid) {
        changePageState(pageStates.email);
      }

      retryPreview = true;
    } else if (
      response.message === purchaseErrors.InvalidPromotionCode &&
      (promoCode || buyFlowStore.cart.promotionCode)
    ) {
      // If promoCode query string is passed and is invalid
      // Override promotionCode to empty
      promoCode = "";
      buyFlowStore.cart.promotionCode = "";
      retryPreview = true;
    } else if (response.message === purchaseErrors.InvalidSubscription && subscriptionNumber) {
      // If subscriptionNumber is passed then 0 and is invalid
      // Override subscriptionNumbers to empty
      subscriptionNumber = "";
      for (const item of buyFlowStore.cart.items) {
        item.subscriptionNumbers = [];
      }
      retryPreview = true;
    }
  }
  if (retryPreview) {
    await buyFlowStore.updateShoppingCartTotal(false, true);
  } else {
    handleApiError(axiosError, false);
  }
}

async function removePromoCode() {
  if (buyFlowStore.cart) {
    buyFlowStore.cart.promotionCode = "";
  }
  promoCode = "";
  await buyFlowStore.updateShoppingCartTotal(false, false, googleTagCartEvents.change);
}

// if we want to allow people to go back to their purchase, this needs to be removed.
// This fixes https://jira.opentext.com/browse/ZUOR-46568
onBeforeUnmount(() => {
  buyFlowStore.paymentType = paymentTypes.CreditCard;
});
</script>
