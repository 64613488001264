<template>
  <ul v-if="props.isUnsupportedOs" class="form">
    <li class="danger-text">
      {{ t(`${parentComponentName}.${productCode}Unsupported`) }}
    </li>
  </ul>
  <ol v-else class="form">
    <li>
      <p>{{ t(`${parentComponentName}.${productCode}StepOne`) }}</p>
      <Spinner :is-spinning="showSpinner"></Spinner>
      <SelectorComponent
        v-if="!showSpinner"
        :product-code="productCode"
        :pure-vpn-mac-installer-url="macInstallerUrl"
        :pure-vpn-windows-installer-url="windowsInstallerUrl"
        :hide-windows-option="isCdlpLicense"
      />
    </li>
    <li>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="t(`${parentComponentName}.${productCode}StepTwo`)"></p>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import SelectorComponent from "@/components/shared/dialogs/DownloadProduct/Selector.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { unifiedApi } from "@/common";
import { ref, onBeforeMount, PropType } from "vue";
import { DeviceType } from "@/globalEnums";
import { ISubscriberDownloadsInfo } from "@/common/api/unifiedPortal/interfaces";

const productCode = "WSVPN";
const componentName = `${productCode}ProductComponent`;
const parentComponentName = "DownloadProductComponent";
const showSpinner = ref<boolean>(true);
const windowsInstallerUrl = ref<string>();
const macInstallerUrl = ref<string>();
const isCdlpLicense = ref<boolean>();

const props = defineProps({
  isUnsupportedOs: {
    type: Boolean,
    default: null,
  },
  keycodes: {
    type: Object as PropType<ISubscriberDownloadsInfo>,
    default: null,
  },
});
onBeforeMount(async () => {
  await getPureVpnLatestVersionDetails();

  isCdlpLicense.value =
    !props.keycodes.webrootSubscriptions.some(w => w.licenseCategoryName === "WSVPN") &&
    props.keycodes.webrootSubscriptions.some(w => w.licenseCategoryName === "WTP" || w.licenseCategoryName === "WTPF");

  showSpinner.value = false;
});

// Get latest installer urls for pure vpn windows and mac
async function getPureVpnLatestVersionDetails() {
  const macResponse = (await unifiedApi.getPureVpnLatestVersionDetails(DeviceType.Mac)).data;
  const windowsResponse = (await unifiedApi.getPureVpnLatestVersionDetails(DeviceType.Windows)).data;
  if (macResponse.header.response_code == 200) {
    macInstallerUrl.value = macResponse.body.app_binary_url;
  }
  if (windowsResponse.header.response_code == 200) {
    windowsInstallerUrl.value = windowsResponse.body.app_binary_url;
  }
}

logEvent("created", componentName, props);
</script>

<style scoped lang="css">
@import "@/styles/variables.css";
</style>
