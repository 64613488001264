import { useSubscriptionsStore } from "@/stores/subscriptions";
import { LocationQuery } from "vue-router";
import { retailCodes } from "@/common/featureDisplayAccess";
import { useUserStore } from "@/stores/user";

export async function getLandingPage(query: LocationQuery | null): Promise<string> {
  const subscriptionsStore = useSubscriptionsStore();
  await subscriptionsStore.populateSubscriptions();
  const userStore = useUserStore();
  const subscriptions = subscriptionsStore.subscriptions;

  const nextRoute =
    userStore.currentUser?.subscriberInfo?.carbLicenseDistributionMethodCodes.some(l => l === retailCodes.AOL) ||
    subscriptions.some(l => l.carbLicenseDistributionMethodCode === retailCodes.AOL)
      ? "backup"
      : "home";

  // Get queryStrings and forward them in the route
  let jsonQueryStrings = "";
  if (query) {
    jsonQueryStrings = JSON.stringify(query);
    jsonQueryStrings = jsonQueryStrings
      .replaceAll("{", "")
      .replaceAll("}", "")
      .replaceAll(" ", "")
      .replaceAll(":", "=")
      .replaceAll(`"`, ``)
      .replaceAll(`,`, `&`);
  }

  return `/${nextRoute}?${jsonQueryStrings}`;
}
