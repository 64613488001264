import { useUserStore } from "@/stores/user";

export enum retailCodes {
  STTS = "STTS", //Staples
  MOZD = "MOZD", // MOZY Direct
  DRCT = "DRCT", //Carbonite/Safe Direct
  RSLS = "RSLS", // Reseller Default
  NOS = "NOS", //Not Specified
  CTRL = "CTRL", //Safe Trial
  TTSB = "TTSB", // Best Buy Total Tech Support
  ZDBB = "ZDBB", // Best Buy Core S2
  AOL = "AOL", // AOL
}

//This is a temporary fix since we have not implemented the per sub
//LicenseDistributionMethodCode for Webroot
export enum retailCodesMapper {
  "BestBuy" = "ZDBB", // Best Buy Core S2
}

export enum perSubscriptionFeatures {
  renew = "renew",
  changePlan = "changePlan", //Upgrade / Downgrade
  setAutoRenew = "setAutoRenew",
  buyFlowSelectPlanLink = "buyFlowSelectPlanLink",
  displayPaymentMethod = "displayPaymentMethod",
  restrictRenewalToOneYear = "restrictRenewalToOneYear",
}

export enum globalFeatures {
  //Down load page
  downloadWebrootApps = "downloadWebrootApps",
  downloadCarbonite = "downloadCarbonite",
  addKeycode = "addKeycode",

  //Backup page
  addOrUpgrade = "addOrUpgrade",
  startATrial = "startATrial",

  //Purchase related
  convertToBusiness = "convertToBusiness", //Home to Business
  startCarboniteTrial = "startCarboniteTrial",
  exploreSecurityOptions = "exploreSecurityOptions",

  //Home page
  allStateTile = "allStateTile",
  securityTile = "securityTile",
  backupTile = "backupTile",
  subscriptionsTile = "subscriptionsTile",

  //Side Nav
  downloadsTab = "downloadsTab",
  backupTab = "backupTab",
  subscriptionInformationTab = "subscriptionInformationTab",
  personalInformationTab = "personalInformationTab",
  paymentHistoryTab = "paymentHistoryTab",
  homeTab = "homeTab",
  securityTab = "securityTab",
  allstateSignUpTab = "allstateSignUpTab",
  supportTab = "supportTab",

  //All State
  allStateUpgrade = "allStateUpgrade",
}

interface IFeature {
  //Down load page
  downloadWebrootApps: boolean;
  downloadCarbonite: boolean;
  exploreSecurityOptions: boolean;
  addKeycode: boolean;

  //Backup page
  addOrUpgrade: boolean;
  startATrial: boolean;

  //Purchase related
  renew: boolean;
  changePlan: boolean; //Upgrade / Downgrade
  setAutoRenew: boolean;
  startCarboniteTrial: boolean;
  convertToBusiness: boolean; //Home to Business
  buyFlowSelectPlanLink: boolean;
  restrictRenewalToOneYear: boolean;

  //Home page
  allStateTile: boolean;
  securityTile: boolean;
  backupTile: boolean;
  subscriptionsTile: boolean;

  //All State
  allStateUpgrade: boolean;

  //Side Nav
  downloadsTab: boolean;
  backupTab: boolean;
  subscriptionInformationTab: boolean;
  personalInformationTab: boolean;
  paymentHistoryTab: boolean;
  homeTab: boolean;
  securityTab: boolean;
  allstateSignUpTab: boolean;
  supportTab: boolean;

  //Subscription Page
  displayPaymentMethod: boolean;
}

interface IDisplayConfig {
  STTS?: IFeature;
  DRCT?: IFeature;
  NOS?: IFeature;
  RSLS?: IFeature;
  MOZD?: IFeature;
  CTRL?: IFeature;
  TTSB?: IFeature;
  ZDBB?: IFeature;
  AOL?: IFeature;
}

const defaultFeature: IFeature = {
  downloadWebrootApps: true,
  downloadCarbonite: true,
  addKeycode: true,
  exploreSecurityOptions: true,
  renew: true,
  changePlan: true,
  setAutoRenew: true,
  convertToBusiness: true,
  startCarboniteTrial: true,
  downloadsTab: true,
  backupTab: true,
  subscriptionInformationTab: true,
  personalInformationTab: true,
  paymentHistoryTab: true,
  homeTab: true,
  securityTab: true,
  allstateSignUpTab: true,
  supportTab: true,
  allStateTile: true,
  securityTile: true,
  backupTile: true,
  subscriptionsTile: true,
  addOrUpgrade: true,
  startATrial: true,
  buyFlowSelectPlanLink: true,
  displayPaymentMethod: true,
  restrictRenewalToOneYear: false,
  allStateUpgrade: true,
};

const alwaysUsePerSubIfMultipleSubs: globalFeatures[] = [
  globalFeatures.convertToBusiness,
  globalFeatures.allStateUpgrade,
];

const displayConfig: IDisplayConfig = {
  // RSLS: Object.assign({}, defaultFeature, {
  //   renew: false,
  //   changePlan: false,
  //   convertToBusiness: false,
  // }),
  STTS: Object.assign({}, defaultFeature, {
    downloadWebrootApps: false,
    exploreSecurityOptions: false,
    changePlan: false,
    convertToBusiness: false,
    paymentHistoryTab: false,
    startCarboniteTrial: false,
    allStateTile: false,
    securityTile: false,
    addOrUpgrade: false,
    buyFlowSelectPlanLink: false,
    startATrial: false,
    allstateSignUpTab: false,
    securityTab: false,
    restrictRenewalToOneYear: true,
  }),
  TTSB: Object.assign({}, defaultFeature, {
    allStateTile: true,
    allStateUpgrade: false,
    changePlan: false,
    backupTile: false,
  }),
  ZDBB: Object.assign({}, defaultFeature, {
    allStateTile: true,
    allStateUpgrade: false,
    changePlan: false,
    backupTile: false,
  }),
  AOL: Object.assign({}, defaultFeature, {
    homeTab: false,
    paymentHistoryTab: false,
    allstateSignUpTab: false,
    securityTab: false,
    exploreSecurityOptions: false,
    addKeycode: false,
    downloadWebrootApps: false,
    convertToBusiness: false,
    addOrUpgrade: false,
    startATrial: false,
    renew: false,
    changePlan: false,
    setAutoRenew: false,
    buyFlowSelectPlanLink: false,
    displayPaymentMethod: false,
  }),
};

//Used to hide or show Overall UI features like the tabs on the side nav or large sections on a page.
//These features may be turned back on if the user has more than 1 sub for 2 different sources
export function canDisplayGlobalFeature(feature: globalFeatures, wrLicenseDistributionMethodCode?: string) {
  const userStore = useUserStore();

  let showFeature = true;
  //If there is no user info show the feature
  if (!userStore.currentUser) {
    return showFeature;
  }

  const safeUserRetailCodes = userStore.currentUser.subscriberInfo?.carbLicenseDistributionMethodCodes;
  let webrootUserRetailCodes = userStore.currentUser.subscriberInfo?.wrLicenseDistributionMethodCodes;
  if (wrLicenseDistributionMethodCode) {
    webrootUserRetailCodes = [wrLicenseDistributionMethodCode];
  }
  const combinedRetailCodes: string[] = [];

  if (safeUserRetailCodes) {
    combinedRetailCodes.push(...safeUserRetailCodes);
  }

  if (webrootUserRetailCodes) {
    combinedRetailCodes.push(...webrootUserRetailCodes);
  }

  //If there is only 1 retail code that means there is one retail type for this user and we can use the config as is
  if (combinedRetailCodes.length === 1 && displayConfig[combinedRetailCodes[0]]) {
    showFeature = displayConfig[combinedRetailCodes[0]][feature];
  } else {
    if (alwaysUsePerSubIfMultipleSubs.some(l => l === feature)) {
      const show: boolean[] = [];
      for (const item of combinedRetailCodes) {
        const config = displayConfig[item];
        if (config) {
          show.push(config[feature]);
        }
      }
      return !show.some(l => l === false);
    }
    //Multiple safe subscriptions from different retailers use the default for now.
    //Will need to add more logic at a later time to support different types of safe subs like resellers
    showFeature = defaultFeature[feature];
  }
  return showFeature;
}

//Used on a per subscription basis to hide or show features that impact only that subscription.
export function canDisplayPerSubscriptionFeature(feature: perSubscriptionFeatures, retailCode: string) {
  const retailDisplay = displayConfig[retailCode];
  if (retailDisplay) {
    return retailDisplay[feature];
  } else {
    //Can't find the retail code so use the default
    return defaultFeature[feature];
  }
}
