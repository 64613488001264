import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { logEvent } from "@/common/logger";
import { IEndpointConfig } from "../interfaces";
import * as Requests from "./requests";
import {
  //  IUser,
  IUserDetails,
  ISecurityQuestions,
  IUserPaymentHistory,
  IUserSecurityPhone,
  IUserSecurityPhoneValidation,
  IZuoraCreditCardFormParams,
  IUserSecurityPhoneRequestCode,
  IUserSecurityPhoneRequest,
  IPhoneCountryCodes,
  ISecurityQuestionsList,
  ICrossSitePersonResponse,
  ITrialSignupResponse,
  IUpdateSubscriberResponse,
  IUpdateSubscriberRequest,
  ISubscriberInfo,
  ISubscriberDownloadsInfo,
  IAddKeycodeResponse,
  IInstallRequest,
  ISubscription,
  ILastPassInfo,
  ILastPassProvisionResponse,
  IOutstandingInvoices,
  IPayOutstandingInvoicesRequest,
  IPayOutstandingInvoicesResponse,
  IActivationCodeResponse,
  IAutoRenewUpdateRequest,
  IAutoRenewDiscountRequest,
  IApplyActivationCodeResponse,
  IWebrootRenewUrlResponse,
  ISubscriberVatInfo,
  IVatInfoResponse,
  IClientException,
  IPasswordHintResponse,
  IBackupSummaryResponse,
  IPendingVerificationEmailResponse,
  IRfaSessionResponse,
  ISkyWebrootDeviceResponse,
  IUpdateWebrootDeviceRequest,
  IAllstateResponse,
  IAllstateRequest,
  IAllstateUpdateRequest,
  ILoggedInUser,
  IRefreshSubscriberResponse,
  ILanguageResponse,
  IWebrootUserInformation,
  IProductCatalogResponse,
  ICountriesInfo,
  ICourierRecoveryRequest,
  ICourierRecoveryResponse,
  IGetPendingRequestToManageResponse,
  IManageCustomerByResellerRequest,
  IInvoiceResponse,
  IShoppingCart,
  ISetLanguagePreferenceRequest,
  IUnauthenticatedSubscription,
  IGetPaymentMethodsResponse,
  ICheckEmailRequest,
  IHasRecentPurchaseResponse,
  INewAccountInfoResponse,
  INewAccountUpdateResponse,
  IUserAccountCreationResponse,
  IPayPalApprovalResponse,
  IPayPalAgreementTokenResponse,
  IPayPalAgreementTokenRequest,
  IActivationCodePurchaseRequest,
  IActivationCodePurchaseResponse,
  IPaymentMethod,
  IAutoRenewRequest,
  ILicenseKeyInfo,
  IPurchaseKeycode,
  IUserSecurityPhoneValidationAndTrustClientResponse,
  IRemoveWebrootDeviceRequest,
  IVendorLicenseKey,
  IPureVpnLatestVersionDetailsResponse,
  IInvoice,
} from "./interfaces";

import {
  ICssbDetailsRequest,
  ICssbDownloadInfoRequest,
  ICssbDownloadInfoResponse,
  ICssbDownloadUrlRequest,
  ICssbRfaInProgressDownloadInfoResponse,
  ICssbRfaResponse,
  ICssbServerName,
} from "@/components/FileAccess/interfaces";

import { HTTP_STATUS_FORBIDDEN, HTTP_STATUS_UNAUTHORIZED, MAX_API_WAIT_DURATION } from "@/define";
import { useUserStore } from "@/stores/user";
import { getUrl } from "@/common/getUrl";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { logout } from "@/common/logout";
import { DeviceType } from "@/globalEnums";

let cpLoginUrl = getUrl("CP_LOGIN");
function redirectToLogin(returnUrl?: string) {
  // If redirect is coming from verifyEmail, override redirect to root
  if (returnUrl?.includes("verifyEmail")) {
    returnUrl = window.location.origin;
  }

  window.location.href = `${cpLoginUrl}${returnUrl ? "?returnUrl=" + encodeURIComponent(returnUrl) : ""}`;
}

function refreshApiIndexUrls() {
  cpLoginUrl = getUrl("CP_LOGIN");
}

registerRefreshFunction(refreshApiIndexUrls, true);
export interface IUnifiedPortalAPI {
  hasLoginCookie: () => boolean;
  // login: (email: string, password: string, captchaToken?: string) => Promise<AxiosResponse<ILoginResponse>>;
  logout: () => Promise<void>;
  // setToken: (token: string | IGetToken) => void;
  getUser: (fromCreateAccount?: boolean) => Promise<AxiosResponse<ILoggedInUser>>;
  getUserDetails: () => Promise<AxiosResponse<IUserDetails>>;
  getUserPaymentMethods: () => Promise<AxiosResponse<IGetPaymentMethodsResponse>>;
  getUserPaymentMethodGuest: (userGuid: string, paymentMethodId: string) => Promise<AxiosResponse<IPaymentMethod>>;
  getUserPaymentHistory: () => Promise<AxiosResponse<IUserPaymentHistory>>;

  getInvoiceDetails: (invoiceNumber: string) => Promise<AxiosResponse<IInvoice>>;

  getSubscriberDownloadsInfo: () => Promise<AxiosResponse<ISubscriberDownloadsInfo>>;
  validateAndUpdatePassword: (currentPassword: string, newPassword: string) => Promise<AxiosResponse<boolean>>;
  validateCastleTokenForProtectedFiles: (token: string) => Promise<AxiosResponse<boolean>>;
  updateUserPersonalInfo: (user: IUpdateSubscriberRequest) => Promise<AxiosResponse<IUpdateSubscriberResponse>>;
  updateComputerDescription: (computerId: number, computerDescription: string) => Promise<AxiosResponse<boolean>>;
  updateAutoRenewOption: (autoRenewUpdateRequest: IAutoRenewUpdateRequest) => Promise<AxiosResponse<boolean>>;
  setRenewalDiscount: (
    zuoraSubscriptionNumber: string,
    autoRenewDiscountRequest: IAutoRenewDiscountRequest
  ) => Promise<AxiosResponse<void>>;
  cancelSubscription: (subscriptionNumber: string) => Promise<AxiosResponse<boolean>>;
  getSecurityQuestionsList: (language: string) => Promise<AxiosResponse<ISecurityQuestionsList>>;
  setSecurityQuestions: (securityQuestions: ISecurityQuestions, language: string) => Promise<AxiosResponse<void>>;
  setDefaultCreditCard: (creditCardId: string) => Promise<AxiosResponse<void>>;
  removeCreditCard: (creditCardId: string) => Promise<AxiosResponse<void>>;
  removeAndReplaceCreditCard: (creditCardId: string, newDefaultCreditCardId: string) => Promise<AxiosResponse<void>>;
  getSecurityQuestions: () => Promise<AxiosResponse<ISecurityQuestions>>;
  getUserSecurityPhone: () => Promise<AxiosResponse<IUserSecurityPhone>>;
  requestVerificationCode: (personId: number, method: string) => Promise<AxiosResponse<IUserSecurityPhoneRequestCode>>;
  requestVerificationCodeWithCastleScore: (
    method: string,
    token: string
  ) => Promise<AxiosResponse<IUserSecurityPhoneRequestCode>>;
  sendVerificationCode: (
    personId: number,
    verificationCode: string
  ) => Promise<AxiosResponse<IUserSecurityPhoneValidation>>;
  sendVerificationCodeAndTrustClient: (
    verificationCode: string,
    isTokenLongTrusted: boolean,
    source: string
  ) => Promise<AxiosResponse<IUserSecurityPhoneValidationAndTrustClientResponse>>;
  setSecurityPhone: (phone: IUserSecurityPhoneRequest) => Promise<AxiosResponse<IUserSecurityPhoneValidation>>;
  deleteSecurityPhone: () => Promise<AxiosResponse<void>>;
  getNewAccountStatus: (guid: string) => Promise<AxiosResponse<INewAccountInfoResponse>>;
  setNewAccountPassword: (guid: string, newPassword: string) => Promise<AxiosResponse<INewAccountUpdateResponse>>;
  getPhoneCountryCodes: () => Promise<AxiosResponse<IPhoneCountryCodes[]>>;
  resetPassword: (personId: number, newPassword: string) => Promise<AxiosResponse<void>>;
  addKeycode: (keycode: string, orderHeaderToken?: string) => Promise<AxiosResponse<IAddKeycodeResponse>>;
  getZuoraCreditCardFormParams: (
    userGuid: string,
    formId: string,
    requestToken: string
  ) => Promise<AxiosResponse<IZuoraCreditCardFormParams>>;
  getZuoraCreditCardFormEmailParams: (
    email: string,
    formId: string,
    requestToken: string
  ) => Promise<AxiosResponse<IZuoraCreditCardFormParams>>;
  getZuoraCreditCardFormComputerIdParams: (
    computerId: number,
    formId: string,
    requestToken: string
  ) => Promise<AxiosResponse<IZuoraCreditCardFormParams>>;
  getZuoraCreditCardFormLicenseKeyParams: (
    licenseKey: string,
    formId: string,
    requestToken: string
  ) => Promise<AxiosResponse<IZuoraCreditCardFormParams>>;
  getCarboniteVersionNumber: () => Promise<AxiosResponse<string>>;
  getWebrootUserInfo: (webrootKey: string) => Promise<AxiosResponse<ICrossSitePersonResponse>>;
  //used for create account for Webroot
  createAccount: (
    email: string,
    password: string,
    keycode: string,
    ecommKey: string,
    recaptchaToken: string,
    languageCode: string
  ) => Promise<AxiosResponse<ITrialSignupResponse>>;
  getSubscriberInfo: () => Promise<AxiosResponse<ISubscriberInfo>>;
  getSafeClientURL: (request: IInstallRequest) => Promise<AxiosResponse<string>>;
  getSubscriptions: () => Promise<AxiosResponse<ISubscription[]>>;
  getLatestSubscriptions: () => Promise<AxiosResponse<ISubscription[]>>;
  releaseSubscription: (deviceId: number) => Promise<AxiosResponse<string>>;
  getLastPassInfo: () => Promise<AxiosResponse<ILastPassInfo[]>>;
  provisionLastPass: (keycode: string) => Promise<AxiosResponse<ILastPassProvisionResponse>>;
  getWebrootKeycode: (deviceId: number, zuoraSubscriptionNumber: string) => Promise<AxiosResponse<string>>;
  getOutstandingInvoices: () => Promise<AxiosResponse<IOutstandingInvoices>>;
  payOutstandingInvoices: (
    payOutstandingInvoicesRequest: IPayOutstandingInvoicesRequest
  ) => Promise<AxiosResponse<IPayOutstandingInvoicesResponse>>;
  verifyActivationCode: (activationCode: string) => Promise<AxiosResponse<IActivationCodeResponse>>;
  applyActivationCode: (
    activationCode: string,
    computerId: number
  ) => Promise<AxiosResponse<IApplyActivationCodeResponse>>;
  getWebrootRenewUrl: (keycode: string) => Promise<AxiosResponse<IWebrootRenewUrlResponse>>;
  getAvailableActivationCodes: () => Promise<AxiosResponse<string[]>>;
  optInToAutomaticVideoBackup: (deviceId: number) => Promise<AxiosResponse<void>>;
  getConsumerVatInfoAsync: (paymentMethodId: string | null) => Promise<AxiosResponse<ISubscriberVatInfo>>;
  setVatInfo: (vatId: string, countryCode: string) => Promise<AxiosResponse<IVatInfoResponse>>;
  putClientException: (ce: IClientException) => Promise<AxiosResponse<void>>;
  addEncryptionKey: (
    computerId: number,
    encryptionKey: string,
    encryptionKeyPassword: string | null
  ) => Promise<AxiosResponse<void>>;
  getEncryptionKeyPasswordHint: (computerId: number) => Promise<AxiosResponse<IPasswordHintResponse>>;
  verifyEmail: (verificationCode: string) => Promise<AxiosResponse<void>>;
  sendNewVerificationEmail: (verificationCode: string) => Promise<AxiosResponse<void>>;
  updateEmail: (email: string, password: string) => Promise<AxiosResponse<string>>;
  resendVerificationEmail: () => Promise<AxiosResponse<string>>;
  getBackupSummary: () => Promise<AxiosResponse<IBackupSummaryResponse>>;
  getPendingVerificationEmail: () => Promise<AxiosResponse<IPendingVerificationEmailResponse>>;
  cancelPendingEmailValidation: () => Promise<AxiosResponse<void>>;
  getRfaSession: (computerId: string) => Promise<AxiosResponse<IRfaSessionResponse>>;
  getSkyWebrootDevices: () => Promise<AxiosResponse<ISkyWebrootDeviceResponse>>;
  updateWebrootDevice: (request: IUpdateWebrootDeviceRequest) => Promise<AxiosResponse<void>>;
  removeWebrootDevice: (request: IRemoveWebrootDeviceRequest, keycode: string) => Promise<AxiosResponse<void>>;
  getAllstateSubscriptions: () => Promise<AxiosResponse<IAllstateResponse[]>>;
  addAllstateSubscription: (request: IAllstateRequest) => Promise<AxiosResponse<IAllstateResponse>>;
  updateAllstateSubscriptions: (request: IAllstateUpdateRequest) => Promise<AxiosResponse<void>>;
  getCssbBackupSets: (computerName: string, sort: string, orderBy: string) => Promise<AxiosResponse<ICssbRfaResponse>>;
  getCssbBackupRuns: (
    computerName: string,
    backupSetName: string,
    sort: string,
    orderBy: string
  ) => Promise<AxiosResponse<ICssbRfaResponse>>;
  getCssbBackupRunDetails: (request: ICssbDetailsRequest) => Promise<AxiosResponse<ICssbRfaResponse>>;
  addCssbDownloadInfo: (request: ICssbDownloadInfoRequest) => Promise<AxiosResponse<ICssbDownloadInfoResponse>>;
  getCssbDownloadUrl: (request: ICssbDownloadUrlRequest) => Promise<AxiosResponse<ICssbDownloadInfoResponse>>;
  cancelCssbDownload: (request: ICssbDownloadUrlRequest) => Promise<AxiosResponse<ICssbDownloadInfoResponse>>;
  getInProgressCssbDownloadRequests: () => Promise<AxiosResponse<ICssbRfaInProgressDownloadInfoResponse[]>>;
  refreshSubscriber: () => Promise<AxiosResponse<IRefreshSubscriberResponse>>;
  getWebrootPIIInformation: (keycode: string) => Promise<AxiosResponse<IWebrootUserInformation>>;
  getCssbServers: () => Promise<AxiosResponse<ICssbServerName[]>>;
  setLangCookie: (lang: string) => Promise<AxiosResponse<ILanguageResponse>>;
  getLangCookie: () => Promise<AxiosResponse<ILanguageResponse>>;
  getCountries: () => Promise<AxiosResponse<ICountriesInfo>>;
  submitCourierRecovery: (request: ICourierRecoveryRequest) => Promise<AxiosResponse<ICourierRecoveryResponse>>;
  getPendingRequestToManageAsync: () => Promise<AxiosResponse<IGetPendingRequestToManageResponse>>;
  manageCustomerByResellerAsync: (request: IManageCustomerByResellerRequest) => Promise<AxiosResponse<string>>;
  getInvoice: (invoiceId: string) => Promise<AxiosResponse<IInvoiceResponse>>;
  purchaseShoppingCart: (request: IShoppingCart) => Promise<AxiosResponse<IShoppingCart>>;
  getShoppingCart: (brand: string, userGuid?: string) => Promise<AxiosResponse<IShoppingCart>>;
  getShoppingCartByOrderCode: (orderCode: string) => Promise<AxiosResponse<IShoppingCart>>;
  getShoppingCartByEnrollmentGuid: (
    enrollmentGuid: string,
    requestToken: string
  ) => Promise<AxiosResponse<IShoppingCart>>;
  saveShoppingCart: (request: IShoppingCart) => Promise<AxiosResponse<IShoppingCart>>;
  getProductCatalog: (type: string, campaignId?: number) => Promise<AxiosResponse<IProductCatalogResponse>>;
  setLanguagePreference: (request: ISetLanguagePreferenceRequest) => Promise<AxiosResponse<void>>;
  getSubscriptionForUnauthorizedFlow: (deviceId: number) => Promise<AxiosResponse<IUnauthenticatedSubscription>>;
  //used for create preliminary account for carbonite non trial purchase
  checkExistingEmail: (request: ICheckEmailRequest) => Promise<AxiosResponse<IUserAccountCreationResponse>>;
  silentLogout: () => Promise<AxiosResponse<void>>;
  checkHasRecentPurchase: (computerId: number) => Promise<AxiosResponse<IHasRecentPurchaseResponse>>;
  getProspectId: (ProspectGuid: string) => Promise<AxiosResponse<number>>;
  payPalApprove: (tokenId: string, brand: string) => Promise<AxiosResponse<IPayPalApprovalResponse>>;
  getActivationCode: (
    request: IActivationCodePurchaseRequest
  ) => Promise<AxiosResponse<IActivationCodePurchaseResponse>>;
  createPayPalAgreementToken: (
    request: IPayPalAgreementTokenRequest
  ) => Promise<AxiosResponse<IPayPalAgreementTokenResponse>>;
  setAutoRenew: (request: IAutoRenewRequest) => Promise<AxiosResponse<boolean>>;
  getLicenseKeyInfo: (licenseKey: string, upgradeSku?: string) => Promise<AxiosResponse<ILicenseKeyInfo>>;
  getLicenseKeyFromVendorKey: (licenseKey: string) => Promise<AxiosResponse<IVendorLicenseKey>>;
  getPurchaseKeycodes: (invoiceNumber: string, orderCode: string) => Promise<AxiosResponse<IPurchaseKeycode[]>>;
  getPureVpnLatestVersionDetails: (
    deviceType: DeviceType
  ) => Promise<AxiosResponse<IPureVpnLatestVersionDetailsResponse>>;
}
// export interface IUnifiedPortalAPIMockFlags {
//   login?: boolean;
//   getUser?: boolean;
//   getUserDetails?: boolean;
//   getSecurityQuestionsList?: boolean;
//   getSubscriberDownloadsInfo?: boolean;
//   getSubscriptions?: boolean;
//   verifyActivationCode?: boolean;
//   applyActivationCode?: boolean;
//   getWebrootRenewUrl?: boolean;
//   getAvailableActivationCodes?: boolean;
//   getSubscriberInfo?: boolean;
//   addCssbDownloadInfo?: boolean;
//   getCssbDownloadUrl?: boolean;
//   cancelCssbDownload?: boolean;
//   getInProgressCssbDownloadRequests?: boolean;
//   mockGetProductCatalog?: boolean;
// }
export class UnifiedPortalAPI implements IUnifiedPortalAPI {
  public readonly axios: AxiosInstance;

  // flag set by the getUser call meant to signify the createAccount page is handling the redirect instead of
  // private fromCreateAccount: boolean = false;

  protected _hasLoginCookie = false;

  constructor(private upEndpoint: IEndpointConfig) {
    //logEvent(`Initializing`, "UnifiedPortalAPI", this.upEndpoint);
    this.axios = axios.create({
      withCredentials: true,
      withXSRFToken: true,
    });

    this.axios.interceptors.response.use(
      res => {
        if (!/api\/cp\/computers\/\d*\/rfasession/.test(res.request.responseURL)) {
          const userStore = useUserStore();
          userStore.lastRequestTime = new Date().getTime();
        }
        this._hasLoginCookie = true; // do we HAVE any unauthenticated requests where this shouldn't happen?

        return res;
      },
      e => {
        const err = e as AxiosError;
        logEvent("failed call", "UnifiedPortalAPI", { response: err.response, request: err.request });
        //both 'Unauthorized' and 'Forbidden' from Api could indicate that the user is not logged in
        if (err.response?.status === HTTP_STATUS_UNAUTHORIZED || err.response?.status === HTTP_STATUS_FORBIDDEN) {
          if (err.response?.status === HTTP_STATUS_FORBIDDEN && this._hasLoginCookie) {
            logout();
          }
          // if user is coming from create account page then this localStorage value will be true , otherwise it will be null
          this._hasLoginCookie = false; // sort of redundant as we are redirecting and ending the world...
          // do NOT handle redirect here if fromCreateAccount has been set
          if (err.response?.config?.headers) {
            const fromCreateAccount = err.response?.config?.headers["FROM_C_A"];
            if (fromCreateAccount !== "1") {
              redirectToLogin(window.location.href);
            }
          }
        }
        // throw error so can be caught later on in router (or elsewhere)
        throw err;
      }
    );

    // add the xsrf token, if there is one
    this.axios.interceptors.request.use(config => {
      const cookies = document.cookie.split(";");

      if (config && config.headers) {
        for (const c of cookies) {
          const split = c?.split("=");
          if (split[0] === "XSRF-TOKEN") {
            config.headers["X-XSRF-TOKEN"] = split[1];
            break;
          }
        }
      }

      if (config && !config.timeout) {
        config.timeout = MAX_API_WAIT_DURATION;
      }
      return config;
    });
  }

  // doesn't do much now, but helpful to add in logging or other hooks
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private send = async <T = any>(request: AxiosRequestConfig) => {
    return this.axios.request<T>(request);
  };

  public hasLoginCookie() {
    return this._hasLoginCookie;
  }

  // public login = async (email: string, password: string, recaptchaToken?: string) => {
  //     const res = await this.send<ILoginResponse>(Requests.postLoginRequest(this.upEndpoint, email, password, recaptchaToken));
  //     this._authToken = res.data.authenticationToken;

  //     // TODO: this is still ugly and would like to use cookies properly
  //     // set __upauth as default param
  //     this.axios.defaults.params = {
  //         "__upauth": this._authToken,
  //         "__cpauth": this._authToken
  //     };

  //     // this.axios.defaults.headers = {
  //     //     "__upauth": this._authToken,
  //     //     "__cpauth": this._authToken
  //     // }

  //     return res;
  // }

  public logout = async () => {
    this.send<void>(Requests.logout(this.upEndpoint));
  };

  public getUser = async (fromCreateAccount = false) => {
    const req = Requests.getUser(this.upEndpoint);
    req.headers = req.headers || {};
    if (fromCreateAccount) req.headers["FROM_C_A"] = "1"; // embed fromCreateAccount info in request headers to be picked up by interceptor for this request properly
    return this.send<ILoggedInUser>(req);
  };

  public getUserDetails = async () => {
    return this.send<IUserDetails>(Requests.getUserDetails(this.upEndpoint));
  };

  public getUserPaymentMethods = async () => {
    return this.send<IGetPaymentMethodsResponse>(Requests.getUserPaymentMethods(this.upEndpoint));
  };

  public getUserPaymentMethodGuest = async (userGuid: string, paymentMethodId: string) => {
    return this.send(Requests.getUserPaymentMethodGuest(this.upEndpoint, userGuid, paymentMethodId));
  };

  public getUserPaymentHistory = async () => {
    return this.send<IUserPaymentHistory>(Requests.getUserPaymentHistory(this.upEndpoint));
  };

  public getInvoiceDetails = async (invoiceNumber: string) => {
    return this.send<IInvoice>(Requests.getInvoiceDetails(this.upEndpoint, invoiceNumber));
  };

  public getSubscriberDownloadsInfo = async () => {
    return this.send<ISubscriberDownloadsInfo>(Requests.getSubscriberDownloadsInfo(this.upEndpoint));
  };

  public getSecurityQuestionsList = async (language: string) => {
    return this.send<ISecurityQuestionsList>(Requests.getSecurityQuestionsList(this.upEndpoint, language));
  };

  public getCarboniteVersionNumber = async () => {
    return this.send<string>(Requests.getCarboniteVersionNumber(this.upEndpoint));
  };

  public setSecurityQuestions = async (securityQuestions: ISecurityQuestions, language: string) => {
    return this.send<void>(Requests.setSecurityQuestions(this.upEndpoint, securityQuestions, language));
  };

  public setDefaultCreditCard = async (creditCardId: string) => {
    return this.send<void>(Requests.setDefaultCreditCard(this.upEndpoint, creditCardId));
  };

  public removeCreditCard = async (creditCardId: string) => {
    return this.send<void>(Requests.removeCreditCard(this.upEndpoint, creditCardId));
  };

  public removeAndReplaceCreditCard = async (creditCardId: string, newDefaultCreditCardId: string) => {
    return this.send<void>(Requests.removeAndReplaceCreditCard(this.upEndpoint, creditCardId, newDefaultCreditCardId));
  };

  public checkExistingEmail = async (email: ICheckEmailRequest) => {
    return this.send<IUserAccountCreationResponse>(Requests.checkExistingEmail(this.upEndpoint, email));
  };

  public getSecurityQuestions = async () => {
    return this.send<ISecurityQuestions>(Requests.getSecurityQuestions(this.upEndpoint));
  };

  public validateAndUpdatePassword = async (currentPassword: string, newPassword: string) => {
    return this.send<boolean>(Requests.validateAndUpdatePassword(this.upEndpoint, currentPassword, newPassword));
  };

  public validateCastleTokenForProtectedFiles = async (token: string) => {
    return this.send<boolean>(Requests.validateCastleTokenForProtectedFiles(this.upEndpoint, token));
  };

  public updateUserPersonalInfo = async (user: IUpdateSubscriberRequest) => {
    return this.send<IUpdateSubscriberResponse>(Requests.updateUserPersonalInfo(this.upEndpoint, user));
  };

  public updateComputerDescription = async (computerId: number, computerDescription: string) => {
    return this.send<boolean>(Requests.updateComputerDescription(this.upEndpoint, computerId, computerDescription));
  };

  public updateAutoRenewOption = async (autoRenewUpdateRequest: IAutoRenewUpdateRequest) => {
    return this.send<boolean>(Requests.updateAutoRenewOption(this.upEndpoint, autoRenewUpdateRequest));
  };

  public setRenewalDiscount = async (
    zuoraSubscriptionNumber: string,
    enableAutoRenewDiscount: IAutoRenewDiscountRequest
  ) => {
    return this.send<void>(
      Requests.setRenewalDiscount(this.upEndpoint, zuoraSubscriptionNumber, enableAutoRenewDiscount)
      // enableAutoRenewDiscount: true results in setting renewalDiscount: 25
    );
  };

  public cancelSubscription = async (subscriptionNumber: string) => {
    return this.send<boolean>(Requests.cancelSubscription(this.upEndpoint, subscriptionNumber));
  };

  // public getUserSecurityPhoneAnonymous = async (personId: number) => {
  //     return this.send<IUserSecurityPhone>(Requests.getUserSecurityPhoneAnonymous(this.upEndpoint, personId));
  // }

  public getUserSecurityPhone = async () => {
    return this.send<IUserSecurityPhone>(Requests.getUserSecurityPhone(this.upEndpoint));
  };

  public getZuoraCreditCardFormParams = async (userGuid: string, formId: string, requestToken: string) => {
    return this.send<IZuoraCreditCardFormParams>(
      Requests.getZuoraCreditCardFormParams(this.upEndpoint, userGuid, formId, requestToken)
    );
  };

  public getZuoraCreditCardFormEmailParams = async (email: string, formId: string, requestToken: string) => {
    return this.send<IZuoraCreditCardFormParams>(
      Requests.getZuoraCreditCardFormEmailParams(this.upEndpoint, email, formId, requestToken)
    );
  };

  public getZuoraCreditCardFormComputerIdParams = async (computerId: number, formId: string, requestToken: string) => {
    return this.send<IZuoraCreditCardFormParams>(
      Requests.getZuoraCreditCardFormComputerIdParams(this.upEndpoint, computerId, formId, requestToken)
    );
  };

  public getZuoraCreditCardFormLicenseKeyParams = async (licenseKey: string, formId: string, requestToken: string) => {
    return this.send<IZuoraCreditCardFormParams>(
      Requests.getZuoraCreditCardFormLicenseKeyParams(this.upEndpoint, licenseKey, formId, requestToken)
    );
  };

  public requestVerificationCode = async (personId: number, method: string) => {
    return this.send<IUserSecurityPhoneRequestCode>(
      Requests.requestVerificationCode(this.upEndpoint, personId, method)
    );
  };

  public requestVerificationCodeWithCastleScore = async (method: string, castleToken: string) => {
    return this.send<IUserSecurityPhoneRequestCode>(
      Requests.requestVerificationCodeWithCastleScore(this.upEndpoint, method, castleToken)
    );
  };

  public sendVerificationCode = async (personId: number, verificationCode: string) => {
    return this.send<IUserSecurityPhoneValidation>(
      Requests.sendVerificationCode(this.upEndpoint, personId, verificationCode)
    );
  };

  public sendVerificationCodeAndTrustClient = async (
    verificationCode: string,
    isTokenLongTrusted: boolean,
    source: string
  ) => {
    return this.send<IUserSecurityPhoneValidationAndTrustClientResponse>(
      Requests.sendVerificationCodeAndTrustClient(this.upEndpoint, verificationCode, isTokenLongTrusted, source)
    );
  };

  public setSecurityPhone = async (phone: IUserSecurityPhoneRequest) => {
    return this.send<IUserSecurityPhoneValidation>(Requests.setSecurityPhone(this.upEndpoint, phone));
  };

  public deleteSecurityPhone = async () => {
    return this.send<void>(Requests.deleteSecurityPhone(this.upEndpoint));
  };

  public getNewAccountStatus = async (guid: string) => {
    return this.send<INewAccountInfoResponse>(Requests.GetNewAccountStatus(this.upEndpoint, guid));
  };

  public setNewAccountPassword = async (guid: string, newPassword: string) => {
    return this.send<INewAccountUpdateResponse>(Requests.setNewAccountPassword(this.upEndpoint, guid, newPassword));
  };

  public getPhoneCountryCodes = async () => {
    return this.send<IPhoneCountryCodes[]>(Requests.getPhoneCountryCodes(this.upEndpoint));
  };

  public resetPassword = async (personId: number, newPassword: string) => {
    return this.send<void>(Requests.resetPassword(this.upEndpoint, personId, newPassword));
  };

  public getWebrootUserInfo = async (webrootKey: string) => {
    return this.send<ICrossSitePersonResponse>(Requests.getWebrootUserInfo(this.upEndpoint, webrootKey));
  };

  public createAccount = async (
    email: string,
    password: string,
    keycode: string,
    ecommKey: string,
    recaptchaToken: string,
    languageCode: string
  ) => {
    return this.send<ITrialSignupResponse>(
      Requests.createAccount(this.upEndpoint, email, password, keycode, ecommKey, recaptchaToken, languageCode)
    );
  };

  public getCountries = async () => {
    return this.send<ICountriesInfo>(Requests.getCountries(this.upEndpoint));
  };

  public getInvoice = async (invoiceId: string) => {
    return this.send<IInvoiceResponse>(Requests.getInvoice(this.upEndpoint, invoiceId));
  };

  public addKeycode = async (keycode: string, orderHeaderToken?: string) => {
    return this.send<IAddKeycodeResponse>(Requests.addKeycode(this.upEndpoint, keycode, orderHeaderToken));
  };

  public getSubscriberInfo = async () => {
    return this.send<ISubscriberInfo>(Requests.getSubscriberInfo(this.upEndpoint));
  };

  public refreshSubscriber = async () => {
    return this.send<IRefreshSubscriberResponse>(Requests.refreshSubscriber(this.upEndpoint));
  };

  public getSafeClientURL = async (request: IInstallRequest) => {
    return this.send<string>(Requests.getSafeClientURL(request, this.upEndpoint));
  };

  public getSubscriptions = async () => {
    return this.send(Requests.getSubscriptions(this.upEndpoint));
  };

  public getLatestSubscriptions = async () => {
    return this.send(Requests.getLatestSubscriptions(this.upEndpoint));
  };

  public releaseSubscription = async (deviceId: number) => {
    return this.send<string>(Requests.releaseSubscription(this.upEndpoint, deviceId));
  };

  public getLastPassInfo = async () => {
    return this.send<ILastPassInfo[]>(Requests.getLastPassInfo(this.upEndpoint));
  };

  public provisionLastPass = async (keyCode: string) => {
    return this.send<ILastPassProvisionResponse>(Requests.provisionLastPass(this.upEndpoint, keyCode));
  };
  public getWebrootKeycode = async (deviceId: number, zuoraSubscriptionNumber: string) => {
    return this.send(Requests.getWebrootKeycode(this.upEndpoint, deviceId, zuoraSubscriptionNumber));
  };
  public verifyActivationCode = async (activationCode: string) => {
    return this.send(Requests.verifyActivationCode(this.upEndpoint, activationCode));
  };
  public applyActivationCode = async (activationCode: string, computerId: number) => {
    return this.send(Requests.applyActivationCode(this.upEndpoint, activationCode, computerId));
  };

  public payOutstandingInvoices = async (payOutstandingInvoicesRequest: IPayOutstandingInvoicesRequest) => {
    return this.send(Requests.payOutstandingInvoices(this.upEndpoint, payOutstandingInvoicesRequest));
  };

  public getOutstandingInvoices = async () => {
    return this.send(Requests.getOutstandingInvoices(this.upEndpoint));
  };

  public getWebrootRenewUrl = async (keycode: string) => {
    return this.send(Requests.getWebrootRenewUrl(this.upEndpoint, keycode));
  };

  public getAvailableActivationCodes = async () => {
    return this.send(Requests.getAvailableActivationCodes(this.upEndpoint));
  };

  public optInToAutomaticVideoBackup = async (deviceId: number) => {
    return this.send<void>(Requests.optInToAutomaticVideoBackup(this.upEndpoint, deviceId));
  };

  public getConsumerVatInfoAsync = async (paymentMethodId: string | null) => {
    return this.send(Requests.getConsumerVatInfoAsync(this.upEndpoint, paymentMethodId));
  };

  public setVatInfo = async (vatId: string, countryCode: string) => {
    return this.send<IVatInfoResponse>(Requests.setVatInfo(this.upEndpoint, vatId, countryCode));
  };

  public putClientException = async (ce: IClientException) => {
    return this.send<void>(Requests.putClientException(this.upEndpoint, ce));
  };

  public addEncryptionKey = async (computerId: number, encryptionKey: string, encryptionKeyPassword: string | null) => {
    return this.send<void>(
      Requests.addEncryptionKey(this.upEndpoint, computerId, encryptionKey, encryptionKeyPassword)
    );
  };

  public getEncryptionKeyPasswordHint = async (computerId: number) => {
    return this.send<IPasswordHintResponse>(Requests.getEncryptionKeyPasswordHint(this.upEndpoint, computerId));
  };

  public verifyEmail = async (verificationCode: string) => {
    return this.send<void>(Requests.verifyEmail(this.upEndpoint, verificationCode));
  };

  public sendNewVerificationEmail = async (verificationCode: string) => {
    return this.send<void>(Requests.sendNewVerificationEmail(this.upEndpoint, verificationCode));
  };

  public cancelPendingEmailValidation = async () => {
    return this.send<void>(Requests.cancelPendingEmailValidation(this.upEndpoint));
  };

  public updateEmail = async (email: string, password: string) => {
    return this.send<string>(Requests.updateEmail(this.upEndpoint, email, password));
  };

  public resendVerificationEmail = async () => {
    return this.send<string>(Requests.resendVerificationEmail(this.upEndpoint));
  };

  public getBackupSummary = async () => {
    return this.send<IBackupSummaryResponse>(Requests.getBackupSummary(this.upEndpoint));
  };

  public getPendingVerificationEmail = async () => {
    return this.send<IPendingVerificationEmailResponse>(Requests.getPendingVerificationEmail(this.upEndpoint));
  };

  public getRfaSession = async (computerId: string) => {
    return this.send<IRfaSessionResponse>(Requests.getRfaSession(this.upEndpoint, computerId));
  };

  public getSkyWebrootDevices = async () => {
    return this.send<ISkyWebrootDeviceResponse>(Requests.getSkyWebrootDevices(this.upEndpoint));
  };

  public updateWebrootDevice = async (request: IUpdateWebrootDeviceRequest) => {
    return this.send<void>(Requests.updateWebrootDevice(this.upEndpoint, request));
  };

  public removeWebrootDevice = async (request: IRemoveWebrootDeviceRequest, keycode) => {
    return this.send<void>(Requests.removeWebrootDevice(this.upEndpoint, request, keycode));
  };

  public getAllstateSubscriptions = async () => {
    return this.send<IAllstateResponse[]>(Requests.getAllstateSubscriptions(this.upEndpoint));
  };

  public addAllstateSubscription = async (request: IAllstateRequest) => {
    return this.send<IAllstateResponse>(Requests.addAllstateSubscription(this.upEndpoint, request));
  };

  public updateAllstateSubscriptions = async (request: IAllstateUpdateRequest) => {
    return this.send<void>(Requests.updateAllstateSubscriptions(this.upEndpoint, request));
  };

  public getCssbBackupSets = async (computerName: string, sort: string, orderBy: string) => {
    return this.send<ICssbRfaResponse>(Requests.getCssbBackupSets(this.upEndpoint, computerName, sort, orderBy));
  };

  public getCssbBackupRuns = async (computerName: string, backupSetName: string, sort: string, orderBy: string) => {
    return this.send<ICssbRfaResponse>(
      Requests.getCssbBackupRuns(this.upEndpoint, computerName, backupSetName, sort, orderBy)
    );
  };

  public getCssbBackupRunDetails = async (request: ICssbDetailsRequest) => {
    return this.send<ICssbRfaResponse>(Requests.getCssbBackupRunDetails(this.upEndpoint, request));
  };

  public addCssbDownloadInfo = async (request: ICssbDownloadInfoRequest) => {
    return this.send<ICssbDownloadInfoResponse>(Requests.addCssbDownloadInfo(this.upEndpoint, request));
  };

  public getCssbDownloadUrl = async (request: ICssbDownloadUrlRequest) => {
    return this.send<ICssbDownloadInfoResponse>(Requests.getCssbDownloadUrl(this.upEndpoint, request));
  };

  public cancelCssbDownload = async (request: ICssbDownloadUrlRequest) => {
    return this.send<ICssbDownloadInfoResponse>(Requests.cancelCssbDownload(this.upEndpoint, request));
  };

  public getInProgressCssbDownloadRequests = async () => {
    return this.send<ICssbRfaInProgressDownloadInfoResponse[]>(
      Requests.getInProgressCssbDownloadRequests(this.upEndpoint)
    );
  };

  public getWebrootPIIInformation = async (keycode: string) => {
    return this.send<IWebrootUserInformation>(Requests.getWebrootPIIInformation(this.upEndpoint, keycode));
  };

  public getCssbServers = async () => {
    return this.send<ICssbServerName[]>(Requests.getCssbServers(this.upEndpoint));
  };

  public getLangCookie = async () => {
    return this.send<ILanguageResponse>(Requests.getLangCookie(this.upEndpoint));
  };

  public setLangCookie = async (lang: string) => {
    return this.send<ILanguageResponse>(Requests.setLangCookie(this.upEndpoint, lang));
  };

  public submitCourierRecovery = async (request: ICourierRecoveryRequest) => {
    return this.send<ICourierRecoveryResponse>(Requests.submitCourierRecovery(this.upEndpoint, request));
  };

  public getPendingRequestToManageAsync = async () => {
    return this.send<IGetPendingRequestToManageResponse>(Requests.getPendingRequestToManageAsync(this.upEndpoint));
  };

  public manageCustomerByResellerAsync = async (request: IManageCustomerByResellerRequest) => {
    return this.send<string>(Requests.manageCustomerByResellerAsync(this.upEndpoint, request));
  };

  public purchaseShoppingCart = async (request: IShoppingCart) => {
    return this.send<IShoppingCart>(Requests.purchaseShoppingCart(this.upEndpoint, request));
  };

  public getPurchaseKeycodes = async (invoiceNumber: string, orderCode: string) => {
    return this.send<IPurchaseKeycode[]>(Requests.getPurchaseKeycodes(this.upEndpoint, invoiceNumber, orderCode));
  };

  public getShoppingCart = async (brand: string, userGuid?: string) => {
    if (userGuid) {
      return this.send<IShoppingCart>(Requests.getShoppingCartForUser(this.upEndpoint, userGuid, brand));
    }

    return this.send<IShoppingCart>(Requests.getShoppingCart(this.upEndpoint, brand));
  };

  public saveShoppingCart = async (request: IShoppingCart) => {
    return this.send<IShoppingCart>(Requests.saveShoppingCart(this.upEndpoint, request));
  };

  public getProductCatalog = async (type: string, campaignId?: number) => {
    return this.send(Requests.getProductCatalog(this.upEndpoint, type, campaignId));
  };

  public setLanguagePreference = async (request: ISetLanguagePreferenceRequest) => {
    return this.send<void>(Requests.setLanguagePreference(this.upEndpoint, request));
  };

  public getSubscriptionForUnauthorizedFlow = async (deviceId: number) => {
    return this.send<IUnauthenticatedSubscription>(
      Requests.getSubscriptionForUnauthorizedFlow(this.upEndpoint, deviceId)
    );
  };

  public silentLogout = async () => {
    return this.send<void>(Requests.silentLogout());
  };

  public checkHasRecentPurchase = async (computerId: number) => {
    return this.send<IHasRecentPurchaseResponse>(Requests.checkHasRecentPurchase(this.upEndpoint, computerId));
  };

  public getProspectId = async (prospectGuid: string) => {
    return this.send<number>(Requests.getProspectId(this.upEndpoint, prospectGuid));
  };

  public payPalApprove = async (tokenId: string, brand: string) => {
    return this.send<IPayPalApprovalResponse>(Requests.payPalApprove(this.upEndpoint, tokenId, brand));
  };

  public createPayPalAgreementToken = async (request: IPayPalAgreementTokenRequest) => {
    return this.send<IPayPalAgreementTokenResponse>(Requests.createPayPalAgreementToken(this.upEndpoint, request));
  };

  public getActivationCode = async (request: IActivationCodePurchaseRequest) => {
    return this.send<IActivationCodePurchaseResponse>(Requests.getActivationCode(this.upEndpoint, request));
  };

  public getShoppingCartByOrderCode(orderCode: string): Promise<AxiosResponse<IShoppingCart>> {
    return this.send<IShoppingCart>(Requests.getShoppingCartByOrderCode(this.upEndpoint, orderCode));
  }

  public getShoppingCartByEnrollmentGuid(
    enrollmentGuid: string,
    requestToken: string
  ): Promise<AxiosResponse<IShoppingCart>> {
    return this.send<IShoppingCart>(
      Requests.getShoppingCartByEnrollmentGuid(this.upEndpoint, enrollmentGuid, requestToken)
    );
  }

  public setAutoRenew = async (request: IAutoRenewRequest) => {
    return this.send<boolean>(Requests.setAutoRenew(this.upEndpoint, request));
  };

  public getLicenseKeyInfo = (licenseKey: string, upgradeSku = ""): Promise<AxiosResponse<ILicenseKeyInfo>> => {
    return this.send<ILicenseKeyInfo>(Requests.getLicenseKeyInfo(this.upEndpoint, licenseKey, upgradeSku));
  };

  public getLicenseKeyFromVendorKey = (vendorKey: string): Promise<AxiosResponse<IVendorLicenseKey>> => {
    return this.send<IVendorLicenseKey>(Requests.getLicenseKeyFromVendorKey(this.upEndpoint, vendorKey));
  };

  public getPureVpnLatestVersionDetails = (
    deviceType: DeviceType
  ): Promise<AxiosResponse<IPureVpnLatestVersionDetailsResponse>> => {
    return this.send<IPureVpnLatestVersionDetailsResponse>(
      Requests.getPureVpnLatestVersionDetails(this.upEndpoint, deviceType)
    );
  };
}

//import { mockUserDetails, mockLoggedInUser } from "@/__mocks__/userDetails";
// import { mockCreditCards } from "@/__mocks__/CreditCards";
// import { mockPersonalInfoSecurityQuestionsList } from "@/__mocks__/personalInfo";
// import { mockUserPaymentHistory } from "@/__mocks__/userPaymentHistory";
// import { mockUserSecurityPhone } from "@/__mocks__/SecurityPhone";
// import { mockSubscriberDownloadsInfo } from "@/__mocks__/SubscriberInfo";
// import { mockSubscriptions } from "@/__mocks__/subscriptions";
// import { mockActivationCodeResponse } from "@/__mocks__/ActivationCodeResponse";
// import { mockSubscriberInfo } from "@/__mocks__/SubscriberInfo";
// import {
//   mockAddCssbDownloadInfo,
//   mockCancelCssbDownload,
//   mockGetCssbDownloadUrl,
//   mockInProcessDownloads,
// } from "@/__mocks__/CssbDownloadResponses";
// import { mockPlans } from "@/__mocks__/buyflow";

// export class UnifiedPortalAPIMock implements IUnifiedPortalAPI {
//   constructor(private upEndpoint: IEndpointConfig) {
//     console.debug(`Initializing UnifiedPortalAPIMock`, this.upEndpoint);
//     this.api = new UnifiedPortalAPI(this.upEndpoint);
//   }

//   private api: UnifiedPortalAPI;
//   public mock: boolean | IUnifiedPortalAPIMockFlags = {
//     //getWebrootRenewUrl: true,
//     //getSubscriptions: true,
//     //getAvailableActivationCodes: true
//     //getSubscriberInfo: true,
//     // addCssbDownloadInfo: true,
//     // getCssbDownloadUrl: true,
//     // getInProgressCssbDownloadRequests: true,
//     // cancelCssbDownload: true,
//     //mockGetProductCatalog: true,
//   };

//   private useMock = (functionName: string) => {
//     return this.mock && (typeof this.mock === "boolean" || (typeof this.mock === "object" && this.mock[functionName]));
//   };
//   private createAxiosResponse<T>(data: T | void): AxiosResponse<T> {
//     return {
//       status: HTTP_STATUS_OK,
//       statusText: "OK",
//       headers: {},
//       config: {},
//       request: {},
//       data: data as T,
//     };
//   }

//   public hasLoginCookie() {
//     return true;
//   }

//   // public login = (email: string, password: string, recaptchaToken?: string) => {
//   //     if (this.useMock("login")) {
//   //         return Promise.resolve(this.createAxiosResponse<ILoginResponse>({
//   //             authenticationToken: "fakeToken"
//   //         }));
//   //     }
//   //     else {
//   //         return this.api.login(email, password, recaptchaToken);
//   //     }
//   // }

//   public logout = async () => {
//     this.api.logout();
//   };

//   public getUser = async (fromCreateAccount = false) => {
//     return this.useMock("getUser")
//       ? Promise.resolve(this.createAxiosResponse(mockLoggedInUser))
//       : this.api.getUser(fromCreateAccount);
//   };

//   public getUserDetails = async () => {
//     return this.useMock("getUserDetails")
//       ? Promise.resolve(this.createAxiosResponse(mockUserDetails))
//       : this.api.getUserDetails();
//   };

//   public getUserCreditCardGuest = async (userGuid: string, paymentMethodId: string) => {
//     return this.useMock("getUserCreditCardGuest")
//       ? Promise.resolve(this.createAxiosResponse<IUnauthenticatedCreditCard>())
//       : this.api.getUserCreditCardGuest(userGuid, paymentMethodId);
//   };

//   public getUserPaymentHistory = async () => {
//     return this.useMock("getUserPaymentHistory")
//       ? Promise.resolve(this.createAxiosResponse<IUserPaymentHistory>(mockUserPaymentHistory))
//       : this.api.getUserPaymentHistory();
//   };

//   public getSubscriberDownloadsInfo = async () => {
//     return this.useMock("getSubscriberDownloadsInfo")
//       ? Promise.resolve(this.createAxiosResponse<ISubscriberDownloadsInfo>(mockSubscriberDownloadsInfo))
//       : this.api.getSubscriberDownloadsInfo();
//   };

//   public getSecurityQuestionsList = async (language: string) => {
//     return this.useMock("getSecurityQuestionsList")
//       ? Promise.resolve(this.createAxiosResponse<ISecurityQuestionsList>(mockPersonalInfoSecurityQuestionsList))
//       : this.api.getSecurityQuestionsList(language);
//   };

//   public getCarboniteVersionNumber = async () => {
//     return this.useMock("getCarboniteVersionNumber")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.getCarboniteVersionNumber();
//   };

//   public setSecurityQuestions = async (securityQuestions: ISecurityQuestions, language: string) => {
//     return this.useMock("setSecurityQuestions")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.setSecurityQuestions(securityQuestions, language);
//   };

//   public setDefaultCreditCard = async (creditCardId: string) => {
//     return this.useMock("setDefaultCreditCard")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.setDefaultCreditCard(creditCardId);
//   };

//   public removeCreditCard = async (creditCardId: string) => {
//     return this.useMock("removeCreditCard")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.removeCreditCard(creditCardId);
//   };

//   public removeAndReplaceCreditCard = async (creditCardId: string, newDefaultCreditCardId: string) => {
//     return this.useMock("removeAndReplaceCreditCard")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.removeAndReplaceCreditCard(creditCardId, newDefaultCreditCardId);
//   };

//   public checkExistingEmail = async (email: ICheckEmailRequest) => {
//     return this.useMock("checkExistingEmail")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.checkExistingEmail(email);
//   };

//   public getSecurityQuestions = async () => {
//     return this.useMock("getSecurityQuestions")
//       ? Promise.resolve(this.createAxiosResponse<ISecurityQuestions>())
//       : this.api.getSecurityQuestions();
//   };

//   public validateAndUpdatePassword = async (currentPassword: string, newPassword: string) => {
//     return this.useMock("validateAndUpdatePassword")
//       ? Promise.resolve(this.createAxiosResponse<boolean>())
//       : this.api.validateAndUpdatePassword(currentPassword, newPassword);
//   };

//   public updateUserPersonalInfo = async (user: IUpdateSubscriberRequest) => {
//     return this.useMock("updateUserPersonalInfo")
//       ? Promise.resolve(this.createAxiosResponse<IUpdateSubscriberResponse>())
//       : this.api.updateUserPersonalInfo(user);
//   };

//   public updateComputerDescription = async (computerId: number, computerDescription: string) => {
//     return this.useMock("updateComputerDescription")
//       ? Promise.resolve(this.createAxiosResponse<boolean>())
//       : this.api.updateComputerDescription(computerId, computerDescription);
//   };

//   public updateAutoRenewOption = async (autoRenewUpdateRequest: IAutoRenewUpdateRequest) => {
//     return this.useMock("updateAutoRenewOption")
//       ? Promise.resolve(this.createAxiosResponse<boolean>())
//       : this.api.updateAutoRenewOption(autoRenewUpdateRequest);
//   };

//   public cancelSubscription = async (subscriptionNumber: string) => {
//     return this.useMock("cancelSubscription")
//       ? Promise.resolve(this.createAxiosResponse<boolean>())
//       : this.api.cancelSubscription(subscriptionNumber);
//   };

//   // public getUserSecurityPhoneAnonymous = async (personId: number) => {
//   //     return this.useMock("getUserSecurityPhone") ?
//   //         Promise.resolve(this.createAxiosResponse<IUserSecurityPhone>(mockUserSecurityPhone)) :
//   //         this.api.getUserSecurityPhoneAnonymous(personId);
//   // }

//   public getUserSecurityPhone = async () => {
//     return this.useMock("getUserSecurityPhone")
//       ? Promise.resolve(this.createAxiosResponse<IUserSecurityPhone>(mockUserSecurityPhone))
//       : this.api.getUserSecurityPhone();
//   };

//   public requestVerificationCode = async (personId: number, method: string) => {
//     return this.useMock("requestVerificationCode")
//       ? Promise.resolve(this.createAxiosResponse<IUserSecurityPhoneRequestCode>())
//       : this.api.requestVerificationCode(personId, method);
//   };

//   public sendVerificationCode = async (personId: number, verificationCode: string) => {
//     return this.useMock("sendVerificationCode")
//       ? Promise.resolve(this.createAxiosResponse<IUserSecurityPhoneValidation>())
//       : this.api.sendVerificationCode(personId, verificationCode);
//   };

//   public setSecurityPhone = async (phone: IUserSecurityPhoneRequest) => {
//     return this.useMock("setSecurityPhone")
//       ? Promise.resolve(this.createAxiosResponse<IUserSecurityPhoneValidation>())
//       : this.api.setSecurityPhone(phone);
//   };

//   public deleteSecurityPhone = async () => {
//     return this.useMock("deleteSecurityPhone")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.deleteSecurityPhone();
//   };

//   public getPhoneCountryCodes = async () => {
//     return this.useMock("getPhoneCountryCodes")
//       ? Promise.resolve(this.createAxiosResponse<IPhoneCountryCodes[]>())
//       : this.api.getPhoneCountryCodes();
//   };

//   public getZuoraCreditCardFormParams = async (userGuid: string, formId: string) => {
//     return this.useMock("getZuoraCreditCardFormParams")
//       ? Promise.resolve(this.createAxiosResponse<IZuoraCreditCardFormParams>())
//       : this.api.getZuoraCreditCardFormParams(userGuid, formId);
//   };

//   public getZuoraCreditCardFormEmailParams = async (email: string, formId: string) => {
//     return this.useMock("getZuoraCreditCardFormEmailParams")
//       ? Promise.resolve(this.createAxiosResponse<IZuoraCreditCardFormParams>())
//       : this.api.getZuoraCreditCardFormEmailParams(email, formId);
//   };

//   public getZuoraCreditCardFormComputerIdParams = async (computerId: number, formId: string) => {
//     return this.useMock("getZuoraCreditCardFormComputerIdParams")
//       ? Promise.resolve(this.createAxiosResponse<IZuoraCreditCardFormParams>())
//       : this.api.getZuoraCreditCardFormComputerIdParams(computerId, formId);
//   };

//   public resetPassword = async (personId: number, newPassword: string) => {
//     return this.useMock("resetPassword")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.resetPassword(personId, newPassword);
//   };

//   public getWebrootUserInfo = async (webrootKey: string) => {
//     return this.useMock("getWebrootUserInfo")
//       ? Promise.resolve(this.createAxiosResponse<ICrossSitePersonResponse>())
//       : this.api.getWebrootUserInfo(webrootKey);
//   };

//   public createAccount = async (
//     email: string,
//     password: string,
//     keycode: string,
//     ecommKey: string,
//     recaptchaToken: string,
//     languageCode: string
//   ) => {
//     return this.useMock("createAccount")
//       ? Promise.resolve(this.createAxiosResponse<ITrialSignupResponse>())
//       : this.api.createAccount(email, password, keycode, ecommKey, recaptchaToken, languageCode);
//   };

//   public getCountries = async () => {
//     return this.useMock("getCountries")
//       ? Promise.resolve(this.createAxiosResponse<ICountriesInfo>())
//       : this.api.getCountries();
//   };

//   public getInvoice = async (invoiceId: string) => {
//     return this.useMock("getInvoice")
//       ? Promise.resolve(this.createAxiosResponse<IInvoiceResponse>())
//       : this.api.getInvoice(invoiceId);
//   };

//   public addKeycode = async (keycode: string, orderHeaderToken?: string) => {
//     return this.useMock("addKeycode")
//       ? Promise.resolve(this.createAxiosResponse<IAddKeycodeResponse>())
//       : this.api.addKeycode(keycode, orderHeaderToken);
//   };

//   public getSubscriberInfo = async () => {
//     return this.useMock("getSubscriberInfo")
//       ? Promise.resolve(this.createAxiosResponse<ISubscriberInfo>(mockSubscriberInfo))
//       : this.api.getSubscriberInfo();
//   };

//   public refreshSubscriber = async () => {
//     return this.useMock("refreshSubscriber")
//       ? Promise.resolve(this.createAxiosResponse<IRefreshSubscriberResponse>())
//       : this.api.refreshSubscriber();
//   };

//   public getSafeClientURL = async (request: IInstallRequest) => {
//     return this.useMock("installResponse")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.getSafeClientURL(request);
//   };

//   public getSubscriptions = async () => {
//     return this.useMock("getSubscriptions")
//       ? Promise.resolve(this.createAxiosResponse<ISubscription[]>(mockSubscriptions))
//       : this.api.getSubscriptions();
//   };

//   public releaseSubscription = async (deviceId: number) => {
//     return this.useMock("releaseSubscription")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.releaseSubscription(deviceId);
//   };

//   public getLastPassInfo = async () => {
//     return this.useMock("mockLastPassProvisionResponse")
//       ? Promise.resolve(this.createAxiosResponse<ILastPassInfo[]>())
//       : this.api.getLastPassInfo();
//   };

//   public provisionLastPass = async (keyCode: string) => {
//     return this.useMock("mockLastPassInfo")
//       ? Promise.resolve(this.createAxiosResponse<ILastPassProvisionResponse>())
//       : this.api.provisionLastPass(keyCode);
//   };

//   public getWebrootKeycode = async (deviceId: number, zuoraSubscriptionNumber: string) => {
//     return this.useMock("getWebrootKeycode")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.getWebrootKeycode(deviceId, zuoraSubscriptionNumber);
//   };

//   public getOutstandingInvoices = async () => {
//     return this.useMock("getOutstandingInvoices")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.getOutstandingInvoices();
//   };

//   public payOutstandingInvoices = async (payOutstandingInvoicesRequest: IPayOutstandingInvoicesRequest) => {
//     return this.useMock("payOutstandingInvoices")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.payOutstandingInvoices(payOutstandingInvoicesRequest);
//   };

//   public verifyActivationCode = async (activationCode: string) => {
//     return this.useMock("verifyActivationCode")
//       ? Promise.resolve(this.createAxiosResponse<IActivationCodeResponse>(mockActivationCodeResponse))
//       : this.api.verifyActivationCode(activationCode);
//   };

//   public applyActivationCode = async (activationCode: string, computerId: number) => {
//     return this.useMock("applyActivationCode")
//       ? Promise.resolve(this.createAxiosResponse<IApplyActivationCodeResponse>())
//       : this.api.applyActivationCode(activationCode, computerId);
//   };

//   public getWebrootRenewUrl = async (keycode: string) => {
//     return this.useMock("getWebrootRenewUrl")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.getWebrootRenewUrl(keycode);
//   };

//   public getAvailableActivationCodes = async () => {
//     return this.useMock("getAvailableActivationCodes")
//       ? Promise.resolve(this.createAxiosResponse<string[]>())
//       : this.api.getAvailableActivationCodes();
//   };

//   public optInToAutomaticVideoBackup = async (deviceId: number) => {
//     return this.useMock("optInToAutomaticVideoBackup")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.optInToAutomaticVideoBackup(deviceId);
//   };

//   public getConsumerVatInfoAsync = async () => {
//     return this.useMock("getConsumerVatInfoAsync")
//       ? Promise.resolve(this.createAxiosResponse<ISubscriberVatInfo>())
//       : this.api.getConsumerVatInfoAsync();
//   };

//   public setVatInfo = async (vatId: string, countryCode: string) => {
//     return this.useMock("setVatInfo")
//       ? Promise.resolve(this.createAxiosResponse<IVatInfoResponse>())
//       : this.api.setVatInfo(vatId, countryCode);
//   };

//   public putClientException = async (ce: IClientException) => {
//     return this.useMock("putClientException")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.putClientException(ce);
//   };

//   public addEncryptionKey = async (computerId: number, encryptionKey: string, encryptionKeyPassword: string | null) => {
//     return this.useMock("addEncryptionKey")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.addEncryptionKey(computerId, encryptionKey, encryptionKeyPassword);
//   };

//   public getEncryptionKeyPasswordHint = async (computerId: number) => {
//     return this.useMock("getEncryptionKeyPasswordHint")
//       ? Promise.resolve(this.createAxiosResponse<IPasswordHintResponse>())
//       : this.api.getEncryptionKeyPasswordHint(computerId);
//   };

//   public verifyEmail = async (verificationCode: string) => {
//     return this.useMock("verifyEmail")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.verifyEmail(verificationCode);
//   };

//   public sendNewVerificationEmail = async (verificationCode: string) => {
//     return this.useMock("sendNewVerificationEmail")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.sendNewVerificationEmail(verificationCode);
//   };

//   public cancelPendingEmailValidation = async () => {
//     return this.useMock("cancelPendingEmailValidation")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.cancelPendingEmailValidation();
//   };

//   public updateEmail = async (email: string, password: string) => {
//     return this.useMock("updateEmail")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.updateEmail(email, password);
//   };

//   public resendVerificationEmail = async () => {
//     return this.useMock("resendVerificationEmail")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.resendVerificationEmail();
//   };

//   public getBackupSummary = async () => {
//     return this.useMock("getBackupSummary")
//       ? Promise.resolve(this.createAxiosResponse<IBackupSummaryResponse>())
//       : this.api.getBackupSummary();
//   };

//   public getPendingVerificationEmail = async () => {
//     return this.useMock("getPendingVerificationEmail")
//       ? Promise.resolve(this.createAxiosResponse<IPendingVerificationEmailResponse>())
//       : this.api.getPendingVerificationEmail();
//   };

//   public getRfaSession = async (computerId: string) => {
//     return this.useMock("getRfaSession")
//       ? Promise.resolve(this.createAxiosResponse<IRfaSessionResponse>())
//       : this.api.getRfaSession(computerId);
//   };

//   public getSkyWebrootDevices = async () => {
//     return this.useMock("getSkyWebrootDevices")
//       ? Promise.resolve(this.createAxiosResponse<ISkyWebrootDeviceResponse>())
//       : this.api.getSkyWebrootDevices();
//   };

//   public updateWebrootDevice = async (request: IUpdateWebrootDeviceRequest) => {
//     return this.useMock("updateWebrootDevice")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.updateWebrootDevice(request);
//   };

//   public getAllstateSubscriptions = async () => {
//     return this.useMock("mockAllstateResponse")
//       ? Promise.resolve(this.createAxiosResponse<IAllstateResponse[]>())
//       : this.api.getAllstateSubscriptions();
//   };

//   public addAllstateSubscription = async (request: IAllstateRequest) => {
//     return this.useMock("mockAllstateAddResponse")
//       ? Promise.resolve(this.createAxiosResponse<IAllstateResponse>())
//       : this.api.addAllstateSubscription(request);
//   };

//   public getCssbBackupSets = async (computerName: string, sort: string, orderBy: string) => {
//     return this.useMock("mockCssbBackupSets")
//       ? Promise.resolve(this.createAxiosResponse<ICssbRfaResponse>())
//       : this.api.getCssbBackupSets(computerName, sort, orderBy);
//   };

//   public getCssbBackupRuns = async (computerName: string, backupSetName: string, sort: string, orderBy: string) => {
//     return this.useMock("mockCssbBackupRuns")
//       ? Promise.resolve(this.createAxiosResponse<ICssbRfaResponse>())
//       : this.api.getCssbBackupRuns(computerName, backupSetName, sort, orderBy);
//   };

//   public getCssbBackupRunDetails = async (request: ICssbDetailsRequest) => {
//     return this.useMock("mockCssbBackupRunsDetails")
//       ? Promise.resolve(this.createAxiosResponse<ICssbRfaResponse>())
//       : this.api.getCssbBackupRunDetails(request);
//   };

//   public addCssbDownloadInfo = async (request: ICssbDownloadInfoRequest) => {
//     return this.useMock("addCssbDownloadInfo")
//       ? Promise.resolve(this.createAxiosResponse<ICssbDownloadInfoResponse>(mockAddCssbDownloadInfo()))
//       : this.api.addCssbDownloadInfo(request);
//   };

//   public getCssbDownloadUrl = async (request: ICssbDownloadUrlRequest) => {
//     return this.useMock("getCssbDownloadUrl")
//       ? Promise.resolve(this.createAxiosResponse<ICssbDownloadInfoResponse>(mockGetCssbDownloadUrl(request)))
//       : this.api.getCssbDownloadUrl(request);
//   };

//   public cancelCssbDownload = async (request: ICssbDownloadUrlRequest) => {
//     return this.useMock("cancelCssbDownload")
//       ? Promise.resolve(this.createAxiosResponse<ICssbDownloadInfoResponse>(mockCancelCssbDownload(request)))
//       : this.api.cancelCssbDownload(request);
//   };

//   public getInProgressCssbDownloadRequests = async () => {
//     return this.useMock("getInProgressCssbDownloadRequests")
//       ? Promise.resolve(this.createAxiosResponse<ICssbRfaInProgressDownloadInfoResponse[]>(mockInProcessDownloads))
//       : this.api.getInProgressCssbDownloadRequests();
//   };

//   public getWebrootPIIInformation = async (keycode: string) => {
//     return this.useMock("mockWebrootPIIInformation")
//       ? Promise.resolve(this.createAxiosResponse<IWebrootUserInformation>())
//       : this.api.getWebrootPIIInformation(keycode);
//   };

//   public getCssbServers = async () => {
//     return this.useMock("MockCssbServers")
//       ? Promise.resolve(this.createAxiosResponse<ICssbServerName[]>())
//       : this.api.getCssbServers();
//   };

//   public getLangCookie = async () => {
//     return this.useMock("MockLanguageCookie")
//       ? Promise.resolve(this.createAxiosResponse<ILanguageResponse>())
//       : this.api.getLangCookie();
//   };

//   public setLangCookie = async (language: string) => {
//     return this.useMock("MockLanguageCookie")
//       ? Promise.resolve(this.createAxiosResponse<ILanguageResponse>())
//       : this.api.setLangCookie(language);
//   };

//   public submitCourierRecovery = async (request: ICourierRecoveryRequest) => {
//     return this.useMock("MockSubmitCourierRecovery")
//       ? Promise.resolve(this.createAxiosResponse<ICourierRecoveryResponse>())
//       : this.api.submitCourierRecovery(request);
//   };

//   public getPendingRequestToManageAsync = async () => {
//     return this.useMock("mockGetPendingRequestToManageAsync")
//       ? Promise.resolve(this.createAxiosResponse<IGetPendingRequestToManageResponse>())
//       : this.api.getPendingRequestToManageAsync();
//   };

//   public manageCustomerByResellerAsync = async (request: IManageCustomerByResellerRequest) => {
//     return this.useMock("mockManageCustomerByResellerAsync")
//       ? Promise.resolve(this.createAxiosResponse<string>())
//       : this.api.manageCustomerByResellerAsync(request);
//   };

//   public purchaseShoppingCart = async (request: IShoppingCart) => {
//     return this.useMock("mockPurchaseShoppingCart")
//       ? Promise.resolve(this.createAxiosResponse<IShoppingCart>())
//       : this.api.purchaseShoppingCart(request);
//   };

//   public saveShoppingCart = async (request: IShoppingCart) => {
//     return this.useMock("mockSaveShoppingCart")
//       ? Promise.resolve(this.createAxiosResponse<IShoppingCart>())
//       : this.api.saveShoppingCart(request);
//   };

//   public getProductCatalog = async (type: string, campaignId?: string) => {
//     return this.useMock("mockGetProductCatalog")
//       ? Promise.resolve(this.createAxiosResponse<IProductCatalogResponse>(mockPlans))
//       : this.api.getProductCatalog(type, campaignId);
//   };

//   public setLanguagePreference = async (request: ISetLanguagePreferenceRequest) => {
//     return this.useMock("mockSetLanguagePreference")
//       ? Promise.resolve(this.createAxiosResponse<void>())
//       : this.api.setLanguagePreference(request);
//   };

//   public getSubscriptionForUnauthorizedFlow = async (request: IUnauthenticatedSubscriptionRequest) => {
//     return this.useMock("mockGetSubscriptionForUnauthorizedFlow")
//       ? Promise.resolve(this.createAxiosResponse<IUnauthenticatedSubscription>())
//       : this.api.getSubscriptionForUnauthorizedFlow(request);
//   };
// }
