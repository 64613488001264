//cSpell:ignore efgh ijkl sbcd
// from https://jira.carboniteinc.com/browse/ZUOR-24939
// Good
// abcd-1234-efgh-5678-ijkl  (lower case and hyphens)
// abcd1234efgh5678ijkl (lower case and no hyphens)
// AbCd1234eFgH5678IjKL (some uppercase and lower case mixed with no hyphens)
// AbCd-1234-eFgH-5678-IjKL (some uppercase and lower case mixed with hyphens)
// Bad
// sbcd-1234-efgh-5678-ijkl. (s is not valid) not obvious why this is bad
// Sbcd1234efgh5678ijkl (S is not a valid) not obvious why this is bad
// Keycodes can only be 20 characters long, and contain only letters and numbers (excluding hyphens)

import { IValidationResult, IValidationRule, validate } from "@/common/validator";
import { WEBROOT_KEYCODE_LENGTH } from "@/define";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { alphaNumericRegex } from "@/globalRegex";
import { t } from "@/i18n";

let rules: IValidationRule<string>[] = [];

function refreshRules() {
  rules = [
    {
      name: "CommonErrors.keycodeInvalid",
      param: { length: WEBROOT_KEYCODE_LENGTH },
      test: k => k.length === WEBROOT_KEYCODE_LENGTH && alphaNumericRegex.test(k),
      description: t("WebrootKeyCodes.keyCodesOnlyLetterAndNumbers"),
    },
  ];
}

function refreshCachedStrings() {
  refreshRules();
}

registerRefreshFunction(refreshCachedStrings, true);

export function validateKeycode(keycode: string): IValidationResult[] {
  // strip of '-' and ' '
  const formattedKeycode = keycode.replace(/-/g, "")?.replace(/ /g, "");
  return validate<string>(formattedKeycode, rules);
}
