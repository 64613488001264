import { computed, ref } from "vue";
import { logEvent } from "@/common/logger";
import { t } from "@/i18n";
import { IRfaItem, IRfaSelectionInfo } from "./interfaces";
import { getSelectionInfo, getCollection } from "./serverComm";
import { convertSize, fileSelectionLimitReached, getDownloadMessage, MAX_DOWNLOAD_COUNT } from "./viewFn";
import { componentName, currentItems, isSmb } from "./commonFn";
import { registerRefreshFunction } from "@/components/shared/LanguageSelector/LanguageSelector";
import { isSearching } from "./searchFn";
import { cssbDownloadError, showEncryptedError } from "./downloadFn";
import { FLAGS } from "@/define";

export const selectedItems = ref<IRfaItem[]>([]);
export const selectionInfo = ref<IRfaSelectionInfo>();
export const showEmptyFolderError = ref<boolean>(false);
export const selectionInfoText = computed(() =>
  t(`${componentName}.selectionInfo`, {
    count: selectionInfo.value?.NumFiles,
    size: convertSize(selectionInfo.value?.EstimatedFileSize ?? 0, true),
  })
);

// *************
// selection functions
// *************

export const allSelectableItems = computed(() => {
  if (isSmb.value && isSearching.value) {
    const itemsWithGroupedItems = currentItems.value.filter(
      l => l.GroupedSearchItems && l.GroupedSearchItems.length > 0
    );
    const itemsWithoutGroupedItems = currentItems.value.filter(
      l => !l.GroupedSearchItems || l.GroupedSearchItems.length === 0
    );
    let childItems: IRfaItem[] = [];
    itemsWithGroupedItems.forEach(item => {
      childItems = [...childItems, ...item.GroupedSearchItems];
    });

    return [...childItems, ...itemsWithoutGroupedItems];
  } else {
    return currentItems.value.filter(
      i => FLAGS.ENABLE_RESTRICTED_FILES_DOWNLOAD === "true" || i.IsDownloadable || i.IsDirectory
    );
  }
});

export const isAllSelected = computed(() => {
  return selectedItems.value.length == allSelectableItems.value.length && selectedItems.value.length > 0;
});

export const isSelecting = computed(() => {
  // temporary behavior as folders do not return size, so it was decided the message should not be displayed.
  return selectedItems.value.some(item => item.IsDirectory) && isSmb.value
    ? false
    : selectedItems.value.length > 0 && selectionInfo.value;
});

export const selectionState = computed(() => {
  return selectionInfo.value
    ? selectionInfo.value.ErrorMsg || fileSelectionLimitReached.value
      ? "warning"
      : "success"
    : "";
});

export const isSelectionError = computed(() => {
  return !!selectionInfo.value?.ErrorMsg;
});

export function selectionDisplayMessage() {
  return getDownloadMessage(selectionInfo.value);
}

registerRefreshFunction(selectionDisplayMessage, true);

export function isSelected(item: IRfaItem) {
  return selectedItems.value.findIndex(i => i.FileId == item.FileId) >= 0;
}

export function isSelectedByGroupedItems(item: IRfaItem) {
  const items = selectedItems.value.filter(l => {
    return item.GroupedSearchItems.findIndex(i => i.FileId === l.FileId) >= 0;
  });

  return items.length === item.GroupedSearchItems.length;
}

export function groupedItemsSelectionChanged(e: MouseEvent, item: IRfaItem) {
  if (isSelectedByGroupedItems(item)) {
    item.GroupedSearchItems.forEach(groupedItem => {
      const index = selectedItems.value.findIndex(l => l.FileId === groupedItem.FileId);
      selectedItems.value.splice(index, 1);
    });
  } else {
    selectedItems.value = [...selectedItems.value, ...item.GroupedSearchItems];
  }

  updateSelectionInfo();
}

export async function updateSelectionInfo() {
  selectionInfo.value = undefined;
  showEmptyFolderError.value = false;
  if (isSmb.value && selectedItems.value.length) {
    selectionInfo.value = {
      ErrorMsg: "",
      EstimatedFileSize: selectedItems.value.reduce((total, item) => {
        return total + item.Size;
      }, 0),
      NumFiles: selectedItems.value.length,
    };
  } else if (selectedItems.value.length) {
    if (
      selectedItems.value.length === 1 &&
      selectedItems.value[0].IsDirectory &&
      selectedItems.value[0].FolderItemCount === 0
    ) {
      showEmptyFolderError.value = true;
    } else if (selectedItems.value.length > MAX_DOWNLOAD_COUNT) {
      logEvent("too many selected", componentName, { count: selectedItems.value.length });
      selectionInfo.value = {
        ErrorMsg: "error",
        EstimatedFileSize: 0,
        NumFiles: selectedItems.value.length,
      };
    } else {
      selectionInfo.value = await getSelectionInfo(getCollection({ items: selectedItems.value }));
    }
  } else selectionInfo.value = undefined;
}

//every time the user changes the selection we need to have the server
//recalculate the size and number of files that would be downloaded
export async function selectionChanged(e: MouseEvent, item: IRfaItem) {
  //Reset the CSSB error message when changing the selection
  cssbDownloadError.value = "";
  showEncryptedError.value = false;
  const index = selectedItems.value.findIndex(i => i.FileId == item.FileId);

  //determine if the user selected or unselected
  if (index >= 0) {
    selectedItems.value.splice(index, 1);
    setPreviousSelectedItem(undefined);
  } else {
    const previousItem = getPreviousSelectedItem();
    if (e.shiftKey && previousItem) {
      //find the index of previousSelectedItem
      const prevIndex = currentItems.value.findIndex(i => i.FileId == previousItem?.FileId);
      //find the index of the currently selected item
      const curIndex = currentItems.value.findIndex(i => i.FileId == item.FileId);
      //for all the items from the previous selection to this one,
      //add them to the selectedItems list if not already on it
      for (let i = prevIndex; i <= curIndex; i++) {
        if (
          currentItems.value[i].IsDownloadable &&
          selectedItems.value.findIndex(s => s.FileId == currentItems.value[i].FileId) < 0
        )
          selectedItems.value.push(currentItems.value[i]);
      }
    } else {
      selectedItems.value.push(item);
      if (!isSmb.value) {
        setPreviousSelectedItem(item);
      }
    }
  }

  //recalculate
  logEvent("updating selection", componentName, { item, count: selectedItems.value.length });
  updateSelectionInfo();
}

//select or unselect all
export async function selectAllChanged() {
  //Reset the CSSB error message when changing the selection
  cssbDownloadError.value = "";
  showEncryptedError.value = false;
  //if all are currently selected, unselect
  if (isAllSelected.value) {
    clearSelection();
    logEvent("clearing all selected", componentName);
  } else {
    //in any other case, select all visible items and get the info
    selectedItems.value = [...allSelectableItems.value];
    logEvent("selecting all", componentName, { count: selectedItems.value.length });
    updateSelectionInfo();
  }
}

export function clearSelection() {
  selectedItems.value = [];
  selectionInfo.value = undefined;
}

let previousSelectedItem: IRfaItem | undefined = undefined;
export function setPreviousSelectedItem(item: IRfaItem | undefined) {
  previousSelectedItem = item;
}
export function getPreviousSelectedItem(): IRfaItem | undefined {
  return previousSelectedItem;
}
