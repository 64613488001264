<template>
  <div v-if="componentState != emailPageStates.hideEmail">
    <!--header-->
    <section>
      <h1 class="opposite-ends">
        <span class="h1">{{ t("Common.email") }}</span>
        <button
          v-if="displayEditButton"
          id="editEmail"
          type="button"
          class="text-sm inline-btn-link no-shrink no-print"
          @click="editEmail()"
        >
          {{ t("Common.edit") }}
        </button>
      </h1>
    </section>
    <!--content-->
    <!--initial view-->
    <section v-if="componentState == emailPageStates.enterEmail" class="constrain-65">
      <p>{{ tn("enterYourEmail") }}</p>
      <BuyEnterEmail @next="emitResponse()" @consumer="nextPage" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mb-double" v-html="t('BuyEmailComponent.willSendEmails')"></p>
    </section>
    <!--existing email, not logged in-->
    <section v-if="componentState == emailPageStates.alreadyAnAccount">
      <div class="accent-box-info">
        {{ tn("alreadyAnAccount") }}
        <strong id="existingEmail">{{ userStore.email }}</strong>
      </div>
      <div class="card pa mb">
        <h4 class="mb">
          {{ tn("option1Heading") }}
        </h4>
        <p>
          {{ tn("option1Message") }}
        </p>
        <div v-if="showAllstateAlert" class="danger-bg">
          <p v-if="buyFlowStore.canNotAddWTPError" class="pa-half">{{ t("BuyEmailComponent.alreadyOwnWTPError") }}</p>
          <i18n-t
            v-else-if="buyFlowStore.hasWebrootPremiumInCart"
            keypath="BuyEmailComponent.allstateAlreadyIncludedError"
            tag="p"
            class="pa-half"
            scope="global"
          >
            <template #productSku>
              {{ t(getProductDisplayName(buyFlowStore?.cart?.items[0].sku || "Webroot")) }}
            </template>
            <template #webrootComplete>
              <button id="btnAddWebrootComplete" type="button" class="inline-btn-link" @click="openWebrootComplete()">
                {{ t("Brand.WebrootComplete") }}
              </button>
            </template>
          </i18n-t>
          <p v-else class="pa-half">{{ tn("allstateLimitError") }}</p>
        </div>
        <div v-if="buyFlowStore.alreadyOwnsVPNPError" class="danger-bg">
          <p v-if="buyFlowStore.canNotAddWTPError" class="pa-half">{{ t("BuyEmailComponent.alreadyOwnWTPError") }}</p>
          <p v-else class="pa-half">{{ t("BuyEmailComponent.alreadyOwnVPNPError") }}</p>
        </div>
        <div class="mb">
          <button
            type="button"
            class="btn-outline-primary mr"
            :disabled="buyFlowStore.alreadyOwnsVPNPError || showAllstateAlert || showAllstateAlreadyIncludedAlert"
            @click="continueWithoutLoggingIn()"
          >
            {{ tn("btnContinueWithoutLoggingIn") }}
          </button>
          <button
            type="button"
            class="btn-primary"
            :disabled="buyFlowStore.alreadyOwnsVPNPError || showAllstateAlert || showAllstateAlreadyIncludedAlert"
            @click="logIn()"
          >
            {{ t("Common.logIn") }}
          </button>
        </div>
      </div>
      <p class="text-center-sm">{{ tn("or") }}</p>
      <div class="card pa mb">
        <h4 class="mb">
          {{ tn("option2Heading") }}
        </h4>
        <BuyEnterEmail :hide-email="true" @next="emitResponse()" @consumer="nextPage" />
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mb-double" v-html="t('BuyEmailComponent.willSendEmails')"></p>
    </section>
    <!--email successfully entered-->
    <section v-if="componentState == emailPageStates.addedEmail">
      <p id="addedEmail" class="truncate">
        {{ userStore.currentUser?.email ?? userStore.email }}
      </p>
    </section>
  </div>
</template>

<script setup lang="ts">
//Components
import BuyEnterEmail from "./EnterEmail.vue";
//Stores
import { useUserStore } from "@/stores/user";
//Others
import { ref, watch, onBeforeMount, computed } from "vue";
import { t } from "@/i18n";
import { logEvent } from "@/common/logger";
import { changePageState } from "@/components/Buy/BuyHelper";
import { emailPageStates, openTextBrands, pageStates } from "@/components/Buy/BuyEnums";
import { getRedirectToLoginURLWithEmail } from "@/common/loginRedirect";
import { pageStates as buyFlowPageStates } from "@/components/Buy/BuyEnums";
import { useBuyFlowStore } from "@/stores/buyFlow";
import { clearSession } from "@/common/logout";
import { unifiedApi } from "@/common";
import { AxiosError } from "axios";
import { useRoute } from "vue-router";
import { useEmailPageStore } from "@/stores/emailPage";
import { useNotificationsStore } from "@/stores/notifications";
import { getProductDisplayNameBySku } from "@/common/productsBySku";
import { getUrl } from "@/common/getUrl";

const componentName = "BuyEmailComponent";
logEvent("created", componentName);
const userStore = useUserStore();
//temporary page switching mechanism
const componentState = ref<emailPageStates>(emailPageStates.enterEmail);
const buyFlowStore = useBuyFlowStore();
const editEmailClicked = ref<boolean>(false);
const route = useRoute();
const emailPageStore = useEmailPageStore();
const showAllstateAlert = ref<boolean>(false);
const showAllstateAlreadyIncludedAlert = ref<boolean>(false);
const notificationsStore = useNotificationsStore();

const deviceId = parseInt(route.query?.deviceId?.toString() || route.query?.did?.toString() || "0");

onBeforeMount(() => {
  if (
    (userStore.currentUser || (!userStore.currentUser && buyFlowStore.userGuid)) &&
    buyFlowStore.pageState === buyFlowPageStates.email &&
    buyFlowStore.currentBrand !== openTextBrands.Webroot
  ) {
    changePageState(buyFlowPageStates.payment);
  }
  calculateRendering();
});

const isNewUser = computed(() => userStore.currentUser && !userStore.currentUser?.subscriberInfo?.hasPasswordSet);

const displayEditButton = computed(
  () => (isNewUser.value || !userStore.currentUser) && componentState.value == emailPageStates.addedEmail
);

function tn(v: string, params?: Record<string, unknown>): string {
  return t(`${componentName}.${v}`, params);
}

function changeEmailState(mode) {
  componentState.value = mode;
}

function openWebrootComplete() {
  window.open(getUrl("WEBROOT_COMPLETE_URL"));
}

function getProductDisplayName(sku: string) {
  return getProductDisplayNameBySku({ sku: sku, isTrial: false });
}

async function continueWithoutLoggingIn() {
  if (userStore.currentUser) {
    try {
      await unifiedApi.silentLogout();
    } catch (error) {
      logEvent(`Error on logout: ${error as AxiosError}`, componentName);
    }
    const email = userStore.email;
    await clearSession();
    userStore.email = email;
  }

  changeEmailState(emailPageStates.addedEmail);

  if (buyFlowStore.cart) {
    buyFlowStore.cart.userEmail = userStore.email;
  }

  changePageState(pageStates.payment);
}

function logIn() {
  window.location.href = getRedirectToLoginURLWithEmail(buyFlowStore.prepareReturnUrl("buy"), userStore.email);
}

async function emitResponse() {
  //Move to next state
  changeEmailState(emailPageStates.addedEmail);
  changePageState(pageStates.payment);
}

function editEmail(clearNotifications = true) {
  emailPageStore.reset();
  editEmailClicked.value = true;
  changePageState(pageStates.email, clearNotifications);
}

function calculateRendering() {
  let state = emailPageStates.enterEmail;

  if (userStore.preliminaryUserTimedOut) {
    userStore.preliminaryUserTimedOut = false;
    changeEmailState(state);
    return;
  }

  // Edit link was clicked display enterEmail state
  // The (edit link only shows for non-established accounts or established accounts that's not logged in and have no UserGuid)
  if (editEmailClicked.value) {
    editEmailClicked.value = false;
    changeEmailState(state);
    return;
  }

  if (!isNewUser.value) {
    if (userStore.currentUser) {
      state = emailPageStates.addedEmail;
    } else if (userStore.email) {
      state = emailPageStates.alreadyAnAccount;
    }
  }
  if (buyFlowStore.pageState != buyFlowPageStates.email) {
    state = emailPageStates.addedEmail;
    if ((buyFlowStore.userGuid || deviceId > 0) && !userStore.currentUser) {
      state = emailPageStates.hideEmail;
    }
  }
  changeEmailState(state);
}

async function nextPage(isAlreadyInUse: boolean) {
  if (isNewUser.value && !isAlreadyInUse) {
    await emitResponse();
    return;
  }
  changeEmailState(emailPageStates.alreadyAnAccount);
}

function blockAllstate() {
  showAllstateAlert.value = false;
  showAllstateAlreadyIncludedAlert.value = false;
  if (buyFlowStore.hasCDLPInCart && buyFlowStore.hasAlreadyHaveAllstateError) {
    showAllstateAlert.value = true;
    notificationsStore.addNotification({ type: "WTPBuyflowLimit", dismissible: false });
  } else if (buyFlowStore.hasWebrootPremium && buyFlowStore.hasAlreadyHaveAllstateError) {
    notificationsStore.clearNotifications();
    showAllstateAlreadyIncludedAlert.value = true;
    notificationsStore.addNotification({ type: "AllstateBuyflowLimit", dismissible: false });
  } else if (buyFlowStore.hasAlreadyHaveAllstateError) {
    notificationsStore.clearNotifications();
    showAllstateAlert.value = true;
    notificationsStore.addNotification({ type: "AllstateBuyflowLimit", dismissible: false });
  }
}

watch(
  () => buyFlowStore.triggerStateChange,
  async () => {
    calculateRendering();
  }
);

watch(
  () => buyFlowStore.isCalculatingCart,
  () => {
    blockAllstate();
    if (buyFlowStore.alreadyOwnsVPNPError) {
      if (buyFlowStore.hasCDLPInCart) {
        notificationsStore.addNotification({ type: "WTPBuyflowLimit", dismissible: false });
      } else if ((buyFlowStore.cart?.items.length ?? 0) > 1) {
        notificationsStore.addNotification({ type: "VPNBuyflowLimitMultipleItems", dismissible: false });
      } else if (buyFlowStore.userGuid && !buyFlowStore.licenseKeyInfo && buyFlowStore.cart?.items.length === 1) {
        notificationsStore.addNotification({ type: "VPNBuyflowLimitSingleItemDeadEnd", dismissible: false });
      } else {
        notificationsStore.addNotification({ type: "VPNPBuyflowLimit", dismissible: false });
      }
    }
  }
);
</script>
